import React, { useState, useEffect } from 'react';
import { Button, Icon } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux"
import Logout from '../common/Logout'
import Individual from './welcomeIndividual';
import Joincode from './welcomeJoincode';
import SchoolSetup from './welcomeSchoolSetup';

export default function WelcomeChooseSchool(props) {
  const [howToJoin, setHowToJoin] = useState(null)
  const history = useHistory()
  const { setShowNavBar } = props

  // temp workaround the loading-not-recognized issue
  const { teacherData: { schoolId } } = useSelector(state => state.teacher)


  useEffect(() => {
    setShowNavBar(false)
  }, [])

  if (schoolId) {
    return <h1>{schoolId?.name} is your school</h1>
  }
  return <Joincode />

  if (howToJoin === "individual") {
    return <Individual />
  }

  if (howToJoin === "joincode") {
    return <Joincode />
  }

  if (howToJoin === "schoolsetup") {
    return <SchoolSetup />
  }

  return (
    <>
      <h1>Welcome to Kansha!</h1>

      <h3 style={{ "color": "black" }}>There are three different ways to get set up.</h3>
      <div onClick={() => setHowToJoin("joincode")} style={{ "margin": "15px" }} >
        <div> <Icon size="huge" color='purple' name='keyboard' /></div>
        <Button size="huge" color="purple">Join current school using a code</Button>
      </div>
      <div onClick={() => setHowToJoin("schoolsetup")} style={{ "margin": "15px" }}>
        <div>  <Icon size="huge" color='blue' name='users' /></div>
        <Button size="huge" color="blue">Set up a new school account</Button>

      </div>
      <div onClick={() => setHowToJoin("individual")} style={{ "margin": "15px" }}>
        <div>  <Icon size="huge" color='green' name='user circle' /></div>
        <Button size="huge" color="green">Join as an individual teacher</Button>
      </div>
      <Logout />
    </>)
};

//export default WelcomeChooseSchool;
