import React, { useState, useEffect } from 'react'
import { Grid, Button } from "semantic-ui-react"
import { useDispatch, useSelector } from "react-redux"
import { getGrouptheme, updateGrouptheme } from '../../redux/classroom/classroomActions'
import pickRandomItem from '../../utils/pickRandomItem'
import PickedItem from './currentGroupPicked'
import RenderSVG from '../common/RenderSVG'
import { useHistory } from 'react-router-dom'
//import notify from '../../utils/notify'
import { useGroupSoundEffects } from '../hooks/useGroupSoundEffects'
import speakerblack from "../../img2/png/speaker-black.png"
import speakercolor from "../../img2/png/speaker-color.png"
import speakermute from "../../img2/png/speaker-mute.png"
//import speakeroutline from "../../img2/png/speaker-outline.png"
import ReactTooltip from 'react-tooltip';
import DragonCoin from '../../img/dragonCoin'

const groupStyles = {
  border: "1px solid",
  borderRadius: "5px", padding: "5px", display: "inline"
}

const TeamworkDisplayBoard = (props) => {
  // CSS options || stackable || doubling || verticalAlign='middle' 
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    studentNames,
    teacherData,
    addGroupPointDb } = props

  const { _id: teacherId, schoolId: { coinName } } = teacherData;

  const {
    loading:
    classroomLoading,
    groupthemeData,
    groupthemeData: { groupIds, topic, audioSetting = "none" },
    groupthemeId_current: { _id: groupthemeIdCurrentId }
  } = useSelector(state => state.classroom)

  const [experimentalSoundObject] = useGroupSoundEffects(groupIds)


  const [groupPicked, setGroupPicked] = useState(null)
  const [currentPointsLocal, setCurrentPointsLocal] = useState({})
  const [currentlyHoveredOver, setCurrentlyHoveredOver] = useState(null)
  const [showNamesOnHover, setShowNamesOnHover] = useState(true)
  const [kanshaAwardNow, setKanshaAwardNow] = useState(false)
  const [speakButton, setSpeakerButton] = useState(null)


  const setLocalPointsFromDb = () => {
    const groupPointObject = {}
    groupIds && groupIds.forEach(gr => {
      groupPointObject[gr._id] = gr.points
    })
    setCurrentPointsLocal(groupPointObject)
  }

  useEffect(() => {

    setLocalPointsFromDb()

    if (audioSetting === "same") { setSpeakerButton(speakerblack) }
    if (audioSetting === "unique") { setSpeakerButton(speakercolor) }
    if (audioSetting === "none") { setSpeakerButton(speakermute) }

  }, [])



  const addGroupPointLocal = (group) => {
    // SOUND
    const { _id: groupId } = group

    if (audioSetting !== "none") {
      if (audioSetting === "same") {
        experimentalSoundObject.generic()
      }

      else {
        if (experimentalSoundObject[groupId]) {
          experimentalSoundObject[groupId]()
        } else {
          experimentalSoundObject.generic()
        }
      }
    }

    const workingPoints = { ...currentPointsLocal }
    workingPoints[group._id] = workingPoints[group._id] + 1
    setCurrentPointsLocal(workingPoints)
    addGroupPointDb(group._id)
  }


  const pickGroup = () => {
    const selectedGroup = pickRandomItem(groupIds)
    setGroupPicked(selectedGroup)
  }

  const pickAgain = () => {
    const prevGroup = groupPicked
    let newGroup = pickRandomItem(groupIds)
    while (prevGroup === newGroup) {
      newGroup = pickRandomItem(groupIds)
    }
    setGroupPicked(newGroup)
  }

  // -------------- KANSHA AWARD -------------- ///

  const kanshaAwardClickHandler = () => {
    setKanshaAwardNow(true)
    dispatch(getGrouptheme(groupthemeIdCurrentId))
  }

  const kanshaTestAfterLoading = () => {
    history.push('/kanshaaward/', {
      coinName, groupthemeIdCurrentId, studentNames, teacherId
    })
  }

  const toggleAudioSetting = () => {
    const arrayOfSettings = ["same", "unique", "none"]

    const currentAudioSettingIndex = arrayOfSettings.indexOf(audioSetting)

    let newAudioSettingIndex = currentAudioSettingIndex + 1
    if (newAudioSettingIndex > arrayOfSettings.length - 1) {
      newAudioSettingIndex = 0
    }
    const newaudioSetting = arrayOfSettings[newAudioSettingIndex]

    const updatedGrouptheme = {
      ...groupthemeData
    }
    updatedGrouptheme.audioSetting = newaudioSetting;

    if (newaudioSetting === "same") { setSpeakerButton(speakerblack) }
    if (newaudioSetting === "unique") { setSpeakerButton(speakercolor) }
    if (newaudioSetting === "none") { setSpeakerButton(speakermute) }

    dispatch(updateGrouptheme(groupthemeIdCurrentId, updatedGrouptheme))
  }

  const currentlyHoveredOverDisplay = showNamesOnHover && currentlyHoveredOver && currentlyHoveredOver.map((student) => {return student[0]})

  const groupsToDisplay = groupIds && groupIds.map(group => {
    if (group.studentIds.length === 0) return null;
    return (
      <Grid.Column
        className="center"
        onClick={() => addGroupPointLocal(group)}

        onMouseEnter={() => setCurrentlyHoveredOver(...[studentNames[group._id]])}
        
        // onMouseEnter={() => setCurrentlyHoveredOver(...[studentNames[group._id]])}

        onMouseLeave={() => setCurrentlyHoveredOver(null)}

        key={group._id}
        data-border={true} data-tip={"" +  currentlyHoveredOverDisplay?.join("\n")}
        style={{
          cursor: "pointer",
          backgroundColor: group.colorOnBoard
        }} >

        <div style={{ textAlign: "center", display: "block" }}>
          <RenderSVG
            size={"120px"}
            svgFilename={group?.displayNameGraphics?.svg} />
        </div>

        <span style={{ textAlign: "center", display: "block", margin: "5px 10px 15px", color: "black", fontSize: "1.5em", textShadow: "1px 1px 2px white", fontWeight: "bold" }}>
          {group?.displayNameGraphics?.english}
        </span>

        <span style={{ textShadow: "2px 2px 4px white", fontWeight: "bold", textAlign: "center", margin: "10px", display: "block", color: "black", fontSize: "4.5em" }}> {currentPointsLocal[group._id]}
        </span>

      </Grid.Column>
    )
  })


  let numberOfColumns = 3
  if (groupsToDisplay?.length > 4) {
    numberOfColumns = 4
  }

  if (groupPicked) {
    return (
      <PickedItem
        group={groupPicked}  // points not updated in groupPicked
        currentPointsLocal={currentPointsLocal[groupPicked._id]}// due to point issue
        setGroupPicked={setGroupPicked}
        names={studentNames[groupPicked._id]}
        addPoint={addGroupPointLocal}
        pickAgain={pickAgain}
        studentNames={studentNames}
      />)
  }

  if (!classroomLoading && kanshaAwardNow) {
    kanshaTestAfterLoading()

    return (<h1>classroomLoading: {classroomLoading} kanshaAwardNow: {kanshaAwardNow}
    </h1>)
  }


  return (
    <>
       <ReactTooltip delayShow='250' />
      <h1
        style={{ margin: "-10px 1px 10px 1px", fontWeight: "800", color: "black", fontSize: "4em", cursor: 'pointer' }}
        data-border={true}  data-tip="Toggle timer">
        {topic} Teamwork
      </h1>

      <div style={{ margin: "10px" }} >
        <Grid
          verticalAlign='middle'
          stackable
          centered
          columns={numberOfColumns}>
          {groupsToDisplay}
        </Grid>
      </div>
      <h1>{" "}</h1>

      <div>
        <Button
          circular
          size="medium"
          color="black"
          style={{  color: "white", fontSize: "1.5em" }}
          onClick={pickGroup}
          content={"Pick a Group"}
        />
      </div>
      

<div style={{margin:"3px 3px 3px 3px"}} onClick={kanshaAwardClickHandler}>
<DragonCoin size={"5em"} />
</div>

      {/* <div>
        <Button 
          circular
          size="large"
          color="white"
          style={{ color: "black", fontSize: "1.5em" }}
          onClick={kanshaAwardClickHandler}
          // content={coinName ? "Award " + coinName + "s!" : "Award!"}
           >
            {coinName ? "Award Time" + coinName + "s!" : "Award!"} 
            </Button>
      </div> */}
      
      <br />
      <img src={speakButton} onClick={toggleAudioSetting} alt="speaker button" width="40" height="40" />

      <br />
      <br />
    </>
  )
}

export default TeamworkDisplayBoard
