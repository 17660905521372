import React, { useState } from 'react';
import { Button, Container } from 'semantic-ui-react'
import RenderSVG from '../common/RenderSVG';
import { useHistory } from 'react-router-dom'
import { useModal } from "react-modal-hook";
import ReactModal from "react-modal";
import Lottie from 'react-lottie'
import logo2 from '../../img2/lottie/logos/kanshaSwirlGreen.json'
import sadFace from '../../img2/lottie/ui/872-empty-list.json'
import useSound from 'use-sound';
import whoosh from '../../sound/whoosh.mp3'
import { processStudentDonation } from "../../redux/classroom/classroomActions"
import { useDispatch, useSelector } from "react-redux"


const Contribute = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [donationAmount, setDonationAmount] = useState(null)
  const [playWhoosh] = useSound(whoosh);

  const {
    //commonGoodsAll,
    commonGoodMostRecent,
    onCompleteRoute,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)

  const { state: { cg, studentData } } = props.history.location

  const { currentCoin } = props
  const maxNumberOfDonations = 5
  const donationButtons = []
  const amountRemaining = cg?.targetAmount - cg?.currentDonatedAmount



  const cancelDonation = () => {
    hideConfirmModal()
    history.push('/commongoodsstudent/')
  }

  const askToConfirmDonation = (data) => {
    setDonationAmount(data)
    showConfirmModal()
  }


  const [showConfirmModal, hideConfirmModal] = useModal(() => {

    const confirmDonation = () => {
      playWhoosh()

      hideConfirmModal()
      const transferObject = {
        studentId: studentData._id,
        commonGoodId: cg._id,
        amount: donationAmount
      }
      dispatch(processStudentDonation(transferObject))
    }

    // add insufficient funds

    if (studentData?.coins - donationAmount < 0) {


      return (
        <ReactModal isOpen >
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: sadFace,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />

          <h1>Sorry, insufficient {currentCoin}s</h1>
          <h2>You only have {studentData?.coins}</h2>
          <br />
          <br />
          <div>
            <Button color="grey"
              size="large"
              onClick={cancelDonation}
            >Return</Button>
          </div>
        </ReactModal>
      )
    }


    return (
      <ReactModal isOpen >
        <Container style={{ "border": "5px solid green" }}>
          <h1 style={{ "color": "black" }}>Please confirm the donation of {donationAmount} {currentCoin}s for {cg.title}</h1>
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: logo2,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />
          <h3 style={{ "color": "black" }}> You will have {studentData?.coins - donationAmount} {currentCoin}s remaining</h3>

          <Button color="green" size="large" onClick={confirmDonation}>Donate</Button>
          <Button color="red" size="large" onClick={cancelDonation}>Cancel</Button>
          <br />
          <br />
        </Container>
      </ReactModal >
    )

  }, [donationAmount, cg] // how to close modal and include 
  );




  for (let i = 0; i < maxNumberOfDonations; i++) {
    if (amountRemaining === i) break; // limit hit

    if (i === maxNumberOfDonations - 1) { // max donation reached
      donationButtons.push(
        <Button onClick={() => askToConfirmDonation(i + 1)} size="large">{i + 1} = Max! </Button>
      )
      break;
    }
    donationButtons.push(<Button onClick={() => askToConfirmDonation(i + 1)} size="large">{i + 1}</Button>)
  }

  // when donation is processed
  if (onCompleteRoute !== null && !classroomLoading) {
    if (commonGoodMostRecent && commonGoodMostRecent?.youDidIt) {

      console.log(" 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔 🍟 🍔")
      history.push('/commongoodsstudentgoalreached', { cg, studentData, showConfetti: true })
    } else {
      history.push(onCompleteRoute)
    }
  }

  if (classroomError) {
    return (<>
      <h1>Oops! We have an error</h1>
      <h6>{classroomError?.data?.message}</h6>
    </>)
  }

  return (<>

    <h1 style={{ "fontSize": "5em" }}>{cg?.title}</h1>
    {cg.image
      ? <RenderSVG
        size={"150"}
        svgFilename={cg.image.svg} />
      : null}
    <h1 style={{ "fontSize": "2em" }}>Description: {cg?.description}</h1>
    <h3 style={{ "margin": "5px" }}>Price: {cg?.targetAmount}</h3>
    <h3 style={{ "margin": "5px" }}>Current Donations: {cg?.currentDonatedAmount}</h3>

    <h3 style={{ "margin": "5px" }}>Amount Remaining: {amountRemaining}</h3>
    <h3>{" "}</h3>
    <h1 style={{ "fontSize": "2em" }}>How many {currentCoin}s would you like to donate?
    </h1>
    {donationButtons}
    <h3>{" "}</h3>
    <br />
  </>
  );
}


export default Contribute;





//     history.push('/commongoodsstudent/') -- direct pagfe

