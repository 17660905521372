import React, { useState } from 'react';
import { Menu, Popup, Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import RenderSVG from '../common/RenderSVG';

// const PopupExample = () => (
//   <Popup content='Add users to your feed' trigger={<Button icon='add' />} />
// )
//compact

const NavStudentSecond = () => {
  const [activeItem, setActiveItem] = useState('home');
  return (
    <Menu widths={4} color='red'  >
      <Menu.Item
        className="menu-button"
        name='exchange'
        active={activeItem === 'studentexchange'}
        onClick={() => setActiveItem('studentexchange')}
        as={Link}
        to={"/studentexchange"}>
        <RenderSVG
          style={{ position: "block" }}
          size={"30"}
          svgFilename={"ui/navbar/exchange"} /> Exchange
      </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='community'
        active={activeItem === 'commongoods'}
        onClick={() => setActiveItem('commongoods')}
        as={Link}
        to={"/commongoodsstudent"}>
        <RenderSVG
          size={"30"}
          svgFilename={"ui/navbar/block"} />
        Common Goods
      </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='raffle'
        active={activeItem === 'raffle'}
        onClick={() => setActiveItem('raffle')}
        as={Link}
        to={"/raffle"}>
        <RenderSVG
          size={"50"}
          svgFilename={"ui/navbar/tickets"} /> Raffle</Menu.Item>

      <Menu.Item
        className="menu-button"
        name='School store'
        active={activeItem === 'store'}
        onClick={() => setActiveItem('store')}
        as={Link}
        to={"/schoolstorestudent"}>
        <RenderSVG
          size={"30"}
          svgFilename={"ui/navbar/store"} />Store</Menu.Item>
    </Menu >

  );
}

export default NavStudentSecond;
