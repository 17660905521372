import React, { useState } from "react";
import { Button, Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import Lottie from 'react-lottie'
import logo2 from '../../img2/lottie/logos/kanshaSwirlGreen.json'
import sadFace from '../../img2/lottie/ui/872-empty-list.json'
import { useModal } from "react-modal-hook";
import ReactModal from "react-modal";
import transferSuccessGraphic from '../../img2/lottie/ui/7679-sending-success.json'
import { adjustStudentCoinsByTransfer } from "../../redux/student/studentActions"
import { createTransaction } from "../../redux/classroom/classroomActions"
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import useSound from 'use-sound';
import whoosh from '../../sound/whoosh.mp3'
import blop from '../../sound/blop.mp3'
import notify from '../../utils/notify'


const AmountToSend = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentCoin } = props
  const { state: { _id: receiverId, displayName: receiverDisplayName, avatar: receiverAvatar } = {} } = history.location

  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState(1);
  const [animationTransferGraphic, setAnimationTransferGraphic] = useState(null);

  const [playWhoosh] = useSound(whoosh);
  const [playBlop] = useSound(blop);

  const {
    // loading: profileLoading,
    // error: profileError,
    studentData,
    studentData: { _id: senderId, coins: senderBalance, displayName }
  } = useSelector(state => state.student)

  const { _id: classroomId, schoolId } = useSelector(state => state.classroom)


  const setTokenAmount = (tokens) => {
    if (tokens < 1) { return }
    setAmount(tokens)
  }

  const handleSubmitTransfer = () => {
    if (message === '') {
      notify({ message: "Please enter a message.", position: "TOP_CENTER", noticeType: "warning" })
      playBlop()
      return
    }
    showConfirmModal()
  }

  const confirmTransfer = () => {

    const transferObject1 = {
      senderId,
      receiverId,
      message,
      amount
    }

    const transferObject2 = {
      amount,
      message,
      senderId,
      senderClassroomId: classroomId,
      senderSchoolId: schoolId?._id,
      receiverId
    }

    playWhoosh()

    // 1 - UPDATE ACCOUNTS
    dispatch(adjustStudentCoinsByTransfer(transferObject1))
    // LATER: change this to check store for errors
    // setAnimationTransferGraphic("confirmed")
    // setAnimationTransferGraphic("error")


    // 2 - UPDATED THE LEDGER
    dispatch(createTransaction(transferObject2))

    // 3 - UI
    hideConfirmModal()
    setAnimationTransferGraphic(true)
  }

  const cancelTransfer = () => {
    hideConfirmModal()
    history.push('/studentexchange/')
  }

  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    // insufficient funds
    if (senderBalance - amount < 0) {

      return (
        <ReactModal isOpen >
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: sadFace,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />

          <h1>Sorry, insufficient {currentCoin}s</h1>
          <h2>You only have {senderBalance}.</h2>
          <br />
          <br />
          <div>
            <Button color="grey"
              size="large"
              onClick={cancelTransfer}
            >Return</Button>
          </div>
        </ReactModal>
      )
    }

    return (
      <ReactModal isOpen >
        <Container style={{ "border": "5px solid green" }}>
          <h1 style={{ "color": "black" }}>Please confirm the transfer</h1>
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: logo2,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />
          <h3 style={{ "margin": "2px" }}>Receiver: {receiverDisplayName}</h3>
          <h3 style={{ "margin": "2px" }}>Sender: {displayName}</h3>
          <h3 style={{ "margin": "2px" }}>Amount: {amount} {currentCoin}{amount === 1 ? null : "s"}</h3>
          <h3 style={{ "margin": "2px" }}>Message: {message}</h3>
          <h3 style={{ "color": "black" }}> You will have {senderBalance - amount} {currentCoin}s remaining</h3>
          <Button color="green" size="large" onClick={confirmTransfer}>Send</Button>
          <Button color="red" size="large" onClick={cancelTransfer}>Cancel</Button>
          <br />
          <br />
        </Container>
      </ReactModal >
    )
  },
    [studentData, amount, message, receiverDisplayName, senderBalance]
  );

  if (animationTransferGraphic) {
    return (
      <>
        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: transferSuccessGraphic,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
            //,viewBox: "0 0 300 300"
          }} />
        <h1>Transfer Complete!</h1>
      </>
    )
  }


  return (<>
    <h1 style={{ "color": "black" }}>Send {currentCoin}s to</h1>

    <RenderAvatar
      size={100}
      avatarId={receiverAvatar.avatarId}
      setOfAvatars={superHeros} />

    <h2>{receiverDisplayName}</h2>
    <>
      <label>Amount of {currentCoin}s</label>
      <input
        type="number"
        pattern="^-?[0-9]\d*\.?\d*$"
        required
        value={amount}
        onChange={(e) => setTokenAmount(e.target.value)}
      />
      <label>Message:</label>
      <input
        type="text"
        required
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <br />
      <br />
      <Button
        size="huge"
        color="green"
        onClick={() => handleSubmitTransfer()}
      >Send {amount} {currentCoin}s</Button>
    </>
    <br />
    <br />

  </>);
}

export default AmountToSend;

