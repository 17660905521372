import React, { useReducer } from 'react'
import _ from 'lodash'
import { Table, Button, Icon } from 'semantic-ui-react'
import abbreviate from "../../utils/tokenAbbrev"
import googleLogo from "../../img2/png/google-logo.png"
import moment from 'moment';
import pdfIcon from '../../img2/ui/general/pdfIcon.svg'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

function sortingReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

function SortableTable(props) {
  const { data: studentInformation, showStudentDetails, coinName, removeStudentFromClass, showStudentProfile, setSelectedStudent, studentAccessoryData_LOCAL, setStudentAccessoryData_LOCAL, classroomName, toggleShowDetails } = props

  const studentOrderedByFirst = _.sortBy(studentInformation, function (stud) { return stud.firstName })

  const [state, dispatch] = useReducer(sortingReducer, {
    column: null,
    data: studentOrderedByFirst,
    direction: null,
  })

  const { column, data, direction } = state

  // make usePDF hook
  const pdfOfList = () => {

    const studentDisplayNames = studentInformation.map(student => student.displayName).join("\n")

    const studentEmails = studentInformation.map(student => student.email).join("\n")

    const studentTokens = studentInformation.map(student => student.coins).join("\n")


    const docDefinition = {
      content: [
        { text: classroomName, fontSize: 20, bold: true },
        { text: "  ", fontSize: 10 },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: 'auto',
              text: "Name \n" + studentDisplayNames
            },
            {
              // auto-sized columns have their widths based on their content
              width: 'auto',
              text: "Emails: \n" + studentEmails
            },
            {
              // auto-sized columns have their widths based on their content
              width: 'auto',
              text: coinName + "s \n" + studentTokens
            },
            // {
            //   // star-sized columns fill the remaining space
            //   // if there's more than one star-column, available width is divided equally
            //   width: '*',
            //   text: extraColumnText
            // },
            // {
            //   // fixed width
            //   width: 100,
            //   text: 'Third column'
            // },
            // {
            //   // % width
            //   width: '20%',
            //   text: extraColumnText
            // }
          ],
          // optional space between columns
          columnGap: 10
        },
        { text: "  ", fontSize: 10 },
        { text: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"), fontSize: 12 },


      ]
    };



    pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).download();


  }

  const handleClickShowProfile = (student) => {
    setSelectedStudent(student)
    showStudentProfile()
  }

  const giveAccessToExchange = (student) => {
    //console.log("GIVE", student)
    const sad = { ...studentAccessoryData_LOCAL }

    if (sad[student._id]) {
      sad[student._id].exchangeAccessRevoked = false
    } else {
      sad[student._id] = { exchangeAccessRevoked: false }
    }

    setStudentAccessoryData_LOCAL(sad)
    console.log("sad", sad)
  }

  const revokeAccessToExchange = (student) => {
    //console.log("REVOKE", student)
    const sad = { ...studentAccessoryData_LOCAL }

    if (sad[student._id]) {
      sad[student._id].exchangeAccessRevoked = true
    } else {
      sad[student._id] = { exchangeAccessRevoked: true }
    }

    setStudentAccessoryData_LOCAL(sad)

  }


  return (
    <>
      <Table sortable celled >
        <Table.Header columns={6}>
          <Table.Row>
            <Table.HeaderCell
              width={3}
              sorted={column === 'firstName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firstName' })}>
              First Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={3}
              sorted={column === 'lastName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lastName' })}>
              Last Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={column === 'email' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'email' })}>
              Email
            </Table.HeaderCell>

            <Table.HeaderCell>
              <span
                style={{ "fontSize": "em" }}
                width={1}
                sorted={column === 'coins' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'coins' })}>
                {abbreviate(coinName) + " " || "K$ "}
              </span>

              {showStudentDetails
                ?
                <span>
                  <Icon color="blue" size="large" name='unhide' onClick={toggleShowDetails} />
                </span>
                :
                <span>
                  <Icon color="grey" size="large" name='hide' onClick={toggleShowDetails} />
                </span>}

            </Table.HeaderCell>
            {/*          <Table.HeaderCell
            onClick={() => console.log("TOGGLE ALL/NO CHECKINS")} >
            Check-ins
          </Table.HeaderCell> */}

            <Table.HeaderCell
              width={1}
              style={{ "fontSize": "1em" }}
              onClick={() => console.log("TOGGLE ALL/NO GAMES")} >
              Exchange
            </Table.HeaderCell>

            <Table.HeaderCell
              width={1}
              style={{ "fontSize": ".5em" }}
            >
              Remove
            </Table.HeaderCell>
            {/* <Table.HeaderCell
            onClick={() => console.log("TOGGLE ALL/NO GAMES")} >
            Remove
          </Table.HeaderCell> */}

          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((student) => {
            const { firstName, lastName, coins, _id, email, google_uid } = student

            const exchangeButton = (studentAccessoryData_LOCAL && studentAccessoryData_LOCAL[_id]?.exchangeAccessRevoked
              ?
              <Button
                onClick={() => giveAccessToExchange(student)}
                basic size="tiny"
                color="red"
                content="Denied" />
              : <Button
                onClick={() => revokeAccessToExchange(student)}
                basic size='tiny'
                color="green"
                content="Access" />)

            return (
              <Table.Row key={_id} style={{ cursor: "pointer" }}>
                <Table.Cell onClick={handleClickShowProfile.bind(null, student)}>{firstName}</Table.Cell>
                <Table.Cell onClick={handleClickShowProfile.bind(null, student)}>{lastName}</Table.Cell>
                <Table.Cell
                  onClick={handleClickShowProfile.bind(null, student)}>{google_uid ? <img src={googleLogo} alt="GL" width="15" height="15" /> : null} {email}</Table.Cell>
                <Table.Cell
                  onClick={handleClickShowProfile.bind(null, student)}>{showStudentDetails ? coins : null}</Table.Cell>
                {/*   <Table.Cell><Button color="green">Check-in</Button></Table.Cell> */}
                <Table.Cell>
                  {exchangeButton}
                </Table.Cell>
                <Table.Cell onClick={() => removeStudentFromClass(firstName, lastName, _id)}>❌</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <h3>Total students: {data && data.length}</h3>
      <br />
      <img src={pdfIcon} height="30" alt="React Logo" onClick={pdfOfList} />
      <br />
    </>
  )
}


export default SortableTable
