import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button, Card } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createGroup } from '../../redux/classroom/classroomActions'
import RenderSVG from '../common/RenderSVG'
import teamObjects from '../../data/teamObjects.json'
import ReactTooltip from 'react-tooltip';
import pickRandomItem from '../../utils/pickRandomItem'
import cssColors from '../../data/colorsCSS'
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css'

export default function GroupListsAddGroup(props) {
  const { state: groupthemeData } = props.history.location
  const currentIconTheme = groupthemeData.groupIds[0].displayNameGraphics.svg.split("/")[0]
  // const { topic } = groupthemeData

  const [availableTeamsObjects, setAvailableTeamsObjects] = useState(teamObjects)
  const [availableThemes, setAvailableThemes] = useState(Object.keys(teamObjects))
  const [selectedTheme, setSelectedTheme] = useState(currentIconTheme)
  const [newGroupDisplayNameGraphic, setNewGroupDisplayNameGraphic] = useState(null)


  const dispatch = useDispatch()
  const history = useHistory()

  const { error, loading, onCompleteRoute } = useSelector(state => state.classroom)


  useEffect(() => {
    const newGroup = {
      english: availableTeamsObjects[selectedTheme][0].english,
      spanish: null,
      svg: availableTeamsObjects[selectedTheme][0].svg, svgAttribution: 'www.abc.com',
      lottie: null,
      colorOnBoard: pickRandomItem(cssColors)
    }
    setNewGroupDisplayNameGraphic(newGroup)
  }, [selectedTheme])

  const saveNewGroup = () => {
    const newGroup = {
      displayNameGraphics: newGroupDisplayNameGraphic,
      colorOnBoard: newGroupDisplayNameGraphic.colorOnBoard, // colorOnBoard pulled out to oun key; later this needs to be fixed
      points: 0,
      studentIds: [],
      teacherNotes: "",
      groupthemeId: groupthemeData._id
    }

    dispatch(createGroup(groupthemeData._id, newGroup))
  };

  const updateAvatar = (newAvatar) => {
    const updatedGroup = { ...newAvatar, ...{ colorOnBoard: newGroupDisplayNameGraphic.colorOnBoard } }
    setNewGroupDisplayNameGraphic(updatedGroup)
  }

  const updateBackgroundColor = () => {
    const newColor = pickRandomItem(cssColors)
    const updatedGroup = { ...newGroupDisplayNameGraphic, ...{ colorOnBoard: newColor } }
    setNewGroupDisplayNameGraphic(updatedGroup)
  }


  const listOfThemeTitles = availableThemes.map(th => {
    return (<Button inverse color="black" onClick={() => setSelectedTheme(th)}>{th}</Button>)
  })


  const setOfAvatars = availableTeamsObjects[selectedTheme] && availableTeamsObjects[selectedTheme].map(avatar => {
    return (<Card onClick={() => {
      updateAvatar(avatar)
    }}>
      <RenderSVG size="35" svgFilename={avatar.svg} />
    </Card>)
  })

  const handleChange = (e, setFn) => {
    const updatedGroupDisplayNameGraphic = { ...newGroupDisplayNameGraphic, ...{ english: e.target.value } }
    setFn(updatedGroupDisplayNameGraphic);
  };


  const showAvailableIcons = () => {
    return (
      <div>
        <Card.Group centered itemsPerRow={8}>
          {setOfAvatars}
        </Card.Group>
      </div>
    )
  }

  if (onCompleteRoute) {

    // BUILD ADD STUDENTS COMPONENT
    // const newGroup = {
    //   displayNameGraphics: {
    //     english: availableTeamsObjects[selectedTheme][0].english, spanish: null, svg: availableTeamsObjects[selectedTheme][0].svg, svgAttribution: 'www.abc.com', lottie: null,
    //     colorOnBoard: "blue"
    //   },
    //   points: 0,
    //   studentIds: [],
    //   teacherNotes: "",
    //   groupthemeId: groupthemeData._id
    // }
    // history.push("/groupslistaddstudents", newGroup)


    history.push("/groupslist")
  }

  if (loading) { return <h1>Loading</h1> }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }

  return (<>
    <h1> {newGroupDisplayNameGraphic && <EditText
      name="textbox2"
      style={{
        padding: '10px',
        color: "black",
        fontSize: '1.5em'
      }}
      defaultValue={newGroupDisplayNameGraphic?.english}
      value={newGroupDisplayNameGraphic?.english}
      onChange={(e) => handleChange(e, setNewGroupDisplayNameGraphic)} />}
    </h1>

    {newGroupDisplayNameGraphic &&
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <ReactTooltip />
        <div
          style={{
            backgroundColor: newGroupDisplayNameGraphic?.colorOnBoard,
            width: "35%"
          }}
          data-tip="Change Background Color"
          onClick={updateBackgroundColor} >

          <div style={{
            padding: "20px"
          }}>
            <RenderSVG
              size={"200px"}
              svgFilename={newGroupDisplayNameGraphic?.svg}
            />
          </div>
        </div>
      </div>
    }
    <br />
    <Button
      size="large"
      color="green"
      style={{ fontSize: "1.5em" }}
      onClick={saveNewGroup}>
      Create {newGroupDisplayNameGraphic?.english} Group
    </Button>
    <br />
    <br />

    <div>
      {availableTeamsObjects[selectedTheme]
        ? showAvailableIcons()
        : null}
      <br />
      {listOfThemeTitles}
      <br />
      <br />
      <Button
        onClick={() => history.push("/groupslist")}
        size="mini">Cancel
      </Button>
    </div>

  </>)
}
