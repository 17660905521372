import React, { useState, useEffect } from 'react';
//import kanshaLogo from "../../img2/png/kansha-logo.png"

const RenderGif = (props) => {
  const { size = 50, filename } = props
  let [icon, setIcon] = useState('');

  useEffect(() => {
    async function makeItHappen() {
      let importedIcon = await import(`../../images/${filename}.gif`);
      setIcon(importedIcon.default);
    }
    makeItHappen()
  }, [filename, size]);
  return (<>
    <img
      // onError={kanshaLogo}
      height={size}
      width={size}
      alt={filename}
      src={icon}
    />
  </>)
};

export default RenderGif;

