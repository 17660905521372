import _ from 'lodash'
import React from 'react'
import { Grid } from 'semantic-ui-react'

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}

const GridExampleColoredColumn = () => (
  <Grid columns={2} padded>
    {colors.map((color, i) => (
      <Grid.Column color={randomColor()} key={i}>
        {_.capitalize(color)}
      </Grid.Column>
    ))}
  </Grid>
)

export default GridExampleColoredColumn