import React, { useState, useEffect } from 'react';
import { Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import UserContainer from '../common/UserContainer'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import group from "../../img2/png/group.png"


const NavStudentMain = (props) => {
  const [activeItem, setActiveItem] = useState('home');
  const { currentCoin, setCurrentCoin } = props

  const [currentStudentGroup, setCurrentStudentGroup] = useState(null)
  const [currentStudentCoins, setCurrentStudentCoins] = useState(null)
  // const [studentNavDisplay, setStudentNavDisplay] = useState(currentStudentCoins)


  const {
    studentData: { coins }
  } = useSelector(state => state.student)

  const {
    name: classroomName } = useSelector(state => state.classroom)

  useEffect(() => {
    setCurrentStudentCoins(coins)
  }, [coins])

  useEffect(() => {
    setCurrentStudentGroup(classroomName)
  }, [classroomName])

  const { userType, googlePhotoURL } = props
  const userTypeDisplay = capitalizeFirstLetter(userType)
  const userDashboard = "/" + userType + "dashboard"
  const coinDisplay = currentCoin + "s " + currentStudentCoins;

  return (

    <Menu
      color='white'
      widths={4}
      inverted
      style={{
        backgroundColor: "grey",
        fontWeight: "bold"
      }} >

      <Menu.Item
        className="menu-button"
        name='home'
        active={activeItem === 'home'}
        onClick={() => setActiveItem('home')}
        as={Link}
        to={userDashboard}>

        <UserContainer
          fontSize="1em"
          color="white"
          pretext={userTypeDisplay + ": "}
          posttest={" "} />
        {googlePhotoURL
          ? <img src={googlePhotoURL} alt="name" />
          : null}
      </Menu.Item>

      <Menu.Item
        className="menu-button"
        name={coinDisplay}
        active={activeItem === coinDisplay}
        onClick={() => setActiveItem(coinDisplay)}
        as={Link}
        to={"/mykanshatokens"} >

        {/* 
        <img src={group} alt="speaker button" width="40" height="40" /> */}
        <div>
          {coinDisplay}
        </div>
      </Menu.Item>
      {/* <Menu.Item
        className="menu-button"
        name={currentCoin + "s " + currentStudentCoins}
        active={activeItem === 'home'}
        onClick={() => setActiveItem('home')}
        as={Link}
        to={"/mykanshatokens"} /> */}

      <Menu.Item
        className="menu-button"
        name={currentStudentGroup}
        active={activeItem === currentStudentGroup}
        onClick={() => setActiveItem(currentStudentGroup)}
        as={Link}
        to={"/currentgroupdisplay"}
      />
      {/* <Menu.Item
        className="menu-button"
        name={currentStudentGroup}
        active={activeItem === 'home'}
        onClick={() => setActiveItem('home')}
        as={Link}
        to={"/classroomsdisplaystudent"}
      /> */}

      <Menu.Item
        className="menu-button"
        name={'bonus'}
        active={activeItem === 'bonus'}
        onClick={() => setActiveItem('bonus')}
        as={Link}
        to={"/bonus"}
      />

    </Menu>

  );
}

export default NavStudentMain;
