//import React from 'react';
import { useGoogleLogout } from 'react-google-login';

const clientId = process.env.REACT_APP_GOOGLE_AUTH

function LogoutHook() {
  const onLogoutSuccess = (res) => {
    console.log('Logged out Success ✌✌');
  };

  const onFailure = () => {
    console.log('Handle failure cases');
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return signOut


}

export default LogoutHook;