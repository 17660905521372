import React, { useState } from 'react';
import RenderAllAvatars from "../common/RenderAllAvatars"
import RenderAvatar from '../common/RenderAvatar'
import { updateTeacherProfile } from '../../redux/teacher/teacherActions'
import { useDispatch } from "react-redux"
import { superHeros } from "../../img/avatars/superHeros"
import { Button } from 'semantic-ui-react'


const WelcomeNewTeacher = (props) => {
  const dispatch = useDispatch()
  const [selectedAvatarId, setSelectedAvatar] = useState(null)
  const [buttonDetails, setButtonDetails] = useState([null, null])
  const { data: { _id, firstName }, signOut } = props

  const handleAvatarSelection = (avatarId) => {
    const colors = ["purple", "blue", "green", "yellow", "orange"];
    const sayings = ["Click here to choose me", "I am the one!", "How about me?", "Choose me!", "Think about it.", "ME!", "We're a match!", "We kind of look alike, right?", "I think you should pick me", "Just click and we're one."];

    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    const randomSaying = sayings[Math.floor(Math.random() * sayings.length)];

    setButtonDetails([randomColor, randomSaying])
    setSelectedAvatar(avatarId)
  }



  const confirmAvatarSelection = (avatarId) => {
    const updatedAvatar = {
      avatar: {
        new: false,
        avatarSet: "kckid01", // change to teacher set
        avatarId: avatarId,
        dateUpdated: Date.now()
      },
      isNewTeacherAccount:false
    }
    dispatch(updateTeacherProfile(_id, updatedAvatar))
  }

  return (
    <>
      <h2 style={{fontSize:"1em"}}>{firstName}, begin by picking an avatar </h2>
{      selectedAvatarId && 
          <RenderAvatar
            className="center"
            size={100}
            setOfAvatars={superHeros}
            avatarId={selectedAvatarId}
          />}
                <RenderAllAvatars
        size="50"
        superHeros={superHeros}
        handleAvatarSelection={handleAvatarSelection}
      />


      {
        selectedAvatarId &&
          <Button
            className="center"
            onClick={() => confirmAvatarSelection(selectedAvatarId)}
            color={buttonDetails[0]}
            size='massive'
          > {buttonDetails[1]}</Button>
      }
    </>
  )
}

export default WelcomeNewTeacher
