import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const StudentRoute = ({ component: RouteComponent, ...rest }) => {
  const user = useSelector(state => state.auth.user)
  const studentData = useSelector(state => state.student)

  const { userType } = user || {}
  return (
    <Route {...rest}
      render={routeProps =>
        !user || userType !== "student"
          ? (<Redirect to={"/login"} />)
          : (<RouteComponent {...routeProps} {...rest} studentDataFromProps={studentData} />)
      }
    />
  )
}

export default StudentRoute