import React from 'react';
import { Card } from 'semantic-ui-react'
//import RenderSVG from '../common/RenderSVG';
import Gif from '../common/gif'

const RaffleTicketDisplay = (props) => {
  const { studentTickets, studentData: { _id: studentId } } = props

  // const PrizesCard = (props) => {
  //   const { header = "header", description = "description", name = "name", content1 = "content" } = props
  //   return (
  //     <Card>
  //       <Card.Content header={header} />
  //       <Card.Content description={description} />
  //       <Card.Content extra>
  //         <Icon name={name} />{content1}
  //       </Card.Content>
  //     </Card>
  //   )
  // }

  let raffleTickets = 0
  let itemsWon = 0

  const ticketDisplay = studentTickets?.map(raffleTicket => {
    if (!raffleTicket.prizeId) {
      raffleTickets++
      return (
        <Card>
          <h6 key={raffleTicket.id} style={{ fontSize: "1em", margin: "1px" }}>{raffleTicket.id}
          </h6>
        </Card>
      )
    }
    return null
  })

  const prizesWonDisplay = studentTickets?.map(raffleTicket => {
    if (raffleTicket.prizeId) {
      itemsWon++

      return (
        <>
          <br />
          <Card >
            <h6 style={{ fontSize: "1em", margin: "1px" }}>{raffleTicket.prizeTitle}</h6>
            {raffleTicket?.prizeImage?.gif
              ?
              <Gif id={raffleTicket.prizeImage.gif} size={50} />
              : null}
            <br />
          </Card>
        </>
      )
    }
    return null
  })

  return (<>
    <h1 style={{ fontSize: "3em", color: "black" }} >You have {raffleTickets} Raffle Tickets</h1 >
    <Card.Group itemsPerRow={8}>
      {ticketDisplay}
    </Card.Group>
    <br />

    {itemsWon > 0
      ? (<>
        <h1 style={{ fontSize: "3em", color: "green" }} >  You have won {itemsWon} prize{itemsWon?.length > 0 ? null : "s"}!</h1 >
        <Card.Group itemsPerRow={8}>
          {prizesWonDisplay}
        </Card.Group>
      </>)
      : null
    }
  </>)
}

export default RaffleTicketDisplay;

