import React from 'react';
import { Grid, Button } from "semantic-ui-react"
import './groups.css'
// on database

const currentGroupThemeSelectedByTeacher = {
  topic: "Math Groups",
  classroomId: "_____",
  dateCreated: "lkashdlaks",
  createdBy: "TeacherID",
  groupIds: ["uid111", "uid2222", "uid333", "uid444"]
}

const kawching = () => {
  console.log("Screen changes and shows from most to least")
  console.log("ANIMTION: Individuals in the top group see their names")
  console.log("the amount of points is turned into __-coins for each")
  console.log("All groups return to zero. ")
  console.log("Message from teacher or generic message is shown encouraging cooperation ")
}


/* NEXT:
- seed groupsDb collection
- route to save groupPoints
- button / route clear and "pay the winners"
- teacher: createGroups and the groupTheme <set></set>up
- MOSH NODE! */

const groupsPopulatedFromGroupTheme = [
  {
    _id: "uid111",
    name: "bulls",
    color: "purple",
    currentPoints: 10,
    groupAvatar: "groupavatarId/url",
    pointsLastCleared: "date",
    members: ["studentId1", "studentId2", "studentId3"],
    cheers: "mp3 url",
    misc: {}
  },
  {
    _id: "uid2222",
    name: "parrots",
    color: "red",
    currentPoints: 5,
    groupAvatar: "groupavatarId/url",
    pointsLastCleared: "date",
    members: ["studentId1", "studentId2", "studentId3"],
    cheers: "mp3 url",
    misc: {}
  },
  {
    _id: "uid333",
    name: "vacas",
    color: "yellow",
    currentPoints: 3,
    groupAvatar: "groupavatarId/url",
    pointsLastCleared: "date",
    members: ["studentId1", "studentId2", "studentId3"],
    cheers: "mp3 url",
    misc: {}

  },
  {
    _id: "uid444",
    name: "bluebirds",
    color: "blue",
    currentPoints: 2,
    groupAvatar: "groupavatarId/url",
    pointsLastCleared: "date",
    members: ["studentId1", "studentId2", "studentId3"],
    cheers: "mp3 url",
    misc: {}
  }
]



const handleClick = (group) => {
  console.log(group._id)
  console.log(group)
  // dispatch addGroupPoint
}

const groupsToDisplay = groupsPopulatedFromGroupTheme.map(group => {
  return (
    <Grid.Column className="center" onClick={() => handleClick(group)} color={group.color} key={group._id} style={{ textColor: "black", fontSize: "2em" }}>
      {group.name} {" "} {group.currentPoints}

    </Grid.Column>
  )
})

const groupsDisplayTest = () => (
  // stackable
  // doubling 
  // verticalAlign='middle' 
  <>
    <h1>{currentGroupThemeSelectedByTeacher.topic}</h1>
    <Grid verticalAlign='middle' stackable centered columns={4}>
      {groupsToDisplay}
      <Button size="huge" style={{ textColor: "black", fontSize: "2em" }} className="center" onClick={kawching}>Show Gratitude</Button>
    </Grid>
  </>
)

export default groupsDisplayTest
