import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import logo2 from '../../img2/lottie/logos/kanshaSwirlGreen.json'
import sadFace from '../../img2/lottie/ui/872-empty-list.json'
import { studentRafflePurchase } from "../../redux/classroom/classroomActions"
import { getStudentBalance } from "../../redux/student/studentActions"
import { useDispatch, useSelector } from "react-redux"
import useSound from 'use-sound';
import whoosh from '../../sound/whoosh.mp3'
import { SimpleSpinner } from "../common/Spinners"

const RaffleBuyTickets = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    _id: classroomId,
    raffleTicketIds,
    onCompleteRoute,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)

  const { studentData, reduxResponseData: balance } = useSelector(state => state.student)

  const [playWhoosh] = useSound(whoosh);

  const { state: { pricePerTicket, amountOfTicketsToBuy } } = props.history.location
  const totalCost = amountOfTicketsToBuy * pricePerTicket
  const { currentCoin } = props

  // compare balance to amount of purchase 
  useEffect(() => {
    dispatch(getStudentBalance(studentData._id))
  }, [])

  const cancelRafflePurchase = () => {
    history.push('/raffle')
  }

  const confirmRafflePurchase = () => {
    playWhoosh()
    const transferObject1 = {
      student: studentData,
      classroomId,
      amountOfTicketsToBuy,
      totalCost,
      raffleTicketIds
    }
    dispatch(studentRafflePurchase(transferObject1))
  }


  if (classroomLoading || balance === null) return <SimpleSpinner />
  if (classroomError) return <h1>Oops! We have an error</h1>
  if (onCompleteRoute !== null && !classroomLoading) {
    history.push(onCompleteRoute)
  }

  if (balance >= totalCost) {
    return (<div>
      <h1 style={{ color: "black", fontSize: "3em" }}>Please confirm you want to trade {totalCost} {currentCoin}s for {amountOfTicketsToBuy} raffle tickets.</h1>
      <Lottie
        width={250}
        height={250}
        options={{
          loop: false,
          autoplay: true,
          animationData: logo2,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
      />
      <h3 style={{ "color": "black" }}> You will have {balance - totalCost} {currentCoin}s remaining</h3>

      <Button color="green" size="large"
        onClick={confirmRafflePurchase}
      >Yes</Button>
      <Button color="red" size="large"
        onClick={cancelRafflePurchase}
      >No</Button>
      <br />
      <br />
    </div>
    )
  } else {
    return (
      <div>
        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: sadFace,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />

        <h1>Sorry, insufficient funds</h1>

        {balance === 0 ? <h2>You have no {currentCoin}s </h2> : <h2>You only have {balance} {currentCoin}{balance > 1 ? "s" : null}</h2>}

        <br />
        <br />
        <div>
          <Button color="grey"
            size="large"
            onClick={cancelRafflePurchase}
          >Return</Button>
        </div>
      </div>
    )
  }
}


export default RaffleBuyTickets;


