import React, { useEffect, useState } from 'react';
import { Container, Button } from "semantic-ui-react"
import { useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
// import { createPhysicalGood, getAllPhysicalGoods, deletePhysicalGood, } from '../../redux/admin/adminActions'
import PhysicalGoodsDisplayBoard from './PhysicalGoodsDisplayBoard'
import RenderSVG from '../common/RenderSVG';


const SchoolStore = () => {
  const {
    error: adminLoadingError,
    loading: adminLoading,
    adminData,
    physicalGoodsAll,
    physicalGoodMostRecent
  } = useSelector(state => state.admin)

  const {
    displayName,
    avatar,
    schoolId_current } = adminData || {}

  const { _id: schoolId, name: schoolName } = schoolId_current || {}

  const schoolImage = schoolId_current?.image?.svg

  const history = useHistory()

  const editItem = (pg) => {
    history.push("/physicalgoodedit", pg)
  }

  const viewItem = (pg) => {
    history.push("/physicalgoodadminview", pg)
  }

  const handleAddPhysicalClick = () => {
    history.push("/physicalgoodcreate")
  }

  if (!schoolId){
    return (<>
        <h1>GRAPHIC</h1>
    <h2>Please select a school.</h2>
    <Button color="blue" inverted onClick={()=>history.push("/schoolsdisplay")}>All Schools</Button>
    </>)
  }

  return (
    <Container>
      <h1 style={{ margin: "10px 5px 0px 5px" }}> {schoolName} Store</h1>

      {schoolImage &&
        <RenderSVG
          size="150"
          svgFilename={schoolImage} />}
      <br />
      <br />
      <br />
      <PhysicalGoodsDisplayBoard
        physicalGoodsAll={physicalGoodsAll}
        viewItem={viewItem}
        editItem={editItem}
        schoolId={schoolId} />
      <br />
      <Button
      size="huge"
      type="submit"
      inverted color='blue'
      onClick={handleAddPhysicalClick}>Add Item</Button>
      {/* <Button>Add Virtual Item</Button> */}
      <br />
      <br />
    </Container>
  )
};
export default SchoolStore;
