import firebase from '../../config/fbConfig'
import * as types from './studentTypes'
import http from '../../services/httpService'
import _ from 'lodash'

const apiEndpoint = "/students/"


export const clearStudent = () => {
  return {
    type: types.CLEAR_STUDENT
  }
}
export const clearStudentRouting = () => {
  return {
    type: types.CLEAR_STUDENT_ROUTING
  }
}



const getStudentProfileRequest = () => {
  return {
    type: types.GET_STUDENT_PROFILE_REQUEST
  }
}
const getStudentProfileSuccess = students => {
  return {
    type: types.GET_STUDENT_PROFILE_SUCCESS,
    payload: students
  }
}
const getStudentProfileFailure = error => {
  return {
    type: types.GET_STUDENT_PROFILE_FAILURE,
    payload: error
  }
}
// returns a function rather than an action
// recieves dispatch method as argument
export const getStudentProfile = (fb_uid) => {
  return (dispatch) => {
    dispatch(getStudentProfileRequest())
    http.get(apiEndpoint + fb_uid)
      .then(response => {
        const profile = response.data
        dispatch(getStudentProfileSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getStudentProfileFailure(errorMsg))
      })
  }
}




// involving firebase
const updateStudentProfileRequest = () => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_REQUEST
  }
}
const updateStudentProfileSuccess = student => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_SUCCESS,
    payload: student
  }
}
const updateStudentProfileFailure = error => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_FAILURE,
    payload: error
  }
}


export const updateStudentProfile = (_id, updatedData) => {
  return async (dispatch) => {

    dispatch(updateStudentProfileRequest())

    try {
      const user = firebase.auth().currentUser;

      if (updatedData.email) {
        // 1a) firebase - email
        await user.updateEmail(updatedData.email)
      }

      // 1b) firebase - displayName
      await user.updateProfile({ displayName: updatedData.displayName })

      // 2) Mongo
      const url = "/students/updateStudentProfile/" + _id;
      const { data: updatedProfile } = await http.patch(url, updatedData)

      dispatch(updateStudentProfileSuccess(updatedProfile))
    }

    catch (err) {
      const errorMsg = err.message
      dispatch(updateStudentProfileFailure(errorMsg))
    }
  }
}




// regular update - use same reducer as original
const updateStudentProfileRequest_2 = () => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_REQUEST
  }
}
const updateStudentProfileSuccess_2 = student => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_SUCCESS,
    payload: student
  }
}
const updateStudentProfileFailure_2 = error => {
  return {
    type: types.UPDATE_STUDENT_PROFILE_FAILURE,
    payload: error
  }
}

export const updateStudentProfile_2 = (_id, updatedData) => {
  return async (dispatch) => {

    dispatch(updateStudentProfileRequest_2())

    try {

      const url = "/students/updateStudentProfile/" + _id;
      const { data: updatedProfile } = await http.patch(url, updatedData)

      dispatch(updateStudentProfileSuccess_2(updatedProfile))
    }

    catch (err) {
      const errorMsg = err.message
      dispatch(updateStudentProfileFailure_2(errorMsg))
    }
  }
}



const checkClassCodeRequest = () => {
  return {
    type: types.CHECK_CLASS_CODE_REQUEST
  }
}
const checkClassCodeSuccess = classroom => {
  return {
    type: types.CHECK_CLASS_CODE_SUCCESS,
    payload: classroom
  }
}
const checkClassCodeFailure = error => {
  return {
    type: types.CHECK_CLASS_CODE_FAILURE,
    payload: error
  }
}

export const checkClassCode = (studentId, joincode) => {

  return async (dispatch) => {
    dispatch(checkClassCodeRequest())
    try {
      // 1) check if class exists
      const url1 = "/classrooms/joincode/" + joincode;
      const { data: classroomToJoin } = await http.get(url1)

      classroomToJoin.studentIds.forEach((st => {
        if (st._id === studentId) {
          throw new Error("User already in class")
        }
      }))

      dispatch(checkClassCodeSuccess(classroomToJoin))
    }
    catch (err) {
      console.log("=====>>>>", err, err?.code)
      dispatch(checkClassCodeFailure(err))
    }
  }
}

// JOIN_CLASS_WITH_CODE_REQUEST


const joinClassWithCodeRequest = () => {
  return {
    type: types.JOIN_CLASS_WITH_CODE_REQUEST
  }
}
const joinClassWithCodeSuccess = classroom => {

  // console.log("classroom payload ==>", classroom)
  return {
    type: types.JOIN_CLASS_WITH_CODE_SUCCESS,
    payload: classroom
  }
}
const joinClassWithCodeFailure = error => {
  return {
    type: types.JOIN_CLASS_WITH_CODE_FAILURE,
    payload: error
  }
}

// Future issue: How to rollback half-way executed series of db writes

export const joinClassWithCode = (studentId, classToJoin) => {

  return async (dispatch) => {
    dispatch(joinClassWithCodeRequest())
    try {
      // 1) push studentId onto classroomIds arr
      const url1 = "/classrooms/addstudenttoclassroom/" + classToJoin._id;


      const studentToAdd = { studentId: studentId }
      const { data: updatedClassroomData } = await http.patch(url1, studentToAdd)


      // 2) push classroomID onto studentIds arr - STUDENT
      const url2 = "/students/addclassroomtostudent/" + studentId;
      const classroomIdToAdd = { classroomId: updatedClassroomData._id }
      await http.patch(url2, classroomIdToAdd)



      // 3) set student classroomId_current AND set school
      const url3 = "/students/updateStudentProfile/" + studentId;


      const addCurrentClassroomAndSchool = {
        classroomId_current: updatedClassroomData._id,
        schoolId: classToJoin.schoolId
      }
      //console.log("addCurrentClassroomAndSchool", addCurrentClassroomAndSchool)

      const { data: updatedStudentData } = await http.patch(url3, addCurrentClassroomAndSchool)
      // console.log("1--updatedClassroomData", updatedClassroomData)

      dispatch(joinClassWithCodeSuccess(updatedStudentData))
    }

    catch (err) {
      console.log(err)
      dispatch(joinClassWithCodeFailure(err.message))
    }
  }


}





/// ------- TEST REMOVAL -------- ///

// const getClassroomRequestStudent = () => {
//   return {
//     type: types.GET_CLASSROOM_REQUEST_STUDENT
//   }
// }
// const getClassroomSuccessStudent = classroomData => {
//   return {
//     type: types.GET_CLASSROOM_SUCCESS_STUDENT,
//     payload: classroomData
//   }
// }
// const getClassroomFailureStudent = error => {
//   return {
//     type: types.GET_CLASSROOM_FAILURE_STUDENT,
//     payload: error
//   }
// }
// // returns a function rather than an action
// // recieves dispatch method as argument
// export const getClassroomStudent = (classroomId) => {
//   return (dispatch) => {
//     dispatch(getClassroomRequestStudent())
//     const url = "/classrooms/"
//     http.get(url + classroomId)
//       .then(response => {
//         const classroom = response.data

//         dispatch(getClassroomSuccessStudent(classroom))
//       })
//       .catch(error => {
//         const errorMsg = error.message
//         dispatch(getClassroomFailureStudent(errorMsg))
//       })
//   }
// }


// --------  NEW -------- ///

const addGroupToStudentRequest = () => {
  return {
    type: types.ADD_GROUP_TO_STUDENT_REQUEST
  }
}
const addGroupToStudentSuccess = classroom => {

  console.log("classroom payload ==>", classroom)
  return {
    type: types.ADD_GROUP_TO_STUDENT_SUCCESS,
    payload: classroom
  }
}
const addGroupToStudentFailure = error => {
  return {
    type: types.ADD_GROUP_TO_STUDENT_FAILURE,
    payload: error
  }
}

// Future issue: How to rollback half-way executed series of db writes

export const addGroupToStudent = (studentId, classToJoin) => {

  return async (dispatch) => {
    dispatch(addGroupToStudentRequest())
    /*     try {
          // 1) push studentId onto classroomIds arr
          const url1 = "/classrooms/addstudenttoclassroom/" + classToJoin._id;
          const studentToAdd = { studentId: studentId }
          const { data: updatedClassroomData } = await http.patch(url1, studentToAdd)
    
    
          // 2) push classroomID onto studentIds arr - STUDENT
          const url2 = "/students/addclassroomtostudent/" + studentId;
          const classroomIdToAdd = { classroomId: updatedClassroomData._id }
          console.log("url2", url2)
          console.log("classroomIdToAdd", classroomIdToAdd)
          await http.patch(url2, classroomIdToAdd)
          //console.log("2----updatedClassroomData", updatedClassroomData)
    
    
          // 3) set classroomId_current - STUDENT
          const url3 = "/students/updateStudentProfile/" + studentId;
          console.log("url3===>", url3)
          const settingCurrentClassroom = { classroomId_current: updatedClassroomData._id }
          console.log("settingCurrentClassroom", settingCurrentClassroom)
    
          const { data: updatedStudentData } = await http.patch(url3, settingCurrentClassroom)
    
          dispatch(addGroupToStudentSuccess(updatedStudentData))
        }
    
        catch (err) {
          console.log(err)
          dispatch(addGroupToStudentFailure(err.message))
        }
    
         */
  }
}



const removeGroupFromStudentRequest = () => {
  return {
    type: types.ADD_GROUP_TO_STUDENT_REQUEST
  }
}
const removeGroupFromStudentSuccess = classroom => {

  console.log("classroom payload ==>", classroom)
  return {
    type: types.ADD_GROUP_TO_STUDENT_SUCCESS,
    payload: classroom
  }
}
const removeGroupFromStudentFailure = error => {
  return {
    type: types.ADD_GROUP_TO_STUDENT_FAILURE,
    payload: error
  }
}

// Future issue: How to rollback half-way executed series of db writes

export const removeGroupFromStudent = (studentId, classToJoin) => {

  return async (dispatch) => {
    dispatch(removeGroupFromStudentRequest())
    /*     try {
          // 1) push studentId onto classroomIds arr
          const url1 = "/classrooms/addstudenttoclassroom/" + classToJoin._id;
          const studentToAdd = { studentId: studentId }
          const { data: updatedClassroomData } = await http.patch(url1, studentToAdd)
    
    
          // 2) push classroomID onto studentIds arr - STUDENT
          const url2 = "/students/addclassroomtostudent/" + studentId;
          const classroomIdToAdd = { classroomId: updatedClassroomData._id }
          console.log("url2", url2)
          console.log("classroomIdToAdd", classroomIdToAdd)
          await http.patch(url2, classroomIdToAdd)
          //console.log("2----updatedClassroomData", updatedClassroomData)
    
    
          // 3) set classroomId_current - STUDENT
          const url3 = "/students/updateStudentProfile/" + studentId;
          console.log("url3===>", url3)
          const settingCurrentClassroom = { classroomId_current: updatedClassroomData._id }
          console.log("settingCurrentClassroom", settingCurrentClassroom)
    
          const { data: updatedStudentData } = await http.patch(url3, settingCurrentClassroom)
    
          dispatch(addGroupToStudentSuccess(updatedStudentData))
        }
    
        catch (err) {
          console.log(err)
          dispatch(addGroupToStudentFailure(err.message))
        }
    
         */
  }
}


// ############################################## //


const adjustStudentCoinsByTransferRequest = () => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_TRANSFER_REQUEST
  }
}
const adjustStudentCoinsByTransferSuccess = (nada) => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_TRANSFER_SUCCESS,
    payload: nada
  }
}
const adjustStudentCoinsByTransferFailure = (error) => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_TRANSFER_FAILURE,
    payload: error
  }
}

export const adjustStudentCoinsByTransfer = ({ senderId, amount, receiverId = null, message = null }) => {

  return async (dispatch) => {
    dispatch(adjustStudentCoinsByTransferRequest());

    // 1a) SENDER'S BALANCE REDUCED
    const url1 = "/students/increasestudentcoins/" + senderId;
    const { data: updatedSenderObject } = await http.patch(url1, { coins: -amount })

    try {
      // IF NO RECEIVER, THEN THIS IS A PURCHASE
      if (!receiverId) {
        console.log("This is a purchase and not a transfer between students")
        dispatch(adjustStudentCoinsByTransferSuccess([updatedSenderObject, null]))

      } else {
        // STUDENT TO STUDENT TRANSER

        // 2) Receiever - positive
        const url2 = "/students/increasestudentcoins/" + receiverId;
        const updatedReceiverObject = await http.patch(url2, {
          coins: amount
        })

        // 3) Update classroom object 
        // const addToLedger = {}
        dispatch(adjustStudentCoinsByTransferSuccess([updatedSenderObject, updatedReceiverObject]));
      }
    }

    catch (err) {
      dispatch(adjustStudentCoinsByTransferFailure(err));
    }
  }
}




const getStudentBalanceRequest = () => {
  return {
    type: types.GET_STUDENT_BALANCE_REQUEST
  }
}
const getStudentBalanceSuccess = amount => {
  return {
    type: types.GET_STUDENT_BALANCE_SUCCESS,
    payload: amount
  }
}
const getStudentBalanceFailure = error => {
  return {
    type: types.GET_STUDENT_BALANCE_FAILURE,
    payload: error
  }
}

export const getStudentBalance = (id) => {
  return (dispatch) => {
    dispatch(getStudentBalanceRequest())
    http.get(apiEndpoint + "balance/" + id)
      .then(response => {
        const amount = response.data
        dispatch(getStudentBalanceSuccess(amount))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getStudentBalanceFailure(errorMsg))
      })
  }
}







/* 

const adjustStudentCoinsByDonationRequest = () => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_DONATION_REQUEST
  }
}
const adjustStudentCoinsByDonationSuccess = (nada) => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_DONATION_SUCCESS,
    payload: nada
  }
}
const adjustStudentCoinsByDonationFailure = (error) => {
  return {
    type: types.ADJUST_STUDENT_COINS_BY_DONATION_FAILURE,
    payload: error
  }
}

export const adjustStudentCoinsByDonation = ({ senderId, amount, commonGoodId, message = "" }) => {
  console.log("{ senderId, amount, commonGoodId, message''}")
  console.log({ senderId, amount, commonGoodId, message })

  // push studentId onto CommonGood array of doners and add amount to current donations


  return async (dispatch) => {
    dispatch(adjustStudentCoinsByDonationRequest());

    try {
      // 1a) SENDER - negative
      const url1 = "/students/increasestudentcoins/" + senderId;
      const updatedSenderObject = await http.patch(url1, {
        coins: -amount
      })

      //   // 2) Receiever - positive
      //   const url2 = "/students/increasestudentcoins/" + receiverId;
      //   const updatedReceiverObject = await http.patch(url2, {
      //     coins: amount
      //   })

      //   // 3) Update classroom object 
      //   const addToLedger = {}
      //   dispatch(adjustStudentCoinsByDonationSuccess([updatedSenderObject, updatedReceiverObject]));
    }

    catch (err) {
      dispatch(adjustStudentCoinsByDonationFailure(err));
    }

  }
}
 */


/// ######  REMOVE CLASSROOM FROM STUDENT ####### ///
// This used only if entire classroom is deleted.
// Removing a student from a class that is not deleted is handled by classroomAction removeStudentFromClassroom")

const removeClassroomFromStudentRequest = () => {
  return {
    type: types.REMOVE_CLASSROOM_FROM_STUDENT_REQUEST
  }
}
const removeClassroomFromStudentSuccess = studentData => {
  return {
    type: types.REMOVE_CLASSROOM_FROM_STUDENT_SUCCESS,
    payload: studentData
  }
}
const removeClassroomFromStudentFailure = error => {
  return {
    type: types.REMOVE_CLASSROOM_FROM_STUDENT_FAILURE,
    payload: error
  }
}

// SOLUTION?  invoke this is classroomAction can not be degbuued
export const removeClassroomFromStudent = (classroomIdToRemove, studentId) => {
  return async (dispatch) => {
    dispatch(removeClassroomFromStudentRequest())
    try {

      // #1a - remove classroom from student
      const url2 = "/students/removeclassroomfromstudent/" + studentId
      const classroomToRemove = { classroomId: classroomIdToRemove }
      const { data: updatedStudentData } = await http.patch(url2, classroomToRemove)

      // if classroom removed is also curent class
      if (updatedStudentData.classroomId_current === classroomIdToRemove) {

        const url3 = "/students/updateStudentProfile/" + studentId;
        const { data: updatedStudentData2 } = await http.patch(url3, { classroomId_current: null })
        dispatch(removeClassroomFromStudentSuccess(updatedStudentData2))

      } else {
        // if classroom removed is not current class
        dispatch(removeClassroomFromStudentSuccess(updatedStudentData))
      }

    }

    catch (err) {
      console.log(err)
      dispatch(removeClassroomFromStudentFailure(err.message))
    }
  }
}

