import * as types from './superTypes'

const initSuper = {
  loading: false,
  students: [],
  teachers: [],
  admins: [],
  error: ''
}


function superReducer(state = initSuper, action) {
  switch (action.type) {

    case types.CLEAR_SUPER:
      return {
        ...state,
        loading: false,
        students: [],
        teachers: [],
        admins: [],
        error: ''

      };

    case types.GET_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload,
        error: '',
      };

    case types.GET_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload
      };


    case types.GET_TEACHERS_REQUEST:
      return {
        ...state,
        loading: true,
        teachers: []
      };

    case types.GET_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        teachers: action.payload,
        error: ''
      };

    case types.GET_TEACHERS_FAILURE:
      return {
        ...state,
        loading: false,
        teachers: [],
        error: action.payload
      };


    case types.GET_ADMINS_REQUEST:
      return {
        ...state,
        loading: true,
        admins: []
      };

    case types.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        error: ''
      };

    case types.GET_ADMINS_FAILURE:
      return {
        ...state,
        loading: false,
        admins: [],
        error: action.payload
      };


    case types.INCREASE_STUDENT_COINS_REQUEST:
      return {
        ...state,
        loading: true
        //students: []
      };

    case types.INCREASE_STUDENT_COINS_SUCCESS:
      return {
        ...state,
        loading: false,
        // student: ______, ** NEED TO ADD newly updated student objoect to the array of students add updated student object to the array of objects. The issue is the order of the array would change.
        error: ''
      };

    case types.INCREASE_STUDENT_COINS_FAILURE:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload
      };


    default:
      return state;
  }
}

export default superReducer