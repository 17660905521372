export const GET_TEACHER_PROFILE_REQUEST = "GET_TEACHER_PROFILE_REQUEST"
export const GET_TEACHER_PROFILE_SUCCESS = "GET_TEACHER_PROFILE_SUCCESS"
export const GET_TEACHER_PROFILE_FAILURE = "GET_TEACHER_PROFILE_FAILURE"

export const CREATE_TEACHER_PROFILE_REQUEST = "CREATE_TEACHER_PROFILE_REQUEST"
export const CREATE_TEACHER_PROFILE_SUCCESS = "CREATE_TEACHER_PROFILE_SUCCESS"
export const CREATE_TEACHER_PROFILE_FAILURE = "CREATE_TEACHER_PROFILE_FAILURE"

export const UPDATE_TEACHER_PROFILE_REQUEST = "UPDATE_TEACHER_PROFILE_REQUEST"
export const UPDATE_TEACHER_PROFILE_SUCCESS = "UPDATE_TEACHER_PROFILE_SUCCESS"
export const UPDATE_TEACHER_PROFILE_FAILURE = "UPDATE_TEACHER_PROFILE_FAILURE"

export const DELETE_TEACHER_PROFILE_REQUEST = "DELETE_TEACHER_PROFILE_REQUEST"
export const DELETE_TEACHER_PROFILE_SUCCESS = "DELETE_TEACHER_PROFILE_SUCCESS"
export const DELETE_TEACHER_PROFILE_FAILURE = "DELETE_TEACHER_PROFILE_FAILURE"

export const CREATE_NEW_CLASS_REQUEST = "CREATE_NEW_CLASS_REQUEST"
export const CREATE_NEW_CLASS_SUCCESS = "CREATE_NEW_CLASS_SUCCESS"
export const CREATE_NEW_CLASS_FAILURE = "CREATE_NEW_CLASS_FAILURE"

export const DELETE_CLASS_REQUEST = "DELETE_CLASS_REQUEST"
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS"
export const DELETE_CLASS_FAILURE = "DELETE_CLASS_FAILURE"


export const INCREASE_STUDENT_COINS_BY_TEACHER_REQUEST = "INCREASE_STUDENT_COINS_BY_TEACHER_REQUEST"
export const INCREASE_STUDENT_COINS_BY_TEACHER_SUCCESS = "INCREASE_STUDENT_COINS_BY_TEACHER_SUCCESS"
export const INCREASE_STUDENT_COINS_BY_TEACHER_FAILURE = "INCREASE_STUDENT_COINS_BY_TEACHER_FAILURE"

export const DECREASE_STUDENT_COINS_BY_TEACHER_REQUEST = "DECREASE_STUDENT_COINS_BY_TEACHER_REQUEST"
export const DECREASE_STUDENT_COINS_BY_TEACHER_SUCCESS = "DECREASE_STUDENT_COINS_BY_TEACHER_SUCCESS"
export const DECREASE_STUDENT_COINS_BY_TEACHER_FAILURE = "DECREASE_STUDENT_COINS_BY_TEACHER_FAILURE"

export const CHECK_SCHOOL_CODE_REQUEST = "CHECK_SCHOOL_CODE_REQUEST"
export const CHECK_SCHOOL_CODE_SUCCESS = "CHECK_SCHOOL_CODE_SUCCESS"
export const CHECK_SCHOOL_CODE_FAILURE = "JOIN_SCHOOL_WITH_CODE_FAILURE"

export const JOIN_SCHOOL_WITH_CODE_REQUEST = "JOIN_SCHOOL_WITH_CODE_REQUEST"
export const JOIN_SCHOOL_WITH_CODE_SUCCESS = "JOIN_SCHOOL_WITH_CODE_SUCCESS"
export const JOIN_SCHOOL_WITH_CODE_FAILURE = "JOIN_SCHOOL_WITH_CODE_FAILURE"

export const UPDATE_TEACHER_NAVBAR = "UPDATE_TEACHER_NAVBAR"
export const CLEAR_TEACHER_NAVBAR = "CLEAR_TEACHER_NAVBAR"

export const CLEAR_TEACHER_ROUTING = "CLEAR_TEACHER_ROUTING"
export const CLEAR_TEACHER = "CLEAR_TEACHER"
