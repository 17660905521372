const abbreviation = (name) => {

  const dividedName = name.split(" ")

  if (dividedName.length === 1) return name[0].toUpperCase() + "$"
  let abbrev = ""
  for (let index = 0; index < dividedName.length; index++) {
    abbrev = abbrev + dividedName[index][0].toUpperCase();
  }

  return abbrev + "$"
}

export default abbreviation