import React from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { SimpleSpinner } from "../common/Spinners"
import RenderSVG from '../common/RenderSVG';


export default function CommonGoodsView(props) {
  const { state: refunds } = props.history.location || {}

  const history = useHistory()

  if (!refunds || refunds.length === 0) {
    console.log("⭐️⭐️⭐️⭐️⭐️== BACK TO TEACHER ==>")
    history.push('/commongoodsteacher')
  }

  //console.log("🌵🌵🌵🌵🌵 state ===>", state)

  const { error, loading } = useSelector(state => state.classroom)

  const refundDisplay = refunds.map(item => {
    return (<h3>{item.displayName} {" "} {item.contribution} tokens</h3>);

  })


  if (loading) { return <SimpleSpinner /> }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }

  return (
    <>
      < h1>The following students have been given refunds:</h1>
      {refundDisplay}
    </>)
}




