import React, { useEffect } from 'react';
import { Card } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux"
import Gif from '../common/gif'
import { getAllSchoolwidePhysicalGoodsClassroom } from '../../redux/classroom/classroomActions'

const DisplayCard = (props) => {
  const { pg, viewItem, studentPurchases } = props
  return (
    <Card key={pg._id}
      style={{
        // margin: "1px",
        padding: "0px",
        fontSize: ".5em"
      }}
    >
      <div
        onClick={() => viewItem(pg)}
        style={{ cursor: 'pointer' }}
      >
        <h1 >
          {pg.name}
        </h1>
        {pg?.image?.gif
          ? <Gif id={pg.image.gif} />
          : null}
        <h2 style={{
          color: "orange",
          margin: "1px"
        }}>{pg.description}</h2>
        <h3 style={{
          color: "black",
          fontSize: "3em",
          margin: "1px"
        }}>{pg.price}</h3>

        {pg.quantityRemaining > 0
          ? <h3 style={{
            color: "black",
            margin: "1px"
          }}>Amount remaining: {pg.quantityRemaining}
          </h3>
          : <h3 style={{
            color: "red",
            fontSize: "2.5em",
            margin: "1px"
          }}>SOLD OUT
          </h3>
        }
        {studentPurchases}
      </div>
    </Card>
  )
}

const PhysicalGoodDisplayBoard = (props) => {
  const { viewItem, schoolId, studentId, showOnlyCurrentlyAvailable } = props

  const {
    error,
    loading,
    _id: classroomId,
    schoolwidePhysicalGoods,
    schoolwidePhysicalGoodMostRecent

  } = useSelector(state => state.classroom)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllSchoolwidePhysicalGoodsClassroom(schoolId))
  }, [dispatch, schoolId, schoolwidePhysicalGoodMostRecent])

  let itemsPerRow = 4

  if (schoolwidePhysicalGoods && schoolwidePhysicalGoods?.length < 4) {
    itemsPerRow = 3
  }

  const physicalGoodsAll = schoolwidePhysicalGoods?.map(pg => {
    if (pg.archived) return null
    let studentPurchases = null

    if (pg?.purchaserIds.some(item => item._id === studentId)) {
      const numberPurchased = pg.purchaserLedger[studentId]?.quantityPurchased
      studentPurchases = <h6 style={{ color: "blue", fontSize: "2em" }}>{numberPurchased} PURCHASED!</h6>
    }

    return <DisplayCard
      pg={pg}
      viewItem={viewItem}
      studentPurchases={studentPurchases}
    />

  })

  const physicalGoodsStocked = schoolwidePhysicalGoods?.filter(pg => pg.quantityRemaining > 0).map(pg => {
    if (pg.archived) return null
    let studentPurchases = null

    if (pg?.purchaserIds.some(item => item._id === studentId)) {
      const numberPurchased = pg.purchaserLedger[studentId]?.quantityPurchased
      studentPurchases = <h6 style={{ color: "blue", fontSize: "2em" }}>{numberPurchased} PURCHASED!</h6>
    }

    return <DisplayCard
      pg={pg}
      viewItem={viewItem}
      studentPurchases={studentPurchases}
    />
  })

  return (<>
    <Card.Group centered itemsPerRow={itemsPerRow}>
      {showOnlyCurrentlyAvailable
        ? physicalGoodsStocked
        : physicalGoodsAll}
    </Card.Group>
  </>
  );
}
export default PhysicalGoodDisplayBoard;

