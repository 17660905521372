import React, { useState, useEffect } from 'react';
import firebase from "../../config/fbConfig"
import useGoogleLogout from '../hooks/useGoogleLogout'
import { clearUserGL } from '../../redux/auth/authActions';
import { clearSuper } from '../../redux/super/superActions'
import { clearStudent } from '../../redux/student/studentActions'
import { clearTeacher } from '../../redux/teacher/teacherActions'
import { clearClassroom } from '../../redux/classroom/classroomActions'
import { clearAdmin } from '../../redux/admin/adminActions'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { clearAdminRouting } from '../../redux/admin/adminActions'
import { Button, Icon, Segment } from 'semantic-ui-react'
import { SimpleSpinner } from "../common/Spinners"
import RenderSVG from '../common/RenderSVG';
import Lottie from 'react-lottie'
import robotGreeter from '../../img2/lottie/ui/73234-robot-assistant-online-manager.json'

const AdminDashboard = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const googleLogout = useGoogleLogout()
  const { setShowNavBar } = props

  const { loading: authLoading, error: adminAuthError, user } = useSelector(state => state.auth)

  const {
    error: adminLoadingError,
    loading: adminLoading,
    adminData
  } = useSelector(state => state.admin)


  const { displayName, schoolId_current, isNewAdminAccount } = adminData || {}

  const schoolImage = schoolId_current?.image?.svg

  const signOut = () => {
    // all are needed?
    dispatch(clearSuper())
    dispatch(clearStudent())
    dispatch(clearTeacher())
    dispatch(clearClassroom())
    dispatch(clearAdmin())
    dispatch(clearUserGL())

    localStorage.clear();
    firebase.auth().signOut().then(() => { googleLogout() })
  }

  useEffect(() => {
    if(isNewAdminAccount) setShowNavBar(false)
    else setShowNavBar(true)
  }, [isNewAdminAccount])

  useEffect(() => {
    dispatch(clearAdminRouting())
  }, [dispatch])


  const handleAddSchool = () => {
    history.push('/schoolcreate')
  }

  const handleEditAdminProfile = () => {
    const userObj = { ...user }
    userObj.fullAuthObject = null
    history.push('/adminprofileedit/', { userObj, adminData })
  }

  if (adminLoading || authLoading) { return <SimpleSpinner /> }

  if (!adminData || adminLoadingError || adminAuthError) {
    history.push("/loginerror")
  }

  if (isNewAdminAccount) {

    return (
<Segment>
<h2>Welcome to Kansha {displayName && displayName}</h2>
<Lottie
            width={150}
            height={150}
            options={{
              loop: false,
              autoplay: true,
              animationData: robotGreeter,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />
<div>
  <br/>
  <br/>
<Button color="green" size="large" inverted onClick={handleAddSchool}>Create a School</Button>
</div>
<br/>
<Button color="black" size="tiny" onClick={signOut}>Log Out</Button>
<br/>
<br/>
</Segment>)}

  return (
    <>
      <div onClick={handleEditAdminProfile}>
        <h2
          style={{ cursor: 'pointer', fontSize: "5em" }}
          className="hvr-grow">
          {displayName && displayName}
        </h2>
      </div>

      {schoolId_current ? 
       <>{schoolImage &&
        <RenderSVG
          size="90"
          svgFilename={schoolImage} />}
       <h6> 
        School: {schoolId_current.name}<br />
         Mascot: {schoolId_current.mascot}<br />
         Info: {schoolId_current.description}
         </h6>
     </>
: 
<>


<div>
<Button color="blue" inverted onClick={()=>history.push("/schoolsdisplay")}>All Schools</Button>
</div>
</>}
      <br />
      <br />
      <Button color="black"  size="tiny"  onClick={signOut}>Log Out</Button>
      <br />
    </ >);
}
export default AdminDashboard;

