import React from 'react';
import { Img} from 'react-image'
import kanshaLogo from "../../img2/png/kansha-logo.png"

const  myComponent = (props) => {
  const { id, selectGif, size = 100 } = props
  const handleClick = () => {
    if (selectGif) { selectGif(id) }
  }

  return(<Img
      src={[ `https://media2.giphy.com/media/${id}/100.gif`, kanshaLogo]}
      alt={"gif"} 
      width={size}
      height={size} 
      onClick={handleClick}
    />)

}

  export default myComponent