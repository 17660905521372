import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
//import "./whoIsBiggest.css"
//import RenderAllAvatars from '../common/RenderAllAvatars';


const WhoIsBiggest = () => {

  const { loading: profileLoading, error: profileError, studentData } = useSelector(state => state.student)
  const { avatar, displayName, _id } = studentData || null

  let [avatarSize, setAvatarSize] = useState(60)
  const [avatarGrowing, setAvatarGrowing] = useState(true)

  const gameCode = { active: true }



  const handleAvatarClick = () => {
    if (avatarGrowing) { setAvatarSize(avatarSize * 1.2) }
    else {
      setAvatarSize(avatarSize / 1.3)
    }

    if (avatarSize > 600) {
      setAvatarGrowing(false)
    }

    if (avatarSize < 100) {
      avatarSize = 60
      setAvatarGrowing(true)
    }
  }

  //console.log("avatar", avatar, displayName, _id, gameCode)
  console.log("avatar size", avatarSize)
  return (
    <>
      <h1>bigger - smaller</h1>
      {/*       <h3>TO DO: add animations, weird </h3> */}
      <div id="biggest" onClick={() => handleAvatarClick()}>
        <RenderAvatar

          size={avatarSize}
          avatarId={avatar.avatarId}
          setOfAvatars={superHeros}
        />
      </div>
    </>
  )
}

export default WhoIsBiggest

