import React from 'react';
import { Card } from 'semantic-ui-react'
import Gif from '../common/gif'

const RafflePrizesDisplay = (props) => {

  const { prizesAll
    // handleDeletePrize, 
    // raffleItOff 
  } = props

  const prizesDisplayBoard = prizesAll?.map(cg => {
    if (cg.archived) return null
    return (
      <Card key={cg._id}>
        <h1 style={{ fontSize: "2em", margin: "1px" }}>{cg.title}</h1>

        {cg?.image?.gif
          ? <Gif id={cg.image.gif} size={100} />
          : null}

        {cg?.quantityRemaining < 1
          ? (
            <h3 style={{ margin: "1px 1px 1px 1px", color: "#FFD700" }}>All have been awarded</h3>)
          : <>
            <h2 style={{ fontSize: "1em", margin: "1px" }}>{cg.description}</h2>
            <h4 style={{ margin: "1px 1px 1px 1px" }}>Amount to raffle:{cg.quantityRemaining}
            </h4>
          </>
        }
      </Card>
    )
  })

  return (<>

    <Card.Group itemsPerRow={4}>
      {prizesDisplayBoard}
    </Card.Group>

  </>
  );
}
export default RafflePrizesDisplay;

