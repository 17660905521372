import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { motion } from 'framer-motion';
import moment from 'moment'
import { updatePrize, updateClassroom } from '../../redux/classroom/classroomActions'
//import RenderSVG from '../common/RenderSVG';
import Gif from '../common/gif'
import useSound from 'use-sound';
import tadah from '../../sound/tadah.mp3'
import Lottie from 'react-lottie'
import RenderAvatar from '../common/RenderAvatar';
import { superHeros } from "../../img/avatars/superHeros"
import raffleConfetti from '../../img2/lottie/ui/69030-confetti-full-screen.json' // use

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}

const winnerTextStyle = {
  color: "gold",
  fontSize: "6em",
  fontWeight: "bold",
  // margin: "-40px 0px 1px 0px",
  textShadow: "2px 2px 4px #000000"
}


export default function RaffleOff(props) {
  const [validTicketsRemaining, setValidTicketsRemaining] = useState(null)
  const [newWinner, setNewWinner] = useState(null)
  const [previousValidTicketsRemaining, setPreviousValidTicketsRemaining] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()
  const [playTadah] = useSound(tadah);

  const { error,
    loading: classroomLoading,
    raffle,
    prizesAll,
    raffleTicketIds,
    studentIds,
    _id: classroomId
  } = useSelector(state => state.classroom)



  // 1) Only use prizeID from router
  const { state: { item: { _id: prizeId } } } = props.history.location

  // 2 local copy of prizes
  const localPrizesAll = [...prizesAll]
  const indexOfPrize = _.findIndex(localPrizesAll, function (o) { return o._id === prizeId; });

  // 3 currentPrize from local 
  const currentPrize = localPrizesAll[indexOfPrize]
  const { title, description, quantityRemaining, image = {} } = currentPrize

  // 4 set localQuantityRemaining
  const [localQuantityRemaining, setLocalQuantityRemaining] = useState(quantityRemaining)

  // 4.1 update localQuantity for UI
  useEffect(() => {
    setLocalQuantityRemaining(quantityRemaining)
  }, [quantityRemaining])

  // set valid tickets remaining.tickets removed each roud
  useEffect(() => {
    const remainingTickets = raffleTicketIds.filter(ti => ti.prizeId === null)

    setValidTicketsRemaining(remainingTickets)
  }, [raffleTicketIds])

  // updating raffle object
  useEffect(() => {
    const updatedClassroomData = { raffle: { ...raffle, active: true, title, description } }
    console.log("+++++++++ RAFFLE IN SESSION +++++++++", updatedClassroomData)
    dispatch(updateClassroom(classroomId, updatedClassroomData))

    return () => {
      const updatedClassroomData = { raffle: { ...raffle, active: false, title: null, description: null } }
      dispatch(updateClassroom(classroomId, updatedClassroomData))
      console.log("------- RAFFLE CLOSED ------", updatedClassroomData)
    }
  }, [])

  const drawWinner = () => {

    currentPrize.quantityRemaining = currentPrize.quantityRemaining - 1

    const rand = getRandomInt(validTicketsRemaining.length - 1)
    const winningTicket = validTicketsRemaining[rand]


    // LATER: save for optimistic update error
    const previousValidTicketsRemaining = [...validTicketsRemaining]
    setPreviousValidTicketsRemaining(previousValidTicketsRemaining)

    // Set prize info to raffle ticket
    winningTicket.prizeDescription = currentPrize.description
    winningTicket.prizeTitle = currentPrize.title
    winningTicket.prizeId = currentPrize._id
    winningTicket.prizeImage = currentPrize.image
    winningTicket.prizeDate = moment().format('MMMM Do YYYY, h:mm:ss a')

    // update db - need to remove updating raffleTicketIds
    const updatedClassroomData = { prizesAll, raffleTicketIds }

    dispatch(updateClassroom(classroomId, updatedClassroomData))

    const tempWinnerIds = [...currentPrize?.winnerIds]
    tempWinnerIds.push(winningTicket?.studentId)

    const tempWinnerLedger = { ...currentPrize?.winnerLedger }
    tempWinnerLedger[winningTicket.id] = [winningTicket.studentId, ...winningTicket.studentName, winningTicket.id, winningTicket.prizeDate]

    const updatedPrize = {
      quantityRemaining: currentPrize.quantityRemaining,
      winnerIds: tempWinnerIds,
      winnerLedger: tempWinnerLedger
    }


    dispatch(updatePrize(prizeId, updatedPrize))

    // GRAPHIC FOR WINNER!

    // console.log("studentIds  in CLASSROOM ==>", studentIds)
    // console.log("winningTicket.studentId", winningTicket.studentId)

    // BUG ALERT: 
    // CURRENT:  Get avatar from studentIds yet name, etc are added to the winningTicket elsewhere 
    // LATER: Add avatar Id to the winning ticket

    // FORMAT: [studentName, id, avatarId]
    const winningStudentData = studentIds.filter(student => student._id === winningTicket.studentId)


    const { avatar: winningStudentAvatar } = winningStudentData[0]


    setNewWinner([winningTicket.studentName?.[2], winningTicket?.id, winningStudentAvatar?.avatarId])

    playTadah()
    setTimeout(() => setNewWinner(null), 4500)
  }

  if (error) { return <h1>Ooops. We are experiencing an error.</h1> }

  const buttonToDisplay = validTicketsRemaining?.length < 1
    ? <Button
      size="massive"
      color="yellow">
      No students have raffle tickets
    </Button>

    : <Button
      onClick={drawWinner}
      size="massive"
      color="green">
      Draw a winner!
    </Button>

  let currentWinnersDisplay = []

  if (currentPrize.winnerLedger) {
    for (const winner in currentPrize.winnerLedger) {
      currentWinnersDisplay.push(<h6 style={{ fontSize: "1.5em", margin: "5px" }}>{currentPrize.winnerLedger[winner][3]}{" - "}{winner}</h6>)
    }
  }

  return (<>

    {newWinner !== null
      ?
      <div>
        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: raffleConfetti,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />

        <motion.h1
          initial={{ x: 1500, y: 30 }}
          animate={{ x: 0 }}
          transition={{ delay: .25 }}
          style={{ ...winnerTextStyle, margin: "-40px 0px 1px 0px" }}
        >
          <span>{newWinner[0]}</span>
        </motion.h1>
        <motion.div
          initial={{ x: 1500, y: 30 }}
          animate={{ x: 0 }}
          transition={{ delay: .50 }}
          style={{
            color: "#42e3f5",
            fontSize: "3em",
            fontWeight: "bold",
            margin: "-20px 0px 20px 0px",
            textShadow: "2px 2px 4px #000000"
          }}
        >
          <RenderAvatar
            size={200}
            avatarId={newWinner[2]}
            setOfAvatars={superHeros}
          />
        </motion.div>

        <motion.h2
          initial={{ x: 1500, y: 30 }}
          animate={{ x: 0 }}
          transition={{ delay: .75 }}
          style={{
            color: "#42e3f5",
            fontSize: "3em",
            fontWeight: "bold",
            margin: "-20px 0px 20px 0px",
            textShadow: "2px 2px 4px #000000"
          }}
        >
          <span>{newWinner[1]}</span>
        </motion.h2>

      </div>


      : <div>
        <h1 style={{ color: "black", fontSize: "4em", padding: "1px" }}>{title}</h1>

        <Gif id={image?.gif} size={250} />
        <h2 style={{ color: "black", fontSize: "2em", margin: "1px" }}>{description}<br />
          <span style={{ color: "blue" }}>Quantity left: {localQuantityRemaining}</span>
          <span style={{ color: "green" }}> Outstanding Tickets: {validTicketsRemaining?.length}</span>
        </h2>

        {localQuantityRemaining < 1
          ? <Button
            onClick={() => history.push('/prizeedit/', currentPrize)}
            size="large"
            color="green">
            Add more {title} prizes</Button>
          : buttonToDisplay}


      </div>
    }

    <div>
      {/* {localQuantityRemaining < 1
        ? <Button
          onClick={() => history.push('/prizeedit/', currentPrize)}
          size="huge"
          color="green">
          Add more {title} prizes</Button>
        : buttonToDisplay} */}
      <br />
      <h6>All of the Winners: </h6>
      {currentWinnersDisplay}
    </div>
    <br />
    <br />
    <br />
  </>);

}
