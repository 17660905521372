import React, { useState } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { updateTeacherNavbar } from "../../redux/teacher/teacherActions"
import { useDispatch, useSelector } from "react-redux"

const NavTeacherGroups = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    navbar: { groups: groupsNavbarState } } = useSelector(state => state.teacher)

  const [activeItem, setActiveItem] = useState(groupsNavbarState || 'groupsteamwork');

  const teamworkView = () => {
    setActiveItem('groupsteamwork')
    dispatch(updateTeacherNavbar({ groups: "groupsteamwork" }))
    history.push("/groupsteamwork")
  }
  const listsView = () => {
    setActiveItem('groupslist')
    dispatch(updateTeacherNavbar({ groups: "groupslist" }))
    history.push("/groupslist")
  }
  const allGroupsView = () => {
    setActiveItem('groupthemeslist')
    dispatch(updateTeacherNavbar({ groups: "groupthemeslist" }))
    history.push("/groupthemeslist")
  }

  return (
    <Menu color='red' widths={3} >

      <Menu.Item
        className="menu-button"
        name='groupsteamwork'
        active={activeItem === 'groupsteamwork'}
        onClick={teamworkView}>
          <Icon size="large" name='group' />
          <span>Teamwork</span>
        </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='groupslist'
        active={activeItem === 'groupslist'}
        onClick={listsView}>
          <Icon name='list' size="large"/>
          <span>Edit</span>
          </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='groupthemeslist'
        active={activeItem === 'groupthemeslist'}
        onClick={allGroupsView}>
          <Icon name='book' size="large"/>
          <span>All Topics</span>
          </Menu.Item>

    </Menu>
  );
}

export default NavTeacherGroups;

