import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createCommonGood } from '../../redux/classroom/classroomActions'
import RenderSVG from '../common/RenderSVG'
//import ReactTooltip from 'react-tooltip';
import commonGoodIcons from '../../data/commonGoodIcons.json'

export default function CommonGoodCreate() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()
  const [amount, setAmount] = useState(1);
  //const [icon, setIcon] = useState(commonGoodIcons.set_1[0].svg);
  const [iconNumber, setIconNumber] = useState(0)

  const { teacherData: { classroomId_current, schoolId: { _id: schoolId, coinName } }, loading } = useSelector(state => state.teacher)

  const { onCompleteRoute } = useSelector(state => state.classroom)

  const onSubmit = commonGoodData => {
    if (classroomId_current?._id) {
      commonGoodData.classroomId = classroomId_current._id
      commonGoodData.targetAmount = amount
      commonGoodData.image = commonGoodIcons.set_1[iconNumber]
      dispatch(createCommonGood(commonGoodData))
    }
  };

  const setTokenAmount = (tokens) => {
    if (tokens < 1) { return }
    setAmount(tokens)
  }

  const changeIcon = () => {
    let newIconNumber = iconNumber + 1
    if (newIconNumber > commonGoodIcons.set_1.length - 1) {
      newIconNumber = 0
    }
    setIconNumber(newIconNumber)
  }

  if (onCompleteRoute) { history.push(onCompleteRoute) }
  if (loading) { return <h1>Loading</h1> }

  return (
    <>
      <h1 >Add a Community Donation Item</h1>
      <div onClick={changeIcon}>
        <RenderSVG
          size="50"
          svgFilename={commonGoodIcons.set_1[iconNumber].svg} />
      </div>

      <h4>Click icon to change</h4>

      <form onSubmit={e => e.preventDefault()}>

        <label>Title</label>
        <input
          name="title"
          type="text"
          ref={register({
            required: "You must include a title",
            minLength: {
              value: 2,
              message: "title must have at least 2 characters"
            }
          })}
        />
        {errors.title && <p>{errors.title.message}</p>}


        <label>Short description</label>
        <input
          name="description"
          type="text"
          ref={register({
            required: "You must include a description",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.description && <p>{errors.description.message}</p>}


        <label>Number of {schoolId ? coinName + "s" : "Kansha Tokens"} required</label>

        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={amount}
          onChange={(e) => setTokenAmount(e.target.value)}
        />
        <h1>{" "}</h1>




        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save New Item
        </Button>



        <h2>{"   "}</h2>

      </form>
      <h1>{" "}</h1>

    </>
  );
}




