// https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2

function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}

function exportCSVFile(
  headers,
  items,
  fileTitle = "TeacherName_class_date_time") {

  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

const defaultHeaders = { f1: 'Pre-assign Room Name', f2: '' }
const defaultGroupDataArray = [
  { f1: 'GroupA', f2: 'test1@123.com' },
  { f1: 'GroupB', f2: 'test2df@123.com' },
  { f1: 'GroupC', f2: 'test3@123.com' },
  { f1: 'GroupD', f2: 'test4@123.com' },
  { f1: 'GroupE', f2: 'test5@123.com' },
  { f1: 'GroupF', f2: 'test6@123.com' }
]

function downloadCSV(
  headers = defaultHeaders,
  groupDataArray = defaultGroupDataArray
) {

  var itemsFormatted = [];

  // format the data
  groupDataArray.forEach((item) => {
    itemsFormatted.push({
      f1: item.f1.replace(/,/g, ''), // remove commas to avoid errors,
      f2: item.f2
    });
  });


  exportCSVFile(headers, itemsFormatted);
  // call the exportCSVFile() function to process the JSON and trigger the download
}

export default downloadCSV



  // const headers2 = {
  //   model: 'Phone Model'.replace(/,/g, ''), // remove commas to avoid errors
  //   chargers: "Chargers",
  //   cases: "Cases",
  //   earphones: "Earphones"
  // };

  // const itemsNotFormatted = [
  //   {
  //     model: 'Samsung S7',
  //     chargers: '55',
  //     cases: '56',
  //     earphones: '57',
  //     scratched: '2'
  //   },
  //   {
  //     model: 'Pixel XL',
  //     chargers: '77',
  //     cases: '78',
  //     earphones: '79',
  //     scratched: '4'
  //   },
  //   {
  //     model: 'iPhone 7',
  //     chargers: '88',
  //     cases: '89',
  //     earphones: '90',
  //     scratched: '6'
  //   }
  // ];

