import { useEffect, useState } from "react";
import useSound from 'use-sound';
import cartoonBleep from '../../sound/cartoon_bleep.mp3'
import successTone from '../../sound/success_tone.mp3'
import pointSound01 from '../../sound/pointSound01.mp3'
import pointSound02 from '../../sound/pointSound02.mp3'
import pointSound03 from '../../sound/pointSound03.mp3'
import pointSound04 from '../../sound/pointSound04.mp3'
import pointSound05 from '../../sound/pointSound05.mp3'
import pointSound06 from '../../sound/pointSound06.mp3'
import pointSound07 from '../../sound/pointSound07.mp3'
import pointSound08 from '../../sound/pointSound08.mp3'
import pointSound09 from '../../sound/pointSound09.mp3'
import pointSound10 from '../../sound/pointSound10.mp3'
import pointSound11 from '../../sound/pointSound11.mp3'

import animalSound01Horse from '../../sound/animal01_horse.mp3'
import animalSound03Lion from '../../sound/animal03_lion_a.mp3'
import animalSound05Elephant from '../../sound/animal05_elephant.mp3'
import animalSound07Monkey from '../../sound/animal07_chimp_a.mp3'
import animalSound08Rhino from '../../sound/animal08_rhino.mp3'
import animalSound11Frog from '../../sound/animal11_frog.mp3'
import animalSound35Sheep from '../../sound/animal35_sheep.mp3'

// TODAY: Combine import with useSound

export function useGroupSoundEffects(groupIds) {

  // 1) CREATE SET OF 100 SOUNDS
  const [playCartoonBleep] = useSound(cartoonBleep);
  const [playSuccessTone] = useSound(successTone);
  const [playPointSound01] = useSound(pointSound01);
  const [playPointSound02] = useSound(pointSound02);
  const [playPointSound03] = useSound(pointSound03);
  const [playPointSound04] = useSound(pointSound04);
  const [playPointSound05] = useSound(pointSound05);
  const [playPointSound06] = useSound(pointSound06);
  const [playPointSound07] = useSound(pointSound07);
  const [playPointSound08] = useSound(pointSound08);
  const [playPointSound09] = useSound(pointSound09);
  const [playPointSound10] = useSound(pointSound10);
  const [playPointSound11] = useSound(pointSound11);

  const [playAnimal01Horse] = useSound(animalSound01Horse);
  const [playAnimal03Lion] = useSound(animalSound03Lion);
  const [playAnimal05Elephant] = useSound(animalSound05Elephant);
  const [playAnimal07Monkey] = useSound(animalSound07Monkey);
  const [playAnimal08Rhino] = useSound(animalSound08Rhino);
  const [playAnimal11Frog] = useSound(animalSound11Frog);
  const [playAnimal35Sheep] = useSound(animalSound35Sheep);

  // 2) MAKE LOOKUP OBJECT
  const soundLibraryLookup = {
    generic: playCartoonBleep,
    soundId001: playSuccessTone,
    ps01: playPointSound01,
    ps02: playPointSound02,
    ps03: playPointSound03,
    ps04: playPointSound04,
    ps05: playPointSound05,
    ps06: playPointSound06,
    ps07: playPointSound07,
    ps08: playPointSound08,
    ps09: playPointSound09,
    ps10: playPointSound10,
    ps11: playPointSound11,

    as01horse: playAnimal01Horse,
    as03lion: playAnimal03Lion,
    as05elephant: playAnimal05Elephant,
    as07monkey: playAnimal07Monkey,
    as08rhino: playAnimal08Rhino,
    as11frog: playAnimal11Frog,
    as35sheep: playAnimal35Sheep,

    animall01horse: playAnimal01Horse
  }

  const finalSoundMachineObject = {
    generic: () => soundLibraryLookup.generic()
  }

  // build finalSoundMachineObject
  groupIds && groupIds.forEach(group => {
    finalSoundMachineObject[group._id] = group?.displayNameGraphics?.soundId
      ? () => soundLibraryLookup[group.displayNameGraphics.soundId]()
      : () => soundLibraryLookup.generic()
  })

  return [finalSoundMachineObject]

}
