import React from 'react';

const SingleStudent = (props) => {
  const { student: { coins, displayName }, student, handleStudentProfileClick, handleAddCoin, handleSubtractCoin } = props
  const styleInc = { "color": "green", "fontSize": "13px", "fontWeight": "bold" }
  const styleDec = { "color": "red", "fontSize": "13px", "fontWeight": "bold" }

  return (
    <>
      <div>
        <span
          style={{ fontWeight: 'bold' }}
          onClick={() => handleStudentProfileClick(student)}>
          {displayName} {coins} {" "}
        </span>
        <span style={styleInc}
          onClick={() => handleAddCoin(student)}>
          --ADD--
          </span>
        <span style={styleDec}
          onClick={() => handleSubtractCoin(student)}>
          --SUBTRACT--
          </span>

      </div>
    </>
  );
}

export default SingleStudent;


