import React, { useEffect } from 'react';
import { Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { useForm } from 'react-hook-form'
import { Button } from 'semantic-ui-react'
import { updateClassroom, clearGrouptheme, createGrouptheme } from "../../redux/classroom/classroomActions"
import Lottie from 'react-lottie'
import student_with_backpack from '../../img2/lottie/ui/40185-student-with-backpack.json'
import RenderSVG from '../common/RenderSVG';



// This component will not check db for groupthemeId_building
// If use navigates back or somehow lands here, currnet groupthemeId_building reset
// No need to block back navigation here, but yes for following pages groupSize, etc, yes         

const GroupThemeCreate = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { register, errors, handleSubmit, watch } = useForm({});

  const { teacherDataFromProps:
    { teacherData: { classroomId_current } } } = props

  useEffect(() => {
    const dataPod = {
      groupthemeId_building: null
    }

    // clear old theme when starting
    classroomId_current && dispatch(updateClassroom(classroomId_current?._id, dataPod))
    dispatch(clearGrouptheme())

  }, [])

  const { studentIds, groupthemeId_building } = useSelector(state => state.classroom)

  // const { classroomData, classroomData: { studentIds, currentStudentsOnline, groupthemeId_building } } = useSelector(state => state.classroom)


  const onSubmitGroupTheme = topic => {
    // object response
    let dataPod = {
      classroomId: classroomId_current._id,
      topic: topic.teacher_created
    }

    // adjust for string response 
    if (typeof topic === 'string') {
      dataPod.topic = topic.trim()
    }

    // check for empty value (ISSUE?)
    if (dataPod.topic === '') return

    dispatch(createGrouptheme(classroomId_current._id, dataPod)) // groupthemeId_building (where is it?)

    history.push('/groupsize')
  };

  if (!studentIds) {
    return (<>
      <h1>There are no students enrolled in this class.</h1>
      <Button size="tiny" onClick={() => history.push("/teacherdashboard")}>Dashboard</Button>
    </>)
  }

  if (studentIds && studentIds?.length < 6) {

    return (<> <h3>You must have at least 6 students enrolled to create groups</h3>
      <Lottie
        width={250}
        height={250}
        options={{
          loop: false,
          autoplay: true,
          animationData: student_with_backpack,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
      />
      <Button onClick={() => history.push('/teacherdashboard')} basic color="green" >I understand</Button>
      <Button onClick={() => history.push('/teacherdashboard')} basic color="blue" >Help me enroll students</Button>
    </>)

  }

  return (
    <Container>
      <h2>What is the theme or topic for these groups?</h2>
      <div>
        <RenderSVG
          size={"200"}
          svgFilename={"ui/general/people"} />
      </div>
      <br />

      <Button
        onClick={(e) => onSubmitGroupTheme(e.target.value)}
        size="massive"
        type="submit"
        color='green'
        value="Math"
      >Math</Button>
      <Button
        onClick={(e) => onSubmitGroupTheme(e.target.value)}
        size="massive"
        type="submit"
        color='yellow'
        value="Reading"
      >Reading</Button>
      <Button
        onClick={(e) => onSubmitGroupTheme(e.target.value)}
        size="massive"
        type="submit"
        color='red'
        value="Writing"
      >Writing</Button>
      <Button
        onClick={(e) => onSubmitGroupTheme(e.target.value)}
        size="massive"
        type="submit"
        color='purple'
        value="Project"
      >Project</Button>
      <Button
        onClick={(e) => onSubmitGroupTheme(e.target.value)}
        size="massive"
        type="submit"
        color='blue'
        value="General"
      >General</Button>
      <h3>Or type your own in the box:</h3>
      <form onSubmitGroupTheme={e => {
        e.preventDefault();
        return "blablabla"
        //return e.target.value
      }}>
        <input
          name="teacher_created"
          type="text"
          ref={register({})}
        />

        <Button
          onClick={handleSubmit(onSubmitGroupTheme)}
          size="massive"
          type="submit"
          inverted color='blue'
        >Submit</Button>

      </form>

    </Container>
  );
}
export default GroupThemeCreate;

