import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from "react-redux"
import logger from 'redux-logger'
import thunkMiddleWare from 'redux-thunk'
import App from './App';

import authReducer from "./redux/auth/authReducer"
import superReducer from "./redux/super/superReducer"
import adminReducer from "./redux/admin/adminReducer"
import teacherReducer from "./redux/teacher/teacherReducer"
import studentReducer from "./redux/student/studentReducer"
import classroomReducer from "./redux/classroom/classroomReducer"

import { ModalProvider } from "react-modal-hook";

import './index.css';


function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  }
  catch (err) {
    console.log(err)
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  }
  catch (err) {
    console.log(err)
    return undefined

  }
}

const persistedState = loadFromLocalStorage()

const rootReducer = combineReducers({
  auth: authReducer,
  super: superReducer,
  student: studentReducer,
  teacher: teacherReducer,
  classroom: classroomReducer,
  admin: adminReducer,
})

let middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware = [logger, thunkMiddleWare];
} else {
  middleware = [thunkMiddleWare];
}


// Note: logger must be the last middleware in chain, otherwise it will log thunk and promise, not actual actions
//const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(logger, thunkMiddleWare)))
const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(...middleware)))


// whenever store changes update local storage
store.subscribe(() => saveToLocalStorage(store.getState()))


ReactDOM.render(
  <ModalProvider>
    <Provider store={store}><App /></Provider>
  </ModalProvider>, document.getElementById('root'));

