import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateStudentProfile } from '../../redux/student/studentActions'
//import notify from '../../utils/notify'
import { SimpleSpinner } from "../common/Spinners"
import googleLogo from "../../img2/png/google-logo.png"


export default function StudentProfileEdit(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state: { userObj } } = props.history.location

  const { fb_uid, userType } = userObj

  // const { firstName, lastName, email, _id: mongoId } = studentData

  const {
    loading: studentLoading,
    error: studentError,
    studentData: { firstName, lastName, email, _id: mongoId, google_uid },
    onCompleteRoute,
    reduxResponseData
  } = useSelector(state => state.student)


  const [errorDisplay, setErrorDisplay] = useState(null)
  const [startingEmail, setStartingEmail] = useState(email)
  const [submittedNewEmail, setSubmittedNewEmail] = useState(null)


  let userTypeDisplay = userType[0].toUpperCase() + userType.substring(1)

  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email
    }
  });

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = updatedData => {
    // notify({
    //   noticeType: "error",
    //   message: updatedData.firstName,
    //   position: "TOP_LEFT",
    //   //autoClose: false
    // })

    if (!google_uid) { setSubmittedNewEmail(updatedData.email) }

    updatedData.displayName = updatedData.firstName + " " + updatedData.lastName
    dispatch(updateStudentProfile(mongoId, updatedData, fb_uid))

  };

  useEffect(() => { setErrorDisplay(studentError) }, [studentError])

  useEffect(() => {
    if (onCompleteRoute && reduxResponseData === "updated") {

      // notify({
      //   noticeType: "error",
      //   message: updatedData.firstName,
      //   position: "TOP_LEFT",
      //   //autoClose: false
      // })

      if (!google_uid && startingEmail !== submittedNewEmail) {
        alert("❗❗❗❗❗❗❗❗\nYour profile has been updated and you have changed your email address to: " + submittedNewEmail + ".  \nBE SURE TO WRITE IT DOWN or you won't be able to log in.\n📝📝📝📝📝📝📝📝📝📝📝📝📝📝📝📝📝 ")
      } else {
        alert("Profile successfully updated.")
      }
      history.push(onCompleteRoute)
    }
  }, [onCompleteRoute])



  return (
    <>
      <h1>Edit {userTypeDisplay} Profile </h1>

      {studentLoading ? < SimpleSpinner /> : null}
      <h4>{errorDisplay}</h4>

      <form onSubmit={e => e.preventDefault()}>

        <label>First</label>
        <input
          name="firstName"
          type="text"
          ref={register({
            required: "You must include a first name",
            minLength: {
              value: 1,
              message: "First name must have at least 2 characters"
            }
          })}
        />
        {errors.firstName && <p>{errors.firstName.message}</p>}


        <label>Last</label>
        <input
          name="lastName"
          type="text"
          ref={register({
            required: "You must include a last name",
            minLength: {
              value: 1,
              message: "Last name must have at least 1 characters"
            }
          })}
        />
        {errors.lastName && <p>{errors.lastName.message}</p>}


        {google_uid
          ?
          <h5 style={{ fontSize: "1.5em", color: "black" }}>
            <img src={googleLogo} alt="GL" width="20" height="20" />
            {" "}{email}</h5>
          :
          <>
            <label>Email</label>
            <input
              name="email"
              type="email"
              ref={register({
                required: "You must include an email"
              })}
            />

            {!google_uid && errors.email && <p>{errors.email.message}</p>}
          </>
        }

        <br />
        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Update Profile</Button>

        <h2>{"   "}</h2>

        {google_uid
          ? null
          : <div>
            <Button onClick={() => history.push('/studentresetpassword/')}>Password reset</Button>
          </div>
        }

        <br />
        <div>
          <Button onClick={() => history.push('/studentdashboard/')}>Return</Button>
        </div>
      </form>

    </>
  );

}

