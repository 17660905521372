import * as types from './authTypes'


const initDataAuth = {
  loading: false,
  user: null,
  error: null,
  userTypeDirectToDashboard: null,
  temporaryInfo: null
}

// initialData here or in action?
function authReducerFB(state = initDataAuth, action) {
  switch (action.type) {

    // --- PASSWORD RESET FIREBASE --- //

    case types.PASSWORD_RESET_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        //  user: action.payload,
        error: null,
        userTypeDirectToDashboard: "emailsent",
        temporaryInfo: null
      };

    case types.PASSWORD_RESET_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        //user: null,
        error: action.payload,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    // --- CREATE FIREBASE --- //

    case types.CREATE_USER_REQUEST_FB:
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.CREATE_USER_SUCCESS_FB:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        userTypeDirectToDashboard: action.payload.userTypeDirectToDashboard,
        temporaryInfo: null
      };

    case types.CREATE_USER_FAILURE_FB:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    // --- FETCH FIREBASE --- //

    case types.FETCH_USER_REQUEST_FB:
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null

      };

    case types.FETCH_USER_SUCCESS_FB:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.FETCH_USER_FAILURE_FB:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    // --- UPDATE FIREBASE --- //

    case types.UPDATE_USER_FB:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null

      };


    // --- CREATE FIREBASE FROM GOOGLE --- //

    case types.CREATE_USER_REQUEST_GL:
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.CREATE_USER_SUCCESS_GL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        userTypeDirectToDashboard: action.payload.userTypeDirectToDashboard,
        temporaryInfo: null
      };

    case types.CREATE_USER_FAILURE_GL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    // --- FETCH FIREBASE FROM GOOGLE --- //

    case types.FETCH_USER_REQUEST_GL:
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null

      };

    case types.FETCH_USER_SUCCESS_GL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.FETCH_USER_FAILURE_GL:
      console.log("action.payload===>", action.payload)
      // SPECIAL PAYLOAD - FIREBASE ERROR and 
      const [firebaseError, googleUserInfoForSignUp] = action.payload
      return {
        ...state,
        loading: false,
        user: null,
        error: firebaseError.message,
        userTypeDirectToDashboard: "signupwithgoogle",
        temporaryInfo: googleUserInfoForSignUp
      };


    // --- LOG OUTS--- //

    case types.CLEAR_USER_GL:
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    case types.LOGOUT_USER_FB:
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };

    case types.CLEAR_USER_FB: // same as logout?
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
        userTypeDirectToDashboard: null,
        temporaryInfo: null
      };


    default:
      return state;
  }
}

export default authReducerFB