
export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAILURE = "GET_STUDENTS_FAILURE"

export const GET_TEACHERS_REQUEST = "GET_TEACHERS_REQUEST"
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS"
export const GET_TEACHERS_FAILURE = "GET_TEACHERS_FAILURE"

export const GET_ADMINS_REQUEST = "GET_ADMINS_REQUEST"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAILURE = "GET_ADMINS_FAILURE"

export const CREATE_USER_PROFILE_REQUEST = "CREATE_USER_PROFILE_REQUEST"
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS"
export const CREATE_USER_PROFILE_FAILURE = "CREATE_USER_PROFILE_FAILURE"

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST"
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS"
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE"

export const DELETE_USER_PROFILE_REQUEST = "DELETE_USER_PROFILE_REQUEST"
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS"
export const DELETE_USER_PROFILE_FAILURE = "DELETE_USER_PROFILE_FAILURE"

export const INCREASE_STUDENT_COINS_REQUEST = "INCREASE_STUDENT_COINS_REQUEST"
export const INCREASE_STUDENT_COINS_SUCCESS = "INCREASE_STUDENT_COINS_SUCCESS"
export const INCREASE_STUDENT_COINS_FAILURE = "INCREASE_STUDENT_COINS_FAILURE"

export const DECREASE_STUDENT_COINS_REQUEST = "DECREASE_STUDENT_COINS_REQUEST"
export const DECREASE_STUDENT_COINS_SUCCESS = "DECREASE_STUDENT_COINS_SUCCESS"
export const DECREASE_STUDENT_COINS_FAILURE = "DECREASE_STUDENT_COINS_FAILURE"

export const CLEAR_SUPER = "CLEAR_SUPER"