import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Table, Button, Icon } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { deleteClass, getTeacherProfile, updateTeacherProfile } from '../../redux/teacher/teacherActions'
import { getClassroom } from "../../redux/classroom/classroomActions"
import { removeClassroomFromStudent, updateStudentProfile_2 } from "../../redux/student/studentActions"
import { SimpleSpinner } from "../common/Spinners"
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import RenderSVG from '../common/RenderSVG';
import ReactTooltip from 'react-tooltip';

const ClassroomsDisplayTeacher = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [deleteSelectionForModal, setDeleteSelectionForModal] = useState(null)
  const [selectedClassroom, setSelectedClassroom] = useState(null)

  const { teacherData: { classroomId_current, classroomIds, _id: teacherId, schoolId }, loading } = useSelector(state => state.teacher)

  const { user: { fb_uid } } = useSelector(state => state.auth)

  const { image } = useSelector(state => state.classroom)
  const classroomImage = image?.svg

  // classroomIdWorkaround
  // temp workaround to deal with the profile update 
  // happening slower than the render, 
  const classroomIdWorkaround = classroomId_current ? classroomId_current.name : ""


  useEffect(() => {
    dispatch(getTeacherProfile(fb_uid))
  }, [classroomIdWorkaround])

  const onClickDelete = (classroom) => {
    setDeleteSelectionForModal(classroom)
  }

  const deleteConfirmed = () => {
    // #1 -- Delete classroom  (and techer reference)
    dispatch(deleteClass(deleteSelectionForModal._id, teacherId))

    // #2 -- Delete all classroom references in student records
    const { studentIds } = deleteSelectionForModal
    studentIds.forEach(studentId => {
      dispatch(removeClassroomFromStudent(deleteSelectionForModal._id, studentId))
    })

    // #3 -- Teacher record updated in deleteClass 
  }

  const joinCodeOptions = (classroom) => {
    console.log(classroom.joinCode)
    copy(classroom.joinCode)
    showJoinCode()
    setSelectedClassroom(() => classroom)
  }

  const [showJoinCode, hideJoinCode] = useModal(() => {
    const { joinCode } = selectedClassroom
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        shouldCloseOnEsc={true}
      // shouldCloseOnOverlayClick={true} not working?
      // className="Modal" - TODO: Create better modal
      >
        <div onClick={() => hideJoinCode()}>
          <h1 style={{ color: "black", fontSize: "2em" }}>The code to join the class {selectedClassroom.name} is:</h1>
          <h1 style={{ color: "blue", fontSize: "6em" }}> {joinCode}</h1>
          <h1 style={{ color: "black", fontSize: "2em" }}>It has been copied to your clipboard.</h1>
        </div>
      </ReactModal >)
  }, [selectedClassroom]);


  const setCurrentClassroom = (classroom) => {
    const updatedData = { classroomId_current: classroom._id }

    // #1 set teacher 
    dispatch(updateTeacherProfile(teacherId, updatedData))

    // #2 to get updated classroom (need a better way)
    dispatch(getClassroom(classroom._id))

    // #3 update students 
    const { studentIds } = classroom
    if (studentIds?.length) {
      studentIds.forEach(studentId => {
        dispatch(updateStudentProfile_2(studentId, updatedData))
      })
    }
  }


  const loadClassroom = (classroom) => {
    history.push('/classroomedit/', classroom)
  }


  const allClassrooms = classroomIds
    ? classroomIds.map(classroom => {
      if (!classroom) { return }
      let activeLight = "🔘"
      if (classroomId_current) {
        if (classroomId_current._id === classroom._id) {
          activeLight = "🟢"
        }
      }
      return (
        <>
          <ReactTooltip multiline={true} delayShow='1000' />

          <Table.Row>

            <Table.Cell>
              <span
                onClick={setCurrentClassroom.bind(null, classroom)}
                style={{ cursor: 'pointer' }}
                data-tip={"Set as current class"}>
                {activeLight}
              </span>
            </Table.Cell>

            <Table.Cell onClick={loadClassroom.bind(null, classroom)}
              style={{ cursor: 'pointer' }}
              data-tip={"Edit " + classroom.name} >
              {classroom.name}
            </Table.Cell>

            <Table.Cell onClick={loadClassroom.bind(null, classroom)}
              style={{ cursor: 'pointer' }}
              data-tip={"Edit " + classroom.name} >
              {classroom?.grade}
            </Table.Cell>

            <Table.Cell onClick={loadClassroom.bind(null, classroom)}
              style={{ cursor: 'pointer' }}
              data-tip={"Edit " + classroom.name} >
              {classroom.description}
            </Table.Cell>

            <Table.Cell onClick={loadClassroom.bind(null, classroom)}
              style={{ cursor: 'pointer' }}
              data-tip={"Edit " + classroom.name} >
              {classroom.studentIds && classroom.studentIds.length}
            </Table.Cell>

            <Table.Cell >
              <span
                onClick={joinCodeOptions.bind(null, classroom)}
                style={{ cursor: 'pointer' }}
                data-tip={"Copy to Clipboard"}>
                {classroom.joinCode}
              </span>
            </Table.Cell>

            <Table.Cell>
              <span
                onClick={onClickDelete.bind(null, classroom)}
                style={{ cursor: 'pointer' }}
                data-tip={"DELETE " + classroom.name} >
                ❌ </span>
            </Table.Cell>

          </Table.Row>
        </>)
    })
    :
    null

  if (loading) { return <SimpleSpinner /> }

  if (!schoolId) {
    return (
      <>
        <h2> You must be part of a school before creating a classroom.</h2>
        <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
      </>
    )
  }

  return (
    <>
      <DeleteModal
        header={"Proceed with caution. This is not a reversable action."}
        modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to delete the class " + (deleteSelectionForModal ? deleteSelectionForModal.name : null) + "?"}</h3>}
        icon={<Icon name='warning sign' />}
        size='large'
        showModal={!!deleteSelectionForModal} // used as boolean
        setShowModal={setDeleteSelectionForModal}
        handleConfirm={deleteConfirmed}
        handleDecline={(info) => console.log("Nothing to do here, yet", info)}
      />

      {/* <ClassDetailsModal
        showModal={selectedClassroomDetails}
        setShowModal={setSelectedClassroomDetails}
        classroomDetails={selectedClassroomDetails}
      /> */}

      <h2 > {classroomId_current ? classroomId_current.name : " None"}</h2>
      {classroomImage &&
        <RenderSVG
          size="150"
          svgFilename={classroomImage} />}

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Current:</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Grade</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Students</Table.HeaderCell>
            <Table.HeaderCell>Join Code</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body> {allClassrooms} </Table.Body>
      </Table>

      <Button color="green" onClick={() => history.push('/classroomscreate')}>Create a New Class</Button>
    </>
  )
}


export default ClassroomsDisplayTeacher;