import React, { useEffect, useState } from 'react';
import { getAllCommonGoods } from '../../redux/classroom/classroomActions'
import { getStudentProfile, clearStudentRouting } from '../../redux/student/studentActions'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { SimpleSpinner } from "../common/Spinners"
import CommonGoodsDisplayBoard from './CommonGoodsStudentDisplayBoard'
import RenderSVG from '../common/RenderSVG';


const CommonGoodsMain = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentCoin: schoolCoinName } = props
  const [currentCommonGoods, setCurrentCommonGoods] = useState(null)

  // A
  const {
    loading: profileLoading,
    error: profileError,
    studentData,
    studentData: { fb_uid, classroomId_current }
  } = useSelector(state => state.student)

  const {
    commonGoodsAll,
    name: classroomName,
    schoolId,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)


  useEffect(() => {
    dispatch(getStudentProfile(fb_uid))
  }, [])


  useEffect(() => {
    classroomId_current && dispatch(getAllCommonGoods(classroomId_current._id))
    setCurrentCommonGoods(commonGoodsAll) // Erase?
  }, [classroomId_current])

  const makeContribution = (cg) => {
    history.push('/commongoodsstudentcontribution/', { cg, studentData })
  }

  const showContributors = (cg) => {
    history.push('/commongoodsstudentgoalreached/', { cg, studentData })
  }

  if (profileLoading || classroomLoading) { return <SimpleSpinner /> }

  if (profileError || classroomError) {
    return (<>
      <h2>You need to be a part of a class in order to join a raffle.</h2>
      <Button
        onClick={() => {
          dispatch(clearStudentRouting())
          return history.push("/classroomjoin")
        }
        }
        size="large"
        color="blue">Join a class
      </Button>
    </>)
  }


  return (<>

    {schoolId
      ?
      (<>
        <h1>The {classroomName && classroomName} Common Goods Board</h1>
        <div>
          <RenderSVG
            size={"200"}
            svgFilename={"ui/general/teamwork"} />
        </div>
        <h6>Pool your {schoolCoinName}s with classmates and earn something for the  entire class!</h6>
        <br />
      </>)
      :
      (<>
        <h2>You need to be a part of a class in order to join a raffle.</h2>
        <Button
          onClick={() => {
            dispatch(clearStudentRouting())
            return history.push("/classroomjoin")
          }
          }
          size="large"
          color="blue">Join a class
        </Button>
      </>)


    }

    {commonGoodsAll
      ? <CommonGoodsDisplayBoard
        makeContribution={makeContribution}
        showContributors={showContributors}
        currentCommonGoods={commonGoodsAll}
      />
      : null}
    <br />
  </>
  );
}
export default CommonGoodsMain;


