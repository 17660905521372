import firebase from '../../config/fbConfig'
import * as types from './adminTypes'
import http from '../../services/httpService'
const apiEndpoint = "/admins/"

export const clearAdmin = () => {
  return {
    type: types.CLEAR_ADMIN
  }
}

export const clearAdminRouting = () => {
  return {
    type: types.CLEAR_ADMIN_ROUTING
  }
}

// ---- get admin --- //

const getAdminProfileRequest = () => {
  return {
    type: types.GET_ADMIN_PROFILE_REQUEST
  }
}
const getAdminProfileSuccess = admin => {
  return {
    type: types.GET_ADMIN_PROFILE_SUCCESS,
    payload: admin
  }
}
const getAdminProfileFailure = error => {
  return {
    type: types.GET_ADMIN_PROFILE_FAILURE,
    payload: error
  }
}
// returns a function rather than an action
// recieves dispatch method as argument
export const getAdminProfile = (fb_uid) => {
  return (dispatch) => {
    dispatch(getAdminProfileRequest())
    http.get(apiEndpoint + fb_uid)
      .then(response => {
        const profile = response.data
        dispatch(getAdminProfileSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getAdminProfileFailure(errorMsg))
      })
  }
}

// ---- update admin --- //

const updateAdminProfileRequest = () => {
  return {
    type: types.UPDATE_ADMIN_PROFILE_REQUEST
  }
}
const updateAdminProfileSuccess = admin => {
  return {
    type: types.UPDATE_ADMIN_PROFILE_SUCCESS,
    payload: admin
  }
}
const updateAdminProfileFailure = error => {
  return {
    type: types.UPDATE_ADMIN_PROFILE_FAILURE,
    payload: error
  }
}

export const updateAdminProfile = (_id, updatedData) => {
  return async (dispatch) => {
    dispatch(updateAdminProfileRequest())
    try {
      if (updatedData.email) {
        // 1a) firebase - email
        const user = firebase.auth().currentUser;
        const res = await user.updateEmail(updatedData.email)
        console.log("res==+=>>>>", res)

        // 1b) firebase - displayName
        await user.updateProfile({ displayName: updatedData.displayName })
      }

      // 2) Mongo
      const url = "/admins/updateAdminProfile/" + _id;
      const { data: updatedProfile } = await http.patch(url, updatedData)

      dispatch(updateAdminProfileSuccess(updatedProfile))
    }

    catch (err) {
      console.log("🍏🍏🍏🍏🍏ERROR", err)
      const errorMsg = err.message
      dispatch(updateAdminProfileFailure(errorMsg))
    }
  }
}


// ==== SCHOOL === //

const createNewSchoolRequest = () => {
  return {
    type: types.CREATE_NEW_SCHOOL_REQUEST
  }
}
const createNewSchoolSuccess = school => {
  return {
    type: types.CREATE_NEW_SCHOOL_SUCCESS,
    payload: school
  }
}
const createNewSchoolFailure = error => {
  return {
    type: types.CREATE_NEW_SCHOOL_FAILURE,
    payload: error
  }
}

export const createNewSchool = (adminId, schoolData) => {

  return async (dispatch) => {
    dispatch(createNewSchoolRequest())
    try {
      // add admin ID as single item in array to schoolData
      const updatedSchoolData = { ...schoolData, ...{ adminIds: [adminId] } }

      // 1) create new school 
      const url1 = "/schools/";
      const { data: newSchoolRecord } = await http.post(url1, updatedSchoolData)
      const { _id: schoolId } = newSchoolRecord
   
      // 2) admin record pt 1: update currentSchool 
      // admin record pt 2: also update array of schools
      // 
      const url2 = "/admins/createnewschool/" + adminId;

      const newSchoolData = {
        schoolId_current: schoolId
      }
      const { data: updatedAdminRecord } = await http.patch(url2, newSchoolData)

      // NOTE: Schools should go to ADMIN obejct, not it's own
      dispatch(createNewSchoolSuccess(updatedAdminRecord))
    }

    catch (err) {
      console.log(err)
      dispatch(createNewSchoolFailure(err))
    }
  }
}


const updateSchoolRequest = () => {
  return {
    type: types.UPDATE_SCHOOL_REQUEST
  }
}
const updateSchoolSuccess = school => {
  return {
    type: types.UPDATE_SCHOOL_SUCCESS,
    payload: school
  }
}
const updateSchoolFailure = error => {
  return {
    type: types.UPDATE_SCHOOL_FAILURE,
    payload: error
  }
}

// to do: adding/removing admins 
export const updateSchool = (schoolId, updatedSchoolRecord) => {
  console.log("updatedSchoolRecord", updatedSchoolRecord)

  return async (dispatch) => {
    dispatch(updateSchoolRequest())
    try {

      const url = "/schools/updateschool/" + schoolId;
      const { data: schoolDataConfirmed } = await http.patch(url, updatedSchoolRecord)

      //const { _id: schoolId } = schoolDataConfirmed
      console.log("schoolDataConfirmed===>", schoolDataConfirmed)

      // reducer need to be updated
      dispatch(updateSchoolSuccess(schoolDataConfirmed))
    }

    catch (err) {
      console.log(err)
      dispatch(updateSchoolFailure(err))
    }
  }
}


const deleteSchoolRequest = () => {
  return {
    type: types.DELETE_SCHOOL_REQUEST
  }
}
const deleteSchoolSuccess = admin => {
  return {
    type: types.DELETE_SCHOOL_SUCCESS,
    payload: admin
  }
}
const deleteSchoolFailure = error => {
  return {
    type: types.DELETE_SCHOOL_FAILURE,
    payload: error
  }
}

// adminId here not necessary? the array on the schoolId should have this adminID
export const deleteSchool = (schoolIdToDelete, adminId) => {

  return async (dispatch) => {
    dispatch(deleteSchoolRequest())
    try {

      // 1) delete classroom record
      const url1 = "/schools/" + schoolIdToDelete;
      const resultOfSchoolSuccessfullyDeleted = await http.delete(url1)

      const { data: { teacherIds, adminIds, _id: schoolDeletedId } } = resultOfSchoolSuccessfullyDeleted

      // schoolId_current
      console.log("0) SAVE TO COMPARE FOR LATER ", schoolDeletedId)

      console.log("1) resultOfSchoolSuccessfullyDeleted-->", resultOfSchoolSuccessfullyDeleted)
      // save schoolId_current here to compare of class deleted to use in part three. IF schoolId_current is also this schoolId then set to null in part three

      console.log("2) Array of teacherIds to update-->", teacherIds)
      // *** Later this must iterate through array of teaacher first and send patch requests to each studennt to remove from array OR incluse code that deletes any entry in the classroomIds array which results in "" or null

      console.log("3) Array of adminIds to update-->", adminIds)
      // *** Later this must iterate through array of admins first and send patch requests to each admin to remove from array; other admins should be notificed as well; also, check for BUG because of other admins have this set as current, will it cause an error?

      // *** Update this route later to check for more than one admin in adminIds; if so then iterate through
      const url2 = "/admins/removeschool/" + adminId;
      const deleteDataPod = { schoolId: schoolIdToDelete }

      console.log("url2", url2)
      console.log("deleteDataPod", deleteDataPod)

      const { data: updatedAdminObject } = await http.patch(url2, deleteDataPod)


      console.log("4) If School deleted is also the current school, update admin schoolId_current to null")


      dispatch(deleteSchoolSuccess(updatedAdminObject)) // reload profile to avoid populating issues



    }

    catch (err) {
      console.log(err)
      dispatch(deleteSchoolFailure(err))
    }
  }
}


// === PHYSICAL GOODS === //


const getPhysicalGoodRequest = () => {
  return {
    type: types.GET_PHYSICAL_GOOD_REQUEST
  }
}
const getPhysicalGoodSuccess = pr => {
  return {
    type: types.GET_PHYSICAL_GOOD_SUCCESS,
    payload: pr
  }
}
const getPhysicalGoodFailure = error => {
  return {
    type: types.GET_PHYSICAL_GOOD_FAILURE,
    payload: error
  }
}


export const getPhysicalGood = (prizeId) => {

  return async (dispatch) => {
    dispatch(getPhysicalGoodRequest())
    try {
      const url = "/prizes/" + prizeId;

      const { data: pr } = await http.get(url)
      dispatch(getPhysicalGoodSuccess(pr))
    }

    catch (err) {
      console.log(err)
      dispatch(getPhysicalGoodFailure(err))
    }
  }
}


// -------- create ------- //


const createPhysicalGoodRequest = () => {
  return {
    type: types.CREATE_PHYSICAL_GOOD_REQUEST
  }
}
const createPhysicalGoodSuccess = prize => {
  return {
    type: types.CREATE_PHYSICAL_GOOD_SUCCESS,
    payload: prize
  }
}
const createPhysicalGoodFailure = error => {
  return {
    type: types.CREATE_PHYSICAL_GOOD_FAILURE,
    payload: error
  }
}

export const createPhysicalGood = (physicalGoodData) => {

  return async (dispatch) => {
    dispatch(createPhysicalGoodRequest())
    try {
      const url = "/physicalgoods/";
      const { data: newPhysicalGoodsData } = await http.post(url, physicalGoodData)

      dispatch(createPhysicalGoodSuccess(newPhysicalGoodsData))
    }

    catch (err) {
      console.log(err)
      dispatch(createPhysicalGoodFailure(err))
    }
  }
}


// -------- update ------- //


const updatePhysicalGoodRequest = () => {
  return {
    type: types.UPDATE_PHYSICAL_GOOD_REQUEST
  }
}
const updatePhysicalGoodSuccess = prize => {
  return {
    type: types.UPDATE_PHYSICAL_GOOD_SUCCESS,
    payload: prize
  }
}
const updatePhysicalGoodFailure = error => {
  return {
    type: types.UPDATE_PHYSICAL_GOOD_FAILURE,
    payload: error
  }
}

export const updatePhysicalGood = (prizeId, updatedPhysicalGoodData) => {

  return async (dispatch) => {
    dispatch(updatePhysicalGoodRequest())

    try {
      const url1 = "/physicalgoods/update/" + prizeId;
      const { data: physicalGoodData } = await http.patch(url1, updatedPhysicalGoodData)
      dispatch(updatePhysicalGoodSuccess(physicalGoodData))
    }

    catch (err) {
      console.log(err)
      dispatch(updatePhysicalGoodFailure(err))
    }
  }
}


// -------- delete  ------- //


const deletePhysicalGoodRequest = () => {
  return {
    type: types.DELETE_PHYSICAL_GOOD_REQUEST
  }
}
const deletePhysicalGoodSuccess = physicalgood => {
  return {
    type: types.DELETE_PHYSICAL_GOOD_SUCCESS,
    payload: physicalgood
  }
}
const deletePhysicalGoodFailure = error => {
  return {
    type: types.DELETE_PHYSICAL_GOOD_FAILURE,
    payload: error
  }
}

export const deletePhysicalGood = (prizeId) => {

  return async (dispatch) => {
    dispatch(deletePhysicalGoodRequest())
    try {
      const url = "/physicalgoods/" + prizeId;
      const { data: physicalGoodData } = await http.delete(url)

      dispatch(deletePhysicalGoodSuccess(physicalGoodData))
    }

    catch (err) {
      console.log(err)
      dispatch(deletePhysicalGoodFailure(err))
    }
  }
}



///------------ get all ---------------///


const getAllPhysicalGoodsRequest = () => {
  return {
    type: types.GET_ALL_PHYSICAL_GOODS_REQUEST
  }
}
const getAllPhysicalGoodsSuccess = pg => {
  return {
    type: types.GET_ALL_PHYSICAL_GOODS_SUCCESS,
    payload: pg
  }
}
const getAllPhysicalGoodsFailure = error => {
  return {
    type: types.GET_ALL_PHYSICAL_GOODS_FAILURE,
    payload: error
  }
}


export const getAllPhysicalGoods = (schoolId) => {

  return async (dispatch) => {
    dispatch(getAllPhysicalGoodsRequest())
    try {
      const url = "/physicalgoods?schoolId=" + schoolId;

      const { data: allPhysicalGoods } = await http.get(url)
      dispatch(getAllPhysicalGoodsSuccess(allPhysicalGoods))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllPhysicalGoodsFailure(err))
    }
  }
}


///------------ get all school data ---------------///


const getAllTeachersRequest = () => {
  return {
    type: types.GET_ALL_TEACHERS_REQUEST
  }
}
const getAllTeachersSuccess = teacherData => {
  return {
    type: types.GET_ALL_TEACHERS_SUCCESS,
    payload: teacherData
  }
}
const getAllTeachersFailure = error => {
  return {
    type: types.GET_ALL_TEACHERS_FAILURE,
    payload: error
  }
}


export const getAllTeachers = (schoolId) => {

  return async (dispatch) => {
    dispatch(getAllTeachersRequest())
    try {
      const url = "/Teachers?schoolId=" + schoolId;

      const { data: allTeachers } = await http.get(url)
      dispatch(getAllTeachersSuccess(allTeachers))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllTeachersFailure(err))
    }
  }
}



const getAllStudentsRequest = () => {
  return {
    type: types.GET_ALL_STUDENTS_REQUEST
  }
}
const getAllStudentsSuccess = studentData => {
  return {
    type: types.GET_ALL_STUDENTS_SUCCESS,
    payload: studentData
  }
}
const getAllStudentsFailure = error => {
  return {
    type: types.GET_ALL_STUDENTS_FAILURE,
    payload: error
  }
}


export const getAllStudents = (schoolId) => {

  return async (dispatch) => {
    dispatch(getAllStudentsRequest())
    try {
      const url = "/Students?schoolId=" + schoolId;

      const { data: allStudents } = await http.get(url)
      dispatch(getAllStudentsSuccess(allStudents))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllStudentsFailure(err))
    }
  }
}




const getAllClassesRequest = () => {
  return {
    type: types.GET_ALL_CLASSES_REQUEST
  }
}
const getAllClassesSuccess = classData => {
  return {
    type: types.GET_ALL_CLASSES_SUCCESS,
    payload: classData
  }
}
const getAllClassesFailure = error => {
  return {
    type: types.GET_ALL_CLASSES_FAILURE,
    payload: error
  }
}


export const getAllClasses = (schoolId) => {

  return async (dispatch) => {
    dispatch(getAllClassesRequest())
    try {
      const url = "/Classrooms?schoolId=" + schoolId;

      const { data: allClasses } = await http.get(url)
      dispatch(getAllClassesSuccess(allClasses))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllClassesFailure(err))
    }
  }
}



