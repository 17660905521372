import React, { useState } from 'react';
import { useDispatch } from "react-redux"
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../utils/refreshToken';
import { fetchUserGL } from '../../redux/auth/authActions';

const clientId = process.env.REACT_APP_GOOGLE_AUTH

function Login(props) {
  const dispatch = useDispatch()
  const [signedInG, setSignedInG] = useState(false)

  const onSuccess = (res) => {
    setSignedInG(res.isSignedIn())
    refreshTokenSetup(res);
    const { email, familyName: lastName, givenName: firstName, googleId: google_uid, imageUrl: googleImageUrl, name: displayName } = res.profileObj
    dispatch(fetchUserGL({ email, lastName, firstName, google_uid, googleImageUrl, displayName }))
  };

  const onFailure = (res) => {
    console.log(' 😢 😢 😢 Login failed: res:', res);
  };

  return (
    <div style={{ margin: "1px" }}>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login / Sign-up with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}/>
    </div>
  );
}

export default Login;