import React from 'react';
import Lottie from 'react-lottie'
import questionMark from '../../img2/lottie/ui/74441-question-bro.json' // use


const Bonus = () => {

  return (
    <>
      <h1 style={{ color: "green" }}>Coming Soon...</h1>
      <Lottie
        width={500}
        height={500}
        options={{
          loop: false,
          autoplay: true,
          animationData: questionMark,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
          //,viewBox: "0 0 300 300"
        }} />
    </>
  );
}
export default Bonus;
