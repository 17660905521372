import React, { useState, useEffect } from "react";
import { Button, Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import sadFace from '../../img2/lottie/ui/872-empty-list.json'
import confusedKitty from '../../img2/lottie/ui/95990-kitty-confused.json'
import { useModal } from "react-modal-hook";
import ReactModal from "react-modal";
import transferSuccessGraphic from '../../img2/lottie/ui/7679-sending-success.json'
import { adjustStudentCoinsByTransfer } from "../../redux/student/studentActions"
import { purchaseSchoolwidePhysicalGoodClassroom } from "../../redux/classroom/classroomActions"
import Gif from '../common/gif'
import Lottie from 'react-lottie'
import useSound from 'use-sound';
import whoosh from '../../sound/whoosh.mp3'
import trumpetWah from '../../sound/trumpetWah.mp3'


export default function PhysicalGood(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentCoin } = props
  const [playTrumpetWah] = useSound(trumpetWah);

  const { state: { name, price, description = "", prizesRanOutFlag = null, quantityRemaining, image, _id: physicalGoodId = "123" } } = props.history.location

  const {
    error: classroomError,
    loading: classroomLoading,
    _id: classroomId,
    name: classroomName,
    teacherIds,
    // schoolId, 
    // schoolwidePhysicalGoods, 
    okayToCompleteTransaction } = useSelector(state => state.classroom)

  const {
    loading: profileLoading,
    error: profileError,
    studentData,
    studentData: { _id: studentId, firstName, lastName, displayName, coins: studentBalance }
  } = useSelector(state => state.student)

  //const [amount, setAmount] = useState(1);
  const [animationTransferGraphic, setAnimationTransferGraphic] = useState(null);
  const [playWhoosh] = useSound(whoosh);


  const purchaseConfirmedAdjustCoins = () => {
    playWhoosh()

    const transferObject2 = {
      senderId: studentId,
      senderClassroomId: classroomId,
      amount: price
    }

    dispatch(adjustStudentCoinsByTransfer(transferObject2))
    hideConfirmModal()
    setAnimationTransferGraphic(true)
    setTimeout(() => history.push('/schoolstorestudent/'), 2000)

  }

  useEffect(() => {
    if (okayToCompleteTransaction) { purchaseConfirmedAdjustCoins() }
    if (classroomError) {
      console.log("classroomError:", classroomError)
      purchaseRejected()
    }
  }, [okayToCompleteTransaction])

  // const setTokenAmount = (tokens) => {
  //   if (tokens < 1) return;
  //   setAmount(tokens)
  // }

  const handleSubmitTransfer = () => {
    //if (message === '') return;
    //console.log("check the balance first")
    showConfirmModal()
  }

  const confirmTransfer = () => {

    const transferObject1 = { studentId, classroomId, classroomName, firstName, lastName, displayName, teacherName: teacherIds?.[0]?.displayName }

    dispatch(purchaseSchoolwidePhysicalGoodClassroom(physicalGoodId, transferObject1))
  }

  const purchaseRejected = () => {
    console.log("🥀🥀🥀  SOLD OUT🥀🥀🥀")
    // setSoldOut((old) => true)
    hideConfirmModal()
  }

  const cancelTransfer = () => {
    hideConfirmModal()
    history.push('/schoolstorestudent/')
  }

  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    // insufficient funds

    playTrumpetWah()
    if (studentBalance - price < 0) {
      return (
        <ReactModal isOpen >
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: sadFace,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />
          <h1>Sorry, insufficient {currentCoin}s</h1>
          <br />
          <br />
          <div>
            <Button color="grey"
              size="large"
              onClick={cancelTransfer}
            >Return</Button>
          </div>
        </ReactModal>
      )
    }

    // if (soldOut) {
    //   return (
    //     <ReactModal isOpen>
    //       <Container>
    //         <h1>Sold Out!</h1>
    //       </Container>
    //     </ReactModal>
    //   )
    // }

    return (
      <ReactModal isOpen >
        <Container>
          <h2 style={{ "color": "black" }} >Are you sure you want to purchase  {quantityRemaining > 1 ? "a" : "the"} {name} for {price} {currentCoin}{price > 1 ? "s" : null}? </h2>

          {image?.gif
            ? <Gif id={image.gif} size={100} />
            : null}

          <h3 style={{ "color": "black" }}> You will have {studentBalance - price} {currentCoin}s remaining</h3>
          <div>
            <Button color="green" size="large" onClick={confirmTransfer}>Confirm</Button>
            <Button color="red" size="large" onClick={cancelTransfer}>Cancel</Button>
          </div>
          <br />
          <br />
        </Container>
      </ReactModal >
    )
  }, [studentData, price, studentBalance]);

  if (animationTransferGraphic) {
    return (
      <>
        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: transferSuccessGraphic,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
            //,viewBox: "0 0 300 300"
          }} />
        <h1>Purchase Complete!</h1>
      </>
    )
  }

  if (classroomError) {
    console.log(classroomError)
    return (<>
      <Lottie
        width={250}
        height={250}
        options={{
          loop: false,
          autoplay: true,
          animationData: confusedKitty,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
          //,viewBox: "0 0 300 300"
        }} />
      <h1>Sorry. That item seems to be sold out.</h1>
    </>)
  }

  if (classroomLoading) { return <h1>Loading</h1> }

  return (<>
    <>
      <h2>Buy {quantityRemaining > 1 ? "a" : "the"} {name} for {price} {currentCoin}{price > 1 ? "s" : null}? </h2>
      {image?.gif
        ? <Gif id={image.gif} size={200} />
        : null}

      <div>
        <Button
          size="huge"
          color="green"
          onClick={() => handleSubmitTransfer()}
        >Yes!</Button>
      </div>
    </>
  </>);
}



