import React from 'react';
import { useHistory } from "react-router-dom"
import { Button } from 'semantic-ui-react'

const TermsOfUse = (props) => {
  const history = useHistory()
  return (<>
    <h1>Terms Of Use</h1>
    <h6>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta pulvinar arcu, sed consequat massa congue a. In placerat massa sit amet urna aliquet consequat. Integer id sapien vitae turpis auctor hendrerit ac id ligula. Aliquam sem mi, tempor nec odio id, tristique maximus diam. Nullam vel risus a metus vulputate sagittis id at est. Vestibulum ac eleifend augue. Fusce at odio scelerisque, lobortis orci vel, volutpat est. Nunc molestie urna eu mauris interdum, non consequat dolor eleifend. Pellentesque massa velit, scelerisque ut lacus sit amet, ultricies eleifend justo. Pellentesque sollicitudin molestie leo eget pharetra. Suspendisse finibus mattis ipsum eget imperdiet.

      Suspendisse ultrices, sapien in tincidunt pharetra, eros nisl aliquet quam, faucibus lacinia ligula mi id nisi. Aenean non suscipit quam, sit amet pulvinar velit. Donec urna dolor, porta at semper nec, pulvinar a augue. Integer scelerisque sapien orci, in dapibus magna dapibus ac. Nam a urna quis turpis auctor aliquet sed vel massa. Cras vestibulum orci condimentum, ullamcorper magna ac, consequat ipsum. Nulla nec magna nibh. Etiam suscipit in erat placerat luctus. Aenean blandit sem eget urna eleifend, a placerat erat ullamcorper. Maecenas porta hendrerit diam. Etiam sit amet orci est. Sed eu risus tincidunt, pretium libero rhoncus, aliquet justo. Mauris rhoncus fringilla pellentesque. Curabitur a lacinia sem, et sollicitudin ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis at dictum tortor.

      In cursus purus sit amet risus vestibulum mollis. Nunc et mi eu dolor tempor pulvinar. Suspendisse venenatis quam risus, vel bibendum nunc commodo nec. Vivamus nisl elit, sodales auctor sagittis at, ornare nec sapien. Nunc finibus sapien urna, at suscipit ipsum imperdiet at. Vivamus fermentum quam ut pretium viverra. Proin volutpat justo eu justo tempor, molestie auctor arcu accumsan. Aliquam eleifend aliquet nisi ac ornare. Fusce id erat ac sapien dapibus maximus. Nullam scelerisque, augue nec aliquet volutpat, mauris massa pharetra erat, imperdiet pellentesque ipsum diam et tortor. Duis ultrices nisi vulputate auctor molestie. Etiam gravida tincidunt nisi quis suscipit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam maximus augue a magna rhoncus vulputate. Nunc fringilla auctor dui sit amet eleifend. Aliquam id orci vitae tellus venenatis tristique a sed risus.

      Pellentesque nunc quam, aliquet sed ultricies ut, consequat sed elit. Integer turpis quam, vestibulum at felis sed, fringilla dapibus magna. Nullam tristique justo ac tortor eleifend pharetra. Nulla feugiat lacus ac volutpat rutrum. Curabitur tempor velit vitae sapien posuere tempus. Praesent sit amet hendrerit elit. Integer vitae elit et felis venenatis tincidunt quis in magna. Pellentesque lacinia justo et accumsan vehicula. Vivamus nisi sem, feugiat in orci sit amet, sollicitudin cursus nibh. Nullam vel sodales metus. Nunc erat diam, pulvinar at orci sed, euismod hendrerit neque.

      Sed mollis sem ut egestas maximus. Duis cursus, metus vel euismod pharetra, ex sem facilisis quam, nec facilisis eros arcu at risus. Donec at neque risus. Nullam tristique ipsum vitae augue finibus, eu lobortis tortor commodo. Mauris id eros ac massa lobortis cursus vel sed massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum fermentum mollis elit eget mattis. Vestibulum sed nulla posuere, accumsan lectus id, elementum enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam erat volutpat. Integer ex lorem, interdum vitae massa quis, aliquam porttitor leo. Curabitur semper eget risus in fermentum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Mauris mauris diam, bibendum at facilisis eu, malesuada vitae libero.

    </h6>

    <Button color="blue" onClick={() => history.push('/login')}>Return to Login</Button>
  </>
  );
}

export default TermsOfUse;

