import React, { useEffect, useState } from 'react';
import { getAllPrizes, deletePrize, updateClassroom, getClassroom } from "../../redux/classroom/classroomActions"
import { useSelector, useDispatch } from "react-redux"
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { SimpleSpinner } from "../common/Spinners"
import PrizesDisplayBoard from './PrizesDisplayBoard'
import TicketsIssued from './TicketsIssued';
import RenderSVG from '../common/RenderSVG';
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'


const RaffleMainTeacher = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPrizesDisplay, setCurrentPrizesDisplay] = useState(null)

  const {
    loading: profileLoading,
    error: profileError,
    teacherData: { classroomId_current }
  } = useSelector(state => state.teacher)

  const {
    name,
    prizesAll,
    raffleTicketIds,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)

  useEffect(() => {
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
    classroomId_current && dispatch(getAllPrizes(classroomId_current._id))
    setCurrentPrizesDisplay(prizesAll)
  }, [])


  // Update prize when added 
  useEffect(() => {
    if (!classroomLoading) { setCurrentPrizesDisplay(prizesAll) }
  }, [classroomLoading, prizesAll])


  const [nameForTicketDeleteModal, setNameForTicketDeleteModal] = useState(null)
  const [nameForPrizeDeleteModal, setNameForPrizeDeleteModal] = useState(null)

  const handleDeleteStudentTickets = (name) => { setNameForTicketDeleteModal(name) }
  const confirmRaffleTicketsDelete = () => {
    const selectedStudentId = nameForTicketDeleteModal?.studentId
    let selectedStudentUnusedRaffleTicketsRemoved = raffleTicketIds.filter(ticket => {
      const theseAreNOTStudentTickets = ticket.studentId !== selectedStudentId
      return theseAreNOTStudentTickets || ticket.prizeId
    }) // keep all other tickets or a ticket with a prizeID (so not a ticket that can be raffled)
    const dataToUpdate = { raffleTicketIds: selectedStudentUnusedRaffleTicketsRemoved }
    dispatch(updateClassroom(classroomId_current._id, dataToUpdate))
  }


  const handleDeleteStudentPrizes = (name) => { setNameForPrizeDeleteModal(name) }
  const confirmPrizeTicketsDelete = () => {
    const selectedStudentId = nameForPrizeDeleteModal?.studentId
    let selectedStudentWinningPrizesRemoved = raffleTicketIds.filter(ticket => {
      const theseAreNOTStudentTickets = ticket.studentId !== selectedStudentId
      return theseAreNOTStudentTickets || !ticket.prizeId
    }) // keep all other tickets or a ticket with a prizeID (so not a ticket that can be raffled)
    const dataToUpdate = { raffleTicketIds: selectedStudentWinningPrizesRemoved }
    dispatch(updateClassroom(classroomId_current._id, dataToUpdate))
  }

  const handleDeletePrize = (prize) => { dispatch(deletePrize(prize._id)) }
  const editItem = (item) => { history.push('/prizeedit', item) }
  const raffleItOff = (item) => { history.push('/prizeraffleoff', { item }) }
  const endRaffle = () => { handleDeleteAllTickets() }

  const handleDeleteAllTickets = () => {
    const dataToUpdate = { raffleTicketIds: [] }
    // iterate and delete all individual tickets
    dispatch(updateClassroom(classroomId_current._id, dataToUpdate))
  }



  if (profileLoading || classroomLoading) { return <SimpleSpinner /> }

  if (profileError || classroomError) {
    return <h2>Error loading teacher profile</h2>
  }

  if (!classroomId_current || !classroomId_current._id) {
    return (<>
      <h2>No class has been selected.</h2>
      <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button> </>)
  }

  return (<>

    <DeleteModal
      header={"PERMANENTLY DELETE RAFFLE TICKETS"}
      modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to vaporize all of " + (nameForTicketDeleteModal ? nameForTicketDeleteModal?.displayName : null) + "'s raffle tickets?"}</h3>}
      icon={<Icon name='warning circle' />}
      size='small'
      showModal={!!nameForTicketDeleteModal} // used as boolean
      setShowModal={setNameForTicketDeleteModal}
      handleConfirm={confirmRaffleTicketsDelete}
      handleDecline={(info) => console.log("Nothing to do here, yet", info)}
    />

    <DeleteModal
      header={"PERMANENTLY REMOVE ALL PRIZES"}
      modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to zap all of " + (nameForPrizeDeleteModal ? nameForPrizeDeleteModal?.displayName : null) + "'s prizes?"}</h3>}
      icon={<Icon name='warning circle' />}
      size='small'
      showModal={!!nameForPrizeDeleteModal} // used as boolean
      setShowModal={setNameForPrizeDeleteModal}
      handleConfirm={confirmPrizeTicketsDelete}
      handleDecline={(info) => console.log("Nothing to do here, yet", info)}
    />


    <h1>The {name && name} Raffle</h1>
    <RenderSVG
      size={"200px"}
      svgFilename={"ui/general/raffleMachine"}
    />
    <br />
    <br />
    <PrizesDisplayBoard
      handleDeletePrize={handleDeletePrize}
      editItem={editItem}
      raffleItOff={raffleItOff}
      currentPrizesDisplay={currentPrizesDisplay} />
    <br />
    <Button
      size="huge" color="black"
      onClick={() => history.push("/prizecreate")}>
      Add New Prize</Button>
    <br />
    <br />
    <TicketsIssued
      endRaffle={endRaffle}
      raffleTicketIds={raffleTicketIds}
      handleDeleteStudentTickets={handleDeleteStudentTickets}
      handleDeleteStudentPrizes={handleDeleteStudentPrizes}
    />
  </>);
}

export default RaffleMainTeacher;

