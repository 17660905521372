export const GET_ADMIN_PROFILE_REQUEST = "GET_ADMIN_PROFILE_REQUEST"
export const GET_ADMIN_PROFILE_SUCCESS = "GET_ADMIN_PROFILE_SUCCESS"
export const GET_ADMIN_PROFILE_FAILURE = "GET_ADMIN_PROFILE_FAILURE"

export const CREATE_ADMIN_PROFILE_REQUEST = "CREATE_ADMIN_PROFILE_REQUEST"
export const CREATE_ADMIN_PROFILE_SUCCESS = "CREATE_ADMIN_PROFILE_SUCCESS"
export const CREATE_ADMIN_PROFILE_FAILURE = "CREATE_ADMIN_PROFILE_FAILURE"

export const UPDATE_ADMIN_PROFILE_REQUEST = "UPDATE_ADMIN_PROFILE_REQUEST"
export const UPDATE_ADMIN_PROFILE_SUCCESS = "UPDATE_ADMIN_PROFILE_SUCCESS"
export const UPDATE_ADMIN_PROFILE_FAILURE = "UPDATE_ADMIN_PROFILE_FAILURE"

export const DELETE_ADMIN_PROFILE_REQUEST = "DELETE_ADMIN_PROFILE_REQUEST"
export const DELETE_ADMIN_PROFILE_SUCCESS = "DELETE_ADMIN_PROFILE_SUCCESS"
export const DELETE_ADMIN_PROFILE_FAILURE = "DELETE_ADMIN_PROFILE_FAILURE"


// -- schools -- //

export const CREATE_NEW_SCHOOL_REQUEST = "CREATE_NEW_SCHOOL_REQUEST"
export const CREATE_NEW_SCHOOL_SUCCESS = "CREATE_NEW_SCHOOL_SUCCESS"
export const CREATE_NEW_SCHOOL_FAILURE = "CREATE_NEW_SCHOOL_FAILURE"

export const GET_SCHOOL_REQUEST = "GET_SCHOOL_REQUEST"
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS"
export const GET_SCHOOL_FAILURE = "GET_SCHOOL_FAILURE"

export const UPDATE_SCHOOL_REQUEST = "UPDATE_SCHOOL_REQUEST"
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS"
export const UPDATE_SCHOOL_FAILURE = "UPDATE_SCHOOL_FAILURE"

export const DELETE_SCHOOL_REQUEST = "DELETE_SCHOOL_REQUEST"
export const DELETE_SCHOOL_SUCCESS = "DELETE_SCHOOL_SUCCESS"
export const DELETE_SCHOOL_FAILURE = "DELETE_SCHOOL_FAILURE"

// -- physical goods -- //

export const CREATE_PHYSICAL_GOOD_REQUEST = "CREATE_NEW_PHYSICAL_GOOD_REQUEST"
export const CREATE_PHYSICAL_GOOD_SUCCESS = "CREATE_NEW_PHYSICAL_GOOD_SUCCESS"
export const CREATE_PHYSICAL_GOOD_FAILURE = "CREATE_NEW_PHYSICAL_GOOD_FAILURE"

export const GET_PHYSICAL_GOOD_REQUEST = "GET_PHYSICAL_GOOD_REQUEST"
export const GET_PHYSICAL_GOOD_SUCCESS = "GET_PHYSICAL_GOOD_SUCCESS"
export const GET_PHYSICAL_GOOD_FAILURE = "GET_PHYSICAL_GOOD_FAILURE"

export const UPDATE_PHYSICAL_GOOD_REQUEST = "UPDATE_PHYSICAL_GOOD_REQUEST"
export const UPDATE_PHYSICAL_GOOD_SUCCESS = "UPDATE_PHYSICAL_GOOD_SUCCESS"
export const UPDATE_PHYSICAL_GOOD_FAILURE = "UPDATE_PHYSICAL_GOOD_FAILURE"

export const DELETE_PHYSICAL_GOOD_REQUEST = "DELETE_PHYSICAL_GOOD_REQUEST"
export const DELETE_PHYSICAL_GOOD_SUCCESS = "DELETE_PHYSICAL_GOOD_SUCCESS"
export const DELETE_PHYSICAL_GOOD_FAILURE = "DELETE_PHYSICAL_GOOD_FAILURE"

export const GET_ALL_PHYSICAL_GOODS_REQUEST = "GET_ALL_PHYSICAL_GOODS_REQUEST"
export const GET_ALL_PHYSICAL_GOODS_SUCCESS = "GET_ALL_PHYSICAL_GOODS_SUCCESS"
export const GET_ALL_PHYSICAL_GOODS_FAILURE = "GET_ALL_PHYSICAL_GOODS_FAILURE"


// -- virtual goods -- //

export const CREATE_VIRTUAL_GOOD_REQUEST = "CREATE_NEW_VIRTUAL_GOOD_REQUEST"
export const CREATE_VIRTUAL_GOOD_SUCCESS = "CREATE_NEW_VIRTUAL_GOOD_SUCCESS"
export const CREATE_VIRTUAL_GOOD_FAILURE = "CREATE_NEW_VIRTUAL_GOOD_FAILURE"

export const GET_VIRTUAL_GOOD_REQUEST = "GET_VIRTUAL_GOOD_REQUEST"
export const GET_VIRTUAL_GOOD_SUCCESS = "GET_VIRTUAL_GOOD_SUCCESS"
export const GET_VIRTUAL_GOOD_FAILURE = "GET_VIRTUAL_GOOD_FAILURE"

export const UPDATE_VIRTUAL_GOOD_REQUEST = "UPDATE_VIRTUAL_GOOD_REQUEST"
export const UPDATE_VIRTUAL_GOOD_SUCCESS = "UPDATE_VIRTUAL_GOOD_SUCCESS"
export const UPDATE_VIRTUAL_GOOD_FAILURE = "UPDATE_VIRTUAL_GOOD_FAILURE"

export const DELETE_VIRTUAL_GOOD_REQUEST = "DELETE_VIRTUAL_GOOD_REQUEST"
export const DELETE_VIRTUAL_GOOD_SUCCESS = "DELETE_VIRTUAL_GOOD_SUCCESS"
export const DELETE_VIRTUAL_GOOD_FAILURE = "DELETE_VIRTUAL_GOOD_FAILURE"

export const GET_ALL_VIRTUAL_GOODS_REQUEST = "GET_ALL_VIRTUAL_GOODS_REQUEST"
export const GET_ALL_VIRTUAL_GOODS_SUCCESS = "GET_ALL_VIRTUAL_GOODS_SUCCESS"
export const GET_ALL_VIRTUAL_GOODS_FAILURE = "GET_ALL_VIRTUAL_GOODS_FAILURE"


// --  get all data for a school -- //

export const GET_ALL_TEACHERS_REQUEST = "GET_ALL_TEACHERS_REQUEST"
export const GET_ALL_TEACHERS_SUCCESS = "GET_ALL_TEACHERS_SUCCESS"
export const GET_ALL_TEACHERS_FAILURE = "GET_ALL_TEACHERS_FAILURE"

export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST"
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS"
export const GET_ALL_STUDENTS_FAILURE = "GET_ALL_STUDENTS_FAILURE"

export const GET_ALL_CLASSES_REQUEST = "GET_ALL_CLASSES_REQUEST"
export const GET_ALL_CLASSES_SUCCESS = "GET_ALL_CLASSES_SUCCESS"
export const GET_ALL_CLASSES_FAILURE = "GET_ALL_CLASSES_FAILURE"


export const CLEAR_ADMIN_ROUTING = "CLEAR_ADMIN_ROUTING"
export const CLEAR_ADMIN = "CLEAR_ADMIN"
