import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'
import RenderSVG from '../common/RenderSVG';
import { useHistory } from 'react-router-dom'
import Confetti from 'react-dom-confetti';
import confettiConfig from '../../data/confettiConfig'


const GoalReached = (props) => {
  const history = useHistory()
  const [donorList, setDonorList] = useState(null)
  const [showConfettiNow, setShowConfettiNow] = useState(null)
  const { state: { cg, showConfetti } = {} } = props.history.location
  const { currentCoin } = props

  const donors = cg?.contributorIds.map(student => {
    return <h4 style={{ "fontSize": "1em" }} key={student._id}>{student.displayName}{" "}{cg.contributorLedger[student._id][0]}</h4>
  })

  // console.log(cg)
  // console.log("showConfetti", showConfetti)

  useEffect(() => {
    setDonorList(donors)
    setShowConfettiNow(showConfetti)
  }, [])


  return (<>
    <Confetti active={showConfettiNow} config={confettiConfig} />
    <h1 style={{ "fontSize": "5em", "color": "gold" }}>{cg?.title} Goal Reached!</h1>
    <h1 style={{ "fontSize": "3em", "color": "gold" }}>
      {cg?.currentDonatedAmount} {currentCoin}s Donated</h1>
    {cg.image
      ? <RenderSVG
        size={"150"}
        svgFilename={cg.image.svg} />
      : null}
    {/* <h1 style={{ "fontSize": "3em", "color": "black" }}> {cg?.description}</h1> */}
    <h2 style={{ "fontSize": "2.5em", "margin": "5px", "color": "black" }}>Thank you to everybody who donated!
      {donorList}</h2>
    <br />
    <br />
    <Button
      size="huge"
      color="yellow"
      onClick={() => history.push('/commongoodsstudent/')}
    >Common Goods</Button>

  </>
  );
}



export default GoalReached;


