
// import RenderAvatar from '../common/RenderAvatar';
// import RenderSVG from '../common/RenderSVG';
// import { superHeros } from "../../img/avatars/superHeros"
import moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs


const pdfTable = ({title, titleFontSize, columnDisplayTitles, pdfRowDataForTable, numberOfRecords="-"}) => {


const pdfRowData= [
  columnDisplayTitles, 
  ...pdfRowDataForTable
]

const widths = Array(columnDisplayTitles.length).fill('auto');
// widths: [ '*', 'auto', 100, '*' ],

var docDefinition = {
  content: [
    { text: title, fontSize: titleFontSize, bold: true },
    { layout: 'headerLineOnly', // optional lightHorizontalLines, headerLineOnly, noBorders
      table: {
        headerRows: 1, 
        widths, 
        body: pdfRowData}
    },
    { text: "  ", fontSize: 20 },
    { text: "Total " + title + ": " + numberOfRecords},
    { text: "  ", fontSize: 8 },
    { text: moment().format("dddd, MMMM Do YYYY, h:mm:ss a") }
  
  ]
};

pdfMake.createPdf(docDefinition).download(); 

}



export default pdfTable