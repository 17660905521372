import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import isEmpty from '../../utils/isEmpty';
import { Button } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { getGrouptheme, updateGrouptheme } from "../../redux/classroom/classroomActions"
import { SimpleSpinner } from '../common/Spinners';
import GroupsListTable from './GroupsListTable';
import { EditText } from 'react-edit-text';

const GroupsList = (props) => {
  const [studentNames, setStudentNames] = useState(null)
  const [groupthemeName, setGroupthemeName] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    studentIds,
    groupthemeData,
    groupthemeData: { groupIds, topic, _id: groupthemeId },
    loading: classroomLoading,
    groupTransferComplete,
    groupthemeId_current } = useSelector(state => state.classroom)


  useEffect(() => {
    if (groupthemeId_current) {
      dispatch(getGrouptheme(groupthemeId_current._id))
    }
    setGroupthemeName(topic)
  }, [])

  useEffect(() => {
    if (groupTransferComplete === true) {
      dispatch(getGrouptheme(groupthemeId_current._id))
    }
  }, [groupTransferComplete])

  // namesObject creates studentNames
  // each key is groupId /each value is array of student tuples
  // [first, last, id, avatarcode, placeholde-for-group=""]
  useEffect(() => {
    const namesObject = {}
    // iterate over groups
    if (studentIds) {
      const studentIdsNoGroup = [...studentIds]
      groupIds && groupIds.forEach(currentGroup => {

        // Build namesObject with keys from each group
        namesObject[currentGroup._id] = []

        // Nested iteration over studentIds of each group 
        // studentGroupMember = student group memeber
        const studentIdsCurrentGroup = currentGroup.studentIds
        studentIdsCurrentGroup.forEach(studentGroupMember => {

          // match to studentIds
          studentIds.forEach(student => {
            if (student._id === studentGroupMember) {

              // REMOVE STUDENT from studentIdsNoGroup
              _.remove(studentIdsNoGroup, function (st) {
                return st._id === student._id;
              });

              // [first, last, id, avatarcode, placeholde-for-group=""]
              namesObject[currentGroup._id].push([student.firstName, student.lastName, student._id, student.avatar?.avatarId, "groupName-placeholder", "groupId-placeholder", null, null, null, null, student.email])
            }
          })

          // build tuples for studentIdsNoGroup
          namesObject.nogroup = []
          studentIdsNoGroup.forEach(st => {
            namesObject["nogroup"].push([st.firstName, st.lastName, st._id, st.avatar?.avatarId, "groupName-placeholder", "groupId-placeholder", null, null, null, null, st.email]) // at index 10 is email
          })
        })
      })

      setStudentNames(namesObject)
    }
  }, [groupIds, studentIds, classroomLoading])

  const handleChange = (e, setFn) => {
    setFn(e.target.value);
  };

  const saveUpdatedName = () => {
    const updatedGrouptheme = {
      ...groupthemeData
    }
    updatedGrouptheme.topic = groupthemeName;
    dispatch(updateGrouptheme(groupthemeId, updatedGrouptheme))
  };

  if (!groupthemeId_current) {
    return (<>
      <h3>No group theme has been selected.</h3>
      <Button onClick={() => history.push("/groupthemeslist")}>Create Group Theme</Button>
    </>)
  }

  if (!studentNames || isEmpty(studentNames)) return <SimpleSpinner />

  return (
    <>
      <h1> <EditText
        name="textbox2"
        style={{
          padding: '10px',
          color: "black",
          fontSize: '1em'
        }}
        defaultValue={topic}
        value={groupthemeName}
        onChange={(e) => handleChange(e, setGroupthemeName)}
        onSave={saveUpdatedName} />
      </h1>

      <GroupsListTable
        topic={topic}
        studentIds={studentIds}
        groupthemeIdCurrentId={groupthemeId_current?._id}
        studentNames={studentNames} />
    </>
  )
};

export default GroupsList;
