
import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateSchool } from '../../redux/admin/adminActions'
import schoolIcons from '../../data/icons_schools.json'
import RenderSVG from '../common/RenderSVG'



export default function SchoolEdit(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { state: { _id: schoolId, schoolJoinCode, mascot, name, coinName, description = "", image } } = props.history.location

  // watch removed
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name,
      mascot,
      coinName,
      description
    }
  });

  const { error, loading, adminData: { _id: adminId } } = useSelector(state => state.admin)

  const [schoolUpdated, setSchoolUpdated] = useState(false)

  const iconNumberOrig = schoolIcons.set_1.findIndex(object => {
    return object.english === image?.english;
  });

  const [iconNumber, setIconNumber] = useState(iconNumberOrig !== -1 ? iconNumberOrig : 0)



  const onSubmit = schoolData => {
    // 1) add option to regenerate joinSchoolCode
    // 2) Add/removed admins (adminId accessable but not needed)
    const updatedSchoolData = { ...schoolData, schoolJoinCode }
    updatedSchoolData.image = schoolIcons.set_1[iconNumber]
    dispatch(updateSchool(schoolId, updatedSchoolData))
    setSchoolUpdated(true)
  };

  const changeIcon = () => {
    let newIconNumber = iconNumber + 1
    if (newIconNumber > schoolIcons.set_1.length - 1) {
      newIconNumber = 0
    }
    setIconNumber(newIconNumber)
  }


  if (loading && schoolUpdated) { history.push("/schoolsdisplay") }
  if (loading) return <h1>Loading</h1>


  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }


  return (
    <>
      <h1>Edit school</h1>

      {iconNumber !== null &&
        <div onClick={changeIcon}>
          <RenderSVG
            size="100"
            // svgFilename={icon}
            svgFilename={schoolIcons.set_1[iconNumber]?.svg} />
        </div>}


      <form onSubmit={e => e.preventDefault()}>

        <label>Name</label>
        <input
          name="name"
          type="text"
          ref={register({
            required: "You must include a school name",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.name && <p>{errors.name.message}</p>}


        <label>Mascot</label>
        <input
          name="mascot"
          type="text"
          ref={register({
            required: "You must include a school mascot",
            minLength: {
              value: 2,
              message: "Mascots must have at least 2 characters"
            }
          })}
        />

        <label>Kansha Token (use singular tense)</label>
        <input
          name="coinName"
          type="text"
          ref={register({
            required: "You must include a school token",
            minLength: {
              value: 2,
              message: "Mascots must have at least 2 characters"
            }
          })}
        />

        <label>Description (optional)</label>
        <input
          name="description"
          type="text"
          ref={register()}
        />

        {/* 
        <label>Upload or drag-n-drop school logo </label>
        <input
          name="description"
          type="text"
        // */}

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='green'
        >Update School</Button>

        <br />

      </form>

    </>
  );
}




