import _ from 'lodash'
import React, { useState } from 'react'

import "./test_fourcorners.css"

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}

const FourCornersTest = () => (

  <>
    <div id="content-test-four-corners">
      <header>Four Corner Game</header>
      <section id="timer" onClick={console.log("timer")}>Prize? Timer :10</section>
      <section id="upper-left" onClick={console.log("MY BIG ICON HERE! -->UL")}>Upperleft</section>
      <section id="upper-right" onClick={console.log("MY BIG ICON HERE! -->UR")}>Upperright</section>
      <section id="lower-left" onClick={console.log("MY BIG ICON HERE! -->LL")}>Lowerleft</section>
      <section id="lower-right" onClick={console.log("MY BIG ICON HERE! -->LR")}>Lowerright</section>
      <footer>Students who are OUT!</footer>
    </div>

  </>

)

export default FourCornersTest