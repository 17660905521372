import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

// const notify = ({
//    message = "TEXT NEEDED",
//  noticeType = "", 
//  position, autoClose = 2000, 
//  onClose: () => console.log("nada") 
// }) => {


//   if (!noticeType || noticeType === "") {
//     toast(message,
//       { position: toast.POSITION[position], onClose: onClose })
//   } else {
//     toast[noticeType](message, { position: toast.POSITION[position], autoClose })
//   }
//   return null
// }
const notify = ({
  message = "TEXT NEEDED",
  noticeType = "", position,
  autoClose = 2000, onClose = (nada) => nada }) => {

  if (!noticeType || noticeType === "") {
    toast(message,
      { onClose: onClose, position: toast.POSITION[position], autoClose })
  } else {
    //  console.log("🌳🌳🌳 onClose 🌳🌳🌳", onClose)
    toast[noticeType](message, { onClose: onClose, position: toast.POSITION[position], autoClose })
  }
  return null
}

export default notify








/*
 Position choices: TOP_LEFT, TOP_CENTER, TOP_RIGHT
                 BOTTOM_LEFT, BOTTOM_CENTER, BOTTOM_RIGHT

NoticeType: success (green), info (blue), warning (yellow), error (red), or default (white w/rainbow)


 autoClose: 5 seconds is default, set false for manual user close
 */

