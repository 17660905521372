import React from 'react';
import { useHistory } from 'react-router-dom'
import firebase from "../../config/fbConfig"
import { Container, Button } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import UserContainer from '../common/UserContainer'

const SuperDashboard = () => {
  //const history = useHistory()
  return (
    <Container>
      <UserContainer />
      <h2>Super Dashboard:</h2>
      <h2>{"  "}</h2>
      <h2>{"  "}</h2>
      <h2>{"  "}</h2>
      <h2>{"  "}</h2>
      <h2>{"  "}</h2>

      <Button onClick={() => firebase.auth().signOut()}>Sign out</Button>

    </Container >);
}
export default SuperDashboard;
