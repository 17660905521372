import React, { Component } from 'react';

class RenderAvatar extends Component {

  render() {
    const { size, avatarId, setOfAvatars } = this.props
    return (
      <>
        <svg id="Capa_1"
          enableBackground="new 0 0 512 512"
          height={size}
          width={size}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg">

          {setOfAvatars[avatarId]}
        </svg>
      </>
    );
  }
}

export default RenderAvatar;


/*
<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
 */

