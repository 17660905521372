import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";
import { Button } from 'semantic-ui-react'
import Lottie from 'react-lottie'
import { clearStudent } from '../../redux/student/studentActions'
import { clearTeacher } from '../../redux/teacher/teacherActions'
import { clearAdmin } from '../../redux/admin/adminActions'
// import useGoogleLogout from '../hooks/useGoogleLogout'
import { clearUserFB, clearUserGL } from '../../redux/auth/authActions';
import errorCat from '../../img2/lottie/ui/9195-error.json'


export default function LoginError(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const goToLogin = () => {
    history.push('/login')
  }

  useEffect(() => {
    dispatch(clearStudent())
    dispatch(clearTeacher())
    dispatch(clearAdmin())
    dispatch(clearUserFB())
    dispatch(clearUserGL())
  }, [dispatch])


  return (<>
    <h1>Something is not quite right.</h1>
    <Lottie
      width={250}
      height={250}
      options={{
        loop: false,
        autoplay: true,
        animationData: errorCat,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
    />
    <h3>Check your username and password, and try again.</h3>
    <Button size="large" onClick={goToLogin}>Login</Button>
    <br />
    <br />
  </>)


}


