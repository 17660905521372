import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie'

const RenderLottie = (props) => {
  const { size = 50, filename } = props
  let [lottie, setLottie] = useState('');

  useEffect(() => {
    async function makeItHappen() {
      let importedIcon = await import(`../../images/${filename}.json`);
      setLottie(importedIcon.default);
    }
    makeItHappen()
  }, [filename, size]);

  return (<>
    <Lottie
      width={size}
      height={size}
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }} />
  </>)
};

export default RenderLottie;

