import React from 'react';
import { useHistory } from "react-router-dom"
import { Button } from 'semantic-ui-react'
import Lottie from 'react-lottie'
import graphic404 from '../../img2/lottie/ui/43391-confused-robot.json'

const PageNotFound = (props) => {
  const history = useHistory()
  return (<>
      <Lottie
      width={400}
      height={400}
      options={{
        loop: false,
        autoplay: true,
        animationData: graphic404,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
    />

    <Button color="blue" size="huge" onClick={() => history.push('/login')}>Try again</Button>
  </>
  );
}

export default PageNotFound;

