import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Table, Button, Icon } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { getAdminProfile, updateAdminProfile, deleteSchool } from '../../redux/admin/adminActions'
import { getAllClasses, getAllTeachers, getAllStudents } from "../../redux/admin/adminActions"

import { SimpleSpinner } from "../common/Spinners"
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import RenderSVG from '../common/RenderSVG';
import ReactTooltip from 'react-tooltip';

const buttonStyle = {background: "#55ede8", color: "white", fontSize:"1em"}

const SchoolsDisplay = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteSelectionForModal, setDeleteSelectionForModal] = useState(null)
  const [selectedSchool, setSelectedSchool] = useState(null)

  const { adminData: { schoolId_current, displayName, schoolIds, _id: adminId }, loading, allStudents, allTeachers } = useSelector(state => state.admin)
  const { user: { fb_uid } } = useSelector(state => state.auth)

  //const schoolIdWorkaround = schoolId_current ? schoolId_current.name : ""
  const schoolImage = schoolId_current?.image?.svg

  // useEffect(() => {
  //   dispatch(getAdminProfile(fb_uid))
  // }, [schoolIdWorkaround])

  useEffect(() => {
    dispatch(getAdminProfile(fb_uid))
  }, [schoolId_current?._id])



  const onClickDelete = (school) => {
    setDeleteSelectionForModal(school)
  }

  const deleteConfirmed = () => {
    dispatch(deleteSchool(deleteSelectionForModal._id, adminId))
  }

  const setCurrentSchool = (school) => {
    const updatedData = {schoolId_current: school._id }
    dispatch(getAllClasses(school._id ))
    dispatch(getAllTeachers(school._id ))
    dispatch(getAllStudents(school._id ))

    dispatch(updateAdminProfile(adminId, updatedData))
  }

  const loadSchool = (school) => {
    history.push('/schooledit/', school)
  }


  const schoolJoinCodeOptions = (school) => {
    copy(school.schoolJoinCode)
    showSchoolJoinCodeModal()
    setSelectedSchool(() => school)
  }


  const [showSchoolJoinCodeModal, hideSchoolJoinCodeModal] = useModal(() => {
    const { schoolJoinCode } = selectedSchool
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
      >
        <div onClick={() => hideSchoolJoinCodeModal()}>
          <h1 style={{ color: "black", fontSize: "2em" }}>The code to join the school {selectedSchool.name} is:</h1>
          <h1 style={{ color: "blue", fontSize: "6em" }}> {schoolJoinCode}</h1>
          <h1 style={{ color: "black", fontSize: "2em" }}>It has been copied to your clipboard.</h1>
        </div>
      </ReactModal >)
  }, [selectedSchool]);

  const allSchools = schoolIds?.map(school => {
    let activeLight = "🔘"
    if (schoolId_current) {
      if (schoolId_current._id === school._id) {
        activeLight = "🟢"
      }
    }

    return (
      <Table.Row>
        <Table.Cell>
          <span
            onClick={setCurrentSchool.bind(null, school)}
            style={{ cursor: 'pointer' }}
            data-tip={"Set " + school?.name + " as current school."} >
            {activeLight}
          </span>
        </Table.Cell>
        <Table.Cell onClick={loadSchool.bind(null, school)} data-tip={"Edit " + school?.name} style={{ cursor: 'pointer' }}  >
          {school.name} </Table.Cell>
        <Table.Cell onClick={loadSchool.bind(null, school)} data-tip={"Edit " + school?.name} style={{ cursor: 'pointer' }} > {school.mascot} </Table.Cell>
        <Table.Cell onClick={loadSchool.bind(null, school)} data-tip={"Edit " + school?.name} style={{ cursor: 'pointer' }} > {school.coinName} </Table.Cell>
        <Table.Cell onClick={loadSchool.bind(null, school)} data-tip={"Edit " + school?.name} style={{ cursor: 'pointer' }}> {school.description} </Table.Cell>
        <Table.Cell >
          <span
            onClick={schoolJoinCodeOptions.bind(null, school)}
            style={{ cursor: 'pointer' }}
            data-tip={"Copy code to clipboard"} >
            {school.schoolJoinCode}
          </span>
        </Table.Cell>
        <Table.Cell onClick={onClickDelete.bind(null, school)} data-tip={"DELETE " + school?.name} style={{ cursor: 'pointer' }}>❌</Table.Cell>
      </Table.Row>)
  })

  if (loading) { return <SimpleSpinner /> }

  return (
    <>
      <ReactTooltip multiline={true} delayShow='1000' />

      <DeleteModal
        header={`This is not a reversable action. There are currently ${allTeachers.length} teacher${allTeachers.length === 1 ? null : "s"} and ${allStudents.length} student${allStudents.length === 1 ? null : "s"}  assigned to this school`}
        modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to delete the school " + (deleteSelectionForModal ? deleteSelectionForModal.name : null) + "?"}</h3>}
        icon={<Icon name='warning sign' />}
        size='small'
        showModal={!!deleteSelectionForModal} // used as boolean
        setShowModal={setDeleteSelectionForModal}
        handleConfirm={deleteConfirmed}
        handleDecline={(info) => console.log("Nothing to do here, yet", info)}
      />
      <h2 >Current School: {schoolId_current ? schoolId_current.name : "None"}</h2> 
      {schoolImage &&
        <RenderSVG
          size="150"
          svgFilename={schoolImage} />}

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Current:</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Mascot</Table.HeaderCell>
            <Table.HeaderCell>Gratitude Token</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Join Code</Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{allSchools}</Table.Body>
      </Table>

      <Button
        onClick={() => history.push('/schoolcreate')}
        size="huge"
        type="submit"
        inverted color='blue'>
        Create a New School
      </Button>
      <br />
      <br />
    </>
  )
}


export default SchoolsDisplay;