import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updatePrize, deletePrize, getAllPrizes } from '../../redux/classroom/classroomActions'
//import RenderSVG from '../common/RenderSVG';
import Gif from '../common/gif'

export default function PrizeEdit(props) {
  const { state: { _id: prizeId, title, description = "", prizesRanOutFlag = null, quantityRemaining, image } } = props.history.location


  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      title,
      description,
      quantityRemaining
    }
  });

  const dispatch = useDispatch()
  const history = useHistory()
  const { error, loading, onCompleteRoute } = useSelector(state => state.classroom)

  const {
    teacherData: { classroomId_current }
  } = useSelector(state => state.teacher)

  const [updateSubmitted, setUpdateSubmitted] = useState(false)

  // console.log("prizesRanOutFlag===>", prizesRanOutFlag)

  const onSubmit = update => {
    setUpdateSubmitted(() => true)
    update.quantityRemaining = parseInt(update.quantityRemaining)
    dispatch(updatePrize(prizeId, update))
    dispatch(getAllPrizes(classroomId_current._id))
  };

  const archivePrize = () => {
    dispatch(updatePrize(prizeId, { archived: true }))
    // later check redux for possible error before pushing
    history.push('/rafflemainteacher')
  }


  const handleDeletePrize = () => {
    dispatch(deletePrize(prizeId))
    history.push('/rafflemainteacher')
  }


  if (updateSubmitted && !loading) {
    history.push('/rafflemainteacher')
  }

  //if (!prizesRanOutFlag && onCompleteRoute) { history.push(onCompleteRoute) }
  // if (!prizesRanOutFlag && !loading) { history.push('/rafflemainteacher/') }

  if (loading) { return <h1>Loading</h1> }
  if (error) { return <><h1>Error</h1><h6>{error.message}</h6></> }

  return (
    <>
      <h1>Edit {title}</h1>
      {/* <RenderSVG
        size={"100"}
        svgFilename={svg} /> */}
      {image?.gif
        ? <Gif id={image.gif} size={200} />
        : null}

      <form onSubmit={e => e.preventDefault()}>
        <label>Title</label>
        <input
          name="title"
          type="text"
          ref={register({
            required: "You must include a prize title",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.title && <p>{errors.title.message}</p>}

        <label>Description (optional)</label>
        <input
          name="description"
          type="text"
          ref={register()}
        />

        <label>Quantity</label>
        <input
          name="quantityRemaining"
          type="text"
          ref={register()}
        />

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save Changes</Button>

        <h6>{" "}</h6>
        <div>
          <Button size="tiny" color="red" onClick={handleDeletePrize}>Delete</Button>
        </div>
        <br />
        <br />
        <br />
      </form>

    </>
  );
}




