import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import _ from 'lodash'
import TeamworkDisplay from './GroupsTeamworkBoard'
import { addPointToGroup, getGrouptheme } from '../../redux/classroom/classroomActions'

const GroupsMain2 = (props) => {
  const { setShowNavBar } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    loading:
    classroomLoading,
    studentIds,
    groupthemeData: { groupIds, topic },
    groupthemeId_current
  } = useSelector(state => state.classroom)


  // setScorefromDB
  // scoreLocal
  // updateScoreLocal without a render
  // pageload sets scoreFromDB
  const [studentNames, setStudentNames] = useState(null)
  //const [scoreFromDB, setScoreFroDB] = useState(null)

  const { teacherData } = useSelector(state => state.teacher)

  useEffect(() => {
    setShowNavBar(true)
    dispatch(getGrouptheme(groupthemeId_current?._id))
  }, [])


  // useEffect(() => {
  //   dispatch(getGrouptheme(groupthemeIdCurrentId))
  // }, [])

  // build namesObject
  // each key is groupId
  // each value is array of student tuples
  // [first, last, id, avatarcode, placeholde-for-group=""]

  useEffect(() => {
    const namesObject = {}
    // iterate over groups

    if (studentIds) {
      const studentIdsNoGroup = [...studentIds]
      groupIds && groupIds.forEach(currentGroup => {

        // Build namesObject with keys from each group
        namesObject[currentGroup._id] = []

        // Nested iteration over studentIds of each group 
        // studentGroupMember = student group memeber
        const studentIdsCurrentGroup = currentGroup.studentIds
        studentIdsCurrentGroup.forEach(studentGroupMember => {

          // match to studentIds
          studentIds.forEach(student => {
            if (student._id === studentGroupMember) {

              // REMOVE STUDENT from studentIdsNoGroup
              _.remove(studentIdsNoGroup, function (st) {
                return st._id === student._id;
              });

              // [first, last, id, avatarcode, placeholde-for-group=""]
              namesObject[currentGroup._id].push([student.firstName, student.lastName, student._id, student.avatar?.avatarId, "groupName-placeholder", "groupId-placeholder", null, null, null, null, student.email])
            }
          })

          // build tuples for studentIdsNoGroup
          namesObject.nogroup = []
          studentIdsNoGroup.forEach(st => {
            namesObject["nogroup"].push([st.firstName, st.lastName, st._id, st.avatar?.avatarId, "groupName-placeholder", "groupId-placeholder", null, null, null, null, st.email]) // at index 10 is email
          })
        })
      })

      setStudentNames(namesObject)
    }
  }, [])

  // }, [groupIds, classroomLoading])

  const addGroupPointDb = (groupId) => {
    dispatch(addPointToGroup(groupId, 1))
    //dispatch(getGrouptheme(groupthemeIdCurrentId))
  }


  if (!groupthemeId_current) {
    return (<>
      <h1>No group theme has been selected.</h1>
      <Button onClick={() => history.push("/groupthemeslist")}>Group Theme</Button>
    </>)
  }

  return (
    <TeamworkDisplay
      topic={topic}
      groupIds={groupIds}
      groupthemeIdCurrentId={groupthemeId_current?._id}
      studentNames={studentNames}
      teacherData={teacherData}
      addGroupPointDb={addGroupPointDb}
      classroomLoading={classroomLoading}
    />)

};

export default GroupsMain2;
