// http://www.4codev.com/javascript/rename-all-object-keys-of-an-array-using-javascript-idpx2458097450239050230.html


const changeKeyObjects = (arr, replaceKeys) => {
  return arr.map(item => {
    const newItem = {};
    Object.keys(item).forEach(key => {
      newItem[replaceKeys[key]] = item[[key]];
    });
    return newItem;
  });
};

export default changeKeyObjects




// enter what key that you want to change by old key in an object
// const replaceKeys = { id: "itemId", name: "itemName" };

//const newArray = changeKeyObjects(array, replaceKeys);
// Result:
// [
//   { itemId: "1", itemName: "Apple" },
//   { itemId: "2", itemName: "Tomato" },
//   { itemId: "3", itemName: "Orange" },
//   { itemId: "4", itemName: "Coconut" },
//   { itemId: "5", itemName: "Strawberry" },
//   { itemId: "6", itemName: "Banana" }
// ];