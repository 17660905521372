import React from 'react'
import { Table, Button } from 'semantic-ui-react'


export default function TicketsTable(props) {
  const { raffleTicketIds, handleDeleteStudentTickets, handleDeleteStudentPrizes } = props
  let totalTickets = 0
  let totalPrizesWon = 0

  if (!raffleTicketIds.length) return null

  // create tableData
  const tableData = {}
  raffleTicketIds.forEach(rt => {
    if (rt.studentId) {
      tableData[rt.studentId] = { displayName: rt.studentName[2], ticketsRemaining: [], prizesWon: [], studentId:rt?.studentId }
    }
  })

  raffleTicketIds.forEach((rt => {
    if (rt.archived === true) return null

    // if no prizeId, push raffleTicketId onto 
    if (rt.prizeId === null) {
      const newArrayOfTicketsRemaining = tableData[rt.studentId].ticketsRemaining
      newArrayOfTicketsRemaining.push(rt.id)
      tableData[rt.studentId].ticketsRemaining = newArrayOfTicketsRemaining
      totalTickets++

    } else {

      if (tableData && tableData[rt.studentId]) {
        // if prize, push raffleTicketId onto 
        const newArrayOfPrizes = tableData?.[rt.studentId]?.prizesWon
        newArrayOfPrizes && newArrayOfPrizes.push([rt.prizeTitle, rt.prizeImage, rt.prizeId])
        tableData[rt.studentId].prizesWon = newArrayOfPrizes
        totalPrizesWon++
      }

    }
  }))


  const tableDataKeys = Object.keys(tableData)
  const tableRows = tableDataKeys.map(studentId => {

    const remainingTicketDisplay = tableData[studentId].ticketsRemaining.map(t => {
      return <Button size="tiny" inverted color='blue' content={t} key={t} />
    })

    const prizesWonDisplay = tableData[studentId].prizesWon.map(p => {
      return <Button size="tiny"  inverted color="green" key={p._id}>{p[0]}</Button >})

    return (
      <Table.Row key={studentId}>
        <Table.Cell>{tableData[studentId].displayName}</Table.Cell>
        <Table.Cell>{tableData[studentId].ticketsRemaining?.length}</Table.Cell>
        <Table.Cell >{remainingTicketDisplay.length > 0 ? <>
        {remainingTicketDisplay}       
        <div>
           <Button style={{color:"white"}} 
           size="tiny" color="blue" 
           onClick={()=>handleDeleteStudentTickets(tableData[studentId])}>
            Delete Tickets 
          </Button>
          </div>
        </> :   null}

  

        </Table.Cell>

        <Table.Cell>{prizesWonDisplay.length > 0 ? <>{prizesWonDisplay} 
  
        <Button 
        style={{color:"white"}} 
        color="green"
        size="tiny" 
        onClick={()=>handleDeleteStudentPrizes(tableData[studentId])}>
          Clear Prizes
          </Button> 
          </> 
          : <h6>None</h6>}
        </Table.Cell>
      </Table.Row>
    )
  })

  return (
    < Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Number</Table.HeaderCell>
          <Table.HeaderCell>Remaining</Table.HeaderCell>
          <Table.HeaderCell>Prizes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tableRows}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell>Total with Tickets: {tableRows.length}</Table.HeaderCell>
          <Table.HeaderCell>{totalTickets}</Table.HeaderCell>
          <Table.HeaderCell>{" "}</Table.HeaderCell>
          <Table.HeaderCell>{totalPrizesWon}</Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table >
  )
}


{/* <Table.Header>
  <Table.Row>
    <Table.HeaderCell width={10}>Name</Table.HeaderCell>
    <Table.HeaderCell width='six'>Status</Table.HeaderCell>
  </Table.Row>
</Table.Header> */}

