import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { checkClassCode, joinClassWithCode, clearStudentRouting } from '../../redux/student/studentActions'

export default function ClassroomJoin() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, onCompleteRoute, reduxResponseData, studentData, error } = useSelector(state => state.student)


  // need to notify user if already in class
  const onSubmit = data => {
    dispatch(checkClassCode(studentData._id, data.joincode))
  };

  const addClassConfirmed = () => {
    dispatch(joinClassWithCode(studentData._id, reduxResponseData))
  }

  const addClassRejected = () => {
    dispatch(clearStudentRouting())
    history.push("/studentdashboard")
  }

  const pauseForError = () => {
    dispatch(clearStudentRouting())
    history.push("/studentdashboard")
  }

  const teacherNameDisplay = reduxResponseData && reduxResponseData.teacherIds ? reduxResponseData.teacherIds[0].displayName : "Unknown Teacher"

  const classnameDisplay = reduxResponseData ? reduxResponseData.name : "Unknown Message"

  const message = <><h2>You are about to join a new class! </h2><h3>The teacher is {teacherNameDisplay} and the class is called {classnameDisplay}</h3></>

  if (onCompleteRoute && reduxResponseData === "class-joined") {
    history.push("/studentdashboard")
  }

  if (loading) return <h1>Loading</h1>

  if (error) {
    console.log("error", error)
    return (
      <>
        <h2>Oops. We have a problem. </h2>
        <h3>{error.message}.</h3>
        <Button onClick={pauseForError}>Got it</Button>
      </>
    )
  }

  if (reduxResponseData && reduxResponseData !== "updated") {
    return (
      <>
        {message}
        <Button onClick={addClassConfirmed}>Yes! Join this class</Button>
        <Button onClick={addClassRejected}>Oops. I'll try again.</Button>
      </>)
  }


  return (
    <>
      <h1>Join a classroom</h1>
      <Icon name="keyboard" size="massive" />
      <form onSubmit={e => e.preventDefault()}>
        <label>Enter the 6-digit classroom code</label>
        <input
          name="joincode"
          type="text"
          ref={register({
            required: "You must enter a valid code",
            minLength: {
              value: 6,
              message: "The code must have at least 6 characters"
            }
          })}
        />
        {errors.joincode && <p>{errors.joincode.message}</p>}
        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Submit Code
        </Button>
        <h2>{"   "}</h2>
      </form>

    </>
  );
}




