import _ from 'lodash'
import React from 'react'

import "./test_dashboard.css"

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}

const DashboardTest = () => (

  <>
    <body>

      <div id="dashboard-test-content">

        <header>Header</header>

        <main>Main</main>

        <aside>Aside</aside>

        <nav>Nav</nav>

        <footer>Footer</footer>

      </div>

    </body>
  </>

)

export default DashboardTest