
import React, { useState } from 'react';
import { Container, Button, Icon } from 'semantic-ui-react'
import { ModalControlledExternally as Modal } from '../common/ModalExternalTrigger'

const TestGround = () => {
  const [showModal1, setShowModal1] = useState(false)

  return (
    <Container>
      <Modal
        header={"Look at this pretty icon. What would like to do?"}
        modalContent={<h2>{"Can you confirm or deny the existance of the yeti??"}</h2>}
        icon={<Icon name='sign language' />}
        size='medium'
        showModal={showModal1}
        setShowModal={setShowModal1}
        handleConfirm={(info) => console.log("YES", info)}
        handleDecline={(info) => console.log("NO", info)} />
      <h1>Testing Ground</h1>



      <Button onClick={() => setShowModal1(true)}>
        External Modal Trigger #1
        </Button>




    </Container>
  )
};

export default TestGround
