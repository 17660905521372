import * as types from './adminTypes'
import _ from 'lodash'

const initAdmin = {
  loading: false,
  adminData: {},
  error: '',
  onCompleteRoute: null, //phase out
  reduxResponseData: null,
  physicalGoodMostRecent: null,
  physicalGoodsAll: null,
  virtualGoodMostRecent: null,
  virtualGoodsAll: null,
  allStudents: [],
  allTeachers: [],
  allClasses: []
}


function adminReducer(state = initAdmin, action) {
  switch (action.type) {

    case types.GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reduxResponseData: null
      };

    case types.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: action.payload[0],
        error: '',
        onCompleteRoute: "/admindashboard",
        reduxResponseData: null
      };

    case types.GET_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        adminData: {},
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };



    case types.UPDATE_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reduxResponseData: null
      };

    case types.UPDATE_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: action.payload,
        error: '',
        onCompleteRoute: "/admindashboard",
        reduxResponseData: "updated"
      };

    case types.UPDATE_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };



    case types.CREATE_NEW_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reduxResponseData: null
      };

    case types.CREATE_NEW_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: action.payload,
        error: '',
        onCompleteRoute: "/admindashboard",
        reduxResponseData: true
      };

    case types.CREATE_NEW_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };


    case types.UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        // try IMMER: https://github.com/immerjs/immer
        adminData: action.payload,
        error: '',
        onCompleteRoute: "/admindashboard"
      };

    case types.UPDATE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null
      };



    case types.DELETE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.DELETE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: action.payload,
        error: ''
        //onCompleteRoute: null,
        //reloadProfile: true
      };

    case types.DELETE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };



    // ####### PHYSICAL GOODS ####### //

    // ----- create ----- //

    case types.CREATE_PHYSICAL_GOOD_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.CREATE_PHYSICAL_GOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: action.payload,
        error: '',
        onCompleteRoute: null
      };

    case types.CREATE_PHYSICAL_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: null,
        error: action.payload,
        onCompleteRoute: null
      };


    // ------ get ------ //

    case types.GET_PHYSICAL_GOOD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_PHYSICAL_GOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: action.payload,
        error: ''
      };

    case types.GET_PHYSICAL_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: null,
        error: action.payload

      };


    // ------ update ------ //

    // const oldCommonGoods = [...state.commonGoodsAll]
    // const removedCommonGood = oldCommonGoods.filter(cg => cg._id != action.payload._id)

    case types.UPDATE_PHYSICAL_GOOD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_PHYSICAL_GOOD_SUCCESS:
      const newPhysicalGoodsAll = [...state.physicalGoodsAll]
      const indexOfPhysicalGood = _.findIndex(newPhysicalGoodsAll, function (o) { return o._id === action.payload._id; });

      newPhysicalGoodsAll[indexOfPhysicalGood] = action.payload

      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: action.payload,
        physicalGoodsAll: newPhysicalGoodsAll,
        onCompleteRoute: "/admindashboard",
        error: '',
      };

    case types.UPDATE_PHYSICAL_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: null,
        error: action.payload,
      };


    // ------ delete ------ //

    case types.DELETE_PHYSICAL_GOOD_REQUEST:
      return {
        ...state,
        loading: true,
        physicalGoodMostRecent: null
      };

    case types.DELETE_PHYSICAL_GOOD_SUCCESS:

      const oldPhysicalGoods = [...state.physicalGoodsAll]
      const removeDeletedPhysicalGood = oldPhysicalGoods.filter(pr => pr._id != action.payload._id)

      return {
        ...state,
        loading: false,
        physicalGoodsAll: removeDeletedPhysicalGood,
        physicalGoodMostRecent: action.payload,
        error: '',
      };

    case types.DELETE_PHYSICAL_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        physicalGoodMostRecent: null,
        error: action.payload,
      };


    // ------ get all ------ //

    case types.GET_ALL_PHYSICAL_GOODS_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.GET_ALL_PHYSICAL_GOODS_SUCCESS:
      return {
        ...state,
        loading: false,
        physicalGoodsAll: action.payload,
        error: '',
        onCompleteRoute: null
      };

    case types.GET_ALL_PHYSICAL_GOODS_FAILURE:
      return {
        ...state,
        loading: false,
        physicalGoodsAll: null,
        error: action.payload,
        onCompleteRoute: null
      };



    // ------ get all school data------ //


    case types.GET_ALL_TEACHERS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_ALL_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTeachers: action.payload,
        error: ''
      };

    case types.GET_ALL_TEACHERS_FAILURE:
      return {
        ...state,
        loading: false,
        allTeachers: null,
        error: action.payload
      };




    case types.GET_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true
      };


    case types.GET_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allStudents: action.payload,
        error: ''
      };

    case types.GET_ALL_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        allStudents: null,
        error: action.payload
      };





    case types.GET_ALL_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };


    case types.GET_ALL_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        allClasses: action.payload,
        error: ''
      };

    case types.GET_ALL_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        allClasses: null,
        error: action.payload
      };







    // ####### CLEAR ALL ####### //

    case types.CLEAR_ADMIN_ROUTING:
      return {
        ...state,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.CLEAR_ADMIN:
      return initAdmin;

    default:
      return state;

  }
}
export default adminReducer


