import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createPhysicalGood, getAllPhysicalGoods } from '../../redux/admin/adminActions'
import GifUI from "../common/gif_UI"

export default function PhysicalGoodCreate() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()

  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(1);
  const [selectedGifId, setSelectedGifId] = useState("xT9IgDEI1iZyb2wqo8")
  const [prizeSubmitted, setPhysicalGoodSubmitted] = useState(false)
  const [title, setTitle] = useState("")

  const {
    //error: adminLoadingError,
    loading: adminLoading,
    adminData
    // physicalGoodsAll,
    // physicalGoodMostRecent
  } = useSelector(state => state.admin)

  const { schoolId_current: { _id: schoolId } } = adminData || {}


  const onSubmit = physicalGoodData => {
    setPhysicalGoodSubmitted(() => true)

    if (schoolId) {
      physicalGoodData.schoolId = schoolId
      physicalGoodData.price = price
      physicalGoodData.quantityOffered = amount
      physicalGoodData.quantityRemaining = amount
      physicalGoodData.archived = false
      physicalGoodData.image = { gif: selectedGifId }
      physicalGoodData.name = title

      dispatch(createPhysicalGood(physicalGoodData))
      dispatch(getAllPhysicalGoods(schoolId))
    }
  };

  const setTokenAmount = (tokens) => {
    if (tokens < 1) { return }
    setAmount(tokens)
  }

  if (prizeSubmitted && !adminLoading) { history.push('/schoolstore') }

  if (adminLoading) { return <h1>Loading</h1> }

  return (
    <>
      <GifUI
        heading="Add Store Item"
        setTitle={setTitle}
        selectedGifId={selectedGifId}
        setSelectedGifId={setSelectedGifId}
        title="Title of Single Item" />

      <form onSubmit={e => e.preventDefault()}>

        <label>Short description</label>
        <input
          name="description"
          type="text"
          ref={register({
            required: "You must include a description",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.description && <p>{errors.description.message}</p>}


        <label>Price</label>
        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <h1>{" "}</h1>

        <label>Quantity Offered</label>
        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={amount}
          onChange={(e) => setTokenAmount(e.target.value)}
        />
        <h1>{" "}</h1>

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save Store Item
        </Button>

        <h2>{"   "}</h2>

      </form>
      <h1>{" "}</h1>

    </>
  );
}




