import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { Container, Segment, Button } from 'semantic-ui-react'
import { getClassroom } from "../../redux/classroom/classroomActions"
import { ToastContainer } from 'react-toastify'
import { clearTeacherRouting } from '../../redux/teacher/teacherActions'
import { SimpleSpinner } from "../common/Spinners"
//import UserContainer from '../common/UserContainer'
import WelcomePickAvatar from './welcomePickAvatar'
// import WelcomeChooseSchool from './welcomeHowToSetUp'
// superHeros: change this to TEACHER/ADMIN avatars
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
//import all30avatars from "../../img/30superheros.png"

import useGoogleLogout from '../hooks/useGoogleLogout'
import firebase from "../../config/fbConfig"

import { clearUserGL } from '../../redux/auth/authActions';
import { clearSuper } from '../../redux/super/superActions'
import { clearStudent } from '../../redux/student/studentActions'
import { clearTeacher } from '../../redux/teacher/teacherActions'
import { clearClassroom } from '../../redux/classroom/classroomActions'
import { clearAdmin } from '../../redux/admin/adminActions'


const TeacherDashboard = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const googleLogout = useGoogleLogout()
  const { setShowNavBar } = props
  const { user, error: teacherAuthError } = useSelector(state => state.auth)
  const { groupthemeId_current } = useSelector(state => state.classroom)

  const {
    error: teacherLoadingError,
    loading: teacherLoading,
    teacherData
  } = useSelector(state => state.teacher)

  const { schoolId, displayName, avatar, classroomId_current, coinsToIssue, isNewTeacherAccount } = teacherData || {}

  const schoolSignup = () => { history.push('/welcomehowtosetup') }
  const signOut = () => {
    // all are needed?
    dispatch(clearSuper())
    dispatch(clearStudent())
    dispatch(clearTeacher())
    dispatch(clearClassroom())
    dispatch(clearAdmin())
    dispatch(clearUserGL())

    localStorage.clear();
    firebase.auth().signOut().then(() => { googleLogout() })
  }

  useEffect(() => {
    dispatch(clearTeacherRouting())
  }, [dispatch])

  useEffect(() => {
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
  }, [dispatch, classroomId_current])


  useEffect(() => {
    if(!isNewTeacherAccount && schoolId)
    setShowNavBar(true)
    else setShowNavBar(false)
  }, [isNewTeacherAccount, schoolId])

  const handleEditTeacherProfile = () => {
    const userObj = { ...user }
    userObj.fullAuthObject = null
    history.push('/teacherprofileedit/', { userObj, teacherData })
  }

  if (teacherLoading) return <SimpleSpinner /> 
  if (!teacherData || teacherLoadingError || teacherAuthError ) { history.push("/loginerror") }
  
  if (isNewTeacherAccount) {
    return (<>
        <h1>Welcome to Kansha!</h1>
        <Segment className="center">
          <h2><WelcomePickAvatar  {...props} data={teacherData} /></h2>
        </Segment>
        <Button color="black" onClick={signOut}>Log Out</Button>
      </>)
  }

  if (!schoolId) {
    return (<>
        <h1>Next, join a school.</h1>
        <h2>If you do not have one, contact your school administrator</h2>
          <Button size="huge " color="green" onClick={schoolSignup}>Enter a School Code
          </Button>
<br/>
<br/>
          <div>
        <Button color="black" onClick={signOut}>Log Out</Button>

          </div>
      </>)
  }


  return (
    <Container>
      <ToastContainer />
      <div>
        {avatar ? (<RenderAvatar
          size={90}
          avatarId={avatar.avatarId}
          setOfAvatars={superHeros}
        />) : null}
      </div>

      <div onClick={handleEditTeacherProfile}>
        <h2
          style={{ cursor: 'pointer', fontSize: "5em" }}
          className="hvr-grow">
          {displayName && displayName}
        </h2>
      </div>
      <br />

      {schoolId
        ? (<>
          <h3 onClick={() => history.push('/currentclassroommain')}
            style={{ cursor: 'pointer' }}
          >Class: {classroomId_current ? " " + classroomId_current.name : "None"}
          </h3>

          <h3 onClick={() => history.push('/groupthemeslist')}
            style={{ cursor: 'pointer' }}>Topic: {groupthemeId_current ? " " + groupthemeId_current?.topic : "None"}
          </h3>
          <br />
          <br />

          <h5>{schoolId?.name}</h5>
          <h5> {schoolId?.coinName}s to issue: {coinsToIssue}</h5>
        </>)
        : <h3>
          <Button size="hige " color="blue" onClick={schoolSignup}>Enter a School Code
          </Button>
        </h3>}


      <br />
      <Button color="black" onClick={signOut}>Log Out</Button>
      <br />
      <br />
      <br />
    </Container>
  )

}
export default TeacherDashboard;



