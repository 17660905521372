
import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createNewSchool } from '../../redux/admin/adminActions'
import schoolIcons from '../../data/icons_schools.json'
import RenderSVG from '../common/RenderSVG'
import { getAdminProfile } from '../../redux/admin/adminActions'

const labelStyle = { fontSize: "1.5em", lineHeight: 1, margin: "6px" }

export default function SchoolCreate() {

  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()
  const [iconNumber, setIconNumber] = useState(0)

  const { error, loading, reduxResponseData, onCompleteRoute, adminData: { _id: adminId } } = useSelector(state => state.admin)

  const { user } = useSelector(state => state.auth)
  const { fb_uid } = user || {}

  const onSubmit = schoolData => {
    schoolData.image = schoolIcons.set_1[iconNumber]
    dispatch(createNewSchool(adminId, schoolData))
  };

  const changeIcon = () => {
    let newIconNumber = iconNumber + 1
    if (newIconNumber > schoolIcons.set_1.length - 1) {
      newIconNumber = 0
    }
    setIconNumber(newIconNumber)
  }

  useEffect(() => {
    let randomIcon = Math.floor(Math.random() * schoolIcons.set_1.length - 1);
    if (randomIcon === -1) randomIcon = 0
    setIconNumber(randomIcon)
  }, [])


  // REDO
  if (reduxResponseData) {
    dispatch(getAdminProfile(fb_uid))
   history.push("/admindashboard")
    console.log("reduxResponseData", reduxResponseData)
    console.log("onCompleteRoute", onCompleteRoute)
  }

  if (loading) { return <h1>Loading</h1> }
  if (error) { return <h1>Error:{error?.message}</h1> }

  return (<>
    <h1>Create a new school</h1>

    {iconNumber !== null &&
      <div onClick={changeIcon}>
        <RenderSVG
          size="150"
          svgFilename={schoolIcons.set_1[iconNumber].svg}
        />
      </div>}



    <form onSubmit={e => e.preventDefault()}>

      <label style={labelStyle}>Name</label>
      <input
        name="name"
        type="text"
        ref={register({
          required: "You must include a school name",
          minLength: {
            value: 2,
            message: "Name must have at least 2 characters"
          }
        })}
      />
      {errors.name && <p>{errors.name.message}</p>}

      {/* TOOL TIP:(Use the plural tense, for example: The Dragons) */}
      <label style={labelStyle}>Mascot </label>
      <input
        name="mascot"
        type="text"
        ref={register({
          required: "You must include a school mascot",
          minLength: {
            value: 2,
            message: "Mascots must have at least 2 characters"
          }
        })}
      />
      {/* * TOOL TIP(Use the singular tense, for example: Dragon Token) */}
      <label style={labelStyle}>Gratitude Token </label>
      <input
        name="coinName"
        type="text"
        ref={register({
          required: "You must create a gratitude token name",
          minLength: {
            value: 2,
            message: "Mascots must have at least 2 characters"
          }
        })}
      />

      <label style={labelStyle}>Description (optional)</label>
      <input
        name="description"
        type="text"
        ref={register()}
      />

      {/* 
        <label>Upload or drag-n-drop school logo </label>
        <input
          name="description"
          type="text"
        />
 */}

      <Button
        onClick={handleSubmit(onSubmit)}
        size="huge"
        type="submit"
        inverted color='blue'
      >Save New School</Button>
      <br />
      <br />
      <br />
    </form>

  </>
  );
}




