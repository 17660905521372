import React from 'react';
import { useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { SimpleSpinner } from "../common/Spinners"
import RenderSVG from '../common/RenderSVG';


export default function CommonGoodsView(props) {
  const { state, state: { _id: commonGoodId, fulfilled: fulfilledGoal, contributorIds, contributorLedger, title, description = "", targetAmount, currentDonatedAmount, image: { svg } } } = props.history.location || {}

  //const dispatch = useDispatch()
  const history = useHistory()
  const { error, loading } = useSelector(state => state.classroom)


  const payUp = () => {
    history.push('/commongoodspayup', state)
  }

  const contributorDisplay = contributorIds && contributorIds.map(student => <h3 style={{ margin: "3px" }}>{student.displayName} {" - "}{contributorLedger[student._id][0]}</h3>)


  //if (onCompleteRoute) { history.push(onCompleteRoute) }

  if (loading) { return <SimpleSpinner /> }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }

  return (
    <>
      <h1 style={{ color: "black", fontSize: "5em" }}>{title}</h1>
      <div>
        <RenderSVG
          size={250}
          svgFilename={svg} />
      </div>
      <h2>{description}</h2>

      {fulfilledGoal
        ? <>
          <h3 style={{ margin: "3px" }}>Thank you for donating!</h3>
          {contributorDisplay}
          <br />
          <Button
            onClick={payUp}
            size="massive"
            type="submit"
            color='green'
          >AWARD THE EFFORT</Button>
        </>
        :
        <>
          {contributorIds?.length > 0
            ?
            <> <h3 style={{ margin: "3px" }}>Thank you for donating!</h3>
              {contributorDisplay}
              <h2>We have {targetAmount - currentDonatedAmount} left to go!</h2>
            </>
            : <h3>No donations so far.</h3>
          }
        </>

      }
      <br />
      <br />
      <br />
    </>
  );
}




