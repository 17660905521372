import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AdminRoute = ({ component: RouteComponent, ...rest }) => {
  const user = useSelector(state => state.auth.user)
  // const adminData = useSelector(state => state.admin)

  const { userType } = user || {}
  return (
    <Route {...rest}
      render={routeProps =>
        !user || userType !== "admin"
          ? (<Redirect to={"/login"} />)
          : (<RouteComponent {...routeProps}  {...rest}/>)
      }
    />
  )
}

export default AdminRoute