import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updatePhysicalGood, deletePhysicalGood } from '../../redux/admin/adminActions'

import Gif from '../common/gif'

export default function PhysicalGood(props) {


  const { state: { name, price, description = "", prizesRanOutFlag = null, quantityRemaining, image, _id: physicalGoodId = "123" } } = props.history.location


  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name,
      price,
      description,
      quantityRemaining
    }
  });

  const dispatch = useDispatch()
  const history = useHistory()

  const {
    error: adminError, loading: adminLoading } = useSelector(state => state.admin)

  const [updateSubmitted, setUpdateSubmitted] = useState(false)

  const onSubmit = update => {
    setUpdateSubmitted(() => true)
    update.quantityRemaining = parseInt(update.quantityRemaining)
    console.log("update====>", update)
    dispatch(updatePhysicalGood(physicalGoodId, update))
  };

  const archivePhysicalGood = () => {
    dispatch(updatePhysicalGood(physicalGoodId, { archived: true }))
    history.push('/admindashboard')
  }

  const handleDeletePrize = () => {
    dispatch(deletePhysicalGood(physicalGoodId))
    history.push('/schoolstore')
  }

  if (updateSubmitted && !adminLoading) { history.push('/schoolstore') }
  if (adminLoading) { return <h1>Loading</h1> }
  if (adminError) {
    console.log(adminError)
    return (<><h1>Error</h1><h6>{adminError.message}</h6></>)
  }

  return (
    <>
      <h1>Edit {name}</h1>
      {/* <RenderSVG
        size={"100"}
        svgFilename={svg} /> */}
      {image?.gif
        ? <Gif id={image.gif} size={200} />
        : null}

      <form onSubmit={e => e.preventDefault()}>
        <label>Name</label>
        <input
          name="name"
          type="text"
          ref={register({
            required: "You must include a physical good name",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.title && <p>{errors.title.message}</p>}

        <label>Description (optional)</label>
        <input
          name="description"
          type="text"
          ref={register()} />

        <label>Price</label>
        <input
          name="price"
          type="text"
          ref={register()} />

        <label>Quantity</label>
        <input
          name="quantityRemaining"
          type="text"
          ref={register()} />

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'>Save Changes
        </Button>

        <h6>{" "}</h6>
        <div>
          <Button
            size="tiny"
            color="red"
            onClick={handleDeletePrize}>Delete
          </Button>
        </div>
        <br />
        <br />
        <br />
      </form>

    </>
  );
}




