import React, { useState, useEffect } from 'react';
import RenderAvatar from '../common/RenderAvatar';
import _ from 'lodash'
import { motion } from 'framer-motion';
import { superHeros } from "../../img/avatars/superHeros"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
//import useSound from 'use-sound';
import { Button } from "semantic-ui-react"
import { getGrouptheme, updateGroup } from '../../redux/classroom/classroomActions'
import { increaseStudentCoinsByTeacher } from "../../redux/teacher/teacherActions"
import notify from '../../utils/notify'
import Confetti from 'react-dom-confetti';
import confettiConfig from '../../data/confettiConfig'
import winningCup from '../../img2/lottie/ui/31177-winning-cup.json'
import Lottie from 'react-lottie'
import { SimpleSpinner } from "../common/Spinners"

// import electroThud from '../../sound/electro_thud.mp3'
// import successTone from '../../sound/success_tone.mp3'

const KanshaAward = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { state: { coinName = "Kansha Token", studentNames, teacherId } } = history.location
  const { setShowNavBar } = props

  const {
    loading, groupthemeData: { groupIds },
    groupthemeId_current: { _id: groupthemeIdCurrentId }
  } = useSelector(state => state.classroom)

  const [showConfetti, setShowConfetti] = useState(false)
  const [reloadTheme, setReloadTheme] = useState(false)
  const [winningStudentNames, setWinningStudentNames] = useState(null)

  // const [playElectroThud] = useSound(electroThud);
  // const [playSuccessTone] = useSound(successTone);

  useEffect(() => {
    setShowConfetti(true)
    setShowNavBar(false)
  }, [])


  // useEffect(() => {
  //   playSuccessTone()
  // }, [])


  useEffect(() => {
    const maxValue = _.maxBy(groupIds, 'points');

    if (maxValue.points === 0) {
      // playElectroThud()
      notify({ message: "No team has any points.", position: "TOP_CENTER", noticeType: "info" })
      history.push("/groupsteamwork")

    } else {

      // playSuccessTone()
      const winningGroups = groupIds.filter(g => {
        return g.points === maxValue.points
      })


      let winningStudentIds = winningGroups.map(gr => gr.studentIds)
      winningStudentIds = _.flatten(winningStudentIds)

      winningStudentIds.forEach(student => {
        dispatch(increaseStudentCoinsByTeacher(student, 1, teacherId))
      })

      let winningStudentNames = []
      winningGroups.forEach((singleGroup, i) => {

        // Add to cumulative wins for each winning gropu
        const winningGroupId = singleGroup._id

        const newSingleGroup = { ...singleGroup }
        newSingleGroup.cumulativeWins = newSingleGroup.cumulativeWins + 1

        dispatch(updateGroup(winningGroupId, newSingleGroup))

        winningStudentNames = winningStudentNames.concat(studentNames[winningGroupId])
      })
      setWinningStudentNames(winningStudentNames)


      // reset the gropus
      const groupIdsTemp = [...groupIds]

      groupIdsTemp.forEach(group => {
        const updatedData = { points: 0 }
        dispatch(updateGroup(group._id, updatedData))
      })
    }
    // return () => {
    // }
  }, [])



  const winnerDisplayStyle = {
    color: "gold",
    fontSize: "2.5em",
    fontWeight: "bold",
    margin: "1px",
    textShadow: "1px 1px 2px #000000"
  }

  const studentsAnimation = winningStudentNames && winningStudentNames?.map(student => {
    return (
      <>
        <div style={{
          "display": "inline-block",
          "margin": "10px"
        }}>
          <motion.div
            initial={{ scale: .2 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 300 }} >
            <RenderAvatar
              size={80}
              avatarId={student[3]}
              setOfAvatars={superHeros} />
          </motion.div>
          <motion.h1
            style={winnerDisplayStyle}
            animate={{ fontSize: "1 em" }}
          >
            {student[0]}
          </motion.h1>
        </div>
      </>
    )
  })

  const returnToTeamWorkBoard = () => {
    setReloadTheme(true)
    dispatch(getGrouptheme(groupthemeIdCurrentId))
  }

  if (reloadTheme === true && !loading) {
    history.push('/groupsteamwork')
  }


  return (<>
    <h1>{reloadTheme}</h1>
    <Confetti active={showConfetti} config={confettiConfig} />
    <br />
    <br />
    {loading ? <SimpleSpinner /> : null}
    <motion.h1
      style={winnerDisplayStyle}
      animate={{ scale: 3, rotateZ: 360 }}
    >{coinName}s
    </motion.h1>
    <Lottie
      width={250}
      height={250}
      options={{
        loop: false,
        autoplay: true,
        animationData: winningCup,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
    />
    <br />
    {studentsAnimation}
    <br />
    <div>
      <Button
        size="medium"
        inverted color='blue'
        onClick={returnToTeamWorkBoard}>Teamwork Board</Button>
    </div></>
  );
}

export default KanshaAward;



