import React, { useEffect } from 'react';
import { Button, Card } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux"
import { getAllPhysicalGoods } from '../../redux/admin/adminActions'
import Gif from '../common/gif'

const PhysicalGoodDisplayBoard = (props) => {
  const { editItem, viewItem } = props

  const {
    // error: adminLoadingError,
    // loading: adminLoading,
    adminData,
    physicalGoodsAll,
    physicalGoodMostRecent
  } = useSelector(state => state.admin)

  const { schoolId_current } = adminData || {}

  const { _id: schoolId, name: schoolName } = schoolId_current || {}

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPhysicalGoods(schoolId))
  }, [])

  useEffect(() => {
    dispatch(getAllPhysicalGoods(schoolId))
  }, [dispatch, schoolId, physicalGoodMostRecent])


  const physicalGoodsDisplayBoard = physicalGoodsAll?.map(pg => {
    if (pg.archived) return null
    return (
      <Card
        key={pg._id}
        style={{
          // margin: "1px",
          padding: "0px",
          fontSize: ".5em"
        }}>
        <div
          onClick={() => viewItem(pg)}
          style={{ cursor: 'pointer' }}>
          <h1 >
            {pg.name}
          </h1>
          {pg?.image?.gif
            ? <Gif id={pg.image.gif} />
            : null}
          <h2 style={{
            color: "orange",
            margin: "1px"
          }}>{pg.description}</h2>
          <h3 style={{
            color: "black",
            fontSize: "3em",
            margin: "1px"
          }}>{pg.price}</h3>
          <h3 style={{
            color: "black",
            margin: "1px"
          }}>Amount remaining: {pg.quantityRemaining}</h3>
        </div>
        <Button
          onClick={() => editItem(pg)}
          size="tiny"
          color="black">Edit
        </Button>
      </Card>)
  })

  return (<>
    <Card.Group centered itemsPerRow={4}>
      {physicalGoodsDisplayBoard}
    </Card.Group>
  </>
  );
}
export default PhysicalGoodDisplayBoard;

