import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";
import { Button } from 'semantic-ui-react'
import Lottie from 'react-lottie'
import RenderSVG from '../common/RenderSVG';
import GoogleLoginButton from './GoogleLoginButton'
import { getStudentProfile, clearStudent } from '../../redux/student/studentActions'
import { getTeacherProfile, clearTeacher } from '../../redux/teacher/teacherActions'
import { getAdminProfile, clearAdmin } from '../../redux/admin/adminActions'
import useGoogleLogout from '../hooks/useGoogleLogout'
import { fetchUserFB, clearUserFB, clearUserGL } from '../../redux/auth/authActions';
import logo2 from '../../img2/lottie/logos/kanshaSwirlGreen.json' // use
import greenFlower from '../../img2/lottie/ui/36932-green-flower.json' // use
import loadingIcon1 from '../../images/loading/4099-loading.json' // use
// import "./authStyles.css";

export default function Login() {
  const { register, handleSubmit, errors } = useForm({ defaultValues: { email: "", password: "" } })

  const [googleLoggedIn, setGoogleLoggedIn] = useState(true)
  const [emailAndPasswordLogin, setEmailAndPasswordLogin] = useState(false)

  const googleLogout = useGoogleLogout()
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, error, loading: authLoading, userTypeDirectToDashboard: directToSignUp, temporaryInfo: googleUserProfile } = useSelector(state => state.auth)

  const { studentData } = useSelector(state => state.student)

  const { _id: studentMongoId } = studentData || {}
  const { fb_uid } = user || {}

  const errorMessage = error ? error.message : ""
  const style1 = { "fontFamily": "Passion One", "color": "blue", "fontSize": "12em", "padding": "2px" }
  const style2 = { "color": "green", "padding": "1px" }

  const appVersionToDisplay = process.env.REACT_APP_NAME === "KANSHA-PRODUCTION" ? null : process.env.REACT_APP_NAME + " Version:" + process.env.REACT_APP_VERSION


  const onSubmit = (data) => { dispatch(fetchUserFB(data)) }

  const goToSignUp = () => {
    dispatch(clearUserFB())
    history.push('/signup')
  }


  const loginTeacherAndUpdateAllStudents = () => {
    dispatch(getTeacherProfile(fb_uid))
    // set state with all users
    // update them all
    // THEN pish to teacher dash

    // push to updating and 'pulling' students page
    //history.push("/teacherdashboard")
    history.push("/teacherupdatingstudents", { loadStudents: true })
  }


  useEffect(() => {
    dispatch(clearStudent())
    dispatch(clearTeacher())
    dispatch(clearAdmin())
  }, [dispatch])



  useEffect(() => {
    if (fb_uid && user && user.userType === "student") {
      dispatch(getStudentProfile(fb_uid))
      history.push("/studentdashboard")
    }
    else if (fb_uid && user && user.userType === "teacher") {
      // dispatch(getTeacherProfile(fb_uid))
      // history.push("/teacherdashboard")
      loginTeacherAndUpdateAllStudents()
    }
    else if (fb_uid && user && user.userType === "admin") {
      dispatch(getAdminProfile(fb_uid))
      history.push("/admindashboard")
    }
    else if (fb_uid && user && user.userType === "super") {
      history.push("/superdashboard")
      // TO DO
    }
  }, [user, history, dispatch, fb_uid])


  useEffect(() => {
    if (studentMongoId) { history.push("/studentdashboard") }
  }, [history, studentMongoId])


  const declineSignUp = () => {
    dispatch(clearUserGL())
    setGoogleLoggedIn(false)
    googleLogout()
  }


  const signUpWithGoogle = () => {
    history.push('/signupwithgoogle', googleUserProfile)
  }

  // OFFLINE TEMPORARILY
  // return (<>
  //   <h1 id="title" style={style}>Kansha</h1>
  //   <h2>Kansha is down for maintence and will be back online Monday morning at 6:00am.</h2>
  // </>)

  if (directToSignUp === "signupwithgoogle") {
    return (
      <>
        <h2>There is no Kansha user with the email: {googleUserProfile?.email}</h2>
        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: greenFlower,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />

        <h4>Would you like to create an account right now?</h4>
        <div>
          <Button onClick={signUpWithGoogle} size="large" color="green">Yes, create an account with my Google ID</Button>
        </div>
        <div>
          <Button onClick={declineSignUp} size="large" color="gray">No thank you.</Button>
        </div>
      </>
    )
  }


  // if (loading) return <h1>Loading</h1>
  if (authLoading) return (
    <Lottie
      width={250}
      height={250}
      options={{
        loop: false,
        autoplay: true,
        animationData: loadingIcon1,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
        //,viewBox: "0 0 300 300"
      }} />)

  return (<>
    <h1 className="hvr-grow" id="title" style={style1}>Kansha</h1>
    <h6 style={{ margin: "2px" }}>{appVersionToDisplay}</h6>
    <div style={{ margin: "-50px 2px 2px 2px" }}>
      <Lottie
        width={350}
        height={350}
        options={{
          loop: false,
          autoplay: true,
          animationData: logo2,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
          // , viewBox: "0 0 300 300"
        }} />
    </div>

    {emailAndPasswordLogin
      ? null

      : <>
        <GoogleLoginButton googleLoggedIn={googleLoggedIn} />
        <button
          style={{ margin: "0 auto" }}
          onClick={() => setEmailAndPasswordLogin(true)}>
          <span style={{ padding: "0px 10px 0px 0px" }}>
            <RenderSVG
              size={"15px"}
              svgFilename={"ui/general/raffleMachine"}
            />
          </span>Email and password
        </button>

      </>
    }


    {emailAndPasswordLogin

      ? <>
        <form onSubmit={e => e.preventDefault()}
        >
          <label style={{ margin: "0px " }} >Email</label>
          <input
            name="email"
            type="text"
            // value=""
            ref={register({ required: true, minLength: 4 })}
          >
          </input>
          <label style={{ margin: "0px " }}>Password</label>
          <input
            name="password"
            type="password"
            ref={register({ required: true, minLength: 6 })}>
          </input>

          <h2>{"   "}</h2>

          <Button
            onClick={handleSubmit(onSubmit)}
            size="huge"
            type="submit"
            inverted color='blue' >Log In!
          </Button>

          {errors.email && <p>Invalid email</p>}
          {errors.password && <p>Invalid password</p>}


          <Button
            style={{ display: "block", margin: "10px auto 2px" }}
            onClick={goToSignUp}
            size="tiny"
            color='black'> Sign-up
          </Button>


          {errorMessage ? <h3 style={style2}> {errorMessage}</h3> : null}

        </form >
      </>
      : null
    }


    <br />
    <h6 style={{ color: "grey", cursor: "pointer", margin: "30px auto 0px 0px" }} >
      <span onClick={() => history.push("/passwordreset")} >Lost Password -</span>
      <span onClick={() => console.log("Terms")}> Terms of Service -</span>
      <span onClick={() => console.log("Privacy")}> Privacy</span>
      <span style={{ display: "block" }}>© Northern Devs 2022</span>

    </h6>
    <br />
    <br />
  </>
  )
}

{/*         <span onClick={() => history.push("/passwordreset")} >Lost Password -</span>
        <span onClick={() => history.push("/termsofuse")}> Terms of Service -</span>
        <span onClick={() => history.push("/privacypolicy")}> Privacy</span> */}
