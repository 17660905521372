import React, { useState, useEffect } from 'react';
import RenderSVG from '../common/RenderSVG'
import { Card, Button } from "semantic-ui-react"
//import { GroupCard } from '../common/GroupCard' - later make reusable
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import ToolTip from '../common/ToolTip'
import { motion } from 'framer-motion';
import pickRandomItem from "../../utils/pickRandomItem"


const PickedGroup = (props) => {

  const [pointAwarded, setPointAwarded] = useState(false)
  const [pointsInARow, setPointsInARow] = useState(0)
  const [groupMemberPicked, setGroupMemberPicked] = useState(null) // [ 0 ] = display=Name , [ 1 ] = firstName
  const compliments = ["Excellent work ", "Right on ", "You're a team player ", "Your teammates thank you ", "Way to go ", "Yes "]

  const {
    addPoint,
    setGroupPicked,
    pickAgain,
    currentPointsLocal,
    studentNames,
    names,
    group,
    group: {
      points,
      _id: groupId,
      displayNameGraphics: { english: groupName, svg } } } = props


  const addGroupPoint = () => {
    setPointsInARow((pointsInARow) => pointsInARow + 1)
    addPoint(group)
    setPointAwarded(true)
  }

  const returnToKansha = () => {
    setGroupPicked(null)

  }
  const chooseAnotherGroup = async () => {
    await setGroupPicked(null)
    pickAgain()
  }

  // refactor out hero code
  const GroupPointGeneric = () => {

    return (
      <>
        <motion.h1
          style={{ fontSize: "7em" }}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{
            duration: .5,
            ease: "easeInOut"
          }}>
          {groupName}!
        </motion.h1>

        <motion.div
          animate={{
            scale: [1, 2, 1.25, 1],
            rotate: [0, 180, 720, 0]
          }}
          transition={{
            duration: .75,
            delay: .5,
            // ease: "easeInOut",
            times: [0, 0.3, 0.3, 1]
          }}
        >
          <RenderSVG
            size={"300"}
            svgFilename={svg}
          />
        </motion.div>


        <motion.h2
          transition={{ delay: 1 }}
          initial={{ x: 1500 }}
          animate={{ x: 0, rotateY: 360 }}
        > {groupMemberPicked
          ? pickRandomItem(compliments) + groupMemberPicked[1].toUpperCase() + "!"
          : null
          }
        </motion.h2>


        <motion.h2
          transition={{ delay: 1 }}
          initial={{ x: -1500 }}
          animate={{ x: 0, rotateY: 360 }}
        >
          {groupMemberPicked
            ? "Your team now has "
            : "You now have "
          }
          {currentPointsLocal} point{currentPointsLocal === 1 ? "!" : "s!"}
        </motion.h2>
      </>
    )
  }

  const GroupPointHero = () => {
    return (
      <>
        <motion.h1
          style={{ fontSize: "7em" }}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{
            duration: .5,
            ease: "easeInOut"
          }}>
          {groupName}!
        </motion.h1>

        <motion.div
          animate={{
            scale: [1, 2, 1.25, 1],
            rotate: [0, 180, 720, 0]
          }}
          transition={{
            duration: .75,
            delay: .5,
            // ease: "easeInOut",
            times: [0, 0.3, 0.3, 1]
          }}
        >
          <RenderSVG
            size={"300"}
            svgFilename={svg}
          />
        </motion.div>

        <motion.h2
          transition={{ delay: 1 }}
          initial={{ x: 1500 }}
          animate={{ x: 0, rotateY: 360 }}
        > {groupMemberPicked
          ? pickRandomItem(compliments) + groupMemberPicked[1].toUpperCase() + "!"
          : null
          }
        </motion.h2>

        <motion.div
          // initial={{ x: 1500 }}
          // animate={{ x: 0 }}
          initial={{ y: -1000 }}
          animate={{
            y: 0,
            // scale: [.25, 4, 3, 1],
            // rotate: [0, 180, 360, 0]
          }}
          transition={{ type: 'spring', stiffness: 500, delay: 1, duration: .75 }}

        //transition={{ delay: 1 }}
        ><RenderAvatar
            size={60}
            avatarId={groupMemberPicked[2]}
            setOfAvatars={superHeros}
          />
        </motion.div>



        <motion.h2
          transition={{ delay: 1 }}
          initial={{ x: -1500 }}
          animate={{ x: 0, rotateY: 360 }}
        >
          Your team now has {currentPointsLocal} point{currentPointsLocal === 1 ? "!" : "s!"}
        </motion.h2>


        {/* initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: .75, duration: 1 }} */}


        {/* <motion.div>
          <>
            <div style={{
              display: "flex",
              justifyContent: "center",
            }}>
              {groupMembers}
            </div>
            <h6 style={{
              marginTop: "8px",
              marginBottom: "2px",
              fontSize: "2em"
            }}
              onClick={() => setGroupMemberPicked(null)}
            >{groupMemberPicked && groupMemberPicked[0]}</h6>
          </>
        </motion.div> */}


      </>
    )
  }

  const ButtonGroup = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, duration: 2 }}>
        <Button
          color="black"
          size="massive"
          onClick={addGroupPoint}>
          How about {pointsInARow + 1} in a row?
        </Button>
        <div>
          <Button onClick={chooseAnotherGroup}
            size="medium">
            New Group</Button>
        </div>
        <div>
          <Button onClick={() => setGroupPicked(null)}
            size="medium"
            color="white" >Teamwork Board</Button>
        </div>
      </motion.div>
    )
  }

  const ButtonGroupHero = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, duration: 2 }}>
        <Button
          color="black"
          size="massive"
          onClick={addGroupPoint}>
          How about {pointsInARow + 1} in a row?
        </Button>
        <div>
          <Button onClick={chooseAnotherGroup}
            size="medium">
            New Lucky Group</Button>
        </div>
        <div>
          <Button onClick={() => setGroupPicked(null)}
            size="medium"
            color="white" >Kansha</Button>
        </div>
      </motion.div>
    )
  }

  const groupMembers = names && names.map(name => {
    let avatarSize = names.length < 9 ? 30 : 20
    return (
      <div
        className="inline"
        style={{ float: "left" }}
        onClick={() => {
          console.log(name[0] + " " + name[1])
          setGroupMemberPicked([name[0] + " " + name[1], name[0], name[3]])
          // [displayName, firstName, avatarCode]
        }}
      >
        <ToolTip text={name[0]}>
          <RenderAvatar
            size={avatarSize}
            avatarId={name[3]}
            setOfAvatars={superHeros}
          />
        </ToolTip>
      </div>
    )
  })


  if (pointAwarded) {
    return (<>
      {groupMemberPicked
        ? (<><GroupPointHero />
          <ButtonGroupHero />
        </>)

        : (<><GroupPointGeneric />
          <ButtonGroup /></>)
      }
    </>
    )
  }

  // no point
  return (<>
    <div>
      <h1>The {groupName} Team</h1>
      <Card
        style={{
          "font-size": "1em",
          "padding-top": "20px",
          "padding-bottom": "20px"
        }}
        centered
        image={<RenderSVG
          size={"250"}
          svgFilename={svg} />}
        header={`${groupName}`}
        description={`Currently have ${currentPointsLocal} team point${points === 1 ? "" : "s"}`}
        extra={
          <>
            <div style={{
              display: "flex",
              justifyContent: "center",
            }}>
              {groupMembers}
            </div>
            <h6 style={{
              marginTop: "8px",
              marginBottom: "2px",
              fontSize: "2em"
            }}
              onClick={() => setGroupMemberPicked(null)}
            >{groupMemberPicked && groupMemberPicked[0]}</h6>
          </>
        }
      />
      <br />

      <div>
        <Button onClick={addGroupPoint} color="black" size="massive">Team Point!</Button>
      </div>

      <div>
        <Button onClick={chooseAnotherGroup} size="medium">New Group</Button>
      </div>
      <div>
        <Button onClick={() => setGroupPicked(null)} size="medium" basic >Teamwork</Button>
      </div>
    </div>
  </>)

}

export default PickedGroup;

/*
const GroupPointByMember = () => {
  return (
    <>
      <motion.h1
        initial={{ x: 1500 }}
        animate={{ x: 0 }}
        transition={{ delay: .25 }}>
        {pickRandomItem(compliments)}{groupMemberPicked[1]}!!
          </motion.h1>

      <motion.div
        initial={{ x: 1500 }}
        animate={{ x: 0 }}
        transition={{ delay: .50 }}
      ><RenderAvatar
          size={30}
          avatarId={groupMemberPicked[2]}
          setOfAvatars={superHeros}
        />
      </motion.div>
    </>
  ) */





