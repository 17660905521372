import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from 'react-redux'
import AdminRoute from './components/common/PrivateRouteAdmin'
import SuperRoute from './components/common/PrivateRouteSuper'
import TeacherRoute from './components/common/PrivateRouteTeacher'
import StudentRoute from './components/common/PrivateRouteStudent'
import './App.css';

// auth
import Login from "./components/auth/LoginForm"
import LoginError from "./components/auth/LoginError"
import SignUp from "./components/auth/SignUp"
import SignUpWithGoogle from "./components/auth/SignUpWithGoogle"
import PasswordReset from "./components/auth/PasswordReset"
import PasswordResetConfirm from "./components/auth/PasswordResetConfirm"
import TermsOfUse from "./components/auth/TermsOfUse"
import PrivacyPolicy from "./components/auth/PrivacyPolicy"
import PageNotFound from "./components/auth/PageNotFound"

// layout
import MainNavStudent from "./components/layout/NavBar_student_main"
import NavBarStudent from "./components/layout/NavBar_student_second"

import MainNavTeacher from "./components/layout/NavBar_teacher_main"
//import SecondaryNavTeacher from "./components/layout/NavBar_teacher_second"

import MainNavAdmin from "./components/layout/NavBar_admin_main"
//import NavBarAdmin from "./components/layout/NavBar_admin_second"
// NavBarSuper from "./components/layout/NavBar_super"


// student
import StudentDashboard from "./components/app_student/StudentDashboard"
import StudentProfileEdit from "./components/app_student/StudentProfileEdit"
import StudentResetPassword from "./components/app_student/StudentResetPassword"
import RightNow from "./components/app_student/RightNow"
import Spinner from "./components/app_student/SpinnerWheel"
import FourCorners from "./components/app_student/fourCorners"
import WhoIsBiggest from "./components/app_student/whoIsBiggest"
// import Groups from "./components/app_student/groups"
import MyKanshaTokens from "./components/app_student/MyKanshaTokens"
import CurrentGroupDisplay from "./components/app_student/CurrentGroupDisplay"
import ClassroomsDisplayList from "./components/app_student/ClassroomsDisplayList"
import ClassroomsDisplayGroupLeader from "./components/app_student/CurrentGroupLeader"
import ClassroomJoin from "./components/app_student/ClassroomJoin2"
import BreakTime from "./components/app_student/breakTime"
import Bonus from "./components/app_student/Bonus"
import CheckIn from "./components/app_student/checkIn"
import StudentStore from "./components/app_student/StudentStore"
import StudentExchange from "./components/app_student/StudentExchange"
import StudentExchangeAmount from "./components/app_student/StudentExchangeAmount"
import CommonGoodsStudent from "./components/app_student/CommonGoodsStudent"
import CommonGoodsStudentContribution from "./components/app_student/CommonGoodsStudentContribution"
import CommonGoodsStudentGoalReached from "./components/app_student/CommonGoodsStudentGoalReached"
import Raffle from "./components/app_student/Raffle"
import RaffleBuyTickets from "./components/app_student/RaffleBuyTickets"
import SchoolStoreStudent from "./components/app_student/SchoolStoreStudent"
import PhysicalGoodBuy from "./components/app_student/PhysicalGoodBuy"

// teacher
import TeacherDashboard from "./components/app_teacher/TeacherDashboard"
import TeacherProfileEdit from "./components/app_teacher/TeacherProfileEdit"
import TeacherResetPassword from "./components/app_teacher/TeacherResetPassword"
import TeacherUpdatingStudents from "./components/app_teacher/TeacherUpdatingStudents"
import KanshaAward from "./components/app_teacher/currentGroupsKanshaAward"
import CurrentClassroomMain from "./components/app_teacher/CurrentClassroomMain"
import ClassroomsDisplay from "./components/app_teacher/ClassroomsDisplay2"
import ClassroomsCreate from "./components/app_teacher/ClassroomsCreate2"
import ClassroomEdit from "./components/app_teacher/ClassroomEdit2"
import SchoolDisplay from "./components/app_teacher/schoolDisplay"
import Play from "./components/app_teacher/playTime"
import Dots from "./components/app_teacher/playTimeDots"
import SpinnerTeacher2 from "./components/app_teacher/SpinnerTeacher2"
import SpinnerSelector from "./components/app_teacher/SpinnerSelector"
import GroupEdit from "./components/app_teacher/GroupEdit2"
import GroupCreateDraggable from "./components/app_teacher/groupCreateDraggable"
import GroupSize from "./components/app_teacher/groupSize"
import GroupDetails from "./components/app_teacher/GroupDetails"
import GroupthemeCreate from "./components/app_teacher/groupthemeCreate"
import GroupthemesList from "./components/app_teacher/groupthemesList"
import WelcomeHowToSetup from "./components/app_teacher/welcomeHowToSetUp"
import CommonGoodsTeacher from "./components/app_teacher/CommonGoodsTeacher"
import CommonGoodsCreate from "./components/app_teacher/CommonGoodsCreate"
import CommonGoodsEdit from "./components/app_teacher/CommonGoodsEdit"
import CommonGoodsPayUp from "./components/app_teacher/CommonGoodsPayUp"
import CommonGoodsView from "./components/app_teacher/CommonGoodsView"
import CommonGoodsCredit from "./components/app_teacher/CommonGoodsCredit"
import RaffleMainTeacher from "./components/app_teacher/RaffleMainTeacher"
import PrizeCreate from "./components/app_teacher/PrizeCreate"
import PrizeEdit from "./components/app_teacher/PrizeEdit"
import PrizeRaffleOff from "./components/app_teacher/PrizeRaffleOff"
import PrizeTickets from "./components/app_teacher/PrizeTickets"
import SchoolStoreTeacher from "./components/app_teacher/SchoolStoreTeacher"
import PhysicalGoodTeacherView from "./components/app_teacher/PhysicalGoodView"
import StudentsListTeacher from "./components/app_teacher/StudentsMainLists"
import StudentsTileTeacher from "./components/app_teacher/StudentsMainTiles"
import GroupsList from "./components/app_teacher/GroupsList"
import GroupsListAddGroup from "./components/app_teacher/GroupsListAddGroup"
import GroupsListAddStudent from "./components/app_teacher/GroupsListAddStudents"
import GroupsTeamwork from "./components/app_teacher/GroupsTeamwork"
import Tokens from "./components/app_teacher/Tokens"

// admin
import AdminDashboard from "./components/app_admin/AdminDashboard"
import AdminProfileEdit from "./components/app_admin/AdminProfileEdit"
import AdminResetPassword from "./components/app_admin/AdminResetPassword"
import SchoolStore from "./components/app_admin/SchoolStore"
import SchoolCreate from "./components/app_admin/SchoolCreate2"
import SchoolEdit from "./components/app_admin/SchoolEdit2"
import SchoolsDisplay from "./components/app_admin/SchoolsDisplay2"
import PhysicalGoodCreate from "./components/app_admin/PhysicalGoodCreate"
import PhysicalGoodEdit from "./components/app_admin/PhysicalGoodEdit"
import PhysicalGoodView from "./components/app_admin/PhysicalGoodView"
import CurrentSchool from "./components/app_admin/CurrentSchool2"

// super
import SuperDashboard from "./components/app_super/SuperDashboard"
import SuperUsers from "./components/app_super/SuperUsers"
import GroupsTest from "./components/app_super/groupsTest"
import AnimatedGrid from "./components/app_super/test_animatedGrid"
import BasicGrid from "./components/app_super/test_basicGrid"
import FourCornersTest from "./components/app_super/test_fourcorners"
import DashboardTest from "./components/app_super/test_dashboard"
import TestGround from "./components/app_super/testGround"

const NavBar = function (showNavBar, currentCoin, setCurrentCoin) {
  const { user } = useSelector(state => state.auth)

  // ** Passing showNavBar and currentCoin, and setCurrentCoin is workaround bc this line rendered screen blank
  // --> showNavBar ?{ NavBar()} : null

  if (showNavBar && user && user.userType === "student") {
    return (<>
      <MainNavStudent
        userType={user?.userType}
        googlePhotoURL={user?.photoURL}
        currentCoin={currentCoin}
        setCurrentCoin={setCurrentCoin} />
      <NavBarStudent
        currentCoin={currentCoin}
        setCurrentCoin={setCurrentCoin} />
    </>)
  }


  if (showNavBar && user && user.userType === "teacher") {
    return (<MainNavTeacher
      userType={user?.userType}
      googlePhotoURL={user?.photoURL}
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />)
  }

  if (showNavBar && user && user.userType === "admin") {
    return (<MainNavAdmin
      userType={user?.userType}
      googlePhotoURL={user?.photoURL}
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />)
  }

  return <>
    {showNavBar && user && user.userType !== null
      ? <h6>ERROR - NO USER </h6>
      : null}</>
}

// create State
// showNavBar setShowNavbar = ....
// turn off navbar on store? dispatch(hideNavBar / show)


// fix routes to be specifc to one of 4 apps
function App() {
  const [showNavBar, setShowNavbar] = useState(true)
  const [currentCoin, setCurrentCoin] = useState("Kansha Token")

  return (
    <>
      <Router>
        {NavBar(showNavBar, currentCoin, setCurrentCoin)}

        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signupwithgoogle" component={SignUpWithGoogle} />
          <Route path="/loginerror" component={LoginError} />
          <Route path="/passwordreset" component={PasswordReset} />
          <Route path="/passwordresetconfirm" component={PasswordResetConfirm} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/pagenotfound" component={PageNotFound} />
          <Route path="/termsofuse" component={TermsOfUse} />
          <StudentRoute path="/studentdashboard"
            component={StudentDashboard}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar}
            currentCoin={currentCoin}
            setCurrentCoin={setCurrentCoin} />
          <StudentRoute path="/rightnow" component={RightNow} />
          <StudentRoute path="/spinner" component={Spinner} />
          <StudentRoute path="/fourcorners" component={FourCorners} />
          <StudentRoute path="/biggest" component={WhoIsBiggest} />
          <StudentRoute path="/MyKanshaTokens" component={MyKanshaTokens}
            setShowNavBar={setShowNavbar} showNavBar={showNavBar} currentCoin={currentCoin} setCurrentCoin={setCurrentCoin} />
          <StudentRoute path="/classroomjoin" component={ClassroomJoin} />
          <StudentRoute path="/currentgroupdisplay"
            component={CurrentGroupDisplay} />
          <StudentRoute path="/classroomsdisplaylist"
            component={ClassroomsDisplayList} />
          <StudentRoute path="/classroomdisplaygroupleader"
            component={ClassroomsDisplayGroupLeader} />

          <StudentRoute path="/breaktime" component={BreakTime} />
          <StudentRoute path="/checkin" component={CheckIn} />

          <StudentRoute path="/commongoodsstudent"
            component={CommonGoodsStudent}
            currentCoin={currentCoin}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <StudentRoute path="/commongoodsstudentcontribution"
            component={CommonGoodsStudentContribution}
            currentCoin={currentCoin}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <StudentRoute path="/commongoodsstudentgoalreached"
            component={CommonGoodsStudentGoalReached}
            currentCoin={currentCoin}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <StudentRoute path="/studentstore"
            component={StudentStore}
            currentCoin={currentCoin}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <StudentRoute path="/studentexchange"
            component={StudentExchange}
            currentCoin={currentCoin}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <StudentRoute path="/studentexchangeamount"
            component={StudentExchangeAmount}
            currentCoin={currentCoin} />

          <StudentRoute path="/studentprofileedit" component={StudentProfileEdit} setShowNavBar={setShowNavbar} />

          <StudentRoute path="/studentresetpassword"
            component={StudentResetPassword} setShowNavBar={setShowNavbar} />

          <StudentRoute path="/raffle" currentCoin={currentCoin} component={Raffle} />

          <StudentRoute path="/rafflebuytickets" currentCoin={currentCoin} component={RaffleBuyTickets} />

          <StudentRoute path="/schoolstorestudent" component={SchoolStoreStudent} />

          <StudentRoute
            path="/physicalgoodbuy"
            currentCoin={currentCoin}
            component={PhysicalGoodBuy} />

          <StudentRoute
            path="/bonus"
            component={Bonus} />

          <TeacherRoute path="/teacherdashboard" component={TeacherDashboard}
            setShowNavBar={setShowNavbar} showNavBar={showNavBar} />
          <TeacherRoute path="/currentclassroommain" component={CurrentClassroomMain}
            setShowNavBar={setShowNavbar} showNavBar={showNavBar} />
          <TeacherRoute path="/welcomehowtosetup" component={WelcomeHowToSetup}
            setShowNavBar={setShowNavbar} showNavBar={showNavBar} />
          <TeacherRoute path="/teacherprofileedit/" component={TeacherProfileEdit} setShowNavBar={setShowNavbar} />
          <TeacherRoute path="/teacherresetpassword"
            component={TeacherResetPassword} setShowNavBar={setShowNavbar} />

          <TeacherRoute path="/teacherupdatingstudents"
            component={TeacherUpdatingStudents}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/classroomsdisplay" component={ClassroomsDisplay} />
          <TeacherRoute path="/classroomscreate" component={ClassroomsCreate} />
          <TeacherRoute path="/classroomedit" component={ClassroomEdit} />
          <TeacherRoute path="/play" component={Play} />
          <TeacherRoute path="/dots" component={Dots} />
          <TeacherRoute path="/spinnerteacher2" component={SpinnerTeacher2}
            setShowNavBar={setShowNavbar} />
          <TeacherRoute path="/spinnerselector"
            component={SpinnerSelector}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />
          <TeacherRoute path="/groupcreatedraggable" component={GroupCreateDraggable} />
          <TeacherRoute path="/groupsize" component={GroupSize} />
          <TeacherRoute path="/groupdetails" component={GroupDetails} />
          <TeacherRoute path="/groupedit" component={GroupEdit} />
          <TeacherRoute path="/groupthemecreate" component={GroupthemeCreate} />

          <TeacherRoute path="/groupthemeslist"
            component={GroupthemesList}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/schooldisplay" component={SchoolDisplay} />

          <TeacherRoute
            path="/kanshaaward"
            component={KanshaAward}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/commongoodsteacher" component={CommonGoodsTeacher} />
          <TeacherRoute path="/commongoodscreate" component={CommonGoodsCreate} />
          <TeacherRoute path="/commongoodsedit" component={CommonGoodsEdit} />
          <TeacherRoute path="/commongoodspayup" component={CommonGoodsPayUp} />
          <TeacherRoute path="/commongoodsview" component={CommonGoodsView} />
          <TeacherRoute path="/commongoodscredit" component={CommonGoodsCredit} />

          <TeacherRoute path="/rafflemainteacher" component={RaffleMainTeacher} />
          <TeacherRoute path="/prizecreate" component={PrizeCreate} />
          <TeacherRoute path="/prizeedit" component={PrizeEdit} />
          <TeacherRoute path="/prizeraffleoff" component={PrizeRaffleOff} />
          <TeacherRoute path="/prizetickets" component={PrizeTickets} />
          <TeacherRoute path="/schoolstoreteacher" component={SchoolStoreTeacher} />
          <TeacherRoute path="/physicalgoodteacherview" component={PhysicalGoodTeacherView} />

          <TeacherRoute path="/studentslistteacher"
            component={StudentsListTeacher}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/studentstileteacher"
            component={StudentsTileTeacher}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/groupslist"
            component={GroupsList}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/groupslistaddgroup"
            component={GroupsListAddGroup}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/groupslistaddstudent"
            component={GroupsListAddStudent}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/groupsteamwork"
            component={GroupsTeamwork}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <TeacherRoute path="/tokens"
            component={Tokens}
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar} />

          <AdminRoute path="/admindashboard" 
            component={AdminDashboard}  
            setShowNavBar={setShowNavbar}
            showNavBar={showNavBar}  />

          <AdminRoute path="/adminprofileedit" component={AdminProfileEdit} />
          <AdminRoute path="/adminresetpassword"
            component={AdminResetPassword} setShowNavBar={setShowNavbar} />
          <AdminRoute path="/schoolstore" component={SchoolStore} />
          <AdminRoute path="/schoolcreate" component={SchoolCreate} />
          <AdminRoute path="/schooledit" component={SchoolEdit} />
          <AdminRoute path="/schoolsdisplay" component={SchoolsDisplay} />
          <AdminRoute path="/physicalgoodcreate" component={PhysicalGoodCreate} />
          <AdminRoute path="/physicalgoodedit" component={PhysicalGoodEdit} />
          <AdminRoute path="/physicalgoodadminview" component={PhysicalGoodView} />
          <AdminRoute path="/currentschool" component={CurrentSchool} />

          <SuperRoute path="/superdashboard" component={SuperDashboard} />
          <SuperRoute path="/superusers" component={SuperUsers} />
          <SuperRoute path="/animatedgrid" component={AnimatedGrid} />
          <SuperRoute path="/groupstest" component={GroupsTest} />
          <SuperRoute path="/basicgrid" component={BasicGrid} />
          <SuperRoute path="/fourcornerstest" component={FourCornersTest} />
          <SuperRoute path="/dashboardtest" component={DashboardTest} />
          <SuperRoute path="/testground" component={TestGround} />
          <Route component={PageNotFound} />
        </Switch>
      </Router >
    </>)
}

export default App;

