import * as types from './classroomTypes'
import _ from 'lodash'


const initClassroom = {
  loading: false,
  classroomData: {},
  image: null,
  groupthemeData: {}, // monitor overall scores
  groupData: {}, // adding points, summing up coins, editing , etc
  groupTransferComplete: null,
  error: '',
  currentGameObject: null,
  socketio: null,
  classroomTransactionMostRecent: null,
  classroomTransactionsAll: null,
  schoolTransactions: null,
  commonGoodMostRecent: null,
  commonGoodsAll: null,
  prizeMostRecent: null,
  prizesAll: null,
  schoolwidePhysicalGoods: null,
  schoolwidePhysicalGoodMostRecent: null,
  schoolwideVirtualGoods: null,
  schoolwideVirtualGoodMostRecent: null,
  raffle: null,
  raffleTicketIds: [],
  raffleTicketLedger: {},
  okayToCompleteTransaction: null,
  onCompleteRoute: null
}


function classroomReducer(state = initClassroom, action) {
  switch (action.type) {

    // ---  get --- //

    case types.GET_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_CLASSROOM_SUCCESS:
      // temporary for all files without classroom graphid
      if (!action.payload.image) {
        action.payload.image = null
      }
      return {
        ...state,
        ...action.payload,
        loading: false,
        classroomData: action.payload, // TEMPORARY!
        error: '',
      };

    case types.GET_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    // ---  update --- ///

    case types.UPDATE_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_CLASSROOM_SUCCESS:

      return {
        ...state,
        ...action.payload,
        loading: false,
        classroomData: action.payload, // TEMPORARY!
        error: '',
        onCompleteRoute: "/teacherdashboard"
      };

    case types.UPDATE_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        classroomData: {},
        error: action.payload,
      };


    // ####### GROUPTHEME ####### //

    // ---  addd --- ///

    case types.ADD_GROUPTHEME_TO_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.ADD_GROUPTHEME_TO_CLASSROOM_SUCCESS:
      return {
        ...state, ...action.payload,
        loading: false,
        error: '',
      };

    case types.ADD_GROUPTHEME_TO_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    // ---  create --- ///

    case types.CREATE_GROUPTHEME_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_GROUPTHEME_SUCCESS:
      return {
        ...state,
        loading: false,
        groupthemeData: action.payload,
        error: '',
      };

    case types.CREATE_GROUPTHEME_FAILURE:
      return {
        ...state,
        loading: false,
        groupthemeData: {},
        error: action.payload,
      };

    // ---  get --- ///

    case types.GET_GROUPTHEME_REQUEST:
      return {
        ...state,
        loading: true,
        groupTransferComplete: null
      };

    case types.GET_GROUPTHEME_SUCCESS:
      return {
        ...state,
        loading: false,
        groupthemeData: action.payload,
        error: '',
        onCompleteRoute: null,
        groupTransferComplete: null
      };

    case types.GET_GROUPTHEME_FAILURE:
      return {
        ...state,
        loading: false,
        // groupthemeData: {},
        error: action.payload,
        groupTransferComplete: null
      };


    // ---  update --- ///

    case types.UPDATE_GROUPTHEME_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_GROUPTHEME_SUCCESS:
      return {
        ...state,
        loading: false,
        groupthemeData: action.payload,
        error: '',
      };

    case types.UPDATE_GROUPTHEME_FAILURE:
      return {
        ...state,
        loading: false,
        //groupthemeData: {},
        error: action.payload,
      };

    // ---  delete --- ///

    case types.DELETE_GROUPTHEME_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.DELETE_GROUPTHEME_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomData: action.payload,
        error: '',
      };

    // ** special case (?)
    case types.DELETE_GROUPTHEME_ALSO_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomData: action.payload,
        groupData: [],
        groupthemeData: {},
        error: '',
      };

    case types.DELETE_GROUPTHEME_FAILURE:
      return {
        ...state,
        loading: false,
        //groupthemeData: {},
        error: action.payload,
      };


    // ####### GROUP ####### //

    // --- create -- //

    case types.CREATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_GROUP_SUCCESS:
      const [newGroupData, updatedGrouptheme] = action.payload


      return {
        ...state,
        loading: false,
        groupData: newGroupData,
        groupthemeData: updatedGrouptheme,
        onCompleteRoute: "/groupslist",
        error: '',
      };

    case types.CREATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    // --- get -- //

    case types.GET_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,
        error: '',
        onCompleteRoute: null
      };

    case types.GET_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        groupData: [],
        error: action.payload
      };


    // --- "add point" -- //

    case types.ADD_POINT_TO_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.ADD_POINT_TO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload, // single group: remove old and add new!
        error: '',
      };

    case types.ADD_POINT_TO_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        //groupData: {},
        error: action.payload,
      };


    // ---  update --- //

    case types.UPDATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,
        onCompleteRoute: "/groupslist",
        error: '',
      };

    case types.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // --- delete --- //

    case types.DELETE_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      };


    case types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload[0],
        groupthemeData: action.payload[1],
        onCompleteRoute: "/groupslist",
        error: '',
      };

    case types.DELETE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    // ####### SETOFGROUPS ####### //

    // --- create --- //

    case types.CREATE_SETOFGROUPS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_SETOFGROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        // for now, groupData will keep current groups.  
        // should this be in groupThemes?
        groupData: action.payload,
        error: '',
      };

    case types.CREATE_SETOFGROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        groupData: [],
        error: action.payload,
      };


    // --- get --- //

    case types.GET_SETOFGROUPS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_SETOFGROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        // for now, groupData will keep current groups.  
        // should this be in groupThemes?
        groupData: action.payload,
        error: '',
      };

    case types.GET_SETOFGROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        groupData: [],
        error: action.payload,
      };



    // --- delete --- //

    case types.DELETE_SETOFGROUPS_REQUEST: // loading necessary
      return {
        ...state,
        loading: true
      };

    case types.DELETE_SETOFGROUPS_SUCCESS:
      return {
        ...state,
        loading: false,

        groupData: [],
        error: '',
      };

    case types.DELETE_SETOFGROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    // -- remove from CLASSROOM --//

    case types.REMOVE_STUDENT_FROM_CLASSROOM_REQUEST: // loading necessary
      return {
        ...state,
        loading: true
      };

    case types.REMOVE_STUDENT_FROM_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomData: action.payload,
        error: '',
      };

    case types.REMOVE_STUDENT_FROM_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        classroomData: {},
        error: action.payload,
      };


    // -- remove from GROUP --//

    case types.REMOVE_STUDENT_FROM_GROUP_REQUEST: // loading necessary
      return {
        ...state,
        loading: true,
        groupTransferComplete: false
      };

    case types.REMOVE_STUDENT_FROM_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,
        groupTransferComplete: true,
        error: ''
      };

    case types.REMOVE_STUDENT_FROM_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        groupTransferComplete: false
      };


    // --- add to GROUP ---//

    case types.ADD_STUDENT_TO_GROUP_REQUEST: // loading necessary
      return {
        ...state,
        loading: true,
        groupTransferComplete: false
      };

    case types.ADD_STUDENT_TO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,
        groupTransferComplete: true,
        error: ''
      };

    case types.ADD_STUDENT_TO_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        groupTransferComplete: false
      };


    // ####### TRANSACTIONS ####### //

    // --- create ---//

    case types.CREATE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomTransactionMostRecent: action.payload,
        error: ''
      };

    case types.CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // --- get ---//

    case types.GET_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomTransactionMostRecent: action.payload,
        error: ''
      };

    case types.GET_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    // --- update ---//

    case types.UPDATE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomTransactionMostRecent: action.payload,
        error: ''
      };

    case types.UPDATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    // --- delete ---//

    case types.DELETE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomTransactionMostRecent: action.payload,
        error: ''
      };

    case types.DELETE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    // ----- get all ----- //


    case types.GET_ALL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        classroomTransactionsAll: action.payload,
        error: ''
      };

    case types.GET_ALL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    // ####### COMMON GOODS ####### //


    // ----- create ----- //

    case types.CREATE_COMMON_GOOD_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.CREATE_COMMON_GOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: action.payload,
        error: '',
        onCompleteRoute: "/commongoodsteacher"
      };

    case types.CREATE_COMMON_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: null,
        error: action.payload,
        onCompleteRoute: null
      };


    // ------ get ------ //

    case types.GET_COMMON_GOOD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_COMMON_GOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: action.payload,
        error: ''
      };

    case types.GET_COMMON_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: null,
        error: action.payload

      };


    // ------ update ------ //

    case types.UPDATE_COMMON_GOOD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_COMMON_GOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: action.payload,
        onCompleteRoute: "/commongoodsteacher",
        error: '',
      };

    case types.UPDATE_COMMON_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: null,
        error: action.payload,
      };


    // ------ delete ------ //


    case types.DELETE_COMMON_GOOD_REQUEST:

      return {
        ...state,
        loading: true,
        commonGoodMostRecent: null,
        onCompleteRoute: null
      };


    case types.DELETE_COMMON_GOOD_SUCCESS:

      const oldCommonGoods = [...state.commonGoodsAll]
      const removedCommonGood = oldCommonGoods.filter(cg => cg._id !== action.payload._id)

      return {
        ...state,
        loading: false,
        commonGoodsAll: removedCommonGood,
        commonGoodMostRecent: action.payload,
        error: '',
        onCompleteRoute: 'commongoodscredit'
      };


    case types.DELETE_COMMON_GOOD_FAILURE:
      return {
        ...state,
        loading: false,
        commonGoodMostRecent: null,
        error: action.payload,
        onCompleteRoute: null
      };

    // ------ get all ------ //

    case types.GET_ALL_COMMON_GOODS_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.GET_ALL_COMMON_GOODS_SUCCESS:
      return {
        ...state,
        loading: false,
        commonGoodsAll: action.payload,
        error: '',
        onCompleteRoute: null
      };

    case types.GET_ALL_COMMON_GOODS_FAILURE:
      return {
        ...state,
        loading: false,
        commonGoodsAll: null,
        error: action.payload,
        onCompleteRoute: null
      };


    // ---- STUDENT DONATIONS ---- ///
    case types.PROCESS_STUDENT_DONATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        commonGoodMostRecent: null,
        onCompleteRoute: null,
        //currentClassroom: null
      };

    case types.PROCESS_STUDENT_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        commonGoodMostRecent: action.payload,
        onCompleteRoute: "/commongoodsstudent"
      };

    case types.PROCESS_STUDENT_DONATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        commonGoodMostRecent: null,
        onCompleteRoute: null
      };


    // ####### PRIZES ####### //


    // ----- create ----- //

    case types.CREATE_PRIZE_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.CREATE_PRIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        prizeMostRecent: action.payload,
        error: '',
        onCompleteRoute: "/rafflemainteacher"
      };

    case types.CREATE_PRIZE_FAILURE:
      return {
        ...state,
        loading: false,
        prizeMostRecent: null,
        error: action.payload,
        onCompleteRoute: null
      };


    // ------ get ------ //

    case types.GET_PRIZE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_PRIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        prizeMostRecent: action.payload,
        error: ''
      };

    case types.GET_PRIZE_FAILURE:
      return {
        ...state,
        loading: false,
        prizeMostRecent: null,
        error: action.payload

      };


    // ------ update ------ //

    // const oldCommonGoods = [...state.commonGoodsAll]
    // const removedCommonGood = oldCommonGoods.filter(cg => cg._id != action.payload._id)

    case types.UPDATE_PRIZE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_PRIZE_SUCCESS:
      const newPrizesAll = [...state.prizesAll]
      const indexOfPrize = _.findIndex(newPrizesAll, function (o) { return o._id == action.payload._id; });

      newPrizesAll[indexOfPrize] = action.payload

      return {
        ...state,
        loading: false,
        prizeMostRecent: action.payload,
        prizesAll: newPrizesAll,
        onCompleteRoute: "/rafflemainteacher",
        error: '',
      };

    case types.UPDATE_PRIZE_FAILURE:
      return {
        ...state,
        loading: false,
        prizeMostRecent: null,
        error: action.payload,
      };


    // ------ delete ------ //

    case types.DELETE_PRIZE_REQUEST:
      return {
        ...state,
        loading: true,
        prizeMostRecent: null
      };

    case types.DELETE_PRIZE_SUCCESS:

      const oldPrizes = [...state.prizesAll]
      const removeDeletedPrize = oldPrizes.filter(pr => pr._id != action.payload._id)

      return {
        ...state,
        loading: false,
        prizesAll: removeDeletedPrize,
        prizeMostRecent: action.payload,
        error: '',
      };


    case types.DELETE_PRIZE_FAILURE:
      return {
        ...state,
        loading: false,
        prizeMostRecent: null,
        error: action.payload,
      };

    // ------ get all ------ //

    case types.GET_ALL_PRIZES_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.GET_ALL_PRIZES_SUCCESS:
      return {
        ...state,
        loading: false,
        prizesAll: action.payload,
        error: '',
        onCompleteRoute: null
      };

    case types.GET_ALL_PRIZES_FAILURE:
      return {
        ...state,
        loading: false,
        prizesAll: null,
        error: action.payload,
        onCompleteRoute: null
      };



    // ------ special update path for "raffle" ------ //

    case types.UPDATE_RAFFLE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_RAFFLE_SUCCESS:
      return {
        ...state,
        loading: false,
        raffle: action.payload, //REMOVES
        error: '',
      };

    case types.UPDATE_RAFFLE_FAILURE:
      return {
        ...state,
        loading: false,
        raffle: null,
        error: action.payload,
      };


    // ------ student raffle purchase------ //


    case types.STUDENT_RAFFLE_PURCHASE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.STUDENT_RAFFLE_PURCHASE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        classroomData: action.payload, // factor out soon
        // raffle: action.payload.raffle,
        // raffleTicketIds: action.payload.raffleTicketIds,
        // raffleTicketLedger: null
        error: '',
        onCompleteRoute: "/raffle"
      };

    case types.STUDENT_RAFFLE_PURCHASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null
      };



    // ####### SCHOOLWIDE PHYSICAL GOODS ####### //

    // ----- create ----- //

    case types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null
      };

    case types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: action.payload,
        error: '',
        onCompleteRoute: "/commongoodsteacher"
      };

    case types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: null,
        error: action.payload,
        onCompleteRoute: null
      };


    // ------ get ------ //

    case types.GET_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: action.payload,
        error: ''
      };

    case types.GET_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: null,
        error: action.payload

      };


    // ------ update ------ //

    case types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: action.payload,
        onCompleteRoute: "/commongoodsteacher",
        error: '',
      };

    case types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: null,
        error: action.payload,
      };

    // ------ purchase (special update) ------ //

    case types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true,
        okayToCompleteTransaction: null
      };

    case types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: action.payload,
        onCompleteRoute: null,
        error: '',
        okayToCompleteTransaction: true
      };

    case types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: null,
        error: action.payload,
        okayToCompleteTransaction: false
      };


    // ------ delete ------ //


    case types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST:

      return {
        ...state,
        loading: true,
        schoolwidePhysicalGoodMostRecent: null
      };


    case types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS:

      const oldPhysicalGoods = [...state.commonGoodsAll]
      const removedPhysicalGood = oldPhysicalGoods.filter(cg => cg._id != action.payload._id)

      return {
        ...state,
        loading: false,
        commonGoodsAll: removedCommonGood,
        schoolwidePhysicalGoodMostRecent: action.payload,
        error: '',
      };


    case types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoodMostRecent: null,
        error: action.payload,
      };


    // ------ get all ------ //

    case types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        okayToCompleteTransaction: null
      };

    case types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoods: action.payload,
        error: '',
        onCompleteRoute: null,
        okayToCompleteTransaction: null
      };

    case types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_FAILURE:
      return {
        ...state,
        loading: false,
        schoolwidePhysicalGoods: null,
        error: action.payload,
        onCompleteRoute: null,
        okayToCompleteTransaction: null
      };


    // ------ get STUDENT group details from GROUPTHEME ------ //
    // note: here we'll yse groupData as an object, even though default is []

    case types.GET_STUDENT_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_STUDENT_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,// HERE
        onCompleteRoute: null,
        error: ''
      };

    case types.GET_STUDENT_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        groupData: {},
        error: action.payload
      };



    // ####### CLEAR ALL ####### //

    case types.CLEAR_GROUP:
      return {
        ...state,
        groupData: [],
      }

    case types.CLEAR_GROUPTHEME:
      return {
        ...state,
        groupthemeData: {},
      }

    case types.CLEAR_CLASSROOM:
      return initClassroom;


    default:
      return state;
  }
}

export default classroomReducer


