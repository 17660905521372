import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateGroup } from '../../redux/classroom/classroomActions'
import RenderSVG from '../common/RenderSVG'
import teamObjects from '../../data/teamObjects_TEST.json'




export default function AddStudents(props) {
  const { state
    // state: { _id: groupId, colorOnBoard, teacherNotes, displayNameGraphics: { english: groupName, svg: svgFilename }, points, studentIds }
  } = props.history.location


  console.log("state", state)
  const [changeIcon, setChangeIcon] = useState(false)
  const [availableTeamsObjects, setAvailableTeamsObjects] = useState(teamObjects)
  const [newAvatarSelected, setNewAvatarSelected] = useState(null)

  const [groupInfo, setGroupInfo] = useState(null)



  const dispatch = useDispatch()
  const history = useHistory()

  const { error, loading, onCompleteRoute } = useSelector(state => state.classroom)

  useEffect(() => {
    setGroupInfo(state)
  }, [])



  const onSubmit = (formData) => {
    const mergeUpdatedName = { ...groupInfo.displayNameGraphics, ...{ english: formData.name } }
    const updatedGroupInfo = { ...groupInfo, ...{ displayNameGraphics: mergeUpdatedName }, ...{ teacherNotes: formData.teacherNotes } }

    // dispatch(updateGroup(groupId, { ...updatedGroupInfo }))
    // history.push("/groupslist")

  };




  // const showForm = () => {
  //   return (<>
  //     <form onSubmit={e => e.preventDefault()}>
  //       <label>Name</label>
  //       <input
  //         name="name"
  //         type="text"
  //         ref={register({
  //           required: "You must include a group name",
  //           minLength: {
  //             value: 2,
  //             message: "Name must have at least 2 characters"
  //           }
  //         })}
  //       />
  //       {errors.name && <p>{errors.name.message}</p>}

  //       <label>Instructor Notes</label>
  //       <div>
  //         <textarea
  //           name="teacherNotes"
  //           type="text"
  //           ref={register()}
  //           style={{ "width": "100%", "height": "200px" }}
  //         />
  //       </div>
  //       <br />
  //       <br />

  //       <Button
  //         onClick={handleSubmit(onSubmit)}
  //         size="huge"
  //         type="submit"
  //         inverted color='blue'
  //       >Save Changes</Button>
  //       <h2>{"   "}</h2>
  //       <div>
  //         <Button
  //           onClick={deleteGroupClickHandler}
  //           size="mini"
  //           color='red'
  //           text="blue"
  //         >Delete</Button>
  //       </div>
  //       <h2>{"   "}</h2>
  //     </form>
  //   </>
  //   )
  // }







  if (onCompleteRoute) {
    history.push("/groupslist")
  }

  if (loading) {
    return <h1>Loading</h1>
  }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }



  return (<>

    <h1>Add student to the {"groupName"}</h1>


    {/* 
    {newAvatarSelected
      ? (<RenderSVG size={"200px"} svgFilename={newAvatarSelected.svg} />)
      : (<div onClick={() => setChangeIcon(!changeIcon)}>
        {groupInfo &&
          <>
            <ReactTooltip />

            <div data-tip="Edit Avatar" >
              <RenderSVG size={"200px"} svgFilename={groupInfo.displayNameGraphics.svg} />

            </div>



          </>}
      </div>)
    } */}



  </>)
}
