import * as types from './teacherTypes'

const initTeacher = {
  loading: false,
  teacherData: {},
  error: '',
  onCompleteRoute: null,
  reduxResponseData: null,
  navbar: { main: null, dashboard: null, students: null, groups: null, tokens: null, play: null }
}

function teacherReducer(state = initTeacher, action) {
  switch (action.type) {

    case types.GET_TEACHER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
      };

    case types.GET_TEACHER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload[0],
        error: '',
        onCompleteRoute: "/teacherdashboard",
        reduxResponseData: null
      };

    case types.GET_TEACHER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };



    case types.UPDATE_TEACHER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reduxResponseData: null
      };

    case types.UPDATE_TEACHER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload,
        error: '',
        onCompleteRoute: "/teacherdashboard",
        reduxResponseData: "updated"
      };

    case types.UPDATE_TEACHER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };


    // Note new class is created in Teacher store rather than classroom
    case types.CREATE_NEW_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.CREATE_NEW_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload,
        error: '',
        reduxResponseData: "classroom-created",
        onCompleteRoute: "/classroomsdisplay"
      };

    case types.CREATE_NEW_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };


    case types.DELETE_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reloadProfile: false
      };

    case types.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload,
        error: '',
        onCompleteRoute: null,
        reloadProfile: true
      };

    case types.DELETE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reloadProfile: false // this could be an issue if series of actions is not completed
      };



    case types.INCREASE_STUDENT_COINS_BY_TEACHER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.INCREASE_STUDENT_COINS_BY_TEACHER_SUCCESS:
      return {
        ...state,
        // no payload here necessary
        loading: false
      };

    case types.INCREASE_STUDENT_COINS_BY_TEACHER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    case types.CHECK_SCHOOL_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.CHECK_SCHOOL_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        //teacherData: no change
        error: '',
        reduxResponseData: action.payload,
        onCompleteRoute: null

      };

    case types.CHECK_SCHOOL_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        //teacherData: no change
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };




    case types.JOIN_SCHOOL_WITH_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.JOIN_SCHOOL_WITH_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload, // need to spread?
        error: '',
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.JOIN_SCHOOL_WITH_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        //teacherData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };


    case types.UPDATE_TEACHER_NAVBAR:
      const newState = { ...state }
      newState.navbar = { ...newState.navbar, ...action.payload }
      return { ...newState };

    case types.CLEAR_TEACHER_NAVBAR:
      return {
        ...state,
        navbar: initTeacher.navbar
      };




    case types.CLEAR_TEACHER_ROUTING:
      return {
        ...state,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.CLEAR_TEACHER:
      return initTeacher;

    default:
      return state;
  }
}

export default teacherReducer










    /// --- updating student coins ---- ///
/*
    case types.DELETE_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reloadProfile: false
      };
 
    case types.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        teacherData: action.payload,
        error: '',
        onCompleteRoute: null,
        reloadProfile: true
      };
 
    case types.DELETE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reloadProfile: false // this could be an issue if series of actions is not completed
      };
 
 
 
 */