import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import notify from '../../utils/notify'
import { createSetOfGroups } from "../../redux/classroom/classroomActions"
import _ from 'lodash'
import myChunk from '../../utils/myChunk'
import changeKeyObjects from "../../utils/changeKeyObjects"
import { SimpleSpinner } from '../common/Spinners'
import pickRandomItem from '../../utils/pickRandomItem'
import RenderSVG from '../common/RenderSVG'
import teamObjects2 from '../../data/teamObjects.json'

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};

const GroupCreateDraggable = (props) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const teamNameThemes2 = Object.keys(teamObjects2)
  const [teamNames2, setTeamNames2] = useState(_.shuffle(teamObjects2[pickRandomItem(teamNameThemes2)]));

  const [orderByFirstAscending, setOrderByFirstAscending] = useState(true);
  const [orderByLastAscending, setOrderByLastAscending] = useState(true);

  const { teacherDataFromProps:
    { teacherData: { classroomId_current, _id: teacherId } } } = props

  const {
    loading,
    groupthemeIds,
    groupthemeData: { setNumberOfGroups: previouslySavedNumberOfGroups, topic },
    groupthemeId_building,
    studentIds
  } = useSelector(state => state.classroom)

  const [currentAmountOfGroups, setCurrentAmountOfGroups] = useState(previouslySavedNumberOfGroups)

  const [currentGroupThemes, setCurrentGroupThemes] = useState(groupthemeIds)
  const closePage = () => history.push('/groupthemeslist')
  // 


  //console.log("teamNames2", teamNames2)

  useEffect(() => {
    setCurrentGroupThemes([...groupthemeIds])
  }, [groupthemeIds])

  const shuffleGroupNames = () => {
    setTeamNames2(_.shuffle(teamNames2)
    )
  }

  const differentSetOfNames = () => {
    const category = pickRandomItem(teamNameThemes2)
    const newTheme = _.shuffle(teamObjects2[category])
    // console.log("newTheme==>", newTheme)
    // console.log("category==>", category)
    // setGroupNamesCategory(category)
    setTeamNames2(newTheme)
  }


  const saveGroups = () => {
    // remove empty groups
    const adjustedColumns = { ...columns }
    const arrayOfKeysToDelete = []

    for (const columnKey in adjustedColumns) {
      if (adjustedColumns[columnKey].items) {
        if (adjustedColumns[columnKey].items.length === 0) {
          arrayOfKeysToDelete.push(columnKey)
        }
      }
    }

    for (const groupKey of arrayOfKeysToDelete) {
      delete adjustedColumns[groupKey]
      // or should we use filter here?
    }
    const adjustedNumberOfGroups = Object.keys(adjustedColumns).length

    if (adjustedNumberOfGroups < 2) {
      console.error("😩😩😩 Something is terribly wrong under the hood.😩😩😩")
      // push to unknow error page
    }

    // why check for a string?
    if (typeof groupthemeId_building === "string") {
      dispatch(createSetOfGroups(adjustedColumns, teacherId, classroomId_current._id, groupthemeId_building, adjustedNumberOfGroups))



      // ---------------------------- //


      // MAIN: createSetOfGroups

      // SUB 1: dispatch(createSetOfGroupsSuccess(allNewGroups))

      // SUB 2: dispatch(updateGrouptheme(groupthemeIdBuiling,
      //   { setNumberOfGroups: updatedNumberOfGroups, groupIds }))

      // SUB 3: dispatch(updateClassroom(classroomId, classroomUpdates))

      // (Why is #4 not part of #3)
      // SUB 4: dispatch(addGroupthemeToClassroom(classroomId, groupthemeIdBuiling))

      // SATURDAY AM
      // What to do: createSetOfGroups should turn loadnig to true - CHECK!
      // FIX addGroupthemeToClassroom REDUCEER TO increases groupthemeIDS
      // REMOVE groupthemeData refernece to setNumberOfGroups -- go directly tothe grouprIds
      // when toastie finishes, there must be a callback, then push

      // ---------------------------- //

      // history.push('/groupthemeslist')



      notify({ onClose: () => closePage(), message: `${topic} groups created!`, noticeType: "success", autoClose: 2000 })
    }

    else {

      notify({ onClose: () => closePage(), message: "Something is wrong with groupthemeId_building 😲😲😲 groupthemeId_building:", groupthemeId_building, noticeType: "error", autoClose: 2000 })

    }

  }


  const fontSize = currentAmountOfGroups < 6 ? "14px" : "10px"

  const styleRegular = {
    fontSize: fontSize, display: "flex", justifyContent: "center", height: "100%"
  }
  const styleSmall = {
    fontSize: "10px", display: "flex", justifyContent: "center", height: "100%"
  }


  // ---  SORTING / SHUFFLING
  // change the order of the ids
  const [studentIdsReordered, setStudentIdsReordered] = useState(studentIds);

  const randomize = () => {
    setStudentIdsReordered(_.shuffle(studentIdsReordered))
  }

  const orderByFirst = () => {
    const iteratees = obj => obj.firstName;
    let sortedStudentIdsByFirst
    if (orderByFirstAscending) {
      sortedStudentIdsByFirst = _.sortBy(studentIdsReordered, iteratees)
    } else {
      sortedStudentIdsByFirst = _.sortBy(studentIdsReordered, iteratees).reverse()
    }
    setOrderByFirstAscending(!orderByFirstAscending)
    setStudentIdsReordered(sortedStudentIdsByFirst)
  }


  const orderByLast = () => {
    const iteratees = obj => obj.lastName;
    let sortedStudentIdsByLast
    if (orderByLastAscending) {
      sortedStudentIdsByLast = _.sortBy(studentIdsReordered, iteratees)
    } else {
      sortedStudentIdsByLast = _.sortBy(studentIdsReordered, iteratees).reverse()
    }
    setOrderByLastAscending(!orderByLastAscending)
    setStudentIdsReordered(sortedStudentIdsByLast)
  }



  /// ----- Option A) Replace Keys for dnd algo using changeKeyObject ------ //
  const replaceKeys = {
    _id: "id",
    displayName: "displayName",
    lastName: "lastName",
    firstName: "firstName",
    avatar: "avatar"
  }

  const studentIdsNewKeys = changeKeyObjects(studentIdsReordered, replaceKeys)



  /// ------- Option B) Replace Keys Manually and simplify avatar object ------- //

  // simplify avatar property (until editable avatars added)
  //const studentIds = studentIds.map((student) => { _id })
  /* 
    const studentIdsNewKeys = studentIdsReordered.map((student) => {
      return {
        id: student.id,
        displayName: student.displayName,
        lastName: student.lastName,
        firstName: student.firstName,
        avatar: student.avatar.avatarId
      }
    })
   
   */


  let allGroupsChunked = myChunk(studentIdsNewKeys, currentAmountOfGroups)



  // Create startingColumns object with groupName and array of students
  // const [chunked, setChunked] = useState({});
  const [columns, setColumns] = useState({});

  // 1 OUTSIDE>
  // create dnd columns
  // const startingColumns = {}
  // allGroupsChunked.forEach((gr, i) => {
  //   startingColumns[uuid()] = { name: groupNames[i], items: gr }
  // })


  // 2 MAKECOLUMNS ?
  // when call the function? when does it just flow?
  // const makeColumns = (chunkedArray, groupNamesArray) => {
  //   const newColumns = {}
  //   chunkedArray.forEach((gr, i) => {
  //     newColumns[uuid()] = { name: groupNamesArray[i], items: gr }
  //   })
  //   setColumns(newColumns)
  // }



  // 3 USE EFFFECT
  useEffect(() => {
    const startingColumns = {}
    allGroupsChunked.forEach((group, i) => {
      startingColumns[uuid()] = {
        name: teamNames2[i]?.english,
        items: group,
        svgPath: teamNames2[i].svg,
        displayNameGraphics: teamNames2[i] // added in order to keep all display data together for db record
      }
    })
    setColumns(startingColumns)
  }, [loading, studentIdsReordered, teamNames2])


  if (loading) {
    return <SimpleSpinner />
  }


  return (<>
    <h1>Finalize the {topic} groups</h1>
    <div style={styleRegular}>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
              key={columnId}
            >
              <RenderSVG size={"50px"} svgFilename={column.svgPath} />

              <h2 style={{ fontSize: "1.5em" }}>{column.name}</h2>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "yellow" // lightblue
                            : "lightgrey",
                          padding: 10, //4
                          width: 120, //250
                          minHeight: 150 // 500
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 16,
                                      margin: "0 0 8px 0",
                                      minHeight: "50px",
                                      backgroundColor: snapshot.isDragging
                                        ? "#263B4A"
                                        : "#456C86",
                                      color: "white",
                                      ...provided.draggableProps.style
                                    }}
                                  // onClick={() => console.log("CLICK--> SEND TO SIDELINE", item)}
                                  // onMouseEnter={() => console.log("ENTER: show full name below")}
                                  // onMouseLeave={() => console.log("EXIT")}
                                  >
                                    {item.firstName}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
    <div>
      <Button onClick={randomize} inverted color="blue">Shuffle Student Names</Button>
      <Button onClick={orderByFirst} inverted color="blue">By First Names</Button>
      <Button onClick={orderByLast} inverted color="blue">By Last Names</Button>
    </div>
    <div>
      <Button onClick={shuffleGroupNames} inverted color="green">Shuffle GROUP names</Button>
      <Button onClick={differentSetOfNames} inverted color="green">New GROUP names</Button>
    </div>
    <Button submit size="massive" onClick={saveGroups} color="black">Save the Set of Groups!
    </Button>

    <div>
      <Button
        color="red"
        size="small"
        onClick={() => history.push('/groupthemeslist')}
      >Cancel</Button>
    </div>
  </>
  );
}

export default GroupCreateDraggable;

