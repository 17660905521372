import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createNewClass } from '../../redux/teacher/teacherActions'
import classroomIcons from '../../data/icons_classrooms.json'
import RenderSVG from '../common/RenderSVG'
import pickRandomItem from '../../utils/pickRandomItem'
import ReactTooltip from 'react-tooltip';

export default function ClassroomCreate() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()
  const [iconNumber, setIconNumber] = useState(0)

  const {
    teacherData: {
      _id,
      classromId_current: { _id: classroomIdCurrent } = {},
      schoolId },
    loading,
    onCompleteRoute,
    reduxResponseData
  } = useSelector(state => state.teacher)

  const schoolIdFromTeacher = schoolId?._id

  const onSubmit = data => {
    data.schoolId = schoolIdFromTeacher
    data.image = classroomIcons.set_1[iconNumber]
    // console.log("data", data)
    dispatch(createNewClass(_id, data))
  };

  const changeIcon = () => {
    let newIconNumber = iconNumber + 1
    if (newIconNumber > classroomIcons.set_1.length - 1) {
      newIconNumber = 0
    }
    setIconNumber(newIconNumber)
  }

  useEffect(() => {
    const randomIcon = Math.floor(Math.random() * classroomIcons.set_1.length - 1);
    setIconNumber(randomIcon)
  }, [])


  if (onCompleteRoute && reduxResponseData === "classroom-created") {
    history.push(onCompleteRoute)
  }

  if (!schoolId) {
    return (<>
      <h2> You must be part of a school before creating a classroom.</h2>
      <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
    </>)
  }
//



  if (loading) {
    return <h1>Loading</h1>
  }


  return (
    <>
      <h1>Create a new classroom</h1>

      {iconNumber !== null &&
        <div onClick={changeIcon}>
          <RenderSVG
            size="100"
            svgFilename={classroomIcons.set_1[iconNumber].svg}
          />
        </div>}


      <form onSubmit={e => e.preventDefault()}>

        <label>Name of this classroom</label>
        <input
          name="name"
          type="text"
          ref={register({
            required: "You must include a name",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.name && <p>{errors.name.message}</p>}

        <label>Grade Level</label>
        <input
          name="grade"
          type="text"
          ref={register({
            required: "You must include a grade level",
            minLength: {
              value: 1,
              message: "Name must have at least 1 characters"
            }
          })}
        />
        {errors.grade && <p>{errors.grade.message}</p>}


        <label>Short description (i.e. 4th grade homeroom)</label>
        <input
          name="description"
          type="text"
          ref={register({
            required: "You must include a description",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.description && <p>{errors.description.message}</p>}
        <h1>{" "}</h1>


        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save New Class
        </Button>

        <h2>{"   "}</h2>

      </form>

    </>
  );
}




