import firebase from '../../config/fbConfig'
import * as types from './authTypes'
import http from '../../services/httpService'

const functions = firebase.functions();


// ---- send password reset email ---- //

export const passwordResetEmailRequest = () => {
  return {
    type: types.PASSWORD_RESET_EMAIL_REQUEST
  }
}

export const passwordResetEmailSuccess = userData => {
  return {
    type: types.PASSWORD_RESET_EMAIL_SUCCESS,
    payload: userData
  }
}

export const passwordResetEmailFailure = (err) => {
  return {
    type: types.PASSWORD_RESET_EMAIL_FAILURE,
    payload: err
  }
}



export const passwordResetEmail = (emailAddress) => {

  return async function (dispatch) {
    dispatch(passwordResetEmailRequest())
    try {

      const result = await firebase.auth().sendPasswordResetEmail(emailAddress)
      console.log(result)
      dispatch(passwordResetEmailSuccess())

    }
    catch (err) {
      dispatch(passwordResetEmailFailure(err.message))
      console.log(err.message)
    }
  }
}



// ---- fetch user FB ------ //
export const fetchUserFB = (userInfo) => {

  return function (dispatch) {
    try {
      dispatch(fetchUserRequestFB(userInfo))
      const { email, password } = userInfo
      // --- regular login --- ///
      firebase.auth().signInWithEmailAndPassword(email, password)
        // --- get custom claims ---- ///
        .then(() => {
          const auth = firebase.auth();
          auth.onAuthStateChanged(user => {
            if (user) {
              const { email, displayName, photoURL, uid } = user

              user
                .getIdTokenResult()
                .then(idTokenResult => {
                  const { authCustomClaim } = idTokenResult.claims;
                  console.log("authCustomClaim-->", authCustomClaim);
                  return authCustomClaim;
                })
                .then((authCustomClaim) => {
                  dispatch(fetchUserSuccessFB({
                    email,
                    displayName,
                    photoURL,
                    userType: authCustomClaim,
                    fb_uid: uid,
                    fullAuthObject: user
                  }))
                })
                .catch(err => console.log("Error in custom claim", err));
            } else {
              console.log("CLAIM ON SIGN-IN CHECKED: Logged out");
              return null;
            }
          });
        })
        .catch(err => {
          console.log("err==>", err)
          dispatch(fetchUserFailureFB(err))
        })
    }

    catch (err) {
      console.log("CATCH ERROR", err)
    }
  }
}

export const fetchUserRequestFB = () => {
  return {
    type: types.FETCH_USER_REQUEST_FB
  }
}

export const fetchUserSuccessFB = userData => {
  return {
    type: types.FETCH_USER_SUCCESS_FB,
    payload: userData
  }
}

export const fetchUserFailureFB = (err) => {
  return {
    type: types.FETCH_USER_FAILURE_FB,
    payload: err
  }
}


// ---- create user FB ---- //
export const createUserFB = (userInfo) => {
  const { email, password, user_type, sign_up_code, firstName, lastName } = userInfo

  let addUserRole

  return function (dispatch) {

    dispatch(createUserRequestFB(userInfo))

    firebase.auth().createUserWithEmailAndPassword(email, password)

      //--------- set display name ----------- //
      .then(firebaseResp => {
        const user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: firstName + " " + lastName,
          photoURL: "https://api.adorable.io/avatars/285/abott@adorable.png"
        });
        return firebaseResp;
      })

      // .catch((err) =>
      //   dispatch(createUserFailureFB(err.message)))
      // // console.log("WHERE TO CATCH THE CREATE USER ERROR?", err))



      //-------- add custom claim ------- //
      .then(firebaseResp => {

        if (user_type === "student") {
          addUserRole = functions.httpsCallable("addStudentRole");
        }
        else if (user_type === "teacher") {
          addUserRole = functions.httpsCallable("addTeacherRole");
        }
        else if (user_type === "admin") {
          addUserRole = functions.httpsCallable("addAdminRole");
        }
        else if (user_type === "super") {
          addUserRole = functions.httpsCallable("addSuperRole");
        }
        else {
          console.error("😱 No user_type added")
          throw Error
        }
        return firebaseResp;
      })

      .then((firebaseResp) => {
        addUserRole({ email })
        return firebaseResp
      })

      // ---------- MONGO ----------- //

      .then(firebaseResp => {
        const { user } = firebaseResp
        const { email, uid } = user

        let url, mongoData;

        if (user_type === "student") {
          url = "/students"
          mongoData = {
            google_uid: null,
            googleImageUrl: null,
            email,
            displayName: firstName + " " + lastName,
            firstName,
            lastName,
            fb_uid: uid,
            avatar: { avatarId: "newuser" }
          };

        } else if (user_type === "teacher") {
          url = "/teachers"

          mongoData = {
            firstName,
            lastName,
            displayName: firstName + " " + lastName,
            email,
            fb_uid: uid,

            google_uid: null,
            googleImageUrl: null,
            // coinsToIssue - done on server
            avatar: { avatarId: "newuser" }

          };

        } else if (user_type === "admin") {
          url = "/admins"

          mongoData = {
            firstName,
            lastName,
            displayName: firstName + " " + lastName,
            email,
            fb_uid: uid,
            google_uid: null,
            googleImageUrl: null,
            avatar: { avatarId: "newuser" },
            sign_up_code
          }

        } else if (user_type === "super") { // later
          url = "/supers"
          throw Error("Intentional Error - no super routes set up yet...")


        } else if (user_type === "parent") { // later
          url = "/parents"
          throw Error("Intentional Error - no super routes set up yet.")

        } else {
          throw Error("Internal Error creating new user")
          // redirect  to special help page?
        }

        http.post(url, mongoData)
          .then((response) => {

            const {
              data: {
                email,
                displayName,
                photoURL,
                fb_uid
              } } = response

            const data2 = {
              email,
              displayName,
              photoURL,
              userType: user_type,
              fb_uid
            }

            dispatch(createUserSuccessFB(data2))
          })
      })

      .catch((err) => {

        dispatch(createUserFailureFB(err.message))
        console.log("WHERE TO CATCH THE CREATE USER ERROR?", err)
      })
  }

}

export const createUserRequestFB = () => {
  return {
    type: types.CREATE_USER_REQUEST_FB
  }
}

export const createUserSuccessFB = userData => {
  return {
    type: types.CREATE_USER_SUCCESS_FB,
    payload: userData
  }
}

export const createUserFailureFB = (err) => {
  console.log("create user FAILURE called")
  return {
    type: types.CREATE_USER_FAILURE_FB,
    payload: err
  }
}

// erase?
export const updateUserFB = (user) => {
  return {
    type: types.UPDATE_USER_FB,
    payload: user // shape the data so they match
  }
}
// request, request, failure?

// ---- fetch user GOOGLE ------ //
export const fetchUserGL = (userInfo) => {

  return function (dispatch) {
    try {
      dispatch(fetchUserGLRequest())
      const { email, lastName, firstName, google_uid, googleImageUrl, displayName } = userInfo

      // VERSION 1) CREATE PASSWORD FROM GOOGLE ID (current)
      // VERSION 2) CREATE PASSWORD FOR FIREBASE FROM GOOGLE ID + SECRET KANSHA CODE = HASH

      const password = google_uid + process.env.REACT_APP_STATIC_SALT

      // --- regular FB login --- ///
      firebase.auth().signInWithEmailAndPassword(email, password)
        // --- get custom claims ---- ///
        .then(() => {
          const auth = firebase.auth();
          auth.onAuthStateChanged(user => {
            if (user) {
              const { email, displayName, photoURL, uid } = user

              user
                .getIdTokenResult()
                .then(idTokenResult => {
                  const { authCustomClaim } = idTokenResult.claims;
                  return authCustomClaim;
                })
                .then((authCustomClaim) => {
                  dispatch(fetchUserGLRequestSuccess({
                    email, displayName, photoURL, userType: authCustomClaim, fb_uid: uid, fullAuthObject: user
                  }))
                })
                .catch(err => console.log("Error in custom claim", err));
            } else {
              console.log("CLAIM ON SIGN-IN CHECKED: Logged out");
              return null;
            }
          });
        })

        .catch(err => {
          console.log("err==>", err)
          dispatch(fetchUserGLRequestFailure([err, userInfo]))
        })
    }

    catch (err) {
      console.log("CATCH ERROR - first level?", err)

    }
  }
}

export const fetchUserGLRequest = () => {
  return {
    type: types.FETCH_USER_REQUEST_GL
  }
}

export const fetchUserGLRequestSuccess = userData => {
  return {
    type: types.FETCH_USER_SUCCESS_GL,
    payload: userData
  }
}

export const fetchUserGLRequestFailure = (err) => {
  return {
    type: types.FETCH_USER_FAILURE_GL,
    payload: err
  }
}

// ---- create user GOOGLE ---- //
export const createUserGL = (googleUserInfo) => {
  // WHY IS google_uid undefined here? 
  const { email, lastName, firstName, google_uid, googleImageUrl, user_type, sign_up_code = "NO-CODE" } = googleUserInfo

  //const password = google_uid + "58365836"
  const password = google_uid + process.env.REACT_APP_STATIC_SALT
  console.log("CREATE USER password ====>", password)


  let addUserRole

  return function (dispatch) {

    dispatch(createUserRequestGL(googleUserInfo))
    firebase.auth().createUserWithEmailAndPassword(email, password)

      //--------- set display name ----------- //
      .then(firebaseResp => {
        const user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: firstName + " " + lastName,
          photoURL: googleImageUrl
        });
        return firebaseResp;
      })

      //-------- add custom claim ------- //
      .then(firebaseResp => {

        if (user_type === "student") {
          addUserRole = functions.httpsCallable("addStudentRole");
        }
        else if (user_type === "teacher") {
          addUserRole = functions.httpsCallable("addTeacherRole");
        }
        else if (user_type === "admin") {
          addUserRole = functions.httpsCallable("addAdminRole");
        }
        else if (user_type === "super") {
          addUserRole = functions.httpsCallable("addSuperRole");
        }
        else {
          console.error("😱 No user_type added")
          throw Error
        }
        return firebaseResp;
      })

      .then((firebaseResp) => {
        addUserRole({ email })
        return firebaseResp
      })

      //---------- MONGO ---------- //
      .then(firebaseResp => {
        const { user: { email, uid } } = firebaseResp
        // const { email, uid } = user

        let url, mongoData;

        if (user_type === "student") {
          url = "/students"
          mongoData = {
            google_uid,
            googleImageUrl,
            email,
            displayName: firstName + " " + lastName,
            lastName,
            firstName,
            fb_uid: uid,
            avatar: { avatarId: "newuser" }
          };

        } else if (user_type === "teacher") {
          url = "/teachers"

          mongoData = {
            firstName,
            lastName,
            displayName: firstName + " " + lastName,
            email,
            fb_uid: uid,
            google_uid,
            googleImageUrl,
            // coinsToIssue, // done on server
            avatar: {
              avatarId: "newuser"
            }

          };

        } else if (user_type === "admin") { // check if admin or administrator
          url = "/admins"

          mongoData = {
            firstName,
            lastName,
            displayName: firstName + " " + lastName,
            email,
            fb_uid: uid,
            google_uid,
            googleImageUrl,
            avatar: {
              avatarId: "newuser"
            },
            sign_up_code
          }

        } else if (user_type === "super") { // later
          url = "/supers"
          throw Error("Intentional Error - no super routes set up yet...")


        } else if (user_type === "parent") { // later
          url = "/parents"
          throw Error("Intentional Error - no super routes set up yet.")

        } else {
          throw Error("Internal Error creating new user")
          // redirect  to special help page?
        }

        http.post(url, mongoData)
          .then((response) => {
            const {
              data: {
                email,
                displayName,
                photoURL,
                fb_uid
              } } = response

            const data2 = {
              email,
              displayName,
              photoURL,
              userType: user_type,
              fb_uid
            }

            dispatch(createUserSuccessGL(data2))
          })

          .catch(err => {
            console.log("OOPSY--INNER", err.message)
            dispatch(createUserFailureFB(err.message))
          })
      })

  }
}


export const createUserRequestGL = () => {
  return {
    type: types.CREATE_USER_REQUEST_GL
  }
}

export const createUserSuccessGL = userData => {
  return {
    type: types.CREATE_USER_SUCCESS_GL,
    payload: userData
  }
}

export const createUserFailureGL = (err) => {
  console.log("create user FAILURE called")
  return {
    type: types.CREATE_USER_FAILURE_GL,
    payload: err
  }
}


export const logoutUserFB = () => {
  return {
    type: types.LOGOUT_USER_FB
  }
}

export const clearUserFB = () => {
  return {
    type: types.CLEAR_USER_FB
  }
}

export const clearUserGL = () => {
  return {
    type: types.CLEAR_USER_GL
  }
}
