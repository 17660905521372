import React, { useState, useEffect } from 'react'
import { Button, Table } from 'semantic-ui-react'
import RenderSVG from '../common/RenderSVG'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash';
import CurrentGroupAssignment from "./CurrentGroupAssignment"

export default function CurrentGroupTable({ groupData, currentClassroomName, studentIds, studentId, topic }) {

  const history = useHistory()
  const [studentLeader, setStudentLeader] = useState(false)

  const [studentsInCurrentGroup, setStudentsInCurrentGroup] = useState([])
  const [leadersInCurrentGroup, setLeadersInCurrentGroup] = useState([])

  const [teacherPermissionToEdit, setTeacherPermissionToEdit] = useState(true)

  const { displayNameGraphics, colorOnBoard, teacherNotes } = groupData
  const { english } = displayNameGraphics || {}


  useEffect(() => {
    console.log("groupData======>>>>>", groupData)

    if (!isEmpty(groupData)) {
      let studentsInCurrentGroup_LOCAL = [...groupData?.studentIds]
      setStudentsInCurrentGroup(studentsInCurrentGroup_LOCAL)

      let leadersInCurrentGroup_LOCAL = [...groupData?.leaderIds]
      setLeadersInCurrentGroup(leadersInCurrentGroup_LOCAL)

      console.log("leadersInCurrentGroup===>", leadersInCurrentGroup)
      if (leadersInCurrentGroup && leadersInCurrentGroup?.includes(studentId)) {
        setStudentLeader(true)
      }

    }
    //}
  }, [groupData])

  if (isEmpty(groupData)) {
    return <h2>No group assigned.</h2>
  }







  const currentGroupNames = studentIds?.filter(student => {
    return studentsInCurrentGroup?.includes(student._id)
  })



  // if (groupData === "" || groupData.length === 0) return <h2>No group assigned.</h2>


  // Add groupleader flag
  currentGroupNames.forEach(student => {
    student.groupRole = "tbd"
    student.groupLeader = false
    if (leadersInCurrentGroup.includes(student._id)) {
      student.groupLeader = true
    }
  })

  const currentGroupDisplay = currentGroupNames.map(student => {
    return <Table.Row>

      <Table.Cell
        key={student._id}
        style={{ fontSize: "1.5em", color: "blue", fontWeight: "600" }}>

        {!studentLeader && student.groupLeader ? "⭐️" : null}

        {teacherPermissionToEdit && studentLeader && student.groupLeader ? <Button inverted onClick={() => history.push("/classroomdisplaygroupleader", { studentLeader, currentGroupNames })} size="tiny" color="yellow">⭐️ Leader </Button> : null}

        {/* {teacherPermissionToEdit && studentLeader ? <Button onClick={() => history.push("/classroomdisplaygroupleader", { studentLeader, currentGroupNames })} size="small" color="yellow">Leader</Button> : null} */}

      </Table.Cell>

      <Table.Cell> {student.displayName}</Table.Cell>
      <Table.Cell> </Table.Cell>
    </Table.Row>
  })

  const styles2 = {
    margin: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const leaderEditGroup = () => {
    if (studentLeader && teacherPermissionToEdit) {
    }
  }

  return (
    <>
      <h6 style={{ fontSize: '1.5em' }}> {currentClassroomName} {topic}</h6>
      <h1 style={{ margin: '5px', color: "black" }}>{english}</h1>
      <div style={styles2}>
        <div
          style={{
            backgroundColor: colorOnBoard,
            width: "35%"
          }}
          onClick={leaderEditGroup} >
          <div style={{ padding: "20px" }}>
            <RenderSVG
              size={"200px"}
              svgFilename={displayNameGraphics?.svg} />
          </div>
        </div>

      </div>

      <Table celled padded>
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell width={1}>Leader</Table.HeaderCell>
            <Table.HeaderCell singleLine width={5}>Name</Table.HeaderCell>
            <Table.HeaderCell width={3}>Group Job</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {currentGroupDisplay}
        </Table.Body>
      </Table>
      <br />
      {teacherNotes && <CurrentGroupAssignment teacherNotes={teacherNotes} />}


    </>)

}

