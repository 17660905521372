import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'


export default function GroupLeader(props) {

  // const [studentLeader, setStudentLeader] = useState(false)
  // const [teacherPermissionToEdit, setTeacherPermissionToEdit] = useState(true)
  const history = useHistory()

  const { state } = props.history.location
  const studentLeader = state?.studentLeader
  const currentGroupNames = state?.currentGroupNames


  // console.log("studentLeader", studentLeader)
  // console.log("currentGroupNames", currentGroupNames)

  if (!studentLeader) {
    history.push("/classroomsdisplaystudent")
  }

  //classroomsdisplaystudent
  // redirect students who are here who are not currently the leader
  return (<>
    <h1>Group Leader</h1>
    <h6>Coming Soon...</h6>

    {/* 
    <Button>Give Leader Role to ________</Button> */}
  </>)

}