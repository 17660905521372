import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const TeacherRoute = ({ component: RouteComponent, ...rest }) => {
  const user = useSelector(state => state.auth.user)
  const teacherData = useSelector(state => state.teacher) // 


  const tempSchoolObject = {
    schoolName: "Tyrrell Elementary",
    mascotName: "Dragons",
    schoolDisplayName: "Tyrrell Dragons",
    schoolCurrency: "Dragon Coin",
    schoolCurrencyPlural: "Dragon Coin",
    jobTitle: "5/6 Grade Teacher"
    // later add grade level
  }

  // REMOVE SOON AND USE ONLY FOR _ID
  const teacherData_TEMP = { ...teacherData, ...{ school: tempSchoolObject } }

  const { userType } = user || {}

  return (
    <Route {...rest}
      render={routeProps =>
        !user || userType !== "teacher"
          ? (<Redirect to={"/login"} />)
          : (<RouteComponent {...routeProps} {...rest} teacherDataFromProps={teacherData_TEMP} />)
      }
    />
  )
}

export default TeacherRoute