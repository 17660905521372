import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux"
import { Button, Icon } from "semantic-ui-react"
import StudentTiles from './CurrentStudentsTiles2'
import { increaseStudentCoinsByTeacher, updateTeacherNavbar } from "../../redux/teacher/teacherActions"
import { SimpleSpinner } from "../common/Spinners"
import { getClassroom, removeStudentFromClassroom, updateClassroom } from "../../redux/classroom/classroomActions"
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import RenderAvatar from '../common/RenderAvatar';
import { superHeros } from "../../img/avatars/superHeros"
import googleLogo from "../../img2/png/google-logo.png"
import notify from '../../utils/notify'
import RenderSVG from '../common/RenderSVG';

const StudentMainTiles = (props) => {

  const { studentAccessoryDataClassroom, setShowNavBar } = props

  const [deleteSelectionForModal, setDeleteSelectionForModal] = useState(null)
  const [showStudentDetails, setShowStudentDetails] = useState(false)
  const [studentInfoDisplayed, setStudentInfoDisplayed] = useState() // WHY EMPTY?
  const [studentAccessoryData_LOCAL, setStudentAccessoryData_LOCAL] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const dispatch = useDispatch()

  const { teacherData: { _id: teacherId, classroomId_current, avatar: { avatarId }, schoolId } } = useSelector(state => state.teacher)

  const { coinName } = schoolId || {}

  const { loading: classroomLoading, name: classroomName, classroomData: { studentIds, _id: classroomId }, image } = useSelector(state => state.classroom)

  const classroomImage = image?.svg

  // WHY getting classroom on every mount?
  useEffect(() => {
    setShowNavBar(true)
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
    setStudentInfoDisplayed(studentIds)
    setStudentAccessoryData_LOCAL(studentAccessoryDataClassroom)
  }, [])



  //  ------- ISSUE 2) --> if studentAccessoryData does changes, update DB with local SAD

  // ** IF not NULL
  useEffect(() => {
    if (studentAccessoryData_LOCAL) {
      const updatedClassroomData = {
        studentAccessoryDataClassroom: studentAccessoryData_LOCAL
      }
      dispatch(updateClassroom(classroomId, updatedClassroomData))
    }
  }, [studentAccessoryData_LOCAL])



  const quickPoint = (student) => {
    const studentInfoDisplayedLocal = [...studentInfoDisplayed]
    const indexOfUpdate = _.findIndex(studentInfoDisplayed, { '_id': student._id })
    const recordToUpdate = _.find(studentInfoDisplayed, { '_id': student._id });

    recordToUpdate.coins = recordToUpdate.coins + 1
    studentInfoDisplayedLocal[indexOfUpdate] = recordToUpdate

    // 1) update local state
    setStudentInfoDisplayed(studentInfoDisplayedLocal)
    // 2) update student record 
    dispatch(increaseStudentCoinsByTeacher(student._id, 1, teacherId))
    // 3) toast
    notify({ message: `${coinName} for ${student.displayName} `, noticeType: "info", autoClose: 2500 })

  }

  const quickDock = (student) => {
    const studentInfoDisplayedLocal = [...studentInfoDisplayed]
    const indexOfUpdate = _.findIndex(studentInfoDisplayed, { '_id': student._id })
    const recordToUpdate = _.find(studentInfoDisplayed, { '_id': student._id });

    if (recordToUpdate.coins < 1) {
      alert("No more coins")
    } else {

      recordToUpdate.coins = recordToUpdate.coins - 1
      studentInfoDisplayedLocal[indexOfUpdate] = recordToUpdate

      // 1) update local state
      setStudentInfoDisplayed(studentInfoDisplayedLocal)
      // 2) update student record 
      dispatch(increaseStudentCoinsByTeacher(student._id, -1, teacherId))
    }
  }


  const toggleShowDetails = () => {
    setShowStudentDetails(!showStudentDetails)
  }

  const removeStudentFromClass = (firstName, lastName, _id) => {
    const studentToRemove = { firstName, lastName, _id }
    setDeleteSelectionForModal(studentToRemove)
  }

  const removeStudentConfirmed = () => {
console.log("🧨🧨🧨 Issues! Deleting from Tiles not supported. Remove or debug!")
   dispatch(removeStudentFromClassroom(deleteSelectionForModal, classroomId_current._id))
  

  }


  const [showStudentProfile, hideStudentProfile] = useModal(() => {
    const { displayName, email, coins, avatar: { avatarId }, google_uid, googleImageUrl } = selectedStudent
    return (
      <ReactModal isOpen ariaHideApp={false}>
        <h1>{displayName}</h1>
        <RenderAvatar
          size={80}
          avatarId={avatarId}
          setOfAvatars={superHeros} />

        <h3> {google_uid ? <img src={googleLogo} alt="GL" width="30" height="30" /> : null} {email}</h3>

        {googleImageUrl ? <img alt="GL" rc={googleImageUrl}></img> : null}

        <h3>Kansha Tokens: {coins}</h3>
        <h4>Classes and Groups coming</h4>
        <div>
          <br />
          <Button color="green" size="large" onClick={hideStudentProfile}>Exit</Button>
        </div>
      </ReactModal>
    )
  },
    [selectedStudent]
  );

  if (classroomLoading) {
    return (<SimpleSpinner />)
  }

  if (!studentIds || !studentInfoDisplayed) {
    return <h1>No classes with students.</h1>
  }

  return (
    <>
      <DeleteModal
        header={"PERMANENTLY REMOVE A STUDENT"}
        modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to remove " + (deleteSelectionForModal ? deleteSelectionForModal.firstName : null) + " from your classroom?"}</h3>}
        icon={<Icon name='warning circle' />}
        size='small'
        showModal={!!deleteSelectionForModal} // used as boolean
        setShowModal={setDeleteSelectionForModal}
        handleConfirm={removeStudentConfirmed}
        handleDecline={(info) => console.log("Nothing to do here, yet", info)} />


      {classroomImage && <div style={{margin:"12px 0 0 0"}}>
        <RenderSVG
          size="150"
          svgFilename={classroomImage} 
          />
      </div>
       }

      <h1 style={{ fontWeight: "600", color: "grey", fontSize: "4em" }}>{classroomName}</h1>


      {studentIds?.length < 1
        ? (<h3>No students are currently enrolled in this class</h3>)
        : <StudentTiles
          coinName={coinName}
          quickPoint={quickPoint}
          quickDock={quickDock}
          data={studentInfoDisplayed}
          showStudentDetails={showStudentDetails}
          teacherId={teacherId}
        />
      }

      <div style={{cursor:"pointer"}}>
        <br />
        {showStudentDetails
          ? 
          <div onClick={toggleShowDetails}>
            <Icon color="grey" size="large" name='hide'  />
            <h6>Hide Amounts</h6>
            </div>
        
          : <div onClick={toggleShowDetails} >
          <Icon color="grey" size="large" name='unhide' /><h6>Show Amounts</h6>
          </div>}
        <br />
        <br />
        <br />
      </div>
    </>)
};


export default StudentMainTiles;