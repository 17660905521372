import React, { useState } from 'react';
import { Button } from "semantic-ui-react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import DisplayBoard from './PhysicalGoodsDisplayBoardStudent'
import notify from '../../utils/notify'
import Lottie from 'react-lottie'
import school from '../../img2/lottie/ui/64386-university-school-icon.json'
import { clearStudentRouting } from "../../redux/student/studentActions"

const SchoolStoreStudent = () => {
  const dispatch = useDispatch()

  const {
    error,
    loading,
    _id: classroomId,
    schoolId,
    // schoolwidePhysicalGoods,
    // physicalGoodMostRecentClassroom
  } = useSelector(state => state.classroom)

  const {
    studentData: { _id: studentId, coins: studentBalance }
  } = useSelector(state => state.student)

  const { name: schoolName, _id: currentSchoolId } = schoolId || {}

  const [showOnlyCurrentlyAvailable, setShowOnlyCurrentlyAvailable] = useState(false)


  const history = useHistory()

  const viewItem = (pg) => {
    if (pg.quantityRemaining > 0) {
      history.push("/physicalgoodbuy", pg)
    } else {
      notify({ message: `${pg.name}s are sold out.`, noticeType: "error", autoClose: 3500 })
    }
  }
  if (!schoolId) {
    return (<>
      <h2>You need to be a part of a class in order to use the School Store.</h2>
      <Button
        onClick={() => {
          dispatch(clearStudentRouting())
          return history.push("/classroomjoin")
        }
        }
        size="large"
        color="blue">Join a class
      </Button>
    </>)
  }

  return (
    <>
      <h1 style={{ margin: "10px 5px 0px 5px" }}> {schoolName} Schoolwide Store</h1>
      <Lottie
        width={200}
        height={200}
        options={{
          loop: false,
          autoplay: true,
          animationData: school,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
      />
      <h3>Your current balance: {studentBalance}</h3>
      <br />
      <DisplayBoard
        viewItem={viewItem}
        schoolId={currentSchoolId}
        studentId={studentId}
        showOnlyCurrentlyAvailable={showOnlyCurrentlyAvailable}
      />
      <br />
      <br />

      <Button
        color="blue"
        onClick={() => setShowOnlyCurrentlyAvailable(prev => !prev)}>
        {showOnlyCurrentlyAvailable ? "Show everything" : "Show only what is available"}
      </Button>
      <br />
      <br />
      <br />

    </>
  )
};
export default SchoolStoreStudent;
