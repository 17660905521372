import React from 'react';
import { Card, Icon } from 'semantic-ui-react'
import Gif from '../common/gif'

const DisplayBoard = (props) => {

  const { editItem, currentPrizesDisplay, handleDeletePrize, raffleItOff } = props
  const prizesDisplayBoard = currentPrizesDisplay?.map(cg => {

    if (cg.archived) return null

    return (
      <Card key={cg._id} color="black">
        <div
          onClick={() => raffleItOff(cg)}
          style={{ cursor: 'pointer' }}>
          <h1 style={{ fontSize: "2em", margin: "1px" }}>{cg.title}</h1>
          {cg?.image?.gif
            ? <Gif id={cg.image.gif} />
            : null}
          <h2 style={{ fontSize: "1.5em", margin: "1px" }}>{cg.description}</h2>

        </div >


        {cg?.quantityRemaining < 1
          ?
          <div onClick={() => editItem(cg)} style={{ cursor: 'pointer' }} >
            <h3 style={{ margin: "1px 1px 1px 1px", color: "#FFD700" }}>
              All have been awarded</h3>
          </div >
          : <>
            <div onClick={() => editItem(cg)} style={{ cursor: 'pointer', margin: "1px 1px 10px 1px" }}>
              <h4 style={{ margin: "1px 1px 1px 1px" }}>Amount to raffle:{cg.quantityRemaining}
              </h4>
              <Icon onClick={() => editItem(cg)} name='edit' inverted size='large' color='black' />
            </div>
          </>
        }

      </Card >
    )
  })

  return (<>
    <Card.Group centered itemsPerRow={4}>
      {prizesDisplayBoard}
    </Card.Group>
  </>
  );
}
export default DisplayBoard;

