import React from 'react'

export default function CurrentGroupAssignment(props) {
  const { teacherNotes } = props

  const parseTeacherNotes = teacherNotes.split("\n")



  const teacherNotesDisplay = parseTeacherNotes.map(item => {
    if (item !== "") {
      return <h6 >{item}</h6>
    }
    return
  })

  return (<>
    <h1>Assignment:</h1>
    {teacherNotesDisplay}
  </>
  )
}