import React, { useState, useRef, useEffect } from 'react';
import firebase from "../../config/fbConfig"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import { createUserFB, clearUserFB, clearUserGL } from '../../redux/auth/authActions';
import { getStudentProfile } from '../../redux/student/studentActions'
import { getTeacherProfile } from '../../redux/teacher/teacherActions'
import { getAdminProfile } from '../../redux/admin/adminActions'

import { SimpleSpinner } from "../common/Spinners"
import gratitudeBlue from "../../img/gratitude_blue.png"
import newUserLogo from '../../img2/lottie/ui/newUser.json'

// 8 to 10 digits
const tempAdminCodes = ["husd2021", "sfusd2021", "oak2021", "mpls2021"]

export default function SignUp() {
  const [userType, setUserType] = useState("initial")
  const { register, errors, handleSubmit, watch, reset } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");


  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, user, error: errorMessage } = useSelector(state => state.auth)

  const { loading: studentLoading, onCompleteRoute: onCompleteStudentRoute } = useSelector(state => state.student)

  const { loading: teacherLoading, onCompleteRoute: onCompleteTeacherRoute } = useSelector(state => state.teacher)

  const { loading: adminLoading, onCompleteRoute: onCompleteAdminRoute } = useSelector(state => state.admin)

  const { fb_uid } = user || {}

  const style = { "color": "mediumBlue", "fontSize": "50px", "fontWeight": "bold" }

  // -------- Instead of modal  --------- //
  const [askForCode, setAskForCode] = useState(false)
  const [dataForAdminSignup, setDataForAdminSignup] = useState(null)
  const [accessCodeInput, setAccessCodeInput] = useState({})
  const handleInputChange = (e) => setAccessCodeInput({
    ...accessCodeInput,
    [e.currentTarget.name]: e.currentTarget.value
  })
  // ------- //

  const onSubmit = data => {
    // clear in case of repeat tries
    // dispatch(clearUserFB())

    let first = data.firstName[0].toUpperCase() + data.firstName.substring(1)
    let last = data.lastName[0].toUpperCase() + data.lastName.substring(1)
    data.firstName = first
    data.lastName = last
    data.user_type = userType

    if (userType === "admin") {
      // check temporary code
      setAskForCode(true)
      setDataForAdminSignup(data)

    } else {
      // teacher or student
      console.log("data===>>>", data)
      dispatch(createUserFB(data))
    }
  };

  const onSubmitAdmin = (e) => {
    if (tempAdminCodes.includes(accessCodeInput.specialcode)) {
      dispatch(createUserFB(dataForAdminSignup))
    } else {
      console.log("Please try again")
      // setAccessCodeInput({}) or null
    }
  }

  const goToLogin = () => {
    dispatch(clearUserFB())
    dispatch(clearUserGL())
    localStorage.clear();
    firebase.auth().signOut().then(() => history.push('/login'))
  }

  const createStudentAccount = () => { setUserType('student') }
  const createTeacherAccount = () => { setUserType('teacher') }
  const createAdminAccount = () => { setUserType('admin') }

  useEffect(() => {
    dispatch(clearUserFB())
  }, [dispatch])

  useEffect(() => {
    if (fb_uid && user && user.userType === "student") {
      console.log("🩸🩸🩸 --- NEW STUDENT ---- 🩸🩸🩸")
      dispatch(getStudentProfile(user.fb_uid))
    }
    if (fb_uid && user && user.userType === "teacher") {
      console.log("🥦🥦🥦 --- NEW  TEACHER ---- 🥦🥦🥦")
      dispatch(getTeacherProfile(user.fb_uid))
    }
    if (fb_uid && user && user.userType === "admin") {
      console.log("🍋🍋🍋 --- NEW ADMIN ---- 🍋🍋🍋 ")
      dispatch(getAdminProfile(user.fb_uid))
    }
  }, [fb_uid, history, user, dispatch])


  useEffect(() => {
    if (fb_uid && user && user.userType === "student" && !studentLoading && onCompleteStudentRoute) {
      console.log("🩸🩸🌕 --- PUSH TO STUDENT DASH ---- 🌕🩸🩸")
      history.push("/studentdashboard")
    }
    if (fb_uid && user && user.userType === "teacher" && !teacherLoading && onCompleteTeacherRoute) {
      console.log("🥦🥦🌕 --- PUSH TO TEACHER DASH ---- 🌕🥦🥦")
      history.push("/teacherdashboard")
    }
    if (fb_uid && user && user.userType === "admin" && !adminLoading && onCompleteAdminRoute) {
      console.log("🍋🍋🌕 --- PUSH TO ADMIN DASH ---- 🌕🍋🍋")
      history.push("/admindashboard")
    }
  })

  const userTypeDisplay = userType[0].toUpperCase() + userType.substring(1)

  if (loading) { return <SimpleSpinner /> }
  if (errorMessage) { return <h2>{errorMessage}</h2> }

  if (userType === 'initial') {
    return (<>
      <h1>Which type of account?</h1>
      <Lottie
        width={250}
        height={250}
        options={{
          loop: false,
          autoplay: true,
          animationData: newUserLogo,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }} />

      <br />
      <div>
        <Icon size="huge" color="blue" name="star" />
        <Button size="huge" color="blue" onClick={createStudentAccount}> Student</Button>
      </div>
      <br />
      <br />
      <div>
        <Icon size="huge" color="green" name="star" />
        <Button size="huge" color="green" onClick={createTeacherAccount}> Teacher</Button>
      </div>
      <br />
      <br />
      <div>
        <Icon size="huge" color="purple" name="star" />
        <Button size="huge" color="purple" onClick={createAdminAccount}> Admin</Button>
      </div>
    </>
    )
  }

  return (<>{askForCode && !errorMessage
    ? (<h2 style={style}>Please enter your special access code
      <form>
        <div>
          <label>Enter 5-10 character code:</label>
          <input
            type="text"
            name="specialcode"
            onChange={handleInputChange}
          />
        </div>
        <Button
          onClick={handleSubmit(onSubmitAdmin)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Submit</Button>
      </form></h2>)
    :
    (<>
      <h1 style={style}>Kansha {userTypeDisplay} Signup</h1>
      <div className="container-div">
        <img src={gratitudeBlue} alt={""} width="200px" height="200px" />
      </div>

      <form onSubmit={e => e.preventDefault()}>
        <label>First Name</label>
        <input
          name="firstName"
          type="text"
          ref={register({
            required: "Please include a first name",
            minLength: {
              value: 1,
              message: "Password must have at least 1 character"
            }
          })}
        />
        {errors.firstName && <p>{errors.firstName.message}</p>}

        <label>Last Name</label>
        <input
          name="lastName"
          type="text"
          ref={register({
            required: "Please include a last name",
            minLength: {
              value: 1,
              message: "Password must have at least 1 character"
            }
          })}
        />
        {errors.lastName && <p>{errors.lastName.message}</p>}

        <label>Email</label>
        <input
          name="email"
          type="text"
          ref={register({
            required: "You must specify a email",
            minLength: {
              value: 4,
              message: "Email must have at least 4 characters"
            }
          })}
        />
        {errors.email && <p>{errors.email.message}</p>}

        <label>Password</label>
        <input
          name="password"
          type="password"
          ref={register({
            required: "You must specify a password",
            minLength: {
              value: 6,
              message: "Password must have at least 6 characters"
            }
          })}
        />
        {errors.password && <p>{errors.password.message}</p>}

        <label>Repeat password</label>
        <input
          name="password_repeat"
          type="password"
          ref={register({
            validate: value =>
              value === password.current || "The passwords do not match"
          })}
        />
        {errors.password_repeat && <p>{errors.password_repeat.message}</p>}

        {userType === 'admin' && (
          <div>
            <label>Please select one of the following:</label>
            <select ref={register} name="sign_up_code" >
              <option value="new-school-account">I am starting a new account for my school.</option>
              <option value="connect-to-school">My school is already set-up and I would like to join it.</option>
              <option value="unsure">Unsure.</option>
            </select>
          </div>)}

        <br />

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Register {userTypeDisplay}</Button>

        <h2>{"   "}</h2>

        <Button
          onClick={reset}
          size="tiny"
          color='grey'>Reset Form</Button>
        <div>
          <h2>{"   "}</h2>
          <Button
            onClick={goToLogin}
            size="small"
            color='black'>Go to Login</Button>
        </div>

      </form>
      <h2>{"   "}</h2>
    </>)}
  </>)
}
