// import RenderAvatar from '../common/RenderAvatar';
// import RenderSVG from '../common/RenderSVG';
// import { superHeros } from "../../img/avatars/superHeros"
import moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs


const pdfOfList = ({title, titleFontSize, pdfDisplayColumnGap, columnDisplayTitles, columnFontSize,  pdfColumnData, numberOfRecords="-"}) => {

const buildColumnDisplay = []
console.log("pdfDisplayColumnGap===>", pdfDisplayColumnGap)

columnDisplayTitles.forEach(((columnTitle, i) => {
  const columnString = pdfColumnData[i].join("\r\n")
  buildColumnDisplay.push({
    width: 'auto',
    text: columnTitle +  "\n" + columnString, 
    fontSize: columnFontSize 
  },)
  return 
}))

  const listDocDefinition = {
    content: [
      { text: title, fontSize: titleFontSize, bold: true },
      { text: "  ", fontSize: columnFontSize},
      { columns: buildColumnDisplay, columnGap: pdfDisplayColumnGap},
      { text: "  ", fontSize: columnFontSize },
      { text: "Total " + title + ": " + numberOfRecords, fontSize: columnFontSize },
      { text: "  ", fontSize: columnFontSize },
      { text: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"), fontSize: columnFontSize }
  ]
}

pdfMake.createPdf(listDocDefinition).download(); 

}


export default pdfOfList