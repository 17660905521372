import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Container, Button } from "semantic-ui-react"
import spinner from "../../img/spinning-donut.gif";
import spinnerFrozen from "../../img/spinner-frozen.png";

import { getStudentProfile, getClassroom } from '../../redux/student/studentActions'

const tempStyle1 = { "color": "blue", "fontSize": "4em", "fontWeight": "bold" }
//const tempStyle2 = { "color": "green", "fontSize": "2em", "fontWeight": "bold" }



const SpinnerWheelMain = (props) => {
  const [spinnerMoving, setSpinnerMoving] = useState(false)
  const { studentData: { classroomId_current, classroomIds, _id: studentId }, loading } = useSelector(state => state.student)
  const { user: { fb_uid } } = useSelector(state => state.auth)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStudentProfile(fb_uid))
    // how to pass studentProfile as prop
    // getClassroom(classroomId_current) - from studentProfile
  }, [fb_uid])




  const initateSpin = () => {
    setSpinnerMoving(true)
  }
  const stopSpin = () => {
    setSpinnerMoving(false)
  }

  const SpinnerDisplay = (props) => {
    const { isSpinning } = props
    return <>
      {isSpinning
        ? <img src={spinner} width="720" height="540" />
        :
        <img src={spinnerFrozen} width="565" height="515" />}
    </>
  }

  const Buttons = spinnerMoving
    ? <Button onClick={stopSpin} size="massive">Click to Stop!</Button>
    : <Button onClick={initateSpin} size="massive">Give me a Spin</Button>


  return (
    <Container>
      <h1 style={tempStyle1}>Spinner!</h1>
      <SpinnerDisplay isSpinning={spinnerMoving} />
      {Buttons}
    </Container>
  );
}

export default SpinnerWheelMain;

