import React from 'react';
import { useHistory } from "react-router-dom"
import { Button } from 'semantic-ui-react'

const PasswordResetConfirm = (props) => {

  const history = useHistory()
  const { state: { email } } = props.history.location

  return (<>
    <h2>Success! A link has been sent to: </h2>
    <h1>{email}</h1>
    <h2>If you don't find the email, please check your archive or trash bin as often times they can be filtered out.</h2>
    <Button onClick={() => history.push('/login')}>Login</Button>
  </>
  );
}

export default PasswordResetConfirm;

