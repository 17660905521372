import React, { useState } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { updateTeacherNavbar } from "../../redux/teacher/teacherActions"
import { useDispatch, useSelector } from "react-redux"

const NavTeacherStudents = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    navbar: { students: studentsNavbarState } } = useSelector(state => state.teacher)


  const [activeItem, setActiveItem] = useState(studentsNavbarState || 'studentstileteacher');

  const tileView = () => {
    setActiveItem('studentstileteacher')
    dispatch(updateTeacherNavbar({ students: "studentstileteacher" }))
    history.push("/studentstileteacher")
  }

  const listView = () => {
    setActiveItem('studentslistteacher')
    dispatch(updateTeacherNavbar({ students: "studentslistteacher" }))
    history.push("/studentslistteacher")
  }

  const extraItems = []

  return (
    <Menu color='red' compact widths={2 + extraItems.length} > 
      <Menu.Item
        className="menu-button"
        name='studentstileteacher'
        active={activeItem === 'studentstileteacher'}
        onClick={tileView} >
        <Icon name='user circle' size='large'/>
        <span> Award</span>
      </Menu.Item>
      <Menu.Item
        className="menu-button"
        name='studentslistteacher'
        active={activeItem === 'studentslistteacher'}
        onClick={listView}>
       <Icon name='list' size='large'/>
       <span>Edit</span>
       </Menu.Item>
    </Menu>

 
  );
}

export default NavTeacherStudents;

