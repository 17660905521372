import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { getTeacherProfile, checkSchoolCode, joinSchoolWithCode } from '../../redux/teacher/teacherActions'


export default function JoinSchool() {
  const { register, errors, handleSubmit } = useForm({});
  // const password = useRef({});
  // password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()


  const { loading, reduxResponseData, teacherData, teacherData: { fb_uid }, error } = useSelector(state => state.teacher)

  const [newSchoolToJoin, setSchoolToJoin] = useState(null)

  useEffect(() => {
    console.log("reduxResponseData", reduxResponseData)
    setSchoolToJoin(() => reduxResponseData?.name)
  }, [reduxResponseData])

  const onSubmitForm = ({ schooljoincode }) => {
    dispatch(checkSchoolCode(teacherData._id, schooljoincode))

  };

  const addSchoolConfirmed = () => {
    dispatch(joinSchoolWithCode(teacherData._id, reduxResponseData))
    history.push('/teacherdashboard/')

  }

  const addSchoolRejected = () => {
    dispatch(getTeacherProfile(fb_uid))
  }

  const pauseForError = () => {
    history.push("/teacherdashboard")
  }


  if (loading) {
    return <h1>Loading</h1>
  }

  if (error) {
    return (
      <>
        <h2>Oops. We have a problem. </h2>
        <h3>The code '{error}' does not seem to be valid.</h3>
        <Button onClick={addSchoolRejected}>Got it</Button>
      </>
    )
  }

  if (newSchoolToJoin) {
    return (<>
      <h2>You are about to join {newSchoolToJoin}</h2>
      <Button onClick={addSchoolConfirmed}>Yes! Join this school</Button>
      <Button onClick={addSchoolRejected}>Oops. I'll try again.</Button>
    </>)
  }


  return (
    <>
      <h1 >Join a school</h1>
      <form onSubmit={e => e.preventDefault()}>
        <label>Enter the school code</label>
        <input
          name="schooljoincode"
          type="text"
          ref={register({
            required: "You must enter a valid code",
            minLength: {
              value: 6,
              message: "The school code must have at least 6 characters"
            }
          })}
        />
        {errors.schooljoincode && <p>{errors.schooljoincode.message}</p>}
        <Button
          onClick={handleSubmit(onSubmitForm)}
          size="huge"
          type="submit"
          inverted color='blue'
          onSubmit={event => {
            event.preventDefault();
            console.log("🟣event", event)
          }}
        >Submit Code
        </Button>
        <br />
        <br />
        <Button
          onClick={() => history.push('/teacherdashboard/')}
          size="tiny"
          type="submit"
        >Dashboard
        </Button>
      </form>

    </>
  );
}




