import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import firebase from "../../config/fbConfig"
import { useDispatch, useSelector } from "react-redux"
import { updateTeacherNavbar } from "../../redux/teacher/teacherActions"

import { clearUserGL } from '../../redux/auth/authActions';
import { clearSuper } from '../../redux/super/superActions'
import { clearStudent } from '../../redux/student/studentActions'
import { clearTeacher } from '../../redux/teacher/teacherActions'
import { clearClassroom } from '../../redux/classroom/classroomActions'
import { clearAdmin } from '../../redux/admin/adminActions'

import useGoogleLogout from '../hooks/useGoogleLogout'
import UserContainer from '../common/UserContainer'
import SecondaryNavTokens from "./NavBar_teacher_2_tokens.jsx"
import SecondaryNavOriginal from "./NavBar_teacher_2_dashboard.jsx"
import SecondaryNavStudents from "./NavBar_teacher_2_students.jsx"
import SecondaryNavGroups from "./NavBar_teacher_2_groups.jsx"
import SecondaryNavPlay from "./NavBar_teacher_2_play.jsx"


const NavTeacherMain = (props) => {
  const { currentCoin, setCurrentCoin } = props
  const { navbar: { main: mainNavbar, dashboard: dashboardSecondNav, students: studentsSecondNav, groups: groupsSecondNav, tokens: tokensSecondNav, play: playSecondNav } } = useSelector(state => state.teacher)
  const [activeItem, setActiveItem] = useState(mainNavbar || 'home');
  const dispatch = useDispatch()
  const history = useHistory()
  const googleLogout = useGoogleLogout()
  const { googlePhotoURL } = props

  const dashboardView = () => {
    setActiveItem('home')
    dispatch(updateTeacherNavbar({ main: "home" }))
    history.push(dashboardSecondNav ? "/" + tokensSecondNav : "/teacherdashboard")
  }

  const studentsView = () => {
    setActiveItem('students')
    dispatch(updateTeacherNavbar({ main: "students" }))
    history.push(studentsSecondNav ? "/" + studentsSecondNav : "/studentstileteacher")
  }

  const groupsView = () => {
    setActiveItem('groups')
    dispatch(updateTeacherNavbar({ main: "groups" }))
    history.push(groupsSecondNav ? "/" + groupsSecondNav : "/groupslist")
  }

  const tokensView = () => {
    setActiveItem('tokens')
    dispatch(updateTeacherNavbar({ main: "tokens" }))
    history.push(tokensSecondNav ? "/" + tokensSecondNav : "/tokens")
  }

  const playView = () => {
    setActiveItem('play')
    dispatch(updateTeacherNavbar({ main: "play" }))
    history.push(playSecondNav ? "/" + playSecondNav : "/play")
  }

  // const signOut = () => {
  //   // all are needed?
  //   dispatch(clearSuper())
  //   dispatch(clearStudent())
  //   dispatch(clearTeacher())
  //   dispatch(clearClassroom())
  //   dispatch(clearAdmin())
  //   dispatch(clearUserGL())
  //   localStorage.clear();
  //   firebase.auth().signOut().then(() => googleLogout())
  // }

  let secondaryNavBar = null

  if (activeItem === "tokens") {
    secondaryNavBar = <SecondaryNavTokens
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />

  } else if (activeItem === "home") {
    secondaryNavBar = <SecondaryNavOriginal
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />

  } else if (activeItem === "students") {
    secondaryNavBar = <SecondaryNavStudents
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />

  } else if (activeItem === "play") {
    secondaryNavBar = <SecondaryNavPlay
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />
  }
  else if (activeItem === "groups") {
    secondaryNavBar = <SecondaryNavGroups
      currentCoin={currentCoin}
      setCurrentCoin={setCurrentCoin} />
  }

  return (
    <>
      <Menu
        color='white'
        widths={5}
        inverted
        style={{
          backgroundColor: "grey",
          fontWeight: "bold"
        }}
      >

        <Menu.Item
          className="menu-button"
          name='home'
          active={activeItem === 'home'}
          fontSize="1em"
          onClick={dashboardView}
        // onClick={() => setActiveItem('home')}
        // as={Link}
        // to={userDashboard}
        >

          <UserContainer
            fontSize="1em"
            color="white"
            posttest={" "} />
          {googlePhotoURL ? <img src={googlePhotoURL} alt="name" />
            : null}
        </Menu.Item>

        <Menu.Item
          className="menu-button"
          name='Students'
          active={activeItem === 'students'}
          onClick={studentsView}
        >Students</Menu.Item>

        <Menu.Item
          className="menu-button"
          name='Groups'
          active={activeItem === 'groups'}
          onClick={groupsView}
        >Groups</Menu.Item>

        <Menu.Item
          className="menu-button"
          name='tokens'
          active={activeItem === 'tokens'}
          onClick={tokensView}
        >Tokens</Menu.Item>

        <Menu.Item
          className="menu-button"
          name='play'
          active={activeItem === 'play'}
          onClick={playView}
        // onClick={() => setActiveItem('play')}
        // as={Link}
        // to={"/play"}
        >Play</Menu.Item>

        {/* <Menu.Item
          className="menu-button"
          name='logout'
          active={activeItem === 'logout'}
          onClick={signOut} /> */}

      </Menu>
      {secondaryNavBar}
    </>
  );
}

export default NavTeacherMain;
