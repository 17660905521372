import React, { useEffect } from 'react';
import { Card } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux"
import Gif from '../common/gif'
import { getAllSchoolwidePhysicalGoodsClassroom } from '../../redux/classroom/classroomActions'

const DisplayCard = (props) => {
  const { pg, viewItem } = props
  return (<>
    <Card key={pg._id}
      style={{
        // margin: "1px",
        padding: "0px",
        fontSize: ".5em"
      }}>
      <div onClick={() => viewItem(pg)} style={{ cursor: 'pointer' }}>
        <h1 >
          {pg.name}
        </h1>
        {pg?.image?.gif
          ? <Gif id={pg.image.gif} />
          : null}
        <h2 style={{
          color: "orange",
          margin: "1px"
        }}>{pg.description}</h2>
        <h3 style={{
          color: "black",
          fontSize: "3em",
          margin: "1px"
        }}>{pg.price}</h3>
        <h3 style={{
          color: "black",
          margin: "1px"
        }}>Amount remaining: {pg.quantityRemaining}</h3>
      </div>
    </Card>
  </>)
}

const PhysicalGoodDisplayBoard = (props) => {
  const { viewItem, schoolId, showOnlyCurrentlyAvailable } = props

  const {
    error,
    loading,
    _id: classroomId,
    schoolwidePhysicalGoods,
    schoolwidePhysicalGoodMostRecent

  } = useSelector(state => state.classroom)

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getAllSchoolwidePhysicalGoodsClassroom(schoolId))
  }, [dispatch, schoolId, schoolwidePhysicalGoodMostRecent])


  const physicalGoodsStocked = schoolwidePhysicalGoods?.filter(pg => pg.quantityRemaining > 0).map(pg => {
    if (pg.archived) return null
    return <DisplayCard pg={pg} viewItem={viewItem} />
  })


  const physicalGoodsAll = schoolwidePhysicalGoods?.map(pg => {
    if (pg.archived) return null
    return <DisplayCard pg={pg} viewItem={viewItem} />
  })


  return (<>
    <Card.Group itemsPerRow={4}>
      {showOnlyCurrentlyAvailable
        ? physicalGoodsStocked
        : physicalGoodsAll}
    </Card.Group>

    <h6>{showOnlyCurrentlyAvailable
      ? "Items in stock: " + physicalGoodsStocked?.length
      : "Items: " + physicalGoodsAll?.length}</h6>
  </>
  );
}
export default PhysicalGoodDisplayBoard;

