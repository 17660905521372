import React, { useEffect, useState } from 'react';
import { getClassroom } from '../../redux/classroom/classroomActions'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
//import RenderSVG from '../common/RenderSVG';
import { SimpleSpinner } from "../common/Spinners"
import { Button, Card } from 'semantic-ui-react'
//import { motion } from 'framer-motion';
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import { getAllTransactions } from "../../redux/classroom/classroomActions"
import Lottie from 'react-lottie'
import interchangeGraphic from '../../img2/lottie/ui/18899-exchange-money.json'

const StudentExchange = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const { currentCoin } = props

  const [sendTokenInterface, setSendTokenInterface] = useState(false)
  const [classListFiltered, setClassListFiltered] = useState(null)
  const [studentAccessoryData, setStudentAccessoryData] = useState(null)
  const [permissionRevoked, setPermissionRevoked] = useState(false)
  //const [allClassroomTransactions, setAllClassroomTransactions] = useState(null)

  // EVENTUALLY
  // const [schoolListFiltered, setSchoolListFiltered] = useState(null)

  // AUTH
  const { loading, error, user: { fb_uid } } = useSelector(state => state.auth)

  // A
  const {
    loading: profileLoading,
    error: profileError,
    currentClassroom,
    studentData: {
      _id: currentStudentUserId }
  } = useSelector(state => state.student)


  const { classroomTransactionsAll, _id: classroomId, studentIds, studentAccessoryDataClassroom, name: classroomName } = useSelector(state => state.classroom)

  const sendTokens = () => {
    setSendTokenInterface(true)
  }

  const handleSelectedReceiver = (receiver) => {
    history.push('/studentexchangeamount/', receiver)
  }

  // //1 
  // useEffect(() => {
  //   dispatch(getStudentProfile(fb_uid))
  // }, [])


  //2
  useEffect(() => {
    classroomId && dispatch(getClassroom(classroomId))
  }, [classroomId])



  useEffect(() => {
    if (studentIds?.length > 0) {
      const currentStudents = [...studentIds]
      const filterOutUser = currentStudents.filter(st => {
        return st._id !== currentStudentUserId
      })
      setClassListFiltered(filterOutUser)
    }
  }, [])


  // GET TRANSACIONS
  useEffect(() => {
    classroomId && dispatch(getAllTransactions(classroomId))
  }, [])



  // check accessoryData if student is blocked
  useEffect(() => {

    // REFACTOR THIS UP TO DASHBOARD
    setStudentAccessoryData(classroomId && studentAccessoryDataClassroom?.[currentStudentUserId])

    setPermissionRevoked(classroomId && studentAccessoryDataClassroom?.[currentStudentUserId]?.exchangeAccessRevoked)

  }, [classroomId])


  const transactionsDisplay = classroomTransactionsAll?.map((trans) => {
    return (
      <>
        <h6 style={{ "color": "blue", "margin": ".3em" }}>
          {trans.senderId.firstName} sent gratitude to {trans.receiverId.firstName}:
          <span style={{ "color": "green" }}>
            {" "}  {trans.message}</span>
        </h6>
      </>)
  })

  const classmatesDisplay = classListFiltered && classListFiltered.map(student => {
    return (
      <Card
        style={{ fontSize: ".8em" }}
        centered key={student._id}
        onClick={() => handleSelectedReceiver(student)}>
        <Card.Content>
          <RenderAvatar
            size={40}
            avatarId={student.avatar.avatarId}
            setOfAvatars={superHeros} />
          <Card.Header >{student.displayName}</Card.Header>
        </Card.Content>
      </Card >
    )
  })

  if (profileLoading || loading) return <SimpleSpinner />

  if (!classroomName) {
    return (<>
      <h2>You need to be a part of a class in order to use The Exchange.</h2>
      <Button
        onClick={() => history.push("/classroomjoin")}
        size="large"
        color="blue">Join a class
      </Button>
    </>)
  }





  return (
    <>
      {/* <h1>The {currentClassroom?.schoolId?.name} Exchange</h1> */}
      <h1>The {classroomName} Exchange</h1>

      {sendTokenInterface
        ? <div>
          {studentIds.length > 1
            ? <>
              <h3>Pick a receiver from: {currentClassroom?.name} </h3>
              <Card.Group itemsPerRow={6}>
                {classmatesDisplay}
              </Card.Group>
            </>
            : <>
              <h3>Currently, you are currently the only one enrolled.</h3>
              <h3>Once your classmates join, you can trade {currentClassroom?.schoolId?.coinName}s </h3>
            </>
          }
          <br />
          <br />
          <Button onClick={() => setSendTokenInterface(false)}>Exit</Button>
        </div>

        : (<>
          <div>
            <Lottie
              width={250}
              height={250}
              options={{
                loop: true,
                autoplay: true,
                animationData: interchangeGraphic,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
            />
          </div>
          <br />
          <h1 style={{ fontSize: "1.5em" }}>
            {currentClassroom
              ? "Recent transactions in " + currentClassroom?.name
              : null}
          </h1>
          {transactionsDisplay}
          <br />

          {permissionRevoked
            ? (<>
              <Button
                inverted color='red'
                size="large"
                onClick={() => history.push('/studentdashboard/')}>
                Access not permitted. Ask your teacher about this.</Button>
            </>)
            : (<>
              <Button
                inverted color='green'
                size="huge"
                onClick={sendTokens}>
                Enter Exchange</Button>
            </>)
          }
        </>)
      }
      <br />
      <br />
      <br />
    </>
  );

}
export default StudentExchange;






