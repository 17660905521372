import * as types from './superTypes'
import http from '../../services/httpService'


export const clearSuper = () => {
  return {
    type: types.CLEAR_SUPER
  }
}

const getStudentsRequest = () => {
  return {
    type: types.GET_STUDENTS_REQUEST
  }
}
const getStudentsSuccess = Students => {
  return {
    type: types.GET_STUDENTS_SUCCESS,
    payload: Students
  }
}
const getStudentsFailure = error => {
  console.log("++++++++ 4b ++++++++++")

  return {
    type: types.GET_STUDENTS_FAILURE,
    payload: error
  }
}
// returns a function rather than an action
// recieves dispatch method as argument
export const getStudents = () => {
  return (dispatch) => {
    dispatch(getStudentsRequest())
    http.get("/students")
      .then(response => {
        const students = response.data
        dispatch(getStudentsSuccess(students))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getStudentsFailure(errorMsg))
      })
  }
}



const getTeachersRequest = () => {
  return {
    type: types.GET_ADMINS_REQUEST
  }
}
const getTeachersSuccess = Teachers => {
  return {
    type: types.GET_TEACHERS_SUCCESS,
    payload: Teachers
  }
}
const getTeachersFailure = error => {
  return {
    type: types.GET_TEACHERS_FAILURE,
    payload: error
  }
}
export const getTeachers = () => {
  return (dispatch) => {
    dispatch(getTeachersRequest())
    http.get("/teachers")
      .then(response => {
        const teachers = response.data
        dispatch(getTeachersSuccess(teachers))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getTeachersFailure(errorMsg))
      })
  }
}



const getAdminsRequest = () => {
  return {
    type: types.GET_ADMINS_REQUEST
  }
}
const getAdminsSuccess = Admins => {
  return {
    type: types.GET_ADMINS_SUCCESS,
    payload: Admins
  }
}
const getAdminsFailure = error => {
  return {
    type: types.GET_ADMINS_FAILURE,
    payload: error
  }
}
export const getAdmins = () => {
  return (dispatch) => {
    dispatch(getAdminsRequest())
    http.get("/admins")
      .then(response => {
        const teachers = response.data
        dispatch(getAdminsSuccess(teachers))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getAdminsFailure(errorMsg))
      })
  }
}


const increaseStudentCoinsRequest = () => {
  return {
    type: types.INCREASE_STUDENT_COINS_REQUEST
  }
}
const increaseStudentCoinsSuccess = (student) => {
  return {
    type: types.INCREASE_STUDENT_COINS_SUCCESS,
    payload: student
  }
}
const increaseStudentCoinsFailure = (error) => {
  return {
    type: types.INCREASE_STUDENT_COINS_FAILURE,
    payload: error
  }
}

export const increaseStudentCoins = (id, creditsAwarded = 1) => {
  return (dispatch) => {
    dispatch(increaseStudentCoinsRequest());
    const url = "/students/increasestudentcoins/" + id;
    http.patch(url, {
      coins: creditsAwarded // new value increased creditsAwarded from prev
    })

      .then(studentResponse => {
        dispatch(increaseStudentCoinsSuccess(studentResponse.data));
        /// LATER fix this with local state?
      })
      .catch(error => {
        dispatch(increaseStudentCoinsFailure(error));
        console.error("+++++Error loading student", error)
      })
  }
}
