import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import UserContainer from '../common/UserContainer'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import image4 from '../../img/emiliano.jpg'

const NavAdminMain = (props) => {
  const [activeItem, setActiveItem] = useState('home');

  const [currentStudentClassroom, setCurrentStudentClassroom] = useState(null)
  const [currentStudentCoins, setCurrentStudentCoins] = useState(null)

  const [currentAdminSchool, setCurrentAdminSchool] = useState(null)


  const { userType, googlePhotoURL } = props
  const userTypeDisplay = capitalizeFirstLetter(userType)
  const userDashboard = "/" + userType + "dashboard"

  return (<>
    <Menu
      widths={4}
      style={{
        backgroundColor: "grey"
      }}  >

      <Menu.Item
        className="menu-button"
        name='home'
        active={activeItem === 'home'}
        onClick={() => setActiveItem('home')}
        as={Link}
        to={userDashboard}>

        <UserContainer
          fontSize="1em"
          color="white"
          pretext={userTypeDisplay + ": "}
          posttest={" "}
        />


        {googlePhotoURL
          ? <img 
          


          src={googlePhotoURL} 
          alt="name" />
          : null}
{/* 
        {googlePhotoURL
          ? <img onError={image4} src={googlePhotoURL+"l"} alt="name" />
          : null} */}
      </Menu.Item>

      <Menu.Item
        className="menu-button"
        style={{
          color: "white"
        }}
        name='Current school'
        active={activeItem === 'currentschool'}
        onClick={() => setActiveItem('currentschool')}
        as={Link}
        to={"/currentschool"}
      />

      <Menu.Item
        className="menu-button"
        style={{
          color: "white"
        }}
        name='Current store'
        active={activeItem === 'store'}
        onClick={() => setActiveItem('store')}
        as={Link}
        to={"/schoolstore"}
      />

      <Menu.Item
        className="menu-button"
        name='schools'
        style={{
          color: "white"
        }}
        active={activeItem === 'schools'}
        onClick={() => setActiveItem('schools')}
        as={Link}
        to={"/schoolsdisplay"}
      />

    </Menu>
  </>
  );
}

export default NavAdminMain;

