import React from 'react';
import { motion } from 'framer-motion';
import DragonCoin from '../../img/dragonCoin'

const variants = {
  hover: {
    scale: 1.5,
    transition: {
      duration: 0.3,
      yoyo: 1
    }
  }
}

export const CoinDisplay = (props) => {
  let { quantityOfCoins, size } = props;
  quantityOfCoins = parseInt(quantityOfCoins, 10)
  let dragonCoinArray = []

  for (let i = 0; i < quantityOfCoins; i++) {
    dragonCoinArray = dragonCoinArray.concat(
      <motion.div
        key={i}
        style={{ display: 'inline-block' }}
        variants={variants}
        whileHover='hover'>
        <DragonCoin size={size} />
      </motion.div>);
  };

  return (
    <div style={{ display: 'block' }}>
      {dragonCoinArray}
    </div>);
}

