import React, { useState } from 'react';
import { Button, Card } from 'semantic-ui-react'
import RenderSVG from '../common/RenderSVG';
import ReactTooltip from 'react-tooltip';

const DisplayBoard = (props) => {

  const [currentlyHoveredOver, setCurrentlyHoveredOver] = useState(null)
  const { currentCommonGoods, makeContribution, showContributors } = props

  const style1 = { margin: "1px 1px 1px 1px", cursor: "pointer" }

  const commonGoodsDisplay = currentCommonGoods?.filter(cg => cg.archived === false && cg.suspended === false)

    .map(cg => {
      console.log("cg", cg)

      let contributors = "Donations: <br/>"
      cg.contributorIds.forEach(student => {
        contributors += student.displayName + "<br/>"
      })

      return (<>
        <ReactTooltip multiline={true} delayShow='1000' />

        <Card
          onMouseEnter={() => setCurrentlyHoveredOver(cg.contributorIds)}
          onMouseLeave={() => setCurrentlyHoveredOver(null)}>

          <h1 style={{ fontSize: "2em", margin: "1px", cursor: "pointer" }}
          >{cg.title}</h1>

          {cg.image
            ? <RenderSVG
              size={"50"}
              svgFilename={cg.image.svg} />
            : null}
          <h6 style={style1}>{cg.description}</h6>
          <h3 style={style1}>Cost:{cg.targetAmount}</h3>


          <h3 style={style1}
            data-tip={contributors}
          >
            Donations:{cg.currentDonatedAmount}
          </h3>


          {cg.fulfilled === true
            ? <Button onClick={() => showContributors(cg)} size="tiny" color="yellow">GOAL REACHED!</Button>
            : <Button onClick={() => makeContribution(cg)} size="tiny" color="green">Contribute!</Button>
          }
        </Card>

      </>

      )
    })


  return (<>

    <Card.Group centered itemsPerRow={4}>
      {commonGoodsDisplay}
    </Card.Group>
    <br />

  </>
  );
}
export default DisplayBoard;

