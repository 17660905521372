import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Button } from "semantic-ui-react"
import RenderGif from "../../components/common/RenderGif"
import arrayOfSpinnerGifs from '../../data/spinnersArray.json'
import { updateClassroom } from '../../redux/classroom/classroomActions'
import { useDispatch, useSelector } from "react-redux"

const SpinnerSelection = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { classroomData: { settings, _id: classroomId } } = useSelector(state => state.classroom)

  const { setShowNavBar } = props

  useEffect(() => {
    setShowNavBar(false)
    return () => setShowNavBar(true)
  }, [])

  const setSpinner = (spinnerObject) => {
    // console.log(spinnerObject)
    const updatedSettings = { ...settings, ...{ spinner: spinnerObject.lottie } }
    const settingsToDb = { settings: updatedSettings }


    dispatch(updateClassroom(classroomId, settingsToDb))
    history.push('/spinnerteacher2', spinnerObject)
  }

  const displayOfSpinners = arrayOfSpinnerGifs.map(sp => {
    return (
      <div onClick={() => setSpinner(sp)}
        style={{ "display": "inline-block" }}>
        <RenderGif
          filename={"spinners/" + sp.gif}
          size="100"
        />
      </div>
    )
  })

  // check classroomId-->
  if (!classroomId) {
    return (
      <>
        <h2>No class has been selected.</h2>
        <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
      </>
    )
  }
  return (
    <>
      <h1>Choose a spinner</h1>
      {displayOfSpinners}
    </>
  )
};

export default SpinnerSelection;
