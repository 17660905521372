import React from 'react';
import { Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import animationData from '../../img2/lottie/descubro.json'

const Playtime = (props) => {
  const history = useHistory()

  const activateSpinner = () => {
    history.push('/spinnerteacher2')
  }


  return (

    <Container>
      <h1>Playtime</h1>

      <Lottie
        width={300}
        height={300}
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }} />

      <h2>Classroom Tools</h2>
      <h3 onClick={activateSpinner} style={{ cursor: "pointer" }}>Kansha Spinner</h3>
      <h2>Games</h2>
      <h3>Four Corners - in development</h3>
      {/*     <h3>Dots dots dots! - in development</h3> */}
      <h3>Bigger Smaller - in development</h3>
      <br />
      <br />
      <br />
    </Container>
  )
}
  ;

export default Playtime;
