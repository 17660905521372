import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Button } from 'semantic-ui-react'
import { getTeacherProfile } from '../../redux/teacher/teacherActions'
import { SimpleSpinner } from "../common/Spinners"



const SchoolDisplay = () => {
  const history = useHistory()
  const dispatch = useDispatch()


  const { teacherData: { classroomId_current, classroomIds, _id: teacherId }, loading } = useSelector(state => state.teacher)

  const { user: { fb_uid } } = useSelector(state => state.auth)

  const classroomIdWorkaround = classroomId_current ? classroomId_current.name : ""


  useEffect(() => {
    dispatch(getTeacherProfile(fb_uid))
  }, [classroomIdWorkaround])



  if (loading) {
    return <SimpleSpinner />
  }

  return (
    <>
      <h1>School Display</h1>
      {/*      <h2 >Current: {classroomId_current ? classroomId_current.name : " None"}</h2>
 */}

      <Button onClick={() => history.push('/teacherdashboard')}>Return to Dashboard</Button>
    </>
  )
}


export default SchoolDisplay;