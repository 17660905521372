import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux"
//import RenderSVG from '../common/RenderSVG';
import Gif from '../common/gif'
import { updatePhysicalGood } from '../../redux/admin/adminActions'


export default function PhysicalGoodTeacherView(props) {

  const { state,
    state: { name, description = "", quantityRemaining, quantityOffered, purchaserIds, purchaserLedger, image, _id: physicalGoodId } } = props.history.location

  const { error: classroomError, loading: classroomLoading, _id: classroomId } = useSelector(state => state.classroom)

  const history = useHistory()
  const dispatch = useDispatch()

  const [purchaserLedgerLocal, setPurchaserLedgerLocal] = useState(null)

  useEffect(() => {
    //console.log("ON LOAD")
    setPurchaserLedgerLocal(purchaserLedger)
  }, [])


  const updateDeliveryStatus = (id, row) => {
    console.log("TOGGLE DELIVERY / NO - add the date if to yes", row)
    console.log("id", id)
    console.log("purchaserLedgerLocal==>", purchaserLedgerLocal)
    const updatedPuchaserLedgerLocal = { ...purchaserLedgerLocal }
    updatedPuchaserLedgerLocal[id].delivered = !updatedPuchaserLedgerLocal[id].delivered
    setPurchaserLedgerLocal(updatedPuchaserLedgerLocal)

    const updateToPhysicalObject = {
      purchaserLedger: updatedPuchaserLedgerLocal
    }

    dispatch(updatePhysicalGood(physicalGoodId, updateToPhysicalObject))
  }


  if (classroomLoading) { return <h1>Loading</h1> }
  if (classroomError) {
    console.log(classroomError)
    return (<><h1>Error</h1><h6>{classroomError.message}</h6></>)
  }

  const purchaserArray = purchaserLedgerLocal && Object.keys(purchaserLedgerLocal)

  const studentPurchasesDisplay = purchaserLedgerLocal && purchaserArray.map((id) => {
    const displayRowContent =
      <tr>
        <td key={id}>{purchaserLedger[id].displayName}</td>
        <td>{purchaserLedger[id]?.teacherName}</td>
        <td>{purchaserLedger[id]?.classroomName}</td>
        <td> {purchaserLedger[id]?.quantityPurchased} </td>

        {purchaserLedger[id]?.delivered
          ? <td onClick={() => updateDeliveryStatus(id, purchaserLedger[id])}
            style={{ cursor: 'pointer' }}
            className="positive"><i className="icon checkmark"></i> Delivered
          </td>
          : <td onClick={() => updateDeliveryStatus(id, purchaserLedger[id])}
            style={{ cursor: 'pointer' }}
            className="negative"> To be delivered
          </td>}
      </tr>
    return displayRowContent;
  })


  return (<>
    <h1>{name}</h1>
    {image?.gif ? <Gif id={image.gif} size={200} /> : null}
    <h3>Items Sold: {purchaserIds.length} <br />Remaining: {quantityRemaining}</h3>
    <table className="ui celled table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Teacher</th>
          <th>Classroom</th>
          <th>Quantity</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {studentPurchasesDisplay}
      </tbody>
    </table>
    <br />
    <br />

  </>);
}




