import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion';
//import { superHeros } from "../../img/avatars/superHeros"
// use this to archive the class
import { updateCommonGood } from '../../redux/classroom/classroomActions'
import Confetti from 'react-dom-confetti';
import confettiConfig from '../../data/confettiConfig'
//import RenderAvatar from '../common/RenderAvatar';
import RenderSVG from '../common/RenderSVG';


export default function PayUp(props) {

  const [showConfetti, setShowConfetti] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()


  const { error, loading } = useSelector(state => state.classroom)
  const { state: { _id: commonGoodId, title, description, contributorIds, contributorLedger, image: { svg: imageSvg }, } } = props.history.location

  const winnerStyle = { "color": "gold", "fontSize": "2.5em", "margin": "1px" }

  const donors = contributorIds && contributorIds.map(student => {
    return <h3 style={{ "fontSize": "3em", margin: "1px 1px 1px 1px" }} key={student._id}>{student.displayName}{" "}{contributorLedger[student._id][0]}</h3>
  })

  const archiveCommonGoodItem = () => {
    dispatch(updateCommonGood(commonGoodId, { archived: true }))
    // later check redux for possible error before pushing
    history.push('/commongoodsteacher/')
  }


  useEffect(() => {
    setShowConfetti(true)
  }, [])

  const studentsAnimation = donors.map(student => {
    return (
      <>
        <div style={{
          "display": "inline-block",
          "margin": "2px"
        }}>
          {/* <motion.div
            initial={{ scale: .2 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 300 }} >
           <RenderAvatar
              size={80}
              avatarId={student[3]} //
              setOfAvatars={superHeros} /> 
          </motion.div> */}
          <motion.h1
            style={winnerStyle}
            animate={{ fontSize: "1em" }}
          >
            {student[0]}
          </motion.h1>
        </div>
      </>
    )
  })

  if (loading) { return <h1>Loading</h1> }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }


  return (
    <>
      <br />
      <br />
      <motion.h1
        style={winnerStyle}
        animate={{ scale: 3, rotateZ: 360 }}
      >{title}
      </motion.h1>
      <br />
      <br />
      {imageSvg
        ? <RenderSVG
          size={"100"}
          svgFilename={imageSvg} />
        : null}
      <br />
      <h2 style={{ "color": "black", margin: "1px 1px 1px 1px" }}>{description}</h2>

      <Confetti active={showConfetti} config={confettiConfig} />
      <h3 style={{ "fontSize": "3em", margin: "1px 1px 1px 1px" }}>Thanks to the donations from:</h3>
      {studentsAnimation}
      {donors}
      <br />
      <Button onClick={archiveCommonGoodItem}>Archive</Button>
      <br />
    </>

  );
}




