import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button, Icon } from "semantic-ui-react"
import StudentsTable from './CurrentStudentsTable2'
import { SimpleSpinner } from "../common/Spinners"
import { getClassroom, removeStudentFromClassroom, updateClassroom } from "../../redux/classroom/classroomActions"
import { removeClassroomFromStudent } from "../../redux/student/studentActions"
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import RenderAvatar from '../common/RenderAvatar';
import { superHeros } from "../../img/avatars/superHeros"
import googleLogo from "../../img2/png/google-logo.png"
import RenderSVG from '../common/RenderSVG';
// import notify from '../../utils/notify'

const StudentsMainList = (props) => {
  const { studentAccessoryDataClassroom, setShowNavBar } = props
  const [deleteSelectionForModal, setDeleteSelectionForModal] = useState(null)
  const [showStudentDetails, setShowStudentDetails] = useState(false)
  const [studentInfoDisplayed, setStudentInfoDisplayed] = useState() // WHY EMPTY?
  const [studentAccessoryData_LOCAL, setStudentAccessoryData_LOCAL] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const dispatch = useDispatch()

  const { navbar, teacherData: { _id: teacherId, classroomId_current, avatar: { avatarId }, schoolId } } = useSelector(state => state.teacher)

  const { loading: classroomLoading, name: classroomName, classroomData: { studentIds, _id: classroomId }, image } = useSelector(state => state.classroom)

  const classroomImage = image?.svg

  const coinName = schoolId?.coinName

  // WHY getting classroom on every mount?
  useEffect(() => {
    setShowNavBar(true)
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
    studentIds && setStudentInfoDisplayed(studentIds)
  }, [])


  // ------- ISSUE 1) --> In loading component, setting local SAD to redux SAD
  useEffect(() => {
    setStudentAccessoryData_LOCAL(studentAccessoryDataClassroom)
  }, [])


  //  ------- ISSUE 2) --> if studentAccessoryData does changes, update DB with local SAD

  // ** IF not NULL
  useEffect(() => {
    if (studentAccessoryData_LOCAL) {
      const updatedClassroomData = {
        studentAccessoryDataClassroom: studentAccessoryData_LOCAL
      }
      dispatch(updateClassroom(classroomId, updatedClassroomData))
    }
  }, [studentAccessoryData_LOCAL])



  // const quickPoint = (student) => {
  //   const studentInfoDisplayedLocal = [...studentInfoDisplayed]
  //   const indexOfUpdate = _.findIndex(studentInfoDisplayed, { '_id': student._id })
  //   const recordToUpdate = _.find(studentInfoDisplayed, { '_id': student._id });

  //   recordToUpdate.coins = recordToUpdate.coins + 1
  //   studentInfoDisplayedLocal[indexOfUpdate] = recordToUpdate

  //   // 1) update local state
  //   setStudentInfoDisplayed(studentInfoDisplayedLocal)
  //   // 2) update student record 
  //   dispatch(increaseStudentCoinsByTeacher(student._id, 1, teacherId))
  //   // 3) toast
  //   notify({ message: `${coinName} for ${student.displayName} `, noticeType: "info", autoClose: 2500 })

  // }

  // const quickDock = (student) => {
  //   const studentInfoDisplayedLocal = [...studentInfoDisplayed]
  //   const indexOfUpdate = _.findIndex(studentInfoDisplayed, { '_id': student._id })
  //   const recordToUpdate = _.find(studentInfoDisplayed, { '_id': student._id });

  //   if (recordToUpdate.coins < 1) {
  //     alert("No more coins")
  //   } else {

  //     recordToUpdate.coins = recordToUpdate.coins - 1
  //     studentInfoDisplayedLocal[indexOfUpdate] = recordToUpdate

  //     // 1) update local state
  //     setStudentInfoDisplayed(studentInfoDisplayedLocal)
  //     // 2) update student record 
  //     dispatch(increaseStudentCoinsByTeacher(student._id, -1, teacherId))

  //   }
  // }


  const toggleShowDetails = () => {
    setShowStudentDetails(!showStudentDetails)
  }

  const removeStudentFromClass = (firstName, lastName, _id) => {
    const studentToRemove = { firstName, lastName, _id }
    setDeleteSelectionForModal(studentToRemove)
  }

  const removeStudentConfirmed = () => {

   dispatch(removeStudentFromClassroom(deleteSelectionForModal, classroomId_current._id))
  
   dispatch(removeClassroomFromStudent( classroomId_current._id, deleteSelectionForModal._id))
  }


  const [showStudentProfile, hideStudentProfile] = useModal(() => {
    const { displayName, email, coins, avatar: { avatarId }, google_uid, googleImageUrl } = selectedStudent
    return (
      <ReactModal isOpen ariaHideApp={false}>
        <h1>{displayName}</h1>
        <RenderAvatar
          size={80}
          avatarId={avatarId}
          setOfAvatars={superHeros}
        />
        <h3> {google_uid ? <img src={googleLogo} alt="GL" width="30" height="30" /> : null} {email}</h3>

        {googleImageUrl ? <img alt="google icon" src={googleImageUrl}></img> : null}

        <h3>Kansha Tokens: {coins}</h3>
        <h4>Classes and Groups coming</h4>
        <div>
          <br />

          <Button color="green" size="large" onClick={hideStudentProfile}>Exit</Button>
        </div>
      </ReactModal>
    )
  },
    [selectedStudent]
  );

  if (classroomLoading) {
    return (<SimpleSpinner />)
  }

  // if (!studentIds || !studentInfoDisplayed) {
  //   return <h1>No classes added yet</h1>
  // }


  return (
    <>
      <DeleteModal
        header={"PERMANENTLY REMOVE A STUDENT"}
        modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to remove " + (deleteSelectionForModal ? deleteSelectionForModal.firstName : null) + " from your classroom?"}</h3>}
        icon={<Icon name='warning circle' />}
        size='small'
        showModal={!!deleteSelectionForModal} // used as boolean
        setShowModal={setDeleteSelectionForModal}
        handleConfirm={removeStudentConfirmed}
        handleDecline={(info) => console.log("Nothing to do here, yet", info)}
      />
      {classroomImage &&
      <div style={{margin:"12px 0 0 0"}}>
        <RenderSVG
          size="150"
          svgFilename={classroomImage} />
      </div>}

      <h1
        style={{ fontWeight: "600", color: "grey", fontSize: "4em" }}
      >{classroomName}</h1>

      {!studentIds || studentIds?.length < 1
        ? (<h1>No classes with students.</h1>)
        : <>

          {/* {showStudentDetails
            ?
            <Icon color="blue" size="large" name='unhide' onClick={toggleShowDetails} />

            : <Icon color="grey" size="large" name='hide' onClick={toggleShowDetails} />

          } */}

          <StudentsTable
            removeStudentFromClass={removeStudentFromClass}
            coinName={coinName}
            data={studentInfoDisplayed}
            showStudentDetails={showStudentDetails}
            showStudentProfile={showStudentProfile}
            setSelectedStudent={setSelectedStudent}
            studentAccessoryData_LOCAL={studentAccessoryData_LOCAL}
            setStudentAccessoryData_LOCAL={setStudentAccessoryData_LOCAL}
            classroomName={classroomName}
            toggleShowDetails={toggleShowDetails} />

        </>
      }
      <br />
      <br />
      <br />
    </>)
};


export default StudentsMainList;

