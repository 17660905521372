import React from 'react';
import { useHistory } from 'react-router-dom'

const Tokens = (props) => {
  const history = useHistory()

  return (<>
    <h1>Classroom Tokenomics</h1>
    <h3>Saving Account Interest</h3>
    <h3>Inflation Rate</h3>
    <h3>Automatic Deposits</h3>
    <h3>QR Code Account Access</h3>
    <br />
    <h3 style={{ color: "black" }}>Currently in development. Release date April 2023</h3 >
  </>)
};

export default Tokens;
