import React from 'react';
import { Container } from "semantic-ui-react"
import UserContainer from '../common/UserContainer'

//import { useSelector, useDispatch } from "react-redux"
// import { getPosts_2 } from '../../redux/super/superActions'



const RightNow = (props) => {

  //const dispatch = useDispatch()  // USE FOR MONGO CALL
  // const superState = useSelector(state => state.super)

  // useEffect(() => {
  //   getPosts_2()
  //   // page load
  //   dispatch(getPosts_2())
  // }, [])

  return (
    <Container>
      <UserContainer />
      <h1>Live Game Room</h1>
      <h3>1. Four Corners</h3>
      <h3>2. Pictionary</h3>
      <h3>3. Tron</h3>
      <h1>Assignments</h1>
      <h3>Personal checkoff(?) </h3>
      <h3>Group progress chart </h3>
      <h1>Chat</h1>
      <h3>By group</h3>
      <h3>Whole class?</h3>

    </Container>
  );
}
export default RightNow;
/*

async componentDidMount() {
  // pending or resolved
  const { data: posts } = await http.get(apiEndpoint)
  this.setState({ posts })
}

handleAdd = async () => {
  const obj = { title: "cat", body: "dog" }
  const { data: post } = await http.post(apiEndpoint, obj)
  const posts = [post, ...this.state.posts]
  console.log(posts)
  this.setState({ posts })

};

handleUpdate = async post => {
  const originalPosts = [...this.state.posts]

  // update:
  post.title = "UPDATED4REAL"
  // v1:
  // PUT  - send entire object
  //const { data } = await http.put(apiEndpoint + "/" + post.id, post)

  // v2:
  // PATCH - send only the updated data
  try {

    const posts = [...this.state.posts]
    const index = posts.indexOf(post)
    posts[index] = { ...post }
    this.setState({ posts })

    const { data } = await http.patch(apiEndpoint + "/" + post.id, {
      title: post.title
    })
    console.log("Updated data", data);

  }
  catch (err) {
    this.setState({
      posts: originalPosts
    })
    console.log("Something went wrong");

  }
};

handleDelete = async post => {
  const originalPosts = [...this.state.posts]
  const posts = this.state.posts.filter(i => i.id !== post.id)
  this.setState({ posts })
  try {
    const result = await http.delete(apiEndpoint + "/" + post.id)
  } catch (ex) {
    console.log("--EX-->", ex)
    // EXPECTED vs UNEXPECTED ERRORS

    // Expected (404: not found, 400: bad request) - Client errors
    // - Display a specific error message

    // Unexpected(network down, server down, db down, bug in application)
    // - log them
    // - display a generic and friend error message

    // ex. response
    // ex. request

    if (ex.response && ex.response.status === 404) {
      alert("This post has already been deleted") // anything else in this sitation is an unexpected error
    } else {
      console.log("Unexpected error!!!", ex)
    }
    this.setState({ posts: originalPosts })
  } */