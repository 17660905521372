import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";

const NavTeacherSecond = () => {
  const [activeItem, setActiveItem] = useState('home');

  return (null
    // <Menu color='red' widths={6} >

    //   <Menu.Item
    //     className="menu-button"
    //     name='Current class'
    //     active={activeItem === 'currentclass'}
    //     onClick={() => setActiveItem('currentclass')}
    //     as={Link}
    //     to={"/currentclassroommain"}
    //   />

    //   <Menu.Item
    //     className="menu-button"
    //     name='all classes'
    //     active={activeItem === 'classes'}
    //     onClick={() => setActiveItem('classes')}
    //     as={Link}
    //     to={"/classroomsdisplay"}
    //   />

    //   <Menu.Item
    //     className="menu-button"
    //     name='Community'
    //     active={activeItem === 'commongoods'}
    //     onClick={() => setActiveItem('commongoods')}
    //     as={Link}
    //     to={"/commongoodsteacher"}
    //   />

    //   <Menu.Item
    //     className="menu-button"
    //     name='Raffle'
    //     active={activeItem === 'raffle'}
    //     onClick={() => setActiveItem('raffle')}
    //     as={Link}
    //     to={"/rafflemainteacher"}
    //   />
    //   <Menu.Item
    //     className="menu-button"
    //     name='School Store'
    //     active={activeItem === 'store'}
    //     onClick={() => setActiveItem('store')}
    //     as={Link}
    //     to={"/schoolstoreteacher"}
    //   />
    //   <Menu.Item
    //     className="menu-button"
    //     name='play'
    //     active={activeItem === 'play'}
    //     onClick={() => setActiveItem('play')}
    //     as={Link}
    //     to={"/play"}
    //   />

    // </Menu>
  );
}

export default NavTeacherSecond;

