import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button, Card } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateGroup, deleteGroup } from '../../redux/classroom/classroomActions'
import RenderSVG from '../common/RenderSVG'
import teamObjects from '../../data/teamObjects.json'
import ReactTooltip from 'react-tooltip';
import pickRandomItem from '../../utils/pickRandomItem'
import cssColors from '../../data/colorsCSS'
import { SimpleSpinner } from "../common/Spinners"
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css'

export default function GroupListsAddGroup(props) {
  const { state } = props.history.location
  const { group, groupthemeData } = state
  const currentIconTheme = groupthemeData.groupIds[0].displayNameGraphics.svg.split("/")[0]

  const [availableTeamsObjects, setAvailableTeamsObjects] = useState(teamObjects)
  const [availableThemes, setAvailableThemes] = useState(Object.keys(teamObjects))
  const [selectedTheme, setSelectedTheme] = useState(currentIconTheme)
  const [newGroupDisplayNameGraphic, setNewGroupDisplayNameGraphic] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()
  const { error, loading, onCompleteRoute } = useSelector(state => state.classroom)


  useEffect(() => {
    setNewGroupDisplayNameGraphic(group.displayNameGraphics)
  }, [])

  const saveAvatar = () => {
    const updatedGroup = {
      ...group,
      displayNameGraphics: newGroupDisplayNameGraphic,
      colorOnBoard: newGroupDisplayNameGraphic.colorOnBoard
    }
    dispatch(updateGroup(group._id, updatedGroup))
  };

  const updateAvatar = (newAvatar) => {
    const updatedGroup = { ...newAvatar, ...{ colorOnBoard: newGroupDisplayNameGraphic.colorOnBoard } }
    setNewGroupDisplayNameGraphic(updatedGroup)
  }

  const deleteGroupHandle = () => {
    dispatch(deleteGroup(group._id))
  }

  const updateBackgroundColor = () => {
    const newColor = pickRandomItem(cssColors)
    const updatedGroup = { ...newGroupDisplayNameGraphic, ...{ colorOnBoard: newColor } }
    setNewGroupDisplayNameGraphic(updatedGroup)
  }

  const listOfThemeTitles = availableThemes.map(th => {
    return (
      <Button inverse color="black" onClick={() => setSelectedTheme(th)}>{th}</Button>
    )
  })

  const setOfAvatars = availableTeamsObjects[selectedTheme] && availableTeamsObjects[selectedTheme].map(avatar => {
    return (<Card onClick={() => {
      updateAvatar(avatar)
    }}>
      <RenderSVG size="35" svgFilename={avatar.svg} />
    </Card>)
  })

  const showAvailableIcons = () => {
    return (
      <div>
        <Card.Group centered itemsPerRow={8}>
          {setOfAvatars}
        </Card.Group>
      </div>
    )
  }

  if (onCompleteRoute) {
    history.push("/groupslist")
  }

  if (loading) { return <SimpleSpinner /> }

  if (error) {
    return <> <h1>Error</h1><h6>{error.message}</h6></>
  }

  const handleChange = (e, setFn) => {
    const updatedGroupDisplayNameGraphic = { ...newGroupDisplayNameGraphic, ...{ english: e.target.value } }
    setFn(updatedGroupDisplayNameGraphic);
  };

  return (<>
    <ReactTooltip delayShow='1000' />
    <h1> {newGroupDisplayNameGraphic && (<div data-tip="Edit Title">
      <EditText
        name="textbox2"
        style={{
          padding: '10px',
          color: "black",
          fontSize: '1em'
        }}
        //defaultValue={newGroupDisplayNameGraphic?.english}
        value={newGroupDisplayNameGraphic?.english}
        onChange={(e) => handleChange(e, setNewGroupDisplayNameGraphic)}
        onSave={saveAvatar} />
    </div>
    )}
    </h1>


    {newGroupDisplayNameGraphic &&
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>

        <ReactTooltip delayShow='1000' />
        <div
          style={{
            backgroundColor: newGroupDisplayNameGraphic?.colorOnBoard,
            width: "35%"
          }}
          data-tip="Change Background Color"
          onClick={updateBackgroundColor}
        >

          <div style={{ padding: "20px" }}>
            <RenderSVG
              size={"200px"}
              svgFilename={newGroupDisplayNameGraphic?.svg} />
          </div>
        </div>
      </div>
    }
    <br />
    <div>
      <br />
      {listOfThemeTitles}
      <br />
      <br />
      {availableTeamsObjects[selectedTheme] ? showAvailableIcons() : null}
    </div>
    <br />
    <Button
      color="green"
      style={{ fontSize: "1.5em" }}
      onClick={saveAvatar}>
      Save
    </Button>
    <br />
    <br />
    <div>
      <Button
        onClick={deleteGroupHandle}
        size="mini">Delete
      </Button>
    </div>
    <br />
    <div>
      <Button
        onClick={() => history.push("/groupslist")}
        size="mini">Cancel
      </Button>
    </div>
    <br />
    <br />
  </>)
}
