export const FETCH_USER_REQUEST_FB = "FETCH_USER_REQUEST_FB"
export const FETCH_USER_SUCCESS_FB = "FETCH_USER_SUCCESS_FB"
export const FETCH_USER_FAILURE_FB = "FETCH_USER_FAILURE_FB"

export const CREATE_USER_REQUEST_FB = "CREATE_USER_REQUEST_FB"
export const CREATE_USER_SUCCESS_FB = "CREATE_USER_SUCCESS_FB"
export const CREATE_USER_FAILURE_FB = "CREATE_USER_FAILURE_FB"


export const FETCH_USER_REQUEST_GL = "FETCH_USER_REQUEST_GL"
export const FETCH_USER_SUCCESS_GL = "FETCH_USER_SUCCESS_GL"
export const FETCH_USER_FAILURE_GL = "FETCH_USER_FAILURE_GL"

export const CREATE_USER_REQUEST_GL = "CREATE_USER_REQUEST_GL"
export const CREATE_USER_SUCCESS_GL = "CREATE_USER_SUCCESS_GL"
export const CREATE_USER_FAILURE_GL = "CREATE_USER_FAILURE_GL"


export const PASSWORD_RESET_EMAIL_REQUEST = "PASSWORD_RESET_EMAIL_REQUEST"
export const PASSWORD_RESET_EMAIL_SUCCESS = "PASSWORD_RESET_EMAIL_SUCCESS"
export const PASSWORD_RESET_EMAIL_FAILURE = "PASSWORD_RESET_EMAIL_FAILURE"


export const UPDATE_USER_FB = "UPDATE_USER_FB"
export const CLEAR_USER_FB = "CLEAR_USER_FB"
export const LOGOUT_USER_FB = "LOGOUT_USER_FB"
export const CLEAR_USER_GL = "CLEAR_USER_GL"

export const SET_USER_TYPE = "SET_USER_TYPE"
export const CLEAR_USER_TYPE = "CLEAR_USER_TYPE"
