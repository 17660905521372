import React, { useReducer, useState, useEffect } from 'react'
import _ from 'lodash'
import { Table, Header } from 'semantic-ui-react'
import RenderAvatar from '../common/RenderAvatar';
import { superHeros } from "../../img/avatars/superHeros"
import pdfIcon from '../../img2/ui/general/pdfIcon.svg'
import pdfListGenerator from './pdfListGenerator.js';
import pdfTableGenerator from './pdfTableGenerator.js';

const removeItem = () => {
  console.log("REMOVE ITEM (check props to validate this)")
}

function tableReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }
      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

function SortableTable({ tableStructure, tableData}) {

  const [orderedTableData, reorderTableDataPassedIn] = useState(tableData)
  const {title, columnDef, allowPdf} = tableStructure
 
  // // --- COLUMN SETUP --- //
  const columnDisplayTitles = columnDef.map(col=>col.displayTitle)
  const columnNames = columnDef.map(col=>col.columnName)
  const columnAvatarExists = columnDef.map(col=>col.avatar)
  const columnSvgExists = columnDef.map(col=>col.svg)
  const columnDataWidths = columnDef.map(col=>col.width)
  const columnSubTextToDisplay = columnDef.map(col=>col.subTextToDisplay)


  // ------------PDF LIST CREATE----------- //

  const pdfPrepare = ()=>{
  
    // NO SUBTEXT items in listPdf
    const buildColumnForListPdf = ()=>{
      const mainPdfColumnData = []
      
      // build each column
      columnNames.forEach((colName, columnNumber)=>{
      
        const localColumn = []
        // CAFE #1
        //columnDef.forEach((col, i)=>{
        orderedTableData.forEach((col, i)=>{

          let pdfCell 

          if (!orderedTableData[i] || !orderedTableData[i][colName]){
            pdfCell = "---"
          } else {
            pdfCell = typeof orderedTableData[i][colName] === "string" || typeof orderedTableData[i][colName] === "number" ? orderedTableData[i][colName] :  orderedTableData[i][colName].length + " " + columnDisplayTitles[columnNumber]
          }

        // Need to remove subdata here
        // const numberOfSubtextItems = orderedTableData[i][colName].length
        // if (typeof orderedTableData[i][colName] !== "string"){
        //     const subTextItemList = orderedTableData[i][colName].map(item=> "\n" + item.name)
        //     pdfCell = numberOfSubtextItems + " " + columnDisplayTitles[columnNumber] + ":" + subTextItemList
        // } 

        localColumn.push(pdfCell)
        })

        mainPdfColumnData.push(localColumn)
      })
      return mainPdfColumnData
    } // -- END OF PDF LIST BUILD --

    const buildRowsForTablePdf = ()=>{
      let pdfRowData= []
     // console.log("orderedTableData*******>", orderedTableData)

      orderedTableData.forEach(singleOrderedRow =>{
   
        let buildRow = []
        columnNames.forEach((colName, ii) =>{

          if (typeof singleOrderedRow[colName] === "string" || typeof singleOrderedRow[colName] ==="number" ){
          buildRow.push(singleOrderedRow[colName])

        } else {
            const {subTextNames, subTextDisplay} = columnDef[ii] || {}

            const frigginWorkaround_columnDisplayTitles = columnDisplayTitles[ii]?.text ? columnDisplayTitles[ii]?.text : columnDisplayTitles[ii]

            let finalSubTextCell = singleOrderedRow[colName].length  + " " + frigginWorkaround_columnDisplayTitles

            singleOrderedRow[colName].forEach(rowItemToBeParsed => {
              // iterate over subTextName
              subTextNames.forEach((subTextName, j)=> {
                const rowValue = subTextDisplay[j] + ": " + rowItemToBeParsed[subTextName];
                console.log("rowValue==>", rowValue)
                // concat the info to final outout
                finalSubTextCell = finalSubTextCell + "\n   " + rowValue 
                //console.log("finalSubTextCell===>", finalSubTextCell)
              })
              finalSubTextCell += "\n    "


              })

            finalSubTextCell += "\n    "

            // break up
            // heading fontSize =12 / subtext fontsize = 8
            buildRow.push({text: finalSubTextCell, fontSize: 8}) // bold:true
          }
      })

      pdfRowData.push(buildRow)

      })


      return pdfRowData

    }  // -- END OF PDF TABLE BUILD --

    const {pdfConfig:{ listOrTable, pdfDisplayColumnGap, titleFontSize, columnFontSize}} = tableStructure || {}

    const numberOfRecords = orderedTableData.length

    if (listOrTable === "list") {
      const pdfColumnData = buildColumnForListPdf()
      pdfListGenerator({title, titleFontSize, pdfDisplayColumnGap, columnDisplayTitles, columnFontSize, pdfColumnData, numberOfRecords})

    } else if (listOrTable === "table"){
      const pdfRowDataForTable = buildRowsForTablePdf()
      pdfTableGenerator({title, titleFontSize, pdfDisplayColumnGap, columnDisplayTitles, pdfRowDataForTable, numberOfRecords})

    } else {
      console.error("pdf not configured correctly")
      return null
    }
  } 

  const [state, dispatch] = useReducer(tableReducer, {
    column: null,
    data: orderedTableData,
    direction: null,
  })

  const { column, data, direction } = state

  useEffect(()=>{
    reorderTableDataPassedIn(()=> data)
  }, [direction, column]) 
  
  useEffect(()=>{
    reorderTableDataPassedIn(()=> data)
    dispatch({ type: 'CHANGE_SORT', column: columnNames[0]})
  }, []) 

  if (data.length === 0) return <h4>Currently there are no {title} registered to this school.</h4>

  const tableHeaders = columnDisplayTitles.map((heading, i)=>{
    return  (
      heading ? <Table.HeaderCell key={i}
      width={columnDataWidths[i]}
      sorted={column === columnNames[i] ? direction : null}
      onClick={() => dispatch({ type: 'CHANGE_SORT', column: columnNames[i]})}>
      {heading}
    </Table.HeaderCell>
       : null)
  })

  const svgTrue = <h1>somthing</h1>
  const avatarTrue = <h1>somthing</h1>

  // ----  BUILD DISPLAY BODY ---- ///
  const tableBodyDisplay = data.map((rowObject, j)=>{ 

    const { avatar } = rowObject // only avatar 
    // const {lastName, firstName, email, classroomIds, avatar } = rowObject

    const allRowsDisplay = []

    // BUILD DISPLAY CELL and ITERATE OVER COLUMN TITLES LOOKING AT COLUMNDEF
    columnDisplayTitles.forEach((columnTitle, i)=>{

      let displayCell = typeof rowObject[columnNames[i]] === "string" || "number" ? rowObject[columnNames[i]] : rowObject[columnNames[i]]?.length + " " + columnTitle
      let avatarExistsInColDef = null
      let subTextCellsDisplay = []
  
      // AVATAR
      if (columnAvatarExists[i]){
          avatarExistsInColDef =  (
          <RenderAvatar
          size={30}
          avatarId={avatar?.avatarId}
          setOfAvatars={superHeros} 
          />)
        // console.log("🐥🐥AVATAR🐥🐥")
      }

      // SVG
      // if (columnDef[i].svg){
      //     svgExistsInColDef = "🪲🪲🪲🪲"
      //     console.log(svgExistsInColDef) }

      // SUBTEXT
      if (columnSubTextToDisplay[i]){
        try {

          if (Array.isArray(rowObject[columnNames[i]]) && rowObject[columnNames[i]].length>0){
            displayCell = rowObject[columnNames[i]].length +  " " + columnDisplayTitles[i]

            const {subTextNames, subTextDisplay, subTextColor} = columnDef[i]

            // build subtext row and push to subTextRows
            rowObject[columnNames[i]].forEach((row, ii) =>{

            // individual items in the cell
             let subTextSingleCell = []
              subTextNames.forEach((fieldName, jj)=>{
                const cellContent = row[fieldName] ? subTextDisplay[jj] + ": " + row[fieldName] + " " : "-"
                subTextSingleCell.push(<span key={jj} style={{fontSize:".6em", color:subTextColor[jj], padding:"2px"}}>{cellContent}</span>)
              })
              subTextCellsDisplay.push(<h6 key={ii}  style={{lineHeight:"75%"}}>{subTextSingleCell}</h6>)
            })
          } else { displayCell = "-"}
        } catch(err){
          console.error(err)
        } 
      }
      
      allRowsDisplay.push(
          <Table.Cell key={i}>
          <Header as='h4' >
            {avatarExistsInColDef && avatarExistsInColDef}
            <Header.Content>
              {displayCell} 
              {subTextCellsDisplay}
            </Header.Content>
          </Header>
        </Table.Cell>
        )
    })
  
    return (<Table.Row key={j} verticalAlign='top'>{allRowsDisplay}</Table.Row>)
    
  })
  

  return (<>
  <h2>{title}</h2>
    <Table sortable celled fixed>
      <Table.Header>
        <Table.Row>{tableHeaders}</Table.Row>
      </Table.Header>
      <Table.Body>{tableBodyDisplay}</Table.Body>  
    </Table>
      <h4>{title} Total: {data.length}</h4>
      <br/>
      {allowPdf &&  <img src={pdfIcon} height="30" alt="Pdf Logo" onClick={pdfPrepare} /> }
      <br/>
    </>
  )
}

export default SortableTable
