import React from 'react';
import { Container } from "semantic-ui-react"
import UserContainer from '../common/UserContainer'

const BreakTime = (props) => {
  return (
    <Container>
      <h2>Currently in Development</h2>
      <h3>1. Four Corners</h3>
      <h3>2. Bigger Smaller</h3>
      <h3>3. The Name Game</h3>
      <h3>4. Team Races</h3>
      {/*       <h3>2. Pictionary</h3>
      <h3>3. Tron</h3>
 */}
    </Container>
  );
}
export default BreakTime;

// eachclassroom will have { gamesCurrentlyActive : [gameId1, gameId2]}
