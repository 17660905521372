import * as types from './studentTypes'

const initStudent = {
  loading: false,
  studentData: {},
  error: '',
  reduxResponseData: null,
  onCompleteRoute: null,
  currentClassroom: null // Remove?
}


function studentReducer(state = initStudent, action) {
  switch (action.type) {

    case types.GET_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
        //  currentClassroom: null
      };

    case types.GET_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        studentData: action.payload[0],
        error: '',
        reduxResponseData: null,
        onCompleteRoute: "/studentdashboard",
        //  currentClassroom: null
      };

    case types.GET_STUDENT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        studentData: {},
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null,
        //  currentClassroom: null
      };


    case types.UPDATE_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        onCompleteRoute: null,
        reduxResponseData: null
      };

    case types.UPDATE_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        studentData: action.payload,
        error: '',
        onCompleteRoute: "/studentdashboard",
        reduxResponseData: "updated"
      };

    case types.UPDATE_STUDENT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onCompleteRoute: null,
        reduxResponseData: null
      };




    case types.CHECK_CLASS_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
        //  currentClassroom: null
      };

    case types.CHECK_CLASS_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        //studentData: no change
        error: '',
        reduxResponseData: action.payload,
        onCompleteRoute: null
      };

    case types.CHECK_CLASS_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        //studentData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };




    case types.JOIN_CLASS_WITH_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
      };

    case types.JOIN_CLASS_WITH_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        studentData: action.payload, // is this the correct payload?
        error: '',
        reduxResponseData: "class-joined",
        onCompleteRoute: true
      };

    case types.JOIN_CLASS_WITH_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        //studentData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null,
        //  currentClassroom: null
      };





    case types.GET_CLASSROOM_REQUEST_STUDENT:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
        currentClassroom: null
      };

    case types.GET_CLASSROOM_SUCCESS_STUDENT:
      return {
        ...state,
        loading: false,
        //studentData: action.payload,
        error: '',
        reduxResponseData: null,
        onCompleteRoute: null,
        currentClassroom: action.payload
      };

    case types.GET_CLASSROOM_FAILURE_STUDENT:
      return {
        ...state,
        loading: false,
        //studentData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null,
        currentClassroom: null
      };



    //ADJUST_STUDENT_COINS_BY_TRANSFER_REQUEST
    case types.ADJUST_STUDENT_COINS_BY_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.ADJUST_STUDENT_COINS_BY_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        studentData: { ...state.studentData, coins: action.payload[0].coins },
        reduxResponseData: action.payload[0].coins,
        onCompleteRoute: null
      };

    case types.ADJUST_STUDENT_COINS_BY_TRANSFER_FAILURE:
      return {
        ...state,
        loading: false,
        //studentData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };


    // REMOVE_CLASSROOM_FROM_STUDENT - only when entire classroom deleted

    case types.REMOVE_CLASSROOM_FROM_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null,
        onCompleteRoute: null,
        //currentClassroom: null
      };

    case types.REMOVE_CLASSROOM_FROM_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        //studentData: action.payload,
        error: '',
        reduxResponseData: action.payload,
        onCompleteRoute: null,
        // currentClassroom: action.payload
      };

    case types.REMOVE_CLASSROOM_FROM_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        //studentData: - leave unchanged
        error: action.payload,
        reduxResponseData: null,
        onCompleteRoute: null
      };


    case types.GET_STUDENT_BALANCE_REQUEST:
      return {
        ...state,
        loading: true,
        reduxResponseData: null
      };

    case types.GET_STUDENT_BALANCE_SUCCESS:

      return {
        ...state,
        loading: false,
        studentData: { ...state.studentData, coins: action.payload.balance },
        reduxResponseData: action.payload.balance,
        error: '',
      };

    case types.GET_STUDENT_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case types.CLEAR_STUDENT_ROUTING:
      return {
        ...state,
        error: null,
        reduxResponseData: null,
        onCompleteRoute: null
      };

    case types.CLEAR_STUDENT:
      return initStudent;

    default:
      return state;
  }
}

export default studentReducer