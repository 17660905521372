import React from 'react'
import { FormButton } from 'semantic-ui-react'
import TicketsTable from './TicketsTable';

export default function TicketsIssued(props) {
  const { endRaffle, raffleTicketIds, handleDeleteStudentTickets, handleDeleteStudentPrizes } = props;
  return (<>
    <h1>Tickets Issued</h1>
    <TicketsTable 
    raffleTicketIds={raffleTicketIds} 
    handleDeleteStudentTickets={handleDeleteStudentTickets}
    handleDeleteStudentPrizes={handleDeleteStudentPrizes}
    />
    <FormButton
      size="large" color="red"
      onClick={endRaffle}>
      End Raffle {raffleTicketIds.length > 1 ? "and Clear Remaining Tickets" : null}
    </FormButton>
    <br />
  </>)
}
