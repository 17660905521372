import React, { useState, useEffect } from 'react';
import { Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { SimpleSpinner } from '../common/Spinners'
import { getClassroom, updateGrouptheme } from "../../redux/classroom/classroomActions"

const GroupSize = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { teacherDataFromProps:
    { teacherData: { classroomId_current } } } = props


  // useEffect(() => {
  //   dispatch(getClassroom(classroomId_current._id))
  // }, [])


  const {
    loading,
    groupthemeData: { topic, setNumberOfGroups: previouslySavedNumberOfGroups },
    classroomData: { studentIds, currentStudentsOnline, groupthemeId_building } } = useSelector(state => state.classroom)


  const wholeClassSize = studentIds.length
  const groupSizeGraphic = ["🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂", "🙂"]

  const [tweener, setTweener] = useState(0)
  const [groupNumber, setGroupNumber] = useState(4)
  const [groupSize, setGroupSize] = useState(Math.floor(wholeClassSize / 4))// this actually needs to be calculated in useEffect to consider tweener (?)

  // send 4 to db as default if no previouslySavedNumberOfGroups
  useEffect(() => {
    if (previouslySavedNumberOfGroups) {
      setGroupSize(wholeClassSize / previouslySavedNumberOfGroups)
      setGroupNumber(previouslySavedNumberOfGroups)
    } else {
      // save to db if no record
      setGroupNumber(4)
      updateGroupSizeBackend(4)
    }
  }, [groupthemeId_building])


  // limits group number and size
  useEffect(() => {
    const newGroupSize = Math.floor(wholeClassSize / groupNumber)
    if (newGroupSize <= 1) return setGroupSize(1)
    if (groupSize >= wholeClassSize) return setGroupSize(wholeClassSize)
    setGroupSize(newGroupSize)
  }, [groupNumber])

  // distributes remainder students
  useEffect(() => {
    if (wholeClassSize % groupNumber !== 0) {
      setTweener(wholeClassSize % groupNumber)
    } else {
      setTweener(0)
    }
  }, [groupNumber, loading]);


  const decreaseNumberOfGroups = () => {
    if (groupNumber <= 2) setGroupNumber(2)
    else {
      setGroupNumber(groupNumber - 1)
      setGroupSize(Math.floor(wholeClassSize / groupNumber))
    }
  }

  const increaseNumberOfGroups = () => {
    if (groupNumber >= Math.floor(wholeClassSize / 2)) setGroupNumber(Math.floor(wholeClassSize / 2))
    else { setGroupNumber(groupNumber + 1) }
    setGroupSize(Math.floor(wholeClassSize / groupNumber))
    // tweener check
  }

  const updateGroupSizeBackend = (groupNum) => {
    const dataPod = {
      setNumberOfGroups: groupNum
    }
    // only push after submit
    dispatch(updateGrouptheme(groupthemeId_building, dataPod))
  };

  if (loading) {
    return <SimpleSpinner />
  }

  console.log("groupthemeId_building", groupthemeId_building)

  return (
    <Container>
      <h1>How many {topic ? topic : null} Groups?</h1>
      <div>
        <div>{" "}</div>
        <h2 style={{ color: "green", fontSize: "9em" }} >{groupNumber}</h2>
        <Button
          onClick={() => {
            decreaseNumberOfGroups() //local
            updateGroupSizeBackend(groupNumber > 2 ? groupNumber - 1 : 2) //db
          }}
          size="large"
          inverted color='inverse green'
        >- Less</Button>
        <Button
          onClick={() => {
            increaseNumberOfGroups() // local 
            updateGroupSizeBackend(groupNumber < Math.floor(wholeClassSize / 2) ? groupNumber + 1 : groupNumber) //db
          }}
          size="large"
          inverted color='inverse green'
        >+ More</Button>
        <h3>There are {wholeClassSize} students in this class. The size of each group is {Math.floor(groupSize)} {tweener
          ? " to " + (Math.floor(groupSize) + 1)
          : null}
        </h3>
        <h2>{groupSizeGraphic.concat().slice(0, groupSize)}</h2>

      </div>
      <h2>{" "}</h2>
      <Button
        onClick={() => {

          // dispatch(updateGrouptheme(groupthemeId_building, dataPod))
          updateGroupSizeBackend(groupNumber);

          history.push('/groupcreatedraggable')
        }}
        size="massive"
        color='black'>
        Save and Continue</Button>
      <br />
      <br />
    </Container >
  );
}
export default GroupSize;

