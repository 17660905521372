import React from 'react';
import RenderAvatar from '../common/RenderAvatar';
import { motion } from 'framer-motion';
import { superHeros } from "../../img/avatars/superHeros"
import Confetti from 'react-dom-confetti';


const winnerTextStyle = {
  color: "gold",
  fontSize: "3em",
  fontWeight: "bold",
  margin: "1px",
  textShadow: "2px 2px 4px #000000"
}

const confettiDelay = 3000

const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: "200",
  dragFriction: 0.12,
  duration: confettiDelay,
  stagger: 3,
  width: "20px",
  height: "20px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};


const StudentWinner = (props) => {
  const { schoolCurrency, initateSpin, randomStudent: student } = props
  return (<>
    <Confetti active={true} config={confettiConfig} />
    <motion.h1
      style={winnerTextStyle}
      animate={{ scale: 1.5, rotateZ: 360 }}
    >{schoolCurrency} for:
    </motion.h1>

    <br />

    <motion.div
      initial={{ scale: .2 }}
      animate={{ scale: 1.2 }}
      transition={{ type: 'spring', stiffness: 300 }}
      onClick={initateSpin}>
      <RenderAvatar
        size={250}
        avatarId={student.avatar.avatarId}
        setOfAvatars={superHeros} />
    </motion.div>

    <motion.h1
      style={winnerTextStyle}
      onClick={initateSpin}
      animate={{ fontSize: "150px", rotateY: 360 }}>
      {student && student.firstName}!
    </motion.h1>

  </>)
}
export default StudentWinner;


