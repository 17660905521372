// -- classroom --- //
export const GET_CLASSROOM_REQUEST = "GET_CLASSROOM_REQUEST"
export const GET_CLASSROOM_SUCCESS = "GET_CLASSROOM_SUCCESS"
export const GET_CLASSROOM_FAILURE = "GET_CLASSROOM_FAILURE"

export const UPDATE_CLASSROOM_REQUEST = "UPDATE_CLASSROOM_REQUEST"
export const UPDATE_CLASSROOM_SUCCESS = "UPDATE_CLASSROOM_SUCCESS"
export const UPDATE_CLASSROOM_FAILURE = "UPDATE_CLASSROOM_FAILURE"

export const ADD_GROUPTHEME_TO_CLASSROOM_REQUEST = "ADD_GROUPTHEME_TO_CLASSROOM_REQUEST"
export const ADD_GROUPTHEME_TO_CLASSROOM_SUCCESS = "ADD_GROUPTHEME_TO_CLASSROOM_SUCCESS"
export const ADD_GROUPTHEME_TO_CLASSROOM_FAILURE = "ADD_GROUPTHEME_TO_CLASSROOM_FAILURE"

export const CLEAR_CLASSROOM = "CLEAR_CLASSROOM"

// -- grouptheme: create --- //
export const CREATE_GROUPTHEME_REQUEST = "CREATE_GROUPTHEME_REQUEST"
export const CREATE_GROUPTHEME_SUCCESS = "CREATE_GROUPTHEME_SUCCESS"
export const CREATE_GROUPTHEME_FAILURE = "CREATE_GROUPTHEME_FAILURE"

// -- grouptheme: get --- //
export const GET_GROUPTHEME_REQUEST = "GET_GROUPTHEME_REQUEST"
export const GET_GROUPTHEME_SUCCESS = "GET_GROUPTHEME_SUCCESS"
export const GET_GROUPTHEME_FAILURE = "GET_GROUPTHEME_FAILURE"

// -- grouptheme: update --- //
export const UPDATE_GROUPTHEME_REQUEST = "UPDATE_GROUPTHEME_REQUEST"
export const UPDATE_GROUPTHEME_SUCCESS = "UPDATE_GROUPTHEME_SUCCESS"
export const UPDATE_GROUPTHEME_FAILURE = "UPDATE_GROUPTHEME_FAILURE"

// -- grouptheme: delete --- //
export const DELETE_GROUPTHEME_REQUEST = "DELETE_GROUPTHEME_REQUEST"
export const DELETE_GROUPTHEME_SUCCESS = "DELETE_GROUPTHEME_SUCCESS"
export const DELETE_GROUPTHEME_FAILURE = "DELETE_GROUPTHEME_FAILURE"

export const DELETE_GROUPTHEME_ALSO_CURRENT_SUCCESS = "DELETE_GROUPTHEME_ALSO_CURRENT_SUCCESS"


export const CLEAR_GROUPTHEME = "CLEAR_GROUPTHEME"

// -- group: create --- //
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE"

// -- group: get --- //
export const GET_GROUP_REQUEST = "GET_GROUP_REQUEST"
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS"
export const GET_GROUP_FAILURE = "GET_GROUP_FAILURE"

// -- group: add point to group --- //
export const ADD_POINT_TO_GROUP_REQUEST = "ADD_POINT_TO_GROUP_REQUEST"
export const ADD_POINT_TO_GROUP_SUCCESS = "ADD_POINT_TO_GROUP_SUCCESS"
export const ADD_POINT_TO_GROUP_FAILURE = "ADD_POINT_TO_GROUP_FAILURE"

// -- group: update --- //
export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE"

// -- group: delete --- //
export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE"

export const CLEAR_GROUP = "CLEAR_GROUP"


// -- setofgroups: create --- //
export const CREATE_SETOFGROUPS_REQUEST = "CREATE_SETOFGROUPS_REQUEST"
export const CREATE_SETOFGROUPS_SUCCESS = "CREATE_SETOFGROUPS_SUCCESS"
export const CREATE_SETOFGROUPS_FAILURE = "CREATE_SETOFGROUPS_FAILURE"


// -- setofgroups: create --- //
export const GET_SETOFGROUPS_REQUEST = "GET_SETOFGROUPS_REQUEST"
export const GET_SETOFGROUPS_SUCCESS = "GET_SETOFGROUPS_SUCCESS"
export const GET_SETOFGROUPS_FAILURE = "GET_SETOFGROUPS_FAILURE"


// -- setofgroups: delete --- // (not built yet)
export const DELETE_SETOFGROUPS_REQUEST = "DELETE_SETOFGROUPS_REQUEST"
export const DELETE_SETOFGROUPS_SUCCESS = "DELETE_SETOFGROUPS_SUCCESS"
export const DELETE_SETOFGROUPS_FAILURE = "DELETE_SETOFGROUPS_FAILURE"


// ## -- NEW: Remove student --- ## //
export const REMOVE_STUDENT_FROM_CLASSROOM_REQUEST = "REMOVE_STUDENT_FROM_CLASSROOM_REQUEST"
export const REMOVE_STUDENT_FROM_CLASSROOM_SUCCESS = "REMOVE_STUDENT_FROM_CLASSROOM_SUCCESS"
export const REMOVE_STUDENT_FROM_CLASSROOM_FAILURE = "REMOVE_STUDENT_FROM_CLASSROOM_FAILURE"


export const REMOVE_STUDENT_FROM_GROUP_REQUEST = "REMOVE_STUDENT_FROM_GROUP_REQUEST"
export const REMOVE_STUDENT_FROM_GROUP_SUCCESS = "REMOVE_STUDENT_FROM_GROUP_SUCCESS"
export const REMOVE_STUDENT_FROM_GROUP_FAILURE = "REMOVE_STUDENT_FROM_GROUP_FAILURE"


export const ADD_STUDENT_TO_GROUP_REQUEST = "ADD_STUDENT_TO_GROUP_REQUEST"
export const ADD_STUDENT_TO_GROUP_SUCCESS = "ADD_STUDENT_TO_GROUP_SUCCESS"
export const ADD_STUDENT_TO_GROUP_FAILURE = "ADD_STUDENT_TO_GROUP_FAILURE"


// ----- transactions ------ //

export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST"
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS"
export const CREATE_TRANSACTION_FAILURE = "CREATE_TRANSACTION_FAILURE"

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST"
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS"
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE"

export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST"
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
export const UPDATE_TRANSACTION_FAILURE = "UPDATE_TRANSACTION_FAILURE"

export const DELETE_TRANSACTION_REQUEST = "DELETE_TRANSACTION_REQUEST"
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_TRANSACTION_FAILURE = "DELETE_TRANSACTION_FAILURE"

export const GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST"
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS"
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE"


// ----- common goods ------ //

export const CREATE_COMMON_GOOD_REQUEST = "CREATE_COMMON_GOOD_REQUEST"
export const CREATE_COMMON_GOOD_SUCCESS = "CREATE_COMMON_GOOD_SUCCESS"
export const CREATE_COMMON_GOOD_FAILURE = "CREATE_COMMON_GOOD_FAILURE"

export const GET_COMMON_GOOD_REQUEST = "GET_COMMON_GOOD_REQUEST"
export const GET_COMMON_GOOD_SUCCESS = "GET_COMMON_GOOD_SUCCESS"
export const GET_COMMON_GOOD_FAILURE = "GET_COMMON_GOOD_FAILURE"

export const UPDATE_COMMON_GOOD_REQUEST = "UPDATE_COMMON_GOOD_REQUEST"
export const UPDATE_COMMON_GOOD_SUCCESS = "UPDATE_COMMON_GOOD_SUCCESS"
export const UPDATE_COMMON_GOOD_FAILURE = "UPDATE_COMMON_GOOD_FAILURE"

export const DELETE_COMMON_GOOD_REQUEST = "DELETE_COMMON_GOOD_REQUEST"
export const DELETE_COMMON_GOOD_SUCCESS = "DELETE_COMMON_GOOD_SUCCESS"
export const DELETE_COMMON_GOOD_FAILURE = "DELETE_COMMON_GOOD_FAILURE"

export const GET_ALL_COMMON_GOODS_REQUEST = "GET_ALL_COMMON_GOODS_REQUEST"
export const GET_ALL_COMMON_GOODS_SUCCESS = "GET_ALL_COMMON_GOODS_SUCCESS"
export const GET_ALL_COMMON_GOODS_FAILURE = "GET_ALL_COMMON_GOODS_FAILURE"


// -- remove ? --// 
export const PROCESS_STUDENT_DONATION_REQUEST = "PROCESS_STUDENT_DONATION_REQUEST"
export const PROCESS_STUDENT_DONATION_SUCCESS = "PROCESS_STUDENT_DONATION_SUCCESS"
export const PROCESS_STUDENT_DONATION_FAILURE = "PROCESS_STUDENT_DONATION_FAILURE"


// --- raffle -- //

export const GET_PRIZE_REQUEST = "GET_PRIZE_REQUEST"
export const GET_PRIZE_SUCCESS = "GET_PRIZE_SUCCESS"
export const GET_PRIZE_FAILURE = "GET_PRIZE_FAILURE"

export const CREATE_PRIZE_REQUEST = "CREATE_PRIZE_REQUEST"
export const CREATE_PRIZE_SUCCESS = "CREATE_PRIZE_SUCCESS"
export const CREATE_PRIZE_FAILURE = "CREATE_PRIZE_FAILURE"

export const UPDATE_PRIZE_REQUEST = "UPDATE_PRIZE_REQUEST"
export const UPDATE_PRIZE_SUCCESS = "UPDATE_PRIZE_SUCCESS"
export const UPDATE_PRIZE_FAILURE = "UPDATE_PRIZE_FAILURE"

export const DELETE_PRIZE_REQUEST = "DELETE_PRIZE_REQUEST"
export const DELETE_PRIZE_SUCCESS = "DELETE_PRIZE_SUCCESS"
export const DELETE_PRIZE_FAILURE = "DELETE_PRIZE_FAILURE"

export const GET_ALL_PRIZES_REQUEST = "GET_ALL_PRIZES_REQUEST"
export const GET_ALL_PRIZES_SUCCESS = "GET_ALL_PRIZES_SUCCESS"
export const GET_ALL_PRIZES_FAILURE = "GET_ALL_PRIZES_FAILURE"

export const UPDATE_RAFFLE_REQUEST = "UPDATE_RAFFLE_REQUEST"
export const UPDATE_RAFFLE_SUCCESS = "UPDATE_RAFFLE_SUCCESS"
export const UPDATE_RAFFLE_FAILURE = "UPDATE_RAFFLE_FAILURE"


// -- student raffle purchase -// 

export const STUDENT_RAFFLE_PURCHASE_REQUEST = "STUDENT_RAFFLE_PURCHASE_REQUEST"
export const STUDENT_RAFFLE_PURCHASE_SUCCESS = "STUDENT_RAFFLE_PURCHASE_SUCCESS"
export const STUDENT_RAFFLE_PURCHASE_FAILURE = "STUDENT_RAFFLE_PURCHASE_FAILURE"





// -- SW = Schoolwide _ physical goods -- //

export const CREATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST = "CREATE_NEW_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST"
export const CREATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS = "CREATE_NEW_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS"
export const CREATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE = "CREATE_NEW_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE"

export const GET_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST = "GET_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST"
export const GET_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS = "GET_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS"
export const GET_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE = "GET_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE"

export const UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST = "UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST"
export const UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS = "UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS"
export const UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE = "UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE"

export const DELETE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST = "DELETE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST"
export const DELETE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS = "DELETE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS"
export const DELETE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE = "DELETE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE"

export const GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_REQUEST = "GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_REQUEST"
export const GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_SUCCESS = "GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_SUCCESS"
export const GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_FAILURE = "GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_FAILURE"


// -- virtual goods -- //

export const CREATE_VIRTUAL_GOOD_CLASSROOM_REQUEST = "CREATE_NEW_VIRTUAL_GOOD_CLASSROOM_REQUEST"
export const CREATE_VIRTUAL_GOOD_CLASSROOM_SUCCESS = "CREATE_NEW_VIRTUAL_GOOD_CLASSROOM_SUCCESS"
export const CREATE_VIRTUAL_GOOD_CLASSROOM_FAILURE = "CREATE_NEW_VIRTUAL_GOOD_CLASSROOM_FAILURE"

export const GET_VIRTUAL_GOOD_CLASSROOM_REQUEST = "GET_VIRTUAL_GOOD_CLASSROOM_REQUEST"
export const GET_VIRTUAL_GOOD_CLASSROOM_SUCCESS = "GET_VIRTUAL_GOOD_CLASSROOM_SUCCESS"
export const GET_VIRTUAL_GOOD_CLASSROOM_FAILURE = "GET_VIRTUAL_GOOD_CLASSROOM_FAILURE"

export const UPDATE_VIRTUAL_GOOD_CLASSROOM_REQUEST = "UPDATE_VIRTUAL_GOOD_CLASSROOM_REQUEST"
export const UPDATE_VIRTUAL_GOOD_CLASSROOM_SUCCESS = "UPDATE_VIRTUAL_GOOD_CLASSROOM_SUCCESS"
export const UPDATE_VIRTUAL_GOOD_CLASSROOM_FAILURE = "UPDATE_VIRTUAL_GOOD_CLASSROOM_FAILURE"

export const DELETE_VIRTUAL_GOOD_CLASSROOM_REQUEST = "DELETE_VIRTUAL_GOOD_CLASSROOM_REQUEST"
export const DELETE_VIRTUAL_GOOD_CLASSROOM_SUCCESS = "DELETE_VIRTUAL_GOOD_CLASSROOM_SUCCESS"
export const DELETE_VIRTUAL_GOOD_CLASSROOM_FAILURE = "DELETE_VIRTUAL_GOOD_CLASSROOM_FAILURE"

export const GET_ALL_VIRTUAL_GOODS_CLASSROOM_REQUEST = "GET_ALL_VIRTUAL_GOODS_CLASSROOM_REQUEST"
export const GET_ALL_VIRTUAL_GOODS_CLASSROOM_SUCCESS = "GET_ALL_VIRTUAL_GOODS_CLASSROOM_SUCCESS"
export const GET_ALL_VIRTUAL_GOODS_CLASSROOM_FAILURE = "GET_ALL_VIRTUAL_GOODS_CLASSROOM_FAILURE"

export const PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST = "PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST"
export const PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS = "PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS"
export const PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE = "PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE"


/// ----- get student group details from grouptheme ------ ///
// ** maybe later: restructure to get groupId from student record? 

export const GET_STUDENT_GROUP_DETAILS_REQUEST = "GET_STUDENT_GROUP_DETAILS_REQUEST"
export const GET_STUDENT_GROUP_DETAILS_SUCCESS = "GET_STUDENT_GROUP_DETAILS_SUCCESS"
export const GET_STUDENT_GROUP_DETAILS_FAILURE = "GET_STUDENT_GROUP_DETAILS_FAILURE"
