import React from 'react';
import { Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'semantic-ui-react'


const CheckIn = (props) => {
  const history = useHistory()
  const { register, errors, handleSubmit, watch } = useForm({});


  const onSubmitFeelings = data => {
    console.log("SEND THIS BEE-OTCH", data)
    //dispatch(createUserFB(data)) // update mongodb
    history.push('/studentdashboard')
  };



  console.log("---props---", props)
  return (
    <Container>
      <h2>Morning Check-in</h2>


      <form onSubmitFeelings={e => e.preventDefault()}>

        <label>How are you feeling?</label>
        <select ref={register} name="how_feeling">
          <option value="excellent">Excellent</option>
          <option value="good">Good</option>
          <option value="okay">Okay</option>
          <option value="yucky">Yucky</option>
          <option value="reallybad">Really Bad</option>
        </select>

        <label>How is the work going?</label>
        <select ref={register} name="progress">
          <option value="finished">Finished</option>
          <option value="still_working">Still Working</option>
          <option value="not_started">Not started</option>
        </select>

        <label>Would you like to leave a comment for [TEACHER]</label>
        <input
          name="comments"
          type="text"
          ref={register({})}
        />

        <Button
          onClick={handleSubmit(onSubmitFeelings)}
          size="massive"
          type="submit"
          inverted color='blue'
        >Submit</Button>

      </form>

    </Container>
  );
}
export default CheckIn;

