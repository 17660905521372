import React from 'react';
import { Button, Card, Icon } from 'semantic-ui-react'
import RenderSVG from '../common/RenderSVG';

const DisplayBoard = (props) => {

  const { currentCommonGoods, viewItem, editItem } = props

  const commonGoodsDisplayBoard = currentCommonGoods?.map(cg => {

    if (cg.archived || cg.suspended) return null

    const donors = cg.contributorIds.map(student => {
      return <h6
        style={{ "fontSize": ".75em", margin: "1px 1px 1px 1px" }}
        key={student._id}>
        {student.displayName}{" "}{cg.contributorLedger[student._id][0]}</h6>
    })

    //console.log("cg.contributorIds", cg.contributorIds)
    return (
      <Card>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => viewItem(cg)}
        >
          <h1 style={{ fontSize: "2em", margin: "1px" }}>{cg.title}</h1>

          {cg.image
            ? <RenderSVG
              size={"50"}
              svgFilename={cg.image.svg} />
            : null}


          {cg?.fulfilled
            ?
            <h3 style={{ margin: "1px 1px 1px 1px", color: "#FFD700" }}>GOAL OF {cg.currentDonatedAmount} REACHED!</h3>

            : <>
              <h3 style={{ margin: "1px 1px 1px 1px" }}>Cost: {cg.targetAmount}
              </h3>
              <h3 style={{ margin: "1px 1px 1px 1px" }}>Donations: {cg.currentDonatedAmount}</h3>
            </>
          }

          {cg?.contributorIds?.length > 0
            ?
            <h6
              style={{ "fontSize": ".75em", margin: "1px 1px 1px 1px" }}>
              {cg.contributorIds.length}

              {cg?.contributorIds?.length > 1 ? " students have contributed" : " student has contributed"}

            </h6>
            : null}

          {/*  TO DO: HOVER OVER TO SEE {donors} */}


        </div>
        {cg?.fulfilled
          ?
          (<>
            <Button onClick={() => viewItem(cg)} size="tiny" color="green">Award Time!</Button>

          </>)
          :
          (<>
            <div onClick={() => editItem(cg)} style={{ margin: "1px 1px 10px 1px" }}>
              <Icon
                name='edit' inverted size='large' color='black' />
            </div>
          </>)
        }
      </Card>
    )
  })

  return (<>
    <Card.Group centered itemsPerRow={4}>
      {commonGoodsDisplayBoard}
    </Card.Group>
  </>
  );
}
export default DisplayBoard;

