import React from 'react';

const StudentResetPassword = () => {
  return (<>
    <h1>Password resest</h1>
    <h2>An email will be sent to this address:</h2>
    <button>Send email</button>
  </>);
}

export default StudentResetPassword;



