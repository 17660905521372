const convertNameToInitials = (string) => {
  if (typeof string !== "string") return null
  const stringArray = string.split(" ")

  let initials = ""

  stringArray.forEach(name => {
    initials = initials + name[0].toUpperCase()
  })
  return initials
}

export default convertNameToInitials