import generatePassword from 'password-generator';
import * as types from './classroomTypes'
import http from '../../services/httpService'
import pickRandomItem from '../../utils/pickRandomItem'
import cssColors from '../../data/colorsCSS'
//import cssColors from '../../data/colorsSemantic'
import moment from 'moment'


const apiEndpoint = "/classrooms/"


///------------ classroom ----------------///
export const clearClassroom = () => {
  return {
    type: types.CLEAR_CLASSROOM
  }
}

const getClassroomRequest = () => {
  return {
    type: types.GET_CLASSROOM_REQUEST
  }
}
const getClassroomSuccess = classroom => {
  return {
    type: types.GET_CLASSROOM_SUCCESS,
    payload: classroom
  }
}
const getClassroomFailure = error => {
  return {
    type: types.GET_CLASSROOM_FAILURE,
    payload: error
  }
}
// including getGrouptheme and groupIds
export const getClassroom = (classroomId) => {
  return async (dispatch) => {
    dispatch(getClassroomRequest())

    try {

      const response = await http.get(apiEndpoint + classroomId)
      const { data: classroomData } = response
      dispatch(getClassroomSuccess(classroomData))


      const { groupthemeId_current } = classroomData
      let groupIds = null

      // GROUPTHEME_CURRENT - clarifyiing
      // 1) if there IS a current grouptheme, getGrouptheme
      // which will populate groupIds array with group basics, everything but student names
      // 2) groupIds here:
      // set to null assuming no group are on grouptheme
      // if they exist, then here they are retrieved
      // 3) GROUPDATA will be "source of truth" on db, but in redux store will only show displayName and ._id


      // ISSUE1 : If groupthemeId_current is not null 
      // AND we are 'building" new groups
      // a) why are we dipspatching getGrouptheme here?
      //  this impacts what? 

      // ISSUE 2: groupthemeId_current needs to be populated with names etc, so is this not happening and compounding the issues?

      // if (groupthemeId_current) {
      //   groupIds = groupthemeId_current.groupIds
      //   dispatch(getGrouptheme(groupthemeId_current))

      // }

      // console.log("groupIds ===>", groupIds)


      // just get grouptheme and populate group info
      // if (groupIds.length){
      //   console.log()
      //   dispatch(getSetOfGroups(groupIds))
      // } else {

      // }

    }
    catch (err) {
      const errorMsg = err.message
      console.log(errorMsg)
      dispatch(getClassroomFailure(errorMsg))
    }
  }
}



const updateClassroomRequest = () => {
  return {
    type: types.UPDATE_CLASSROOM_REQUEST
  }
}
const updateClassroomSuccess = classroom => {
  return {
    type: types.UPDATE_CLASSROOM_SUCCESS,
    payload: classroom
  }
}
const updateClassroomFailure = error => {
  return {
    type: types.UPDATE_CLASSROOM_FAILURE,
    payload: error
  }
}
export const updateClassroom = (classroomId, dataToUpdate) => {
  return (dispatch) => {

    dispatch(updateClassroomRequest())
    const url = "/classrooms/updateclassroom/" + classroomId;
    http.patch(url, dataToUpdate)
      .then(response => {
        const updatedClassroom = response.data

        dispatch(updateClassroomSuccess(updatedClassroom))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(updateClassroomFailure(errorMsg))
      })
  }
}


//--- classroom: Add grouptheme to classroom ---///

const addGroupthemeToClassroomRequest = () => {
  return {
    type: types.ADD_GROUPTHEME_TO_CLASSROOM_REQUEST
  }
}
const addGroupthemeToClassroomSuccess = classroom => {
  return {
    type: types.ADD_GROUPTHEME_TO_CLASSROOM_SUCCESS,
    payload: classroom
  }
}
const addGroupthemeToClassroomFailure = error => {
  return {
    type: types.ADD_GROUPTHEME_TO_CLASSROOM_FAILURE,
    payload: error
  }
}
export const addGroupthemeToClassroom = (classroomId, groupthemeId) => {
  return (dispatch) => {
    const groupthemeIdObject = { groupthemeId }
    dispatch(addGroupthemeToClassroomRequest())
    const url = "/classrooms/addgroupthemetoclassroom/" + classroomId;
    http.patch(url, groupthemeIdObject)
      .then(response => {
        const updatedClassroom = response.data
        dispatch(addGroupthemeToClassroomSuccess(updatedClassroom))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(addGroupthemeToClassroomFailure(errorMsg))
      })
  }
}



///------------ grouptheme ----------------///

export const clearGrouptheme = () => {
  return {
    type: types.CLEAR_GROUPTHEME
  }
}


const createGroupthemeRequest = () => {
  return {
    type: types.CREATE_GROUPTHEME_REQUEST
  }
}
const createGroupthemeSuccess = grouptheme => {
  return {
    type: types.CREATE_GROUPTHEME_SUCCESS,
    payload: grouptheme
  }
}
const createGroupthemeFailure = error => {
  return {
    type: types.CREATE_GROUPTHEME_FAILURE,
    payload: error
  }
}

export const createGrouptheme = (classroomId, newDataPod) => {

  return async (dispatch) => {
    dispatch(createGroupthemeRequest())
    try {
      // 1) create new groupTheme with topic and classroomId (newDataPod)
      const url1 = "/groupthemes/";
      const { data: groupthemeData } = await http.post(url1, newDataPod)
      const { _id: groupthemeId } = groupthemeData

      // 2) update classroomId, set grouptheme_building: groupthemeId_building
      const url2 = "/classrooms/updateclassroom/" + classroomId;
      const updateToClassroomId = {
        groupthemeId_building: groupthemeId
      }
      const { data: classroomData } = await http.patch(url2, updateToClassroomId)

      // update grouptheme
      dispatch(createGroupthemeSuccess(groupthemeData))

      // update classroom with grouptheme_building
      dispatch(getClassroomSuccess(classroomData))
    }

    catch (err) {
      console.log(err)
      dispatch(createGroupthemeFailure(err))
    }
  }
}


const getGroupthemeRequest = () => {
  return {
    type: types.GET_GROUPTHEME_REQUEST
  }
}
const getGroupthemeSuccess = grouptheme => {
  return {
    type: types.GET_GROUPTHEME_SUCCESS,
    payload: grouptheme
  }
}
const getGroupthemeFailure = error => {
  return {
    type: types.GET_GROUPTHEME_FAILURE,
    payload: error
  }
}

export const getGrouptheme = (groupthemeId) => {
  const url1 = "/groupthemes/";
  return (dispatch) => {
    dispatch(getGroupthemeRequest())
    http.get(url1 + groupthemeId)
      .then(response => {
        const profile = response.data
        dispatch(getGroupthemeSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getGroupthemeFailure(errorMsg))
      })
  }
}


const updateGroupthemeRequest = () => {
  return {
    type: types.UPDATE_GROUPTHEME_REQUEST
  }
}
const updateGroupthemeSuccess = grouptheme => {
  return {
    type: types.UPDATE_GROUPTHEME_SUCCESS,
    payload: grouptheme
  }
}
const updateGroupthemeFailure = error => {
  return {
    type: types.UPDATE_GROUPTHEME_FAILURE,
    payload: error
  }
}


export const updateGrouptheme = (groupthemeId, dataToAdd) => {
  return async (dispatch) => {

    dispatch(updateGroupthemeRequest())

    try {
      const url = "/groupthemes/updateGrouptheme/" + groupthemeId;
      const { data: updatedGroupthemeData } = await http.patch(url, dataToAdd)
      dispatch(updateGroupthemeSuccess(updatedGroupthemeData))
    }

    catch (error) {
      const errorMsg = error.message
      dispatch(updateGroupthemeFailure(errorMsg))
    }
  }
}


// __ NOT CHECKED __
const deleteGroupthemeRequest = () => {
  return {
    type: types.DELETE_GROUPTHEME_REQUEST
  }
}
const deleteGroupthemeSuccess = updatedClassroom => {
  return {
    type: types.DELETE_GROUPTHEME_SUCCESS,
    payload: updatedClassroom
  }
}
//** */
const deleteGroupthemeAlsoCurrentSuccess = updatedClassroom => {
  return {
    type: types.DELETE_GROUPTHEME_ALSO_CURRENT_SUCCESS,
    payload: updatedClassroom
  }
}
const deleteGroupthemeFailure = error => {
  return {
    type: types.DELETE_GROUPTHEME_FAILURE,
    payload: error
  }
}

export const deleteGrouptheme = (groupthemeIdToDelete, groupthemeCurrent) => {

  // FUTURE ISSUE HERE: How to deal with partial errors? Should erros be logged and some sort of "cleanup" chron jon be build to hunt down and cleanup?

  return async (dispatch) => {
    dispatch(deleteGroupthemeRequest())
    try {

      // 1) getGrouptheme (NO dispatch), this is to get groupIds array to iterate over and delete,  
      const url1 = "/groupthemes/"
      const { data: profile } = await http.get(url1 + groupthemeIdToDelete)
      const { groupIds, classroomId } = profile

      // 2) delete grouptheme
      await http.delete(url1 + groupthemeIdToDelete)

      // 3) removegroupthemefromclassroom
      const { data: updatedClassroom } = await http.patch("/classrooms/removegroupthemefromclassroom/" + classroomId, {
        groupthemeId: groupthemeIdToDelete
      })


      // 4) delete individual gropus
      //console.log("DELETE THE INDIVIUUAL GROUPS!===>>>", groupIds)
      const deleteSingleGroup = async (groupId) => {
        // no need to save value returned from api call
        return await http.delete("/groups/" + groupId)
      }

      const deleteAllGroups = async () => {
        const allDeleteResults = groupIds.map((group) => {
          return deleteSingleGroup(group._id) // Async post
          // .then((groupData) => {
          //   return groupData // Returns the user info.
          // })
        })
        return //Promise.all(allPostRequests)
      }
      await deleteAllGroups(groupIds)



      // EDGE: IF item to delete is also the current theme
      if (groupthemeIdToDelete === groupthemeCurrent) {
        // --- IF CURRENT == ITEM TO DELELTE:
        // 1) update class and set to null
        try {
          await http.patch("/classrooms/updateclassroom/" + classroomId, {
            groupthemeId_current: null
          })
          // DISPATCH 
          dispatch(deleteGroupthemeAlsoCurrentSuccess(updatedClassroom))
          // added
          // groupData = [], 
          // groupthemeData = {}
        }
        catch (err) {
          console.log("LOCAL ERROR", err)
        }
      } else {
        // NORMAL DISPATCH
        dispatch(deleteGroupthemeSuccess(updatedClassroom))
      }

      console.log("updatedClassroom===>", updatedClassroom)

    }
    catch (error) {
      const errorMsg = error.message
      console.log(error)
      dispatch(deleteGroupthemeFailure(errorMsg))
    }
  }
  /* 
  
  
        // ------// 
        // 1) delete grouptheme record
        const url1 = "/groupthemes/" + groupthemeId;
        const resultOfGroupthemeSuccessfullyDeleted = await http.delete(url1)
  
        const { data: { studentIds, teacherIds, _id: classroomDeletedId } } = resultOfGroupthemeSuccessfullyDeleted
  
  
        console.log("0) SAVE TO COMPARE FOR LATER ", classroomDeletedId)
  
        console.log("1) resultOfGroupthemeSuccessfullyDeleted-->", resultOfGroupthemeSuccessfullyDeleted)
        // save classroomId_current here to compare of class deleted to use in part three. IF classroomId_current is also this classroomId then set to null in part three
  
        // rethink //  console.log("2) Array of studentIds to update-->", studentIds)
        // *** Later this must iterate through array of students first and send patch requests to each studennt to remove from array OR incluse code that deletes any entry in the classroomIds array which results in "" or null
  
        // rethink // console.log("3) Array of teacherIds to update-->", teacherIds)
        // *** Later this must iterate through array of students first and send patch requests to each studennt to remove from array OR incluse code that deletes any entry in the classroomIds array which results in "" or null
  
        // *** Update later to check for more than one teacher in teacherIds
        const url2 = "/classrooms/removegroupthemefromclassroom/" + classroomId;
        const deleteDataPod = { groupthemeId: classroomId }
        const { data: "updatedTeacherObject" } = await http.patch(url2, deleteDataPod)
  
  
  
        dispatch(deleteGroupthemeSuccess("updatedTeacherObject")) // reload profile to avoid populating issues
  
  
  
  
   */

}


///-------------- individual group --------------///


const getGroupRequest = () => {
  return {
    type: types.GET_GROUP_REQUEST
  }
}
const getGroupSuccess = teacher => {
  return {
    type: types.GET_GROUP_SUCCESS,
    payload: teacher
  }
}
const getGroupFailure = error => {
  return {
    type: types.GET_GROUP_FAILURE,
    payload: error
  }
}
// returns a function rather than an action
// recieves dispatch method as argument
export const getGroup = (fb_uid) => {
  return (dispatch) => {
    dispatch(getGroupRequest())
    http.get(apiEndpoint + fb_uid)
      .then(response => {
        const profile = response.data
        //       console.log("profile===>", profile)
        dispatch(getGroupSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getGroupFailure(errorMsg))
      })
  }
}

// add point to group //

const addPointToGroupRequest = () => {
  return {
    type: types.ADD_POINT_TO_GROUP_REQUEST
  }
}
const addPointToGroupSuccess = group => {
  return {
    type: types.ADD_POINT_TO_GROUP_SUCCESS,
    payload: group
  }
}
const addPointToGroupFailure = error => {
  return {
    type: types.ADD_POINT_TO_GROUP_FAILURE,
    payload: error
  }
}

export const addPointToGroup = (groupId, points = 1) => {
  return async (dispatch) => {

    dispatch(addPointToGroupRequest())
    const updatedData = {
      points
    }
    try {
      const url = "/groups/addPoint/" + groupId;
      const { data: updatedGroup } = await http.patch(url, updatedData)
      //console.log("updatedGroup===>", updatedGroup)
      dispatch(addPointToGroupSuccess(updatedGroup))
    }
    catch (err) {
      const errorMsg = err.message
      dispatch(addPointToGroupFailure(errorMsg))
    }
  }
}



// -- update group -- //


const updateGroupRequest = () => {
  return {
    type: types.UPDATE_GROUP_REQUEST
  }
}
const updateGroupSuccess = group => {
  return {
    type: types.UPDATE_GROUP_SUCCESS,
    payload: group
  }
}
const updateGroupFailure = error => {
  return {
    type: types.UPDATE_GROUP_FAILURE,
    payload: error
  }
}

export const updateGroup = (groupId, updatedData) => {
  return (dispatch) => {
    dispatch(updateGroupRequest())
    const url = "/groups/update/" + groupId;
    http.patch(url, updatedData)
      .then(response => {
        const newlyUpdatedGroup = response.data
        dispatch(updateGroupSuccess(newlyUpdatedGroup))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(updateGroupFailure(errorMsg))
      })
  }
}



const createGroupRequest = () => {
  return {
    type: types.CREATE_GROUP_REQUEST
  }
}
const createGroupSuccess = group => {
  return {
    type: types.CREATE_GROUP_SUCCESS,
    payload: group
  }
}
const createGroupFailure = error => {
  return {
    type: types.CREATE_GROUP_FAILURE,
    payload: error
  }
}


export const createGroup = (groupthemeId, newGroup) => {

  return async (dispatch) => {
    dispatch(createGroupRequest())

    try {

      const url1 = "/groups/";
      const { data: newGroupData } = await http.post(url1, newGroup)
      const { _id: newGroupId } = newGroupData


      // #2 Add EMPTY group to grouptheme
      const newGroupDataPod = {
        groupId: newGroupId
      }

      const url2 = "/groupthemes/addgrouptogrouptheme/" + groupthemeId
      const { data: updatedGrouptheme } = await http.patch(url2, newGroupDataPod)


      // console.log("🪣 🪣 newGroupData=======>>>", newGroupData)
      // console.log("🪣 🪣 updatedGrouptheme=======>>>", updatedGrouptheme)
      // #1 FIX THE REDUCDER
      // #2 ADVANCE TO ADD STUDENTS!

      dispatch(createGroupSuccess([newGroupData, updatedGrouptheme]))
    }

    catch (err) {
      console.log(err)
      dispatch(createGroupFailure(err))
    }
  }
}



const deleteGroupRequest = () => {
  return {
    type: types.DELETE_GROUP_REQUEST
  }
}
const deleteGroupSuccess = teacher => {
  return {
    type: types.DELETE_GROUP_SUCCESS,
    payload: teacher
  }
}
const deleteGroupFailure = error => {
  return {
    type: types.DELETE_GROUP_FAILURE,
    payload: error
  }
}

export const deleteGroup = (groupIdToDelete) => {

  return async (dispatch) => {
    dispatch(deleteGroupRequest())
    try {

      // 1) delete classroom record
      const url1 = "/groups/" + groupIdToDelete;
      const responseFromGroupDelete = await http.delete(url1)

      const { data: { groupthemeId } } = responseFromGroupDelete

      // groupId_current

      const url2 = "/groupthemes/removegroupfromgrouptheme/" + groupthemeId;

      const updatedGrouptheme = { groupId: groupIdToDelete }

      const { data: updatedGroupthemeObject } = await http.patch(url2, updatedGrouptheme)


      // *** Later when student record show groups, this must iterate through array of students first and send patch requests to each student

      // set flag on deletGroup reducder
      // makesure to get groupthemes on load

      dispatch(deleteGroupSuccess([groupIdToDelete, updatedGroupthemeObject]))
      // sned groupIdTODelete to recent group
      // use updateGroupthemeObect to correctly update state if grouptheme


      // reload profile to avoid populating issues
    }

    catch (err) {
      console.log(err)
      dispatch(deleteGroupFailure(err))
    }
  }
}


///------------ setofgroups : create -----------------///

const createSetOfGroupsRequest = () => {
  return {
    type: types.CREATE_SETOFGROUPS_REQUEST
  }
}
const createSetOfGroupsSuccess = arrayOfGroups => {
  return {
    type: types.CREATE_SETOFGROUPS_SUCCESS,
    payload: arrayOfGroups
  }
}
const createSetOfGroupsFailure = error => {
  return {
    type: types.CREATE_SETOFGROUPS_FAILURE,
    payload: error
  }
}


//createSetOfGroup (and update grouptheme)
export const createSetOfGroups = (dndColumns, teacherId, classroomId, groupthemeIdBuiling, updatedNumberOfGroups) => {

  // Convert dndColumns to array for db
  const updatedGroup = { ...dndColumns }

  // 1) ------ add points and groupthemeId ----
  // ** add teacher/classroom as well?

  //   import pickRandomItem from '../../utils/pickRandomItem'
  // import cssColors from '../../data/colorCSS'
  let colorsLeft = [...cssColors]


  for (const property in updatedGroup) {
    const studentIds = updatedGroup[property].items
    const colorThisRound = pickRandomItem(colorsLeft)

    let addToGroup = {
      points: 0,
      groupthemeId: groupthemeIdBuiling,
      colorOnBoard: colorThisRound,
    }

    // remove name and svgpath property
    delete updatedGroup[property].name
    delete updatedGroup[property].svgPath

    // remove used color
    const index = colorsLeft.indexOf(colorThisRound)
    if (index > -1) { colorsLeft.splice(index, 1) }

    // start over if all colpors used
    if (colorsLeft.length < 1) {
      colorsLeft = [...cssColors]
    }

    //just the id rather than student mini object for db
    const studentIdsStrings = studentIds.map(e => {
      return (e.id)
    })
    // reset property and change from 'items' to 'studentIds'
    updatedGroup[property].studentIds = studentIdsStrings
    delete updatedGroup[property].items

    updatedGroup[property] = { ...updatedGroup[property], ...addToGroup }
  }

  // use groupKeys to iterate over object and send to db
  const groupKeysArray = Object.keys(updatedGroup)


  return async (dispatch) => {
    dispatch(createSetOfGroupsRequest())
    const url1 = "/groups/";

    try {
      const saveSingleGroup = async (key) => {
        const itemToSend = updatedGroup[key]
        const { data: groupResult } = await http.post(url1, itemToSend)
        return groupResult // remove excesses groupID
        //return { studentIds: groupResult.studentIds } // remove excesses groupID
        /*  return {
            // thing1: groupResult.thing1,
            // thing2: groupResult.thing2,
            // thing3: groupResult.thing3 } */
      }

      const saveAllGroups = async (arrayOfKeys) => {
        const allPostRequests = arrayOfKeys.map((singleGroupKey) => {
          return saveSingleGroup(singleGroupKey) // Async post
            .then((groupData) => {
              return groupData // Returns the user info.
            })
        })
        return Promise.all(allPostRequests) // Waiting f
      }

      const allNewGroups = await saveAllGroups(groupKeysArray)
      dispatch(createSetOfGroupsSuccess(allNewGroups))


      // 2) change groupIds to strings & update groupthemeID on db 
      // NEXT: check if any groups have ZERO members and if so delete
      const groupIds = allNewGroups.map(e => e._id)

      // setNumberOfGroups updated here in case groups are eliminated while dragging
      dispatch(updateGrouptheme(groupthemeIdBuiling,
        {
          setNumberOfGroups: updatedNumberOfGroups,
          groupIds
        }))

      // 3 classroom: upadte groupthemeId_current and reset groupthemeId_building to null
      const classroomUpdates = {
        groupthemeId_building: null,
        groupthemeId_current: groupthemeIdBuiling
      }
      dispatch(updateClassroom(classroomId, classroomUpdates))

      // 4 classroom: push grouptheme onto groupthemeIds
      dispatch(addGroupthemeToClassroom(classroomId, groupthemeIdBuiling))

    } catch (err) {
      console.log("err", err)
      dispatch(createSetOfGroupsFailure(err))
    }
  }
}



///------------ setofgroups : get (UNUSED) -----------------///

const getSetOfGroupsRequest = () => {
  return {
    type: types.GET_SETOFGROUPS_REQUEST
  }
}
const getSetOfGroupsSuccess = arrayOfGroups => {
  return {
    type: types.GET_SETOFGROUPS_SUCCESS,
    payload: arrayOfGroups
  }
}
const getSetOfGroupsFailure = error => {
  return {
    type: types.GET_SETOFGROUPS_FAILURE,
    payload: error
  }
}


//getSetOfGroup (and update grouptheme)
export const getSetOfGroups = (dndColumns, teacherId, classroomId, groupthemeId) => {

  /*   // Convert dndColumns to array for db
    const updatedGroup = { ...dndColumns }
  
    // 1) ------ add points and groupthemeId ----
    // ** add teacher/classroom as well?
    let addToGroup = { points: 0, groupthemeId: groupthemeId }
  
    for (const property in updatedGroup) {
      const studentIds = updatedGroup[property].items
  
      // 2) just the id rather than student mini object
      const studentIdsStrings = studentIds.map(e => e.id)
  
      // reset property and change from 'items' to 'studentIds'
      updatedGroup[property].studentIds = studentIdsStrings
      delete updatedGroup[property].items
  
      updatedGroup[property] = { ...updatedGroup[property], ...addToGroup }
    }
  
    // use groupKeys to iterate over object and send to db
    const groupKeysArray = Object.keys(updatedGroup)
   */
  return async (dispatch) => {

    dispatch(getSetOfGroupsRequest())
    // const url1 = "/groups/";

    try {

      console.log("TRYING")
      // const saveSingleGroup = async (key) => {
      //   const itemToSend = updatedGroup[key]
      //   const { data: groupResult } = await http.post(url1, itemToSend)
      //   console.log(groupResult)
      //   return groupResult
      //   /*  return {
      //       // thing1: groupResult.thing1,
      //       // thing2: groupResult.thing2,
      //       // thing3: groupResult.thing3 } */
      // }

      // const saveAllGroups = async (arrayOfKeys) => {
      //   const allPostRequests = arrayOfKeys.map((singleGroupKey) => {
      //     return saveSingleGroup(singleGroupKey) // Async post
      //       .then((groupData) => {
      //         return groupData // Returns the user info.
      //       })
      //   })
      //   return Promise.all(allPostRequests) // Waiting f
      // }

      // const allNewGroups = await saveAllGroups(groupKeysArray)
      // dispatch(getSetOfGroupsSuccess(allNewGroups))


      // // 2) change grouupIds to strings & update groupthemeID on db 
      // const groupIds = allNewGroups.map(e => e._id)
      // dispatch(updateGrouptheme(groupthemeId, { groupIds }))

      // // 3 classroom: upadte groupthemeId_current and groupthemeId_buildlinng
      // const classroomUpdates = {
      //   groupthemeId_building: null,
      //   groupthemeId_current: groupthemeId
      // }
      // dispatch(updateClassroom(classroomId, classroomUpdates))

      // // 4 classroom: push grouptheme onto groupthemeIds
      // dispatch(addGroupthemeToClassroom(classroomId, groupthemeId))

    } catch (err) {
      console.log("err", err)
      // dispatch(getSetOfGroupsFailure(err))
    }
  }
}



/// ######  REMOVE STUDENT FROM CLASSROOM ####### ///
// (And classroom from student if just removing. If classroom is deleted, this is handled by studentAction "removeClassroomFromStudent")

const removeStudentFromClassroomRequest = () => {
  return {
    type: types.REMOVE_STUDENT_FROM_CLASSROOM_REQUEST
  }
}
const removeStudentFromClassroomSuccess = classroom => {
  return {
    type: types.REMOVE_STUDENT_FROM_CLASSROOM_SUCCESS,
    payload: classroom
  }
}
const removeStudentFromClassroomFailure = error => {
  return {
    type: types.REMOVE_STUDENT_FROM_CLASSROOM_FAILURE,
    payload: error
  }
}

// FIX:  student from classroom ok; classroom from student only half working; student is remoed from the array but the check to see if this is current student is failing
export const removeStudentFromClassroom = (studentToRemove, classroomId) => {
  return async (dispatch) => {

    dispatch(removeStudentFromClassroomRequest())
    try {

      // #1 - remove student from classroom
      const url1 = "/classrooms/removestudentfromclassroom/" + classroomId;

      const updateToClassroomRecord = { studentId: studentToRemove._id }
      const { data: updatedClassroomData } = await http.patch(url1, updateToClassroomRecord)


      // -- Oct 8th -- temp comment out 947 to 960
      // -- instead, call removeStudentFromClassroom at #116 in StudentsMainList
    

      // #2a - remove classroom from student
      // const url2 = "/students/removeclassroomfromstudent/" + studentToRemove._id;
      // const classroomIdToRemove = { classroomId: classroomId }
      // const { data: updatedStudentData } = await http.patch(url2, classroomIdToRemove)

      // console.log("Plan OUTER 📺📺🅱️📺📺")
      // // #2b - check classroomId_current of student is same as classroomId, then
      // if (updatedStudentData.classroomId_current === classroomId) {
      //   console.log("Plan INNER 🅱️🅱️🅱️-- change to removeclassroomfromstudentarry AND THEN set classroomId_current to NULL as we see below on another updatestudentprofile api call")
        
      //   const url3 = "/students/updatestudentprofile/" + studentToRemove._id;
      //   const { data: updatedStudentData } = await http.patch(url3, { classroomId_current: null })
      // }

      dispatch(removeStudentFromClassroomSuccess(updatedClassroomData))
    }

    catch (err) {
      console.log(err)
      dispatch(removeStudentFromClassroomFailure(err.message))
    }
  }
}



// ### REMOVE STUDENT FROM GROUP ### ///

const removeStudentFromGroupRequest = () => {
  return {
    type: types.REMOVE_STUDENT_FROM_GROUP_REQUEST
  }
}
const removeStudentFromGroupSuccess = updatedGroup => {
  return {
    type: types.REMOVE_STUDENT_FROM_GROUP_SUCCESS,
    payload: updatedGroup
  }
}
const removeStudentFromGroupFailure = error => {
  return {
    type: types.REMOVE_STUDENT_FROM_GROUP_FAILURE,
    payload: error
  }
}

// NEXT!
export const removeStudentFromGroup = (studentId, groupId) => {

  return async (dispatch) => {
    dispatch(removeStudentFromGroupRequest())
    try {

      const url1 = "/groups/removestudentfromgroup/" + groupId;
      const studentIdToSend = { studentId: studentId }
      const { data: updatedGroupData } = await http.patch(url1, studentIdToSend)
      //console.log("updatedGroupData ==>>>>", updatedGroupData)
      dispatch(removeStudentFromGroupSuccess(updatedGroupData))
    }

    catch (err) {
      console.log(err)
      dispatch(removeStudentFromGroupFailure(err.message))
    }
  }
}




// ### ADD STUDENT FROM GROUP ### ///

const addStudentToGroupRequest = () => {
  return {
    type: types.ADD_STUDENT_TO_GROUP_REQUEST
  }
}
const addStudentToGroupSuccess = student => {
  return {
    type: types.ADD_STUDENT_TO_GROUP_SUCCESS,
    payload: student
  }
}
const addStudentToGroupFailure = error => {
  return {
    type: types.ADD_STUDENT_TO_GROUP_FAILURE,
    payload: error
  }
}


export const addStudentToGroup = (studentId, groupId) => {

  return async (dispatch) => {
    dispatch(addStudentToGroupRequest())
    try {
      const url1 = "/groups/addstudenttogroup/" + groupId;
      const studentIdToSend = { studentId: studentId }
      const { data: updatedGroupData } = await http.patch(url1, studentIdToSend)
      dispatch(addStudentToGroupSuccess(updatedGroupData))
    }

    catch (err) {
      console.log(err)
      dispatch(addStudentToGroupFailure(err.message))
    }
  }
}


// ############## TRANSACTIONS ############## //

// -------- create ------- //

const createTransactionRequest = () => {
  return {
    type: types.CREATE_TRANSACTION_REQUEST
  }
}
const createTransactionSuccess = transaction => {
  return {
    type: types.CREATE_TRANSACTION_SUCCESS,
    payload: transaction
  }
}
const createTransactionFailure = error => {
  return {
    type: types.CREATE_TRANSACTION_FAILURE,
    payload: error
  }
}

export const createTransaction = (transferObject) => {

  return async (dispatch) => {
    dispatch(createTransactionRequest())
    try {
      const url = "/transactions";
      // const studentIdToSend = { studentId: studentId }
      const { data: transactionData } = await http.post(url, transferObject)
      dispatch(createTransactionSuccess(transactionData))
    }

    catch (err) {
      console.log(err)
      dispatch(createTransactionFailure(err))
    }
  }
}


// -------- get ------- //

const getTransactionRequest = () => {
  return {
    type: types.GET_TRANSACTION_REQUEST
  }
}
const getTransactionSuccess = transaction => {
  return {
    type: types.GET_TRANSACTION_SUCCESS,
    payload: transaction
  }
}
const getTransactionFailure = error => {
  return {
    type: types.GET_TRANSACTION_FAILURE,
    payload: error
  }
}

export const getTransaction = (transferObject) => {

  return async (dispatch) => {
    dispatch(getTransactionRequest())
    try {
      const url = "/transactions";
      // const studentIdToSend = { studentId: studentId }
      const { data: transactionData } = await http.post(url, transferObject)
      dispatch(getTransactionSuccess(transactionData))
    }

    catch (err) {
      console.log(err)
      dispatch(getTransactionFailure(err))
    }
  }
}


// -------- update ------- //

const updateTransactionRequest = () => {
  return {
    type: types.UPDATE_TRANSACTION_REQUEST
  }
}
const updateTransactionSuccess = transaction => {
  return {
    type: types.UPDATE_TRANSACTION_SUCCESS,
    payload: transaction
  }
}
const updateTransactionFailure = error => {
  return {
    type: types.UPDATE_TRANSACTION_FAILURE,
    payload: error
  }
}

export const updateTransaction = (transferObject) => {

  return async (dispatch) => {
    dispatch(updateTransactionRequest())
    try {
      const url = "/transactions";
      // const studentIdToSend = { studentId: studentId }
      const { data: transactionData } = await http.post(url, transferObject)
      dispatch(updateTransactionSuccess(transactionData))
    }

    catch (err) {
      console.log(err)
      dispatch(updateTransactionFailure(err))
    }
  }
}


// -------- delete ------- //

const deleteTransactionRequest = () => {
  return {
    type: types.DELETE_TRANSACTION_REQUEST
  }
}
const deleteTransactionSuccess = transaction => {
  return {
    type: types.DELETE_TRANSACTION_SUCCESS,
    payload: transaction
  }
}
const deleteTransactionFailure = error => {
  return {
    type: types.DELETE_TRANSACTION_FAILURE,
    payload: error
  }
}

export const deleteTransaction = (transactionId) => {

  return async (dispatch) => {
    dispatch(deleteTransactionRequest())
    try {
      const url = "/transactions/" + transactionId;
      const { data: deletedTransaction } = await http.delete(url)
      dispatch(deleteTransactionSuccess(deletedTransaction))
    }

    catch (err) {
      console.log(err)
      dispatch(deleteTransactionFailure(err))
    }
  }
}


// -------- get all ------- //

const getAllTransactionsRequest = () => {
  return {
    type: types.GET_ALL_TRANSACTIONS_REQUEST
  }
}
const getAllTransactionsSuccess = transactions => {
  return {
    type: types.GET_ALL_TRANSACTIONS_SUCCESS,
    payload: transactions
  }
}
const getAllTransactionsFailure = error => {
  return {
    type: types.GET_ALL_TRANSACTIONS_FAILURE,
    payload: error
  }
}


export const getAllTransactions = (senderClassroomId) => {

  return async (dispatch) => {
    dispatch(getAllTransactionsRequest())
    try {
      const url = "/transactions?senderClassroomId=" + senderClassroomId;

      const { data: allClassroomTransactions } = await http.get(url)
      dispatch(getAllTransactionsSuccess(allClassroomTransactions))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllTransactionsFailure(err))
    }
  }
}




// ############## COMMON GOODS ############## //


// -------- get ------- //


const getCommonGoodRequest = () => {
  return {
    type: types.GET_COMMON_GOOD_REQUEST
  }
}
const getCommonGoodSuccess = trans => {
  return {
    type: types.GET_COMMON_GOOD_SUCCESS,
    payload: trans
  }
}
const getCommonGoodFailure = error => {
  return {
    type: types.GET_COMMON_GOOD_FAILURE,
    payload: error
  }
}


export const getCommonGood = (commonGoodId) => {

  return async (dispatch) => {
    dispatch(getCommonGoodRequest())
    try {
      const url = "/commongood/" + commonGoodId;

      const { data: cg } = await http.get(url)
      dispatch(getCommonGoodSuccess(cg))
    }

    catch (err) {
      console.log(err)
      dispatch(getCommonGoodFailure(err))
    }
  }
}


// -------- create ------- //


const createCommonGoodRequest = () => {
  return {
    type: types.CREATE_COMMON_GOOD_REQUEST
  }
}
const createCommonGoodSuccess = grouptheme => {
  return {
    type: types.CREATE_COMMON_GOOD_SUCCESS,
    payload: grouptheme
  }
}
const createCommonGoodFailure = error => {
  return {
    type: types.CREATE_COMMON_GOOD_FAILURE,
    payload: error
  }
}

export const createCommonGood = (commonGoodData) => {
  console.log("commonGoodData:", commonGoodData)

  return async (dispatch) => {
    dispatch(createCommonGoodRequest())
    try {
      const url = "/commongoods/";
      const { data: newCommonGoodsData } = await http.post(url, commonGoodData)

      // Form data for the reducer

      dispatch(createCommonGoodSuccess(newCommonGoodsData))
    }

    catch (err) {
      console.log(err)
      dispatch(createCommonGoodFailure(err))
    }
  }
}


// -------- update ------- //


const updateCommonGoodRequest = () => {
  return {
    type: types.UPDATE_COMMON_GOOD_REQUEST
  }
}
const updateCommonGoodSuccess = commongood => {
  return {
    type: types.UPDATE_COMMON_GOOD_SUCCESS,
    payload: commongood
  }
}
const updateCommonGoodFailure = error => {
  return {
    type: types.UPDATE_COMMON_GOOD_FAILURE,
    payload: error
  }
}

export const updateCommonGood = (commonGoodId, updatedData) => {

  return async (dispatch) => {
    dispatch(updateCommonGoodRequest())
    try {

      const url1 = "/commongoods/update/" + commonGoodId;
      const { data: commonGoodsData } = await http.patch(url1, updatedData)
      const { updatedCommonGood } = commonGoodsData

      dispatch(updateCommonGoodSuccess(updatedCommonGood))

    }

    catch (err) {
      console.log(err)
      dispatch(updateCommonGoodFailure(err))
    }
  }
}


// -------- delete  ------- //


const deleteCommonGoodRequest = () => {
  return {
    type: types.DELETE_COMMON_GOOD_REQUEST
  }
}
const deleteCommonGoodSuccess = commongood => {
  return {
    type: types.DELETE_COMMON_GOOD_SUCCESS,
    payload: commongood
  }
}
const deleteCommonGoodFailure = error => {
  return {
    type: types.DELETE_COMMON_GOOD_FAILURE,
    payload: error
  }
}

export const deleteCommonGood = (commonGoodId) => {

  return async (dispatch) => {
    dispatch(deleteCommonGoodRequest())
    try {
      const url = "/commongoods/" + commonGoodId;
      const { data: commonGoodsData } = await http.delete(url)

      dispatch(deleteCommonGoodSuccess(commonGoodsData))
    }

    catch (err) {
      console.log(err)
      dispatch(deleteCommonGoodFailure(err))
    }
  }
}



///------------ get all ---------------///


const getAllCommonGoodsRequest = () => {
  return {
    type: types.GET_ALL_COMMON_GOODS_REQUEST
  }
}
const getAllCommonGoodsSuccess = cg => {
  return {
    type: types.GET_ALL_COMMON_GOODS_SUCCESS,
    payload: cg
  }
}
const getAllCommonGoodsFailure = error => {
  return {
    type: types.GET_ALL_COMMON_GOODS_FAILURE,
    payload: error
  }
}


export const getAllCommonGoods = (senderClassroomId) => {

  return async (dispatch) => {
    dispatch(getAllCommonGoodsRequest())
    try {
      const url = "/commongoods?classroomId=" + senderClassroomId;

      const { data: allCommonGoods } = await http.get(url)
      dispatch(getAllCommonGoodsSuccess(allCommonGoods))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllCommonGoodsFailure(err))
    }
  }
}



// ############   STUDENT DONATION   ################### //


const processStudentDonationRequest = () => {
  return {
    type: types.PROCESS_STUDENT_DONATION_REQUEST
  }
}
const processStudentDonationSuccess = (commonGood) => {
  return {
    type: types.PROCESS_STUDENT_DONATION_SUCCESS,
    payload: commonGood
  }
}
const processStudentDonationFailure = (error) => {
  console.log("ERROR", error)
  return {
    type: types.PROCESS_STUDENT_DONATION_FAILURE,
    payload: error
  }
}

export const processStudentDonation = ({ studentId, amount, commonGoodId }) => {

  return async (dispatch) => {
    dispatch(processStudentDonationRequest());

    try {
      // 1a) Check donation
      const url = "/commongoods/processdonation/" + commonGoodId + "/" + amount
      const { data: updatedCommonGood } = await http.patch(url, { studentId })

      if (updatedCommonGood.currentDonatedAmount >= updatedCommonGood.targetAmount) {
        console.log("$$$$$$$$$$$ HURRAYY $$$$$$$$$$$")
        updatedCommonGood.youDidIt = true
      }

      console.log("updatedCommonGood==>", updatedCommonGood)

      // 2) subtract tokens from contributor
      const url2 = "/students/increasestudentcoins/" + studentId;
      const updatedStudentobject = await http.patch(url2, {
        coins: -amount
      })

      // TODO: What do we do to update student state?
      // originally, this was in 
      // adjustStudentCoinsByDonationRequest

      dispatch(processStudentDonationSuccess(updatedCommonGood));
    }

    catch (err) {
      const { response } = err
      dispatch(processStudentDonationFailure(response));
    }
  }
}


// ######## PRIZES ######### //


// -------- get ------- //


const getPrizeRequest = () => {
  return {
    type: types.GET_PRIZE_REQUEST
  }
}
const getPrizeSuccess = pr => {
  return {
    type: types.GET_PRIZE_SUCCESS,
    payload: pr
  }
}
const getPrizeFailure = error => {
  return {
    type: types.GET_PRIZE_FAILURE,
    payload: error
  }
}


export const getPrize = (prizeId) => {

  return async (dispatch) => {
    dispatch(getPrizeRequest())
    try {
      const url = "/prizes/" + prizeId;

      const { data: pr } = await http.get(url)
      dispatch(getPrizeSuccess(pr))
    }

    catch (err) {
      console.log(err)
      dispatch(getPrizeFailure(err))
    }
  }
}


// -------- create ------- //


const createPrizeRequest = () => {
  return {
    type: types.CREATE_PRIZE_REQUEST
  }
}
const createPrizeSuccess = prize => {
  return {
    type: types.CREATE_PRIZE_SUCCESS,
    payload: prize
  }
}
const createPrizeFailure = error => {
  return {
    type: types.CREATE_PRIZE_FAILURE,
    payload: error
  }
}

export const createPrize = (prizeData) => {
  console.log("prizeData===>", prizeData)

  return async (dispatch) => {
    dispatch(createPrizeRequest())
    try {
      const url = "/prizes/";
      const { data: newPrizesData } = await http.post(url, prizeData)

      // Form data for the reducer

      dispatch(createPrizeSuccess(newPrizesData))
    }

    catch (err) {
      console.log(err)
      dispatch(createPrizeFailure(err))
    }
  }
}


// -------- update ------- //


const updatePrizeRequest = () => {
  return {
    type: types.UPDATE_PRIZE_REQUEST
  }
}
const updatePrizeSuccess = prize => {
  return {
    type: types.UPDATE_PRIZE_SUCCESS,
    payload: prize
  }
}
const updatePrizeFailure = error => {
  return {
    type: types.UPDATE_PRIZE_FAILURE,
    payload: error
  }
}

export const updatePrize = (prizeId, updatedPrizeData) => {

  return async (dispatch) => {
    dispatch(updatePrizeRequest())

    try {
      const url1 = "/prizes/update/" + prizeId;
      const { data: prizeData } = await http.patch(url1, updatedPrizeData)

      // console.log("prizeData====>>>", prizeData)
      //const { updatedPrize } = prizeData
      dispatch(updatePrizeSuccess(prizeData))
    }

    catch (err) {
      console.log(err)
      dispatch(updatePrizeFailure(err))
    }
  }
}


// -------- delete  ------- //


const deletePrizeRequest = () => {
  return {
    type: types.DELETE_PRIZE_REQUEST
  }
}
const deletePrizeSuccess = prize => {
  return {
    type: types.DELETE_PRIZE_SUCCESS,
    payload: prize
  }
}
const deletePrizeFailure = error => {
  return {
    type: types.DELETE_PRIZE_FAILURE,
    payload: error
  }
}

export const deletePrize = (prizeId) => {

  return async (dispatch) => {
    dispatch(deletePrizeRequest())
    try {
      const url = "/prizes/" + prizeId;
      const { data: prizeData } = await http.delete(url)

      dispatch(deletePrizeSuccess(prizeData))
    }

    catch (err) {
      console.log(err)
      dispatch(deletePrizeFailure(err))
    }
  }
}



///------------ get all ---------------///


const getAllPrizesRequest = () => {
  return {
    type: types.GET_ALL_PRIZES_REQUEST
  }
}
const getAllPrizesSuccess = pr => {
  return {
    type: types.GET_ALL_PRIZES_SUCCESS,
    payload: pr
  }
}
const getAllPrizesFailure = error => {
  return {
    type: types.GET_ALL_PRIZES_FAILURE,
    payload: error
  }
}


export const getAllPrizes = (senderClassroomId) => {

  return async (dispatch) => {
    dispatch(getAllPrizesRequest())
    try {
      const url = "/prizes?classroomId=" + senderClassroomId;

      const { data: allPrizes } = await http.get(url)
      dispatch(getAllPrizesSuccess(allPrizes))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllPrizesFailure(err))
    }
  }
}



// -------- special update classroom, update raffle------- //


const updateRaffleRequest = () => {
  return {
    type: types.UPDATE_RAFFLE_REQUEST
  }
}
const updateRaffleSuccess = raffle => {
  return {
    type: types.UPDATE_RAFFLE_SUCCESS,
    payload: raffle
  }
}
const updateRaffleFailure = error => {
  return {
    type: types.UPDATE_RAFFLE_FAILURE,
    payload: error
  }
}

export const updateRaffle = (classroomId, updatedData) => {

  return async (dispatch) => {
    dispatch(updateRaffleRequest())
    try {

      const url1 = "/classrooms/updateclassroom/" + classroomId;
      const { data: updatedRaffleData } = await http.patch(url1, updatedData)

      dispatch(updateRaffleSuccess(updatedRaffleData.raffle))

    }

    catch (err) {
      console.log(err)
      dispatch(updateRaffleFailure(err))
    }
  }
}




// ############  STUDENT RAFFLE PURCHASE  ############### //


const studentRafflePurchaseRequest = () => {
  return {
    type: types.STUDENT_RAFFLE_PURCHASE_REQUEST
  }
}
const studentRafflePurchaseSuccess = (raffleTicket) => {
  return {
    type: types.STUDENT_RAFFLE_PURCHASE_SUCCESS,
    payload: raffleTicket
  }
}
const studentRafflePurchaseFailure = (error) => {
  return {
    type: types.STUDENT_RAFFLE_PURCHASE_FAILURE,
    payload: error
  }
}


export const studentRafflePurchase = ({ student, classroomId, amountOfTicketsToBuy, totalCost, raffleTicketIds = [] }) => {


  const { firstName, lastName, displayName, _id: studentId } = student

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  return async (dispatch) => {
    dispatch(studentRafflePurchaseRequest());

    // 1) generate raffle tickets 
    const arrayOfTickets = []
    const newTicketNumber = () => {
      return getRandomInt(10, 30) + "-" + generatePassword(5)
    }
    for (let i = 0; i < amountOfTicketsToBuy; i++) {
      arrayOfTickets.push(
        {
          id: newTicketNumber(),
          studentId,
          studentName: [firstName, lastName, displayName],
          classroomId: classroomId,
          ticketPurchasedDate: moment().format('MMMM Do YYYY, h:mm:ss a'),
          prizeId: null,
          prizeTitle: null,
          prizeDescription: null,
          prizeImage: { "svgFilename": null, "gif": null },
          prizeDate: null,
          archived: false
        })
    }

    try {

      const url = "/classrooms/addmultipleraffletickets/" + classroomId;
      const addNewTickets = http.patch(url, { arrayOfTickets })


      console.log("amountOfTicketsToBuy", amountOfTicketsToBuy)
      console.log("totalCost", totalCost)

      // 3) else reduce student balance (this will not be reflected in state unless getStudentBalance(?) is called)
      // const url1 = "/students/increasestudentcoins/" + studentId;
      // const { data: updatedStudentObjectWhichWillGoWhere } = await http.patch(url1, { coins: -totalCost })

      // 4) add raffle tickets
      // update classrooom with array of new tickets

      // orig
      // const { data: updatedClassroom } = await http.patch(url, { arrayOfTickets })





      const urlAdjustStudentCoins = "/students/increasestudentcoins/" + studentId;
      const adjustStudentBalance = http.patch(urlAdjustStudentCoins, { coins: -totalCost })


      // replace addNewTickets2 with reduceStudentBalance

      http.all([addNewTickets, adjustStudentBalance]).then(http.spread((...allData) => {

        const { data: updatedClassroom } = allData[0]
        console.log("updatedClassroom", updatedClassroom)
        dispatch(studentRafflePurchaseSuccess(updatedClassroom));


        const { data: updatedStudentBalance } = allData[1]
        console.log("updatedStudentBalance", updatedStudentBalance)
        // since it can not be set to student from classroom, getStudentCoins must be called at /raffle

      }))
    }

    catch (err) {
      const { response } = err
      dispatch(studentRafflePurchaseFailure(response));
    }
  }
}




// ####### PHYSICAL GOODS ######## //

const getSchoolwidePhysicalGoodClassroomRequest = () => {
  return {
    type: types.GET_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST
  }
}
const getSchoolwidePhysicalGoodClassroomSuccess = pg => {
  return {
    type: types.GET_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS,
    payload: pg
  }
}
const getSchoolwidePhysicalGoodClassroomFailure = error => {
  return {
    type: types.GET_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE,
    payload: error
  }
}


export const getSchoolwidePhysicalGoodClassroom = (physicalgood) => {

  return async (dispatch) => {
    dispatch(getSchoolwidePhysicalGoodClassroomRequest())
    try {
      const url = "/pg/" + physicalgood;

      const { data: physicalGoodData } = await http.get(url)
      dispatch(getSchoolwidePhysicalGoodClassroomSuccess(physicalGoodData))
    }

    catch (err) {
      console.log(err)
      dispatch(getSchoolwidePhysicalGoodClassroomFailure(err))
    }
  }
}


// -------- create ------- //


const createSchoolwidePhysicalGoodClassroomRequest = () => {
  return {
    type: types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST
  }
}
const createSchoolwidePhysicalGoodClassroomSuccess = pg => {
  return {
    type: types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS,
    payload: pg
  }
}
const createSchoolwidePhysicalGoodClassroomFailure = error => {
  return {
    type: types.CREATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE,
    payload: error
  }
}

export const createSchoolwidePhysicalGoodClassroom = (physicalGoodSchoolwideData) => {

  console.log("physicalGoodSchoolwideData===>", physicalGoodSchoolwideData)
  return async (dispatch) => {
    dispatch(createSchoolwidePhysicalGoodClassroomRequest())
    try {
      const url = "/pg/";
      const { data: newSchoolwidePhysicalGoodClassroomsData } = await http.post(url, physicalGoodSchoolwideData)

      dispatch(createSchoolwidePhysicalGoodClassroomSuccess(newSchoolwidePhysicalGoodClassroomsData))
    }

    catch (err) {
      console.log(err)
      dispatch(createSchoolwidePhysicalGoodClassroomFailure(err))
    }
  }
}


// -------- update ------- //


const updateSchoolwidePhysicalGoodClassroomRequest = () => {
  return {
    type: types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST
  }
}
const updateSchoolwidePhysicalGoodClassroomSuccess = pg => {
  return {
    type: types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS,
    payload: pg
  }
}
const updateSchoolwidePhysicalGoodClassroomFailure = error => {
  return {
    type: types.UPDATE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE,
    payload: error
  }
}

export const updateSchoolwidePhysicalGoodClassroom = (physicalGoodId, updatedData) => {

  return async (dispatch) => {
    dispatch(updateSchoolwidePhysicalGoodClassroomRequest())

    try {
      const url1 = "/pg/update/" + physicalGoodId;

      console.log("physicalGoodId", "updatedData!!!!")
      console.log(physicalGoodId, updatedData)

      const { data: physicalGoodClassroomData } = await http.patch(url1, updatedData)

      console.log("physicalGoodClassroomData====>>>", physicalGoodClassroomData)

      dispatch(updateSchoolwidePhysicalGoodClassroomSuccess(physicalGoodClassroomData))
    }

    catch (err) {
      console.log(err)
      dispatch(updateSchoolwidePhysicalGoodClassroomFailure(err))
    }
  }
}



// -------- purchase (special update) ------- //


const purchaseSchoolwidePhysicalGoodClassroomRequest = () => {
  return {
    type: types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST
  }
}
const purchaseSchoolwidePhysicalGoodClassroomSuccess = pg => {
  return {
    type: types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS,
    payload: pg
  }
}
const purchaseSchoolwidePhysicalGoodClassroomFailure = error => {
  return {
    type: types.PURCHASE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE,
    payload: error
  }
}

export const purchaseSchoolwidePhysicalGoodClassroom = (physicalGoodId, updatedData) => {


  return async (dispatch) => {
    dispatch(purchaseSchoolwidePhysicalGoodClassroomRequest())

    try {
      const url1 = "/physicalgoods/purchase/" + physicalGoodId;

      console.log("physicalGoodId", "updatedData")
      console.log(physicalGoodId, updatedData)

      const { data: physicalGoodClassroomData } = await http.patch(url1, updatedData)

      console.log("physicalGoodClassroomData====>>>", physicalGoodClassroomData)

      dispatch(purchaseSchoolwidePhysicalGoodClassroomSuccess(physicalGoodClassroomData))
    }

    catch (err) {
      console.log(err)
      dispatch(purchaseSchoolwidePhysicalGoodClassroomFailure(err))
    }
  }
}



// -------- delete  ------- //


const deleteSchoolwidePhysicalGoodClassroomRequest = () => {
  return {
    type: types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_REQUEST
  }
}
const deleteSchoolwidePhysicalGoodClassroomSuccess = physicalgood => {
  return {
    type: types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_SUCCESS,
    payload: physicalgood
  }
}
const deleteSchoolwidePhysicalGoodClassroomFailure = error => {
  return {
    type: types.DELETE_SW_PHYSICAL_GOOD_CLASSROOM_FAILURE,
    payload: error
  }
}

export const deleteSchoolwidePhysicalGoodClassroom = (prizeId) => {

  return async (dispatch) => {
    dispatch(deleteSchoolwidePhysicalGoodClassroomRequest())
    try {
      const url = "/physicalgoods/" + prizeId;
      const { data: physicalGoodTeacherData } = await http.delete(url)

      dispatch(deleteSchoolwidePhysicalGoodClassroomSuccess(physicalGoodTeacherData))
    }

    catch (err) {
      console.log(err)
      dispatch(deleteSchoolwidePhysicalGoodClassroomFailure(err))
    }
  }
}



///------------ get all physicalGoods ---------------///

const getAllSchoolwidePhysicalGoodsClassroomRequest = () => {
  return {
    type: types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_REQUEST
  }
}
const getAllSchoolwidePhysicalGoodsClassroomSuccess = pg => {
  return {
    type: types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_SUCCESS,
    payload: pg
  }
}
const getAllSchoolwidePhysicalGoodsClassroomFailure = error => {
  return {
    type: types.GET_ALL_SW_PHYSICAL_GOODS_CLASSROOM_FAILURE,
    payload: error
  }
}


export const getAllSchoolwidePhysicalGoodsClassroom = (schoolId) => {

  return async (dispatch) => {
    dispatch(getAllSchoolwidePhysicalGoodsClassroomRequest())
    try {
      const url = "/physicalgoods?schoolId=" + schoolId;

      const { data: allSchoolwidePhysicalGoods } = await http.get(url)

      dispatch(getAllSchoolwidePhysicalGoodsClassroomSuccess(allSchoolwidePhysicalGoods))
    }

    catch (err) {
      console.log(err)
      dispatch(getAllSchoolwidePhysicalGoodsClassroomFailure(err))
    }
  }
}


///---------- GetStudentGroupDetail from grouptheme ----------///


const getStudentGroupDetailsRequest = () => {
  return {
    type: types.GET_STUDENT_GROUP_DETAILS_REQUEST
  }
}
const getStudentGroupDetailsSuccess = group => {
  return {
    type: types.GET_STUDENT_GROUP_DETAILS_SUCCESS,
    payload: group
  }
}
const getStudentGroupDetailsFailure = error => {
  return {
    type: types.GET_STUDENT_GROUP_DETAILS_FAILURE,
    payload: error
  }
}


export const getStudentGroupDetails = (groupthemeId, studentId) => {
  return (dispatch) => {
    dispatch(getStudentGroupDetailsRequest())

    const url = "/groupthemes/studentgroupdetails?groupthemeId=" + groupthemeId + "&studentId=" + studentId;

    http.get(url)
      .then(response => {
        const profile = response.data

        dispatch(getStudentGroupDetailsSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getStudentGroupDetailsFailure(errorMsg))
      })
  }
}
