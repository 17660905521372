import firebase from '../../config/fbConfig'
import * as types from './teacherTypes'
import http from '../../services/httpService'
const apiEndpoint = "/teachers/"


export const clearTeacher = () => {
  return {
    type: types.CLEAR_TEACHER
  }
}

export const clearTeacherRouting = () => {
  return {
    type: types.CLEAR_TEACHER_ROUTING
  }
}


const getTeacherProfileRequest = () => {
  return {
    type: types.GET_TEACHER_PROFILE_REQUEST
  }
}
const getTeacherProfileSuccess = teacher => {
  return {
    type: types.GET_TEACHER_PROFILE_SUCCESS,
    payload: teacher
  }
}
const getTeacherProfileFailure = error => {
  return {
    type: types.GET_TEACHER_PROFILE_FAILURE,
    payload: error
  }
}
// returns a function rather than an action
// recieves dispatch method as argument
export const getTeacherProfile = (fb_uid) => {
  return (dispatch) => {
    dispatch(getTeacherProfileRequest())
    http.get(apiEndpoint + fb_uid)
      .then(response => {
        const profile = response.data
        dispatch(getTeacherProfileSuccess(profile))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getTeacherProfileFailure(errorMsg))
      })
  }
}



const updateTeacherProfileRequest = () => {
  return {
    type: types.UPDATE_TEACHER_PROFILE_REQUEST
  }
}
const updateTeacherProfileSuccess = teacher => {
  return {
    type: types.UPDATE_TEACHER_PROFILE_SUCCESS,
    payload: teacher
  }
}
const updateTeacherProfileFailure = error => {
  return {
    type: types.UPDATE_TEACHER_PROFILE_FAILURE,
    payload: error
  }
}

export const updateTeacherProfile = (_id, updatedData) => {
  return async (dispatch) => {
    dispatch(updateTeacherProfileRequest())
    try {

      if (updatedData.email) {

        // 1a) firebase - email
        const user = firebase.auth().currentUser;
        await user.updateEmail(updatedData.email)

        // 1b) firebase - displayName
        await user.updateProfile({ displayName: updatedData.displayName })
      }

      // 2) Mongo
      const url = "/teachers/updateTeacherProfile/" + _id;
      const { data: updatedProfile } = await http.patch(url, updatedData)

      dispatch(updateTeacherProfileSuccess(updatedProfile))
    }

    catch (error) {
      const errorMsg = error.message
      dispatch(updateTeacherProfileFailure(errorMsg))
    }
  }
}



const createNewClassRequest = () => {
  return {
    type: types.CREATE_NEW_CLASS_REQUEST
  }
}
const createNewClassSuccess = teacher => {
  return {
    type: types.CREATE_NEW_CLASS_SUCCESS,
    payload: teacher
  }
}
const createNewClassFailure = error => {
  return {
    type: types.CREATE_NEW_CLASS_FAILURE,
    payload: error
  }
}

export const createNewClass = (teacherId, newData) => {

  return async (dispatch) => {
    dispatch(createNewClassRequest())

    try {
      // add teacher ID as single item in array to newClassroomData
      const classroomDataWithTeacherId = { ...newData, ...{ teacherIds: [teacherId] } }

      // 1) create new class 
      const url1 = "/classrooms/";
      const { data } = await http.post(url1, classroomDataWithTeacherId)
      const { _id: newClassroomId } = data

      // 2) update array of teacher classes and set classroomId_current
      const url2 = "/teachers/createnewclassroom/" + teacherId;
      const newClassroomData = {
        classroomId_current: newClassroomId
      }

      const { data: updatedTeacherRecord } = await http.patch(url2, newClassroomData)
      // WHY NULL?

      // But where the the classromIds updated???
      // THIS NEEDS TO BE POPULATED WITH
      // , _id, name, description, joinCode
      // What we are getting is: teacherIds, groupthemes, etc...

      console.log("updatedTeacherRecord 😡😡😡😡😡😡 ====>>>", updatedTeacherRecord)
      // 3) Call getClassrooom 
      // where is the redux.classroom getting new classrom Data?


      dispatch(createNewClassSuccess(updatedTeacherRecord))
    }

    catch (err) {
      console.log(err)
      dispatch(createNewClassFailure(err))
    }


  }
}





// const updateClassroomRequest = () => {
//   return {
//     type: types.UPDATE_CLASSROOM_REQUEST
//   }
// }
// const updateClassroomSuccess = school => {
//   return {
//     type: types.UPDATE_CLASSROOM_SUCCESS,
//     payload: school
//   }
// }
// const updateClassroomFailure = error => {
//   return {
//     type: types.UPDATE_CLASSROOM_FAILURE,
//     payload: error
//   }
// }

// // to do: adding/removing admins 
// export const updateClassroom = (schoolId, updatedSchoolRecord) => {
//   console.log("updatedSchoolRecord", updatedSchoolRecord)

//   return async (dispatch) => {
//     dispatch(updateClassroomRequest())
//     try {

//       const url = "/schools/updateschool/" + schoolId;
//       const { data: schoolDataConfirmed } = await http.patch(url, updatedSchoolRecord)

//       //const { _id: schoolId } = schoolDataConfirmed
//       console.log("schoolDataConfirmed===>", schoolDataConfirmed)

//       // reducer need to be updated
//       dispatch(updateClassroomSuccess(schoolDataConfirmed))
//     }

//     catch (err) {
//       console.log(err)
//       dispatch(updateClassroomFailure(err))
//     }
//   }
// }


// ---- Why is this in teacher actions instead of classrooms?
const deleteClassRequest = () => {
  return {
    type: types.DELETE_CLASS_REQUEST
  }
}
const deleteClassSuccess = teacher => {
  return {
    type: types.DELETE_CLASS_SUCCESS,
    payload: teacher
  }
}
const deleteClassFailure = error => {
  return {
    type: types.DELETE_CLASS_FAILURE,
    payload: error
  }
}

export const deleteClass = (classroomId, teacherId) => {

  return async (dispatch) => {
    dispatch(deleteClassRequest())
    try {

      // 1) delete classroom record
      const url1 = "/classrooms/" + classroomId;
      const resultOfClassSuccessfullyDeleted = await http.delete(url1)

      const { data: { teacherIds, _id: classroomDeletedId } } = resultOfClassSuccessfullyDeleted

      // 2) SAVE classroomId_current
      console.log("2) classroomDeletedId SAVE TO COMPARE FOR LATER ", classroomDeletedId)

      // 3) Delete reference to class on teacher record
      // ---> FIX: Currently this only handles one teacher. Update to delete class on array of teachers
      console.log("3) Array of teacherId to update and remove class-->", teacherIds)

      const url2 = "/teachers/removeclassroom/" + teacherId;
      const deleteDataPod = { classroomId: classroomId }
      const { data: updatedTeacherObject } = await http.patch(url2, deleteDataPod)


      // Deleting references to studentIDs done in classroomsDisplay
      // line 47
      // move here?

      //  studentIds.forEach(studentId => {
      //  dispatch(removeClassroomFromStudent(deleteSelectionForModal._id, studentId))
      // })


      dispatch(deleteClassSuccess(updatedTeacherObject)) // reload profile to avoid populating issues


    }

    catch (err) {
      console.log(err)
      dispatch(deleteClassFailure(err))
    }
  }
}




const increaseStudentCoinsByTeacherRequest = () => {
  return {
    type: types.INCREASE_STUDENT_COINS_BY_TEACHER_REQUEST
  }
}
const increaseStudentCoinsByTeacherSuccess = (nada) => {
  return {
    type: types.INCREASE_STUDENT_COINS_BY_TEACHER_SUCCESS,
    payload: nada
  }
}
const increaseStudentCoinsByTeacherFailure = (error) => {
  return {
    type: types.INCREASE_STUDENT_COINS_BY_TEACHER_FAILURE,
    payload: error
  }
}

export const increaseStudentCoinsByTeacher = (studentId, creditsAwarded = 1, teacherId) => {
  return async (dispatch) => {
    dispatch(increaseStudentCoinsByTeacherRequest());

    try {
      // 1a) update student record with credits increase by 1 (unless this can happen automatically)


      const url1 = "/students/increasestudentcoins/" + studentId; // change this to special route /increasestudentcoinsbyteacher/ ??
      await http.patch(url1, {
        coins: creditsAwarded
      })

      // since result is student total coins, we do not need that here
      // teacher only sees coins that he/she gave

      // 1b) TO DO --update NEW value to coinsLedger:  [ ] array of objects :
      // { value: 1, originator: _teacherId, transactionDate: Date.now()}


      // 2a) update teacher coinsToIssue IF teacherId is not null

      // payload irrelavant here?

      if (teacherId) {

        const url2 = "/teachers/adjustteachercoinstoissue/" + teacherId; // change this to special route /increasestudentcoinsbyteacher/ ??
        const updatedTeacherObject = await http.patch(url2, {
          coinsToIssue: -creditsAwarded
        })

        // 2b) update teacher ledger
        // to be added
        const addToLedger = {}

        dispatch(increaseStudentCoinsByTeacherSuccess(updatedTeacherObject));
      }
      // no teacher -- nothing needed here?
      dispatch(increaseStudentCoinsByTeacherSuccess());


    }

    catch (err) {
      dispatch(increaseStudentCoinsByTeacherFailure(err));
    }

  }
}




const checkSchoolCodeRequest = () => {
  return {
    type: types.CHECK_SCHOOL_CODE_REQUEST
  }
}
const checkSchoolCodeSuccess = classroom => {
  return {
    type: types.CHECK_SCHOOL_CODE_SUCCESS,
    payload: classroom
  }
}
const checkSchoolCodeFailure = error => {
  return {
    type: types.CHECK_SCHOOL_CODE_FAILURE,
    payload: error
  }
}

export const checkSchoolCode = (teacherId, schooljoincode) => {
  return async (dispatch) => {
    dispatch(checkSchoolCodeRequest())
    try {
      // 1) check if school exists
      const url1 = "/schools/schooljoincode/" + schooljoincode;
      const { data: schoolToJoin } = await http.get(url1)
      dispatch(checkSchoolCodeSuccess(schoolToJoin))
    }
    catch (err) {
      console.log(err)
      dispatch(checkSchoolCodeFailure(schooljoincode))
    }
  }
}




const joinSchoolWithCodeRequest = () => {
  return {
    type: types.JOIN_SCHOOL_WITH_CODE_REQUEST
  }
}
const joinSchoolWithCodeSuccess = classroom => {

  console.log("classroom payload ==>", classroom)
  return {
    type: types.JOIN_SCHOOL_WITH_CODE_SUCCESS,
    payload: classroom
  }
}
const joinSchoolWithCodeFailure = error => {
  return {
    type: types.JOIN_SCHOOL_WITH_CODE_FAILURE,
    payload: error
  }
}

// Future issue: How to rollback half-way executed series of db writes

export const joinSchoolWithCode = (teacherId, schoolToJoin) => {

  return async (dispatch) => {
    dispatch(joinSchoolWithCodeRequest())
    try {
      console.log("schoolToJoin", schoolToJoin._id)
      console.log("teacherId", teacherId)

      const url1 = "/schools/addteachertoschool/" + schoolToJoin._id;
      const teacherIdToAdd = { teacherId: teacherId }

      console.log("url1", url1)
      console.log("teacherIdToAdd", teacherIdToAdd)

      const { data: updatedSchoolData } = await http.patch(url1, teacherIdToAdd)

      console.log("updatedSchoolData===>", updatedSchoolData._id)
      const { _id: newSchoolId } = updatedSchoolData
      // console.log("newSchoolId===>", newSchoolId)


      // 2) set schoolID on teacher
      const url3 = "/teachers/updateTeacherProfile/" + teacherId;

      const settingNewSchool = { schoolId: newSchoolId }
      console.log("settingNewSchool======>", settingNewSchool)

      const { data: updatedTeacherData } = await http.patch(url3, settingNewSchool)

      dispatch(joinSchoolWithCodeSuccess(updatedTeacherData))

    }

    catch (err) {
      console.log(err)
      dispatch(joinSchoolWithCodeFailure(err.message))
    }
  }
}


// ----  navbar ---- ///

export const updateTeacherNavbar = (updatedNavbar) => {
  // console.log("2nd navbar object==>🟦", updatedNavbar)
  return {
    type: types.UPDATE_TEACHER_NAVBAR,
    payload: updatedNavbar
  }
}


export const clearTeacherNavbar = () => {
  return {
    type: types.CLEAR_TEACHER_NAVBAR,
    payload: null
  }
}
