import React, { useState, useEffect } from 'react';
import { Button } from "semantic-ui-react"
import { useSelector, useDispatch } from "react-redux"
import CountUp from 'react-countup'
import { CoinDisplay } from './CoinDisplay2'
import { getStudentProfile } from '../../redux/student/studentActions'
import RenderSVG from '../common/RenderSVG';
import useSound from 'use-sound';
import coinsSound from '../../sound/coin_1.mp3'
import Lottie from 'react-lottie'
import sadFace from '../../img2/lottie/ui/872-empty-list.json'


// const BoopButton = () => {
//   const [play] = useSound(coinsSound);
//   return <button onClick={play}>Boop!</button>;
// };


const coinNumberDisplay = { "backgroundColor": "royalBlue", "color": "gold", "fontSize": "3em", "fontWeight": "900" }

const MyKanshaTokens = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStudentProfile(fb_uid))
  }, [])

  const { loading: profileLoading, error: profileError, studentData, studentData: { fb_uid } } = useSelector(state => state.student)

  const { schoolId } = useSelector(state => state.classroom)
  const currentCoin = schoolId?.coinName || "Kansha Coin"


  const { coins } = studentData || "null" // fix issue
  let coinSize = coins > 125 ? 15 : 150 - 2 * (coins ? coins : 0)

  if (coinSize < 50) {
    coinSize = 50
  }

  const toggleCoinDisplay = () => {
    setCoinDisplay(!coinDisplay);
    dispatch(getStudentProfile(fb_uid))
    //play()
  }

  const [coinDisplay, setCoinDisplay] = useState(false)
  const [play] = useSound(coinsSound);

  const [denominationDivisor, setdenominationDivisor] = useState(1)

  const DENOMINATIONS_CONSTANTS = [100, 50, 25, 10, 5, 1]
  // 

  const differentDenominations = (studentCoins) => {
    //  1) Build object
    const buildCoinCounter = (coins) => {
      const coinsObject = {}
      //if (coins === 0) return

      // iterate over denominations array
      DENOMINATIONS_CONSTANTS.forEach((denomination) => {
        const fullNumberAvailable = Math.floor(coins / denomination)
        const remainder = Math.floor(coins % denomination)
        coinsObject[denomination] = [denomination, fullNumberAvailable, remainder]
      }) // end of forEach
      return coinsObject
    } // end of buildCbject

    // const filledCoinsObject = buildCoinCounter(studentCoins)
    // console.log("filledCoinsObject", filledCoinsObject)


    // const breakDownFunction(denomDesired)=>
    // new function that checks for remainder referring to the coinsObject
    // - build new object
    // - iterate to [0] in DENOM_CONST
    // - checks level if denomDesired
    // -- if  remainder, try higher up in list of constants buiding newObject
    // output: newObject with largest denominations available giving inputed starting level

    // create row of buttons for each denomination [denom, total, remainder]
  }

  if (coins < 1) return <><h1>You have zero {currentCoin}s </h1>
    <Lottie
      width={250}
      height={250}
      options={{
        loop: false,
        autoplay: true,
        animationData: sadFace,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
    />
  </>



  return (
    <>
      <h1 style={{ "color": "royalblue" }}>My {currentCoin}s</h1>

      <div>

        {coinDisplay
          ? (<>
            <div
              onClick={toggleCoinDisplay} >
              <RenderSVG
                size={"300px"}
                svgFilename={"ui/general/007-bank"} />
            </div>
            <h2 style={coinNumberDisplay}>Account balance:
              <span><CountUp end={coins} duration={2} /></span>
            </h2>
          </>)

          : <>
            <div style={{ width: "100%", margin: "0 auto" }}>
              <CoinDisplay quantityOfCoins={coins} size={coinSize} />
            </div>
            <br />
          </>}

        <Button center size="large"
          style={{
            backgroundColor: "royalblue",
            color: "gold"
          }}
          onClick={() => setCoinDisplay(!coinDisplay)}>Toggle
        </Button>
      </div >
      <br />
      <br />
    </>
  );
}

export default MyKanshaTokens;


{/* <div onClick={toggleCoinDisplay} >
                <Lottie
                  width={500}
                  height={500}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: logo2,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                    //,viewBox: "0 0 300 300"
                  }} />
              </div> */}


    // const coinsObjectModel = {
    //   100: 0,
    //   50: 0,
    //   25: 0,
    //   10: 0,
    //   5: 0,
    //   1: 0
    // }


    // ---- 100 ---- //
    // 150/100 = 1
    // if (coins >= DENOMINATIONS_CONSTANTS[0]) {
    //   const amount100s = Math.floor(coins / DENOMINATIONS_CONSTANTS[0])
    //   //console.log("💛 amount100s", amount100s)

    //   coinsObject[DENOMINATIONS_CONSTANTS[0]] = amount100s; //1
    //   //console.log("💛💛coinsObject", coinsObject)

    //   const remainder100s = coins % DENOMINATIONS_CONSTANTS[0]
    //   console.log("💛💛💛remainder100s", remainder100s)
    //   if (remainder100s > 0) {
    //     buildCoinObject(remainder100s)
    //   }
    // }


    // ---- 50 ---- //
    // if (coins >= DENOMINATIONS_CONSTANTS[1]) {
    //   const amount50s = Math.floor(coins / DENOMINATIONS_CONSTANTS[1])
    //   console.log("💛 amount50s", amount50s)

    //   coinsObject[DENOMINATIONS_CONSTANTS[1]] = amount50s; //1
    //   console.log("💛💛coinsObject", coinsObject)

    //   const remainder50s = coins % DENOMINATIONS_CONSTANTS[1]
    //   console.log("💛💛💛remainder50s", remainder50s)
    //   if (remainder50s > 0) {
    //     buildCoinObject(remainder50s)
    //   }
    // }