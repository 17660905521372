import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

export const ModalControlledExternally = function (props) {
  const { showModal, setShowModal, header, icon, size, modalContent, handleConfirm, handleDecline } = props

  // size:  "mini","tiny","small","large","fullscreen"

  const handleButtonOne = () => {
    handleDecline("no info")
    setShowModal(false)
  }
  const handleButtonTwo = () => {
    handleConfirm("yes info")
    setShowModal(false)
  }

  return (
    <Modal
      basic
      open={showModal}
      size={size ? size : 'small'}
    //onClose={() => setInternalOpen(false)}
    //onOpen={() => setInternalOpen(true)}
    //trigger={<Button>{buttonText}</Button>}
    >
      <Header icon>
        {icon}
        {/*    <Icon name='archive' /> */}
        {header}
      </Header>
      <Modal.Content>
        {modalContent}
      </Modal.Content>
      <Modal.Actions>
        <Button
          //button-one
          basic
          color='red'
          inverted
          onClick={handleButtonOne}>
          <Icon name='remove' /> No
        </Button>
        <Button
          //button-two
          color='green'
          inverted
          onClick={handleButtonTwo}>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
