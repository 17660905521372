import React from 'react';
import { Button, Icon } from "semantic-ui-react"
import { motion } from 'framer-motion';
import RenderAvatar from '../common/RenderAvatar';
import { superHeros } from "../../img/avatars/superHeros"
const tempStyle2 = { "margin": "1px" }

const StudentSelectedCard = (props) => {
  const { schoolCurrency, issueToken, chooseAnother, randomStudent: student } = props
  return (
    <>
      <div
        style={tempStyle2}>
        {student &&
          (<>
            <div >
              <motion.h1
                initial={{ y: -250 }}
                animate={{ y: 60 }}
                transition={{ delay: .25, duration: .25 }}
              >{student.displayName}
              </motion.h1>

              <motion.div
                initial={{ y: -250 }}
                animate={{ y: 50 }}
                transition={{ type: 'spring', stiffness: 500 }}>
                <RenderAvatar
                  size={120}
                  avatarId={student.avatar.avatarId}
                  setOfAvatars={superHeros}
                />
              </motion.div>
            </div>
            <br />
            <motion.div
              initial={{ opacity: 0, y: 75 }}
              animate={{ opacity: 1, y: 75 }}
              transition={{ delay: .75, duration: 1 }}
            >
              <Button
                onClick={() => issueToken(student)}
                style={tempStyle2}
                color="green"
                size='huge'>{schoolCurrency}
              </Button>
              <div
                onClick={() => chooseAnother()}>
                <br />
                <Icon
                  name='sync'
                  color="grey"
                  size="huge"
                />
              </div>

            </motion.div>

          </>)
        }
      </div>
    </>
  )
}
export default StudentSelectedCard;



// const tempStyle = { "color": "blue", "fontSize": "4em", "fontWeight": "bold" }
// const tempStyle2 = { "margin": "1px" }
// const winnerStyle = { "color": "gold", "fontSize": "5em", "margin": "1px" }


