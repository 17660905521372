import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { passwordResetEmail, clearUserFB } from '../../redux/auth/authActions';
import { useDispatch, useSelector } from "react-redux"
import { SimpleSpinner } from "../common/Spinners"


const PasswordReset = () => {
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, loading, error, userTypeDirectToDashboard } = useSelector(state => state.auth)
  const [userEmail, setUserEmail] = useState(null)

  const onSubmit = ({ email }) => {
    setUserEmail(email)
    console.log("email", email)
    dispatch(passwordResetEmail(email))
  }


  useEffect(() => {
    if (userTypeDirectToDashboard === "emailsent") {
      history.push('/passwordresetconfirm', { email: userEmail })
      dispatch(clearUserFB())
    }
  }, [userTypeDirectToDashboard])



  if (loading) {
    return <SimpleSpinner />
  }

  if (error) {
    return (
      <>
        <h2>{error}</h2>
        <Button color="blue" onClick={() => dispatch(clearUserFB())}>Try again</Button>

        <div>
          <h1>{" "}</h1>
          <Button color="green" onClick={() => history.push('/login')}>Login</Button>
        </div>
      </>
    )
  }

  return (<>
    <h1>Lost Password</h1>
    <Icon name='question circle' size='massive' color="grey" />
    <form onSubmit={e => e.preventDefault()}>
      <label>Please enter your email address and a link to reset it will be sent.</label>
      <input
        name="email"
        type="text"
        ref={register({ required: true, minLength: 4 })}
      >
      </input>

      {errors.email && <p>Invalid email</p>}
      <div>
        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          color='grey'
        >Send link</Button>
      </div>

    </form ></>);
}

export default PasswordReset;