import React from 'react';

const SchoolDetails = (props) => {
  const { schoolData: { name, mascot, description } } = props
  return (<>
    <h3>School: {name}</h3>
    {mascot ? <h3>Mascot: {mascot} </h3> : null}
    {description ? <h3>Motto: {description} </h3> : null}
    <br />
    <br />
  </>
  );
}
export default SchoolDetails;


