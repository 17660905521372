import React from 'react';

function DragonCoin(props) {
  const { size = 100 } = props;
  return (
    <svg viewBox="0 0 100 100" width={size}>
      <circle className="spinner circle" cx="50" cy="50" r="45" />
      <rect className="spinner rect" x="25" y="25" width="50" height="50" />
      <polygon className="spinner hex" points="63,57.5 50,65 37,57 37,42.5 50,35 63,42.5" />
    </svg>
  );
}

export default DragonCoin;

//https://able.bio/dbmzzo/intro-to-svg-for-react-developers--56cmmcy

