
import React, { useState } from 'react'
import SearchBar from './gif_search_bar';
import Gif from './gif';
import GifList from './gif_list';
import { Button } from 'semantic-ui-react'
import { RectShape } from 'react-placeholder/lib/placeholders';
const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

const Gif_UI = ({ heading = "Choose a GIF", backgroundcolor = "white", selectedGifId, setSelectedGifId, setTitle, title }) => {

  const [gifs, setGifs] = useState([])
  const [offset, setOffset] = useState(0)

  // const [selectedGifId, setSelectedGifId] = useState("xT9IgDEI1iZyb2wqo8")
  const [gifError, setGifError] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [showGifSelection, setShowGifSelection] = useState(true)


  const search = (query) => {
    setSearchQuery(query)
    const giphEndpoint = `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${query}&limit=10&offset=${offset}`

    fetch(giphEndpoint)
      .then(response => response.json())
      .then((data) => {
        const gifs = data.data.map(giph => giph.id);
        setGifs(gifs)
        setGifError(null)
      })
      .catch((err) => {
        setGifError(err?.message)
      })
  }

  const selectGif = (id) => {
    setSelectedGifId(id)
    setTitle(searchQuery)
  }

  const newGiphs = () => {
    setOffset(offset + 10)
    search(searchQuery)
    setTitle(searchQuery)
  }

  if (gifError) {
    console.error(gifError)
    return (<h6>Hmmm... We seem to having trouble with the connetion.</h6>)
  }

  return (<>

    <h1>{heading}</h1>
    <div>
      <div>
        <Gif id={selectedGifId} size={150} />
      </div>
      <div>
        {gifs.length > 0 ? <GifList size={75} gifs={gifs} selectGif={selectGif} /> : <RectShape color={backgroundcolor} style={{ width: 780, height: 80 }} />}
      </div>
      <Button onClick={newGiphs}>More</Button>
      <Button onClick={() => setShowGifSelection(!showGifSelection)}>None</Button>
      <SearchBar setTitle={setTitle} searchFunction={search} title={title} />
    </div>
  </>);
}

export default Gif_UI;

// orig source: https://github.com/lewagon/react-giphy
    // const giphEndpoint_ORIG = `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${query}&limit=10`

