import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { getAllClasses, getAllTeachers, getAllStudents } from "../../redux/admin/adminActions"
import RenderSVG from '../common/RenderSVG';
import ClassesTable from './CurrentSchoolClassesTable'
import TableWithPdf from './TableWithPdf'

const CurrentSchool = (props) => {
  const dispatch = useDispatch()
  const { adminData: { schoolId_current, schoolIds, _id: adminId }, loading, allClasses, allTeachers, allStudents } = useSelector(state => state.admin)
  const { _id: schoolId } = schoolId_current || {}
  const schoolImage = schoolId_current?.image?.svg

  const [showClasses, setShowClasses] = useState(false)
  const [showStudents, setShowStudents] = useState(false)
  const [showTeachers, setShowTeachers] = useState(false)
  
  const history = useHistory()

  useEffect(() => {
    if(schoolId){
      dispatch(getAllClasses(schoolId))
      dispatch(getAllTeachers(schoolId))
      dispatch(getAllStudents(schoolId))
    }
  }, [])

  useEffect(() => {
    if(schoolId){
      dispatch(getAllClasses(schoolId))
      dispatch(getAllTeachers(schoolId))
      dispatch(getAllStudents(schoolId))
    }
  }, [schoolId])

  const toggleClasses = () => {
    setShowClasses(!showClasses)
    setShowStudents(false)
    setShowTeachers(false)
  }

  const toggleStudents = () => {
    setShowStudents(!showStudents)
    setShowClasses(false)
    setShowTeachers(false)
  }

  const toggleTeachers = () => {
    setShowTeachers(!showTeachers)
    setShowStudents(false)
    setShowClasses(false)
  }


const teacherTablePdfList={
  title: "Teachers",// + schoolId_current?.name,
  columnDef: [{
    displayTitle: "First",
    columnName:"firstName",
    width: 4,
    svg:false,
    avatar: true,
    subTextToDisplay:false
  },
  {
    displayTitle: "Last",
    columnName:"lastName",
    width: 4,
    svg: false,
    avatar: false,
    subTextToDisplay:false
  },
  {
    displayTitle: "Email",
    columnName:"email",
    width: 5,
    svg:false,
    avatar: false,
    subTextToDisplay:false
  },
  {
    displayTitle: "Classes",
    columnName:"classroomIds",
    width: 5,
    svg:false,
    avatar: false,
    subTextToDisplay:true,
    subTextNames: ["name"],
    subTextDisplay: ["Name"],
    subTextColor: ["blue"]
  } ],

  allowPdf: true,
  pdfConfig:{ 
    listOrTable: "list",
    titleFontSize: 20,
    columnFontSize: 10,
    pdfDisplayColumnGap: 20
  }
}


 

const studentTablePdfDef={
  title: "Students",// + schoolId_current?.name,
  columnDef: [{
    displayTitle: "First",
    columnName:"firstName",
    width: 4,
    svg:false,
    avatar: true,
    subTextToDisplay:false
  },
  {
    displayTitle: "Last",
    columnName:"lastName",
    width: 4,
    svg: false,
    avatar: false,
    subTextToDisplay:false
  },
  {
    displayTitle: "Email",
    columnName:"email",
    width: 5,
    svg:false,
    avatar: false,
    subTextToDisplay:false
  },
  {
    displayTitle: "Tokens",
    columnName:"coins",
    width: 2,
    svg:false,
    avatar: false,
    subTextToDisplay:false
  },
  {
    displayTitle: "Classes",
    columnName:"classroomIds",
    width: 7,
    svg:false,
    avatar: false,
    subTextToDisplay:true,
    subTextNames: ["name", "teacherName"],
    subTextDisplay: ["Name", "Teacher"],
    subTextColor: ["blue", "red"]
  } 

],
  allowPdf: true,
  pdfConfig:{ 
    listOrTable: "table",
    titleFontSize: 20,
    columnFontSize: 10,
    pdfDisplayColumnGap: 20
  }
 
}
// const classesTablePdfDef={}


  if (!schoolId){
    return (<>
    <h1>GRAPHIC</h1>
    <h2>Please select a school.</h2>
    <Button color="blue" inverted onClick={()=>history.push("/schoolsdisplay")}>All Schools</Button>
    </>)
  }

const buildAdaptedAllStudents = ()=> {
  return allStudents.map(student=>{

    const classroomIdsWithTeacherName = []
    student.classroomIds.forEach((singleClassroom, i) =>{

    const teacherName = singleClassroom.teacherIds[0]?.displayName
    
    singleClassroom = {...singleClassroom, ...{teacherName: teacherName ? teacherName: "no name"}}
   // console.log("singleClassroom===>", singleClassroom)
    classroomIdsWithTeacherName.push(singleClassroom)
  })

  return {...student, classroomIds: classroomIdsWithTeacherName}  } )


}

  return (<>

    <h2 >{schoolId_current ? schoolId_current.name : "None"}</h2>
    {schoolImage &&
    <RenderSVG size="150" svgFilename={schoolImage} />}
    <br />
    <br />
    <div className="grid-container">
      <div className="grid-item"></div>

      <div className="grid-item">
      <Button onClick={toggleTeachers} size="large" color="green">Teachers</Button>
      </div>

      <div className="grid-item">
      <Button onClick={toggleStudents} size="large" color="green">Students</Button>
      </div>

      <div className="grid-item">
      <Button onClick={toggleClasses} size="large" color="green">Classes</Button>
      </div>
     
    </div>


    {showTeachers && 
    <TableWithPdf 
    tableStructure={teacherTablePdfList}
    tableData={allTeachers} />}


    {showStudents && 
    <TableWithPdf 
    tableStructure={studentTablePdfDef} 
    tableData={buildAdaptedAllStudents()} 
    />} 

    {/* {showClasses && 
    <ClassesTable 
    tableData={allClasses} 
    pdfDef={classesTablePdfDef} 
    /> } */}

  </>)
};


export default CurrentSchool
