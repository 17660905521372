import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { getClassroom } from "../../redux/classroom/classroomActions"
import { updateStudentProfile_2 } from "../../redux/student/studentActions"
import { Button } from 'semantic-ui-react'


const TeacherUpdatingStudents = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { setShowNavBar } = props
  const [studentUpdateComplete, setStudentUpdateComplete] = useState([])

  const { teacherData, error: teacherLoadingError, loading:teacherLoading } = useSelector(state => state.teacher)
  const { classroomId_current, isNewTeacherAccount } = teacherData || {}

  const { studentIds } = useSelector(state => state.classroom)
  const { studentData } = useSelector(state => state.student)

  const { displayName: studentDisplayName } = studentData

  const updateStudentsCurrentClassStatus = () => {

    // Note: This will also be called upon chaching classes from student dashboard, but updateTeacher will neeed to be added:
    // dispatch(updateTeacherProfile(teacherId, updatedData))

    // LATER -- BETTER UPDATES? 
    // perhaps use axios.all, 
    // example here: classroomActions #1870
    // or write a new clasroom action?
    // updateAllStudents to updateStateProfile(studentIdsArray, updatedData)

    // LATER #2 -- AVOID CONFLICTING CLASSE 
    // a check needs to happen to be sure a student is not ACTIVE in one class while a teacher logs in or changes classes. If the student is in an active class and such a change occurs, their update should be skipped.

    const updatedData = { classroomId_current: classroomId_current._id }

    if (studentIds?.length) {
      studentIds.forEach(student => {
        dispatch(updateStudentProfile_2(student._id, updatedData))
      })
    }
  }

  useEffect(() => { 
    console.log("classroomId_current===>", classroomId_current)
    console.log("teacherLoading===>", teacherLoading)

    if (!classroomId_current && !teacherLoading) {
      console.log("INNER classroomId_current===>", classroomId_current)
      console.log("INNER teacherLoading===>", teacherLoading)
     history.push('/teacherdashboard')
    }
 }, [teacherLoading])


  useEffect(() => { 
    if (isNewTeacherAccount) {
     history.push('/teacherdashboard')
    }
 }, [isNewTeacherAccount])


  useEffect(() => { setShowNavBar(false) }, [])

  useEffect(() => {
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
  }, [classroomId_current])


  useEffect(() => {
    classroomId_current && updateStudentsCurrentClassStatus()

    if (studentIds?.length === 0) {
      console.log("STUDENT IDS LENGTH", studentIds?.length )
      history.push('/teacherdashboard')
    }

  }, [studentIds, classroomId_current])

  useEffect(() => {
    if (studentDisplayName) {
      studentUpdateComplete.push(<h6>{studentDisplayName}</h6>)
      const newStudentList = [...studentUpdateComplete]
      setStudentUpdateComplete(newStudentList)
    }

    if (studentUpdateComplete.length === studentIds?.length) {      
      history.push('/teacherdashboard')
    }
  }, [studentDisplayName])


  if (!teacherData || teacherLoadingError ) { history.push("/loginerror") }

  return (<>
    <h1 style={{ fontSize: "10rem" }}>⏳</h1>
    <h1>Updating {studentIds && studentIds.length} Students</h1>
    {studentUpdateComplete}
  </>)
};

export default TeacherUpdatingStudents;


