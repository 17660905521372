import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Table, Icon } from 'semantic-ui-react'
import { getGrouptheme, removeStudentFromGroup, addStudentToGroup, updateGroup } from "../../redux/classroom/classroomActions"
import { useDispatch, useSelector } from "react-redux"
import RenderSVG from '../common/RenderSVG'
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import downloadJSON from '../../utils/jsonToCSV'
import { SimpleSpinner } from '../common/Spinners';
import arrayRemove from '../../utils/arrayRemove';
import { EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css'
import ReactTooltip from 'react-tooltip';

const GroupsListTable = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedStudent, setSelectedStudent] = useState([null, null])
  const { topic, groupthemeIdCurrentId, studentNames } = props
  const { loading: classroomLoading, groupthemeData, groupthemeData: { groupIds } } = useSelector(state => state.classroom)
  const [teacherNotes, setTeacherNotes] = useState({})

  useEffect(() => {
    const teacherNotesObject = {}
    groupIds.forEach((gr) => teacherNotesObject[gr._id] = gr.teacherNotes)
    setTeacherNotes(() => teacherNotesObject)
  }, [])

  useEffect(() => {
    dispatch(getGrouptheme(groupthemeIdCurrentId))
  }, [groupthemeIdCurrentId])

  useEffect(() => {
    if (classroomLoading) {
      dispatch(getGrouptheme(groupthemeIdCurrentId))
    }
  }, [classroomLoading])


  const deleteStudentFromGroupConfirmed = (studentTupleWithGroupId, group) => {
    const { _id: groupId } = group
    const studentId = studentTupleWithGroupId[2]
    dispatch(removeStudentFromGroup(studentId, groupId))

    // remove from possible leader role
    removeGroupLeader(studentTupleWithGroupId, group)
    hideEditModal()
  }


  const moveStudentToGroupConfirmed = (studentTupleWithBothGroupIds, groupFrom) => {
    const studentId = studentTupleWithBothGroupIds[2]
    const groupId = studentTupleWithBothGroupIds[5]
    const newGroupId = studentTupleWithBothGroupIds[7]
    dispatch(removeStudentFromGroup(studentId, groupId))

    // remove from possible leader role
    removeGroupLeader(studentTupleWithBothGroupIds, groupFrom)

    dispatch(addStudentToGroup(studentId, newGroupId))
    hideEditModal()
  }

  // --- ADD ONLY -- //
  const addStudentToGroupConfirmed = (studentTuple, newGroup) => {
    const studentId = studentTuple[2]
    dispatch(addStudentToGroup(studentId, newGroup._id))
    dispatch(getGrouptheme(groupthemeIdCurrentId))
    hideOnlyAddModal()
  }

  let groupsDisplay = null
  let groupsFiltered = null

  if (selectedStudent && selectedStudent[1]) {
    // 1 filter out current group
    groupsFiltered = groupIds && groupIds.filter((group) => group._id !== selectedStudent[1]._id)

    // 2 build UI
    groupsDisplay = groupsFiltered
      ? groupsFiltered.map((gr) => {

        const entireGroupFrom = selectedStudent[1]
        const selectedStudentNew = [...selectedStudent[0]]
        const groupIdFrom = selectedStudent[1]._id
        const groupIdTo = gr._id
        selectedStudentNew[4] = "placeholder - 4"
        selectedStudentNew[5] = groupIdFrom
        selectedStudentNew[6] = "placerholder - 6"
        selectedStudentNew[7] = groupIdTo

        return (<>
          <Button basic color="black" onClick={() => moveStudentToGroupConfirmed(selectedStudentNew, entireGroupFrom)}>
            <RenderSVG
              size={"70px"}
              svgFilename={gr.displayNameGraphics.svg} />
            <div>
              {gr.displayNameGraphics.english}
            </div>
          </Button>
        </>)
      })
      : null
  }


  // --- ADD STUDENT TO GROUP WHO IS CURRENTLY IN NO GROUP ---
  // --- refactor this into function? shouldn't run every render ?
  let groupsDisplayOnlyAdd = null
  // let groupsFiltered = null

  if (selectedStudent && selectedStudent[1]) {
    // 1 filter out current group

    // 2 build UI
    groupsDisplayOnlyAdd = groupIds
      ? groupIds.map((selectedGroup) => {
        return (<>
          <Button basic color="black" onClick={addStudentToGroupConfirmed.bind(null, selectedStudent[0], selectedGroup)}>
            <div>
              <RenderSVG
                size={"70px"}
                svgFilename={selectedGroup.displayNameGraphics?.svg} />
            </div>
            {selectedGroup.displayNameGraphics.english}</Button>
        </>)
      })
      : null
  }


  const [showEditModal, hideEditModal] = useModal(() => {
    return (
      <ReactModal isOpen>
        <RenderSVG
          size={"100px"}
          svgFilename={selectedStudent[1].displayNameGraphics.svg}
        />
        <h2>Move {selectedStudent[0][0]} {selectedStudent[0][1]} to which group?</h2>
        {groupsDisplay}
        <div>
          <br />
          <Button onClick={() => deleteStudentFromGroupConfirmed(selectedStudent[0], selectedStudent[1])} basic color="facebook" > <Icon name='remove user' size="huge" />Just REMOVE from the {selectedStudent[1].displayNameGraphics.english}
          </Button>
          {/* <Button onClick={() => deleteStudentFromGroupConfirmed(selectedStudent[0], selectedStudent[1]._id)} basic color="facebook" > <Icon name='remove user' size="huge" />Just REMOVE from the {selectedStudent[1].displayNameGraphics.english}
          </Button> */}
        </div>
        <div>
          <br />
          <Button color="red" size="tiny" onClick={hideEditModal}>Cancel</Button>
        </div>
      </ReactModal>)
  }, [selectedStudent]);


  const [showOnlyAddModal, hideOnlyAddModal] = useModal(() => {
    return (
      <ReactModal isOpen>
        <h1>Add {selectedStudent[0][0]} to which group?</h1>
        {groupsDisplayOnlyAdd}
        <div>
          <br />
          <br />
          <Button color="red" size="small" onClick={hideOnlyAddModal}>Cancel</Button>
        </div>
      </ReactModal>)
  }, [selectedStudent]);

  const handleClickEditGroup = (studentTuple, gr) => {
    setSelectedStudent([studentTuple, gr])
    showEditModal()
  }

  const handleClickOnlyAdd = (studentTuple) => {
    setSelectedStudent([studentTuple, "nogroup"])
    showOnlyAddModal()
  }

  // const handleToggleLeaderStatus = (studentTuple, gr) => {
  //   console.log("LEADER:", [studentTuple, gr])
  // }


  const handleClickCSV = () => {
    const groupDataFormatted = []
    groupIds.forEach((gr) => {
      // console.log(gr.displayNameGraphics.english, gr._id)
      // console.log(studentNames[gr._id])
      // iterate over each group to get student emails
      if (studentNames[gr._id].length > 0) {
        studentNames[gr._id].forEach(st => {
          groupDataFormatted.push({ f1: gr.displayNameGraphics.english, f2: st[10] })
        })
      }
    })

    const headers = { f1: 'Pre-assign Room Name', f2: '' }
    downloadJSON(headers, groupDataFormatted)

  }

  // const handleClickPDF = () => {
  //   console.log("handleClickPDF!!!")
  // }

  const editGroup = (group) => {
    history.push('/groupedit/', { group, groupthemeData })
  }

  // What will save the text? Need a save button insideof the text box
  const handleChangeTeacherGroupNotes = (e, setFn, groupId) => {
    const teacherNotesTemp = { ...teacherNotes }
    teacherNotesTemp[groupId] = e.target?.value
    setFn(teacherNotesTemp);
  };

  const saveTeacherGroupNotes = (group) => {
    const updatedGroup = { ...group }
    updatedGroup.teacherNotes = teacherNotes[group._id]
    console.log("🔻🔻🔻 saveTeacherGroupNotes-- DOM issue here conflicting ith onSave function from edit text. If the next click is on (group) Name, getGRoupTheme is fired and desire action of editing name requires a second click.")
    dispatch(updateGroup(group._id, updatedGroup))
  }

  const awardDisplay = (quantity) => {
    return Array(quantity).fill('🏆').join("");
  }

  const setGroupLeader = (studentTuple, group) => {
    const studentId = studentTuple[2]
    const { _id: groupId, leaderIds } = group
    const updatedGroup = { ...group }

    // MULTIPLE LEADERS -- Return to this later
    // const updatedLeaderIds = [...leaderIds]
    // updatedLeaderIds.push(studentId)
    // updatedGroup.leaderIds = updatedLeaderIds

    // ONLY ONE LEADER PER GRROUP -- By adding one any previous is demoted
    updatedGroup.leaderIds = [studentId]

    dispatch(updateGroup(groupId, updatedGroup))

  }

  const removeGroupLeader = (studentTuple, group) => {
    const studentId = studentTuple[2]
    const { _id: groupId, leaderIds } = group
    let updatedGroup = { ...group }
    let updatedLeaderIds = [...leaderIds]

    if (updatedLeaderIds.includes(studentId)) {
      updatedLeaderIds = arrayRemove(updatedLeaderIds, studentId)
      updatedGroup.leaderIds = updatedLeaderIds
      dispatch(updateGroup(groupId, updatedGroup))
    }
  }


  const allGroups = groupIds
    ? groupIds.map((gr) => {
      // Need to sort A->Z ?

      const currentGroupLeaders = gr?.leaderIds
      let groupLeaderExists = currentGroupLeaders?.length > 0 ? [...currentGroupLeaders] : null

      let studentNamesDisplay = studentNames && studentNames[gr._id]?.map((studentTuple, i) => {

        let studentIsLeader = false
        const studentId = studentTuple[2]

        if (currentGroupLeaders.includes(studentId)) { studentIsLeader = true }

        return (<li key={i}
          style={{ "listStyle": "none", fontSize: "1.2em", cursor: "pointer" }}>

            {studentIsLeader
            ? <span style={{padding: "2px 5px 0px 0px"}} data-tip={"Remove " + studentTuple[0] + " as leader"}
              onClick={() => removeGroupLeader(studentTuple, gr)}>
              🔵
            </span>
            : <span style={{padding: "2px 5px 0px 0px"}}  data-tip={"Set " + studentTuple[0] + " as leader"}
              onClick={() => setGroupLeader(studentTuple, gr)}>
              🔘 
              </span>}

          <span data-tip={"Move " + studentTuple[0] + " to another group"}
            onClick={() => handleClickEditGroup(studentTuple, gr)} >
            {studentTuple[0]} {studentTuple[1]}{" "}
          </span>

          {/* {studentIsLeader
            ? <span data-tip={"Remove " + studentTuple[0] + " as leader"}
              onClick={() => removeGroupLeader(studentTuple, gr)}>
              ⭐️
            </span>
            : <span data-tip={"Set " + studentTuple[0] + " as leader"}
              onClick={() => setGroupLeader(studentTuple, gr)}>
              🔘
            </span>} */}
        </li>)
      }
      )

      gr.points && awardDisplay(gr.points)

      return (
        <Table.Row>
          <Table.Cell
            textAlign='center'
            style={{ cursor: "pointer" }}
            onClick={editGroup.bind(null, gr)}
          >

            <ReactTooltip delayShow='1000' />
            <div data-tip="Edit title & avatar">
              <div>
                <RenderSVG
                  size={"40px"}
                  svgFilename={gr?.displayNameGraphics?.svg} />
              </div>
              <h6>{gr?.displayNameGraphics?.english}</h6>

            </div>
          </Table.Cell>

          <Table.Cell singleLine >
            <div>{studentNamesDisplay}</div>
            {gr.studentIds?.length === 0 || groupLeaderExists ? null : <div style={{ fontSize: ".75em", fontWeight: "600", color: "orange" }}>No leader</div>}
          </Table.Cell>

          <Table.Cell>
            <div  >
              <EditTextarea
                name="textbox2"
                style={{
                  padding: '2px',
                  color: "black",
                  fontSize: '1.2em',
                  cursor: "text"
                }}
                //  defaultValue={gr.teacherNotes}
                value={teacherNotes[gr._id]}
                onChange={(e) => handleChangeTeacherGroupNotes(e, setTeacherNotes, gr._id)}
                onSave={() => saveTeacherGroupNotes(gr)}
              />
            </div>
          </Table.Cell>

          <Table.Cell >
            <div data-tip="Click to add/subtract">
              <span style={{ fontSize: "2em", fontWeight: "600", cursor: 'pointer' }}>{gr.points}</span>
            </div>
          </Table.Cell>

          <Table.Cell>
            <div data-tip={`${gr?.cumulativeWins} awards won`} >
              <span style={{ fontSize: "2em", cursor: 'pointer' }}>{awardDisplay(gr?.cumulativeWins)}</span>
            </div>
          </Table.Cell>
        </Table.Row>)
    }) : null
  // end of all groups

  if (!allGroups || allGroups?.length === 0) {
    return (<>
      <h3>There are currently no groups.</h3>
      <Button color="black" onClick={() => history.push('/groupthemecreate')}>Create New Group Set</Button>
    </>)
  }

  let studentNoGroupDisplay = studentNames && studentNames["nogroup"]?.map(studentTuple => <li
    style={{ listStyle: "none", fontSize: "1.2em", cursor: "pointer" }}>
    <span onClick={() => handleClickOnlyAdd(studentTuple)}>
      {studentTuple[0]} {studentTuple[1]}{" "}
    </span>
  </li>)


  let allGroupsPlusExtras = [...allGroups];

  // if needed, append extra row of students without group 
  if (studentNoGroupDisplay && studentNoGroupDisplay.length > 0) {
    allGroupsPlusExtras = allGroups.concat(<Table.Row>
      <Table.Cell>No Group</Table.Cell>
      <Table.Cell >
        <div data-tip="Add to a group">
          {studentNoGroupDisplay}
        </div>
      </Table.Cell>
      <Table.Cell ></Table.Cell>
      <Table.Cell> </Table.Cell>
      <Table.Cell> </Table.Cell>
    </Table.Row>)
  }

  if (classroomLoading) return <SimpleSpinner />

  return (<>
    <div>
      <Table celled padded>
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell singleLine >Name</Table.HeaderCell>
            <Table.HeaderCell>Students</Table.HeaderCell>
            <Table.HeaderCell>Group Instructions</Table.HeaderCell>
            <Table.HeaderCell>Current Points</Table.HeaderCell>
            <Table.HeaderCell>Teamwork Awards</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {allGroupsPlusExtras}
        </Table.Body>
      </Table>
    </div>
    <br />

    <Button color="green" onClick={() => history.push('/groupslistaddgroup', groupthemeData)}>Create a New {topic} Group</Button>
    <div>
      <Button color="black"
        onClick={handleClickCSV}
      >Zoom CSV</Button>
    </div>
    <br />
    <br />
  </>)
}

export default GroupsListTable;

