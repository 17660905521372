// orig source: https://github.com/lewagon/react-giphy

import React from 'react';
import Gif from './gif';

const GifList = (props) => {
  const { selectGif, gifs, size } = props
  return (
    <div>
      {gifs.map(gif => <Gif id={gif} key={gif} size={size} selectGif={selectGif} />)}
    </div>
  );
};

export default GifList;
