import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Icon, Table, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux"
import { getClassroom, deleteGrouptheme, updateClassroom, getGrouptheme } from "../../redux/classroom/classroomActions"
import { ModalControlledExternally as DeleteModal } from '../common/ModalExternalTrigger'

const GroupThemesList = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteSelectionForModal, setDeleteSelectionForModal] = useState(null)
  const [currentTheme, setCurrentTheme] = useState(null)

  const { teacherData: { classroomId_current } } = useSelector(state => state.teacher)

  const { setShowNavBar } = props

  const {
    groupthemeId_current,
    groupthemeIds,
    name: currentClassroomName
  }
    = useSelector(state => state.classroom)


  useEffect(() => {
    setShowNavBar(true)
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
    if (groupthemeId_current?._id) {
      dispatch(getGrouptheme(groupthemeId_current._id))
      setCurrentTheme(groupthemeId_current?._id)
    }
  }, [])

  // if no current grouptheme set but others exist:
  useEffect(() => {

    if (!groupthemeId_current && groupthemeIds?.length > 0) {
      const firstThemeInList = { ...groupthemeIds[0] }
      // console.log("groupthemeIds[0]", groupthemeIds[0])
      // console.log("firstThemeInList", firstThemeInList)

      setCurrentTheme(() => firstThemeInList._id)
      const dataPod = { groupthemeId_current: firstThemeInList._id }
      dispatch(updateClassroom(classroomId_current._id, dataPod))
      dispatch(getGrouptheme(firstThemeInList._id))
    }
  }, [groupthemeId_current?._id])


  const handleClickDelete = (theme) => {
    setDeleteSelectionForModal(theme)
  }

  const deleteConfirmed = () => {
    dispatch(deleteGrouptheme(deleteSelectionForModal._id, groupthemeId_current._id))
    dispatch(getClassroom(classroomId_current._id))
  }


  const handleClickSet = (theme) => {
    setCurrentTheme(() => theme?._id)
    const dataPod = { groupthemeId_current: theme._id }
    dispatch(updateClassroom(classroomId_current._id, dataPod))
    dispatch(getGrouptheme(theme._id))
  }


  const allGroupthemes = groupthemeIds
    ? groupthemeIds.map(theme => {
      let activeLight = "🔘"
      if (groupthemeId_current) {
        if (currentTheme === theme._id) {
          activeLight = "🟢"
        }
      }

      return (
        <Table.Row onClick={handleClickSet.bind(null, theme)}
          style={{ cursor: "pointer" }}>
          <Table.Cell >
            {activeLight}
          </Table.Cell>
          <Table.Cell>{theme.topic}</Table.Cell>
          <Table.Cell> {theme?.setNumberOfGroups} </Table.Cell>
          <Table.Cell> {theme?.dateCreated} </Table.Cell>

          <Table.Cell>
            <span
              onClick={handleClickDelete.bind(null, theme)}
              style={{ color: "red", "fontWeight": "bold", fontSize: "15px", cursor: "pointer" }}>
              X
            </span>
          </Table.Cell>
        </Table.Row>)
    })
    : null

  const CreateNewThemeButton = () => (
    <Button
      onClick={() => history.push('/groupthemecreate')}
      color="green"
      size="large">
      Create New Topic
    </Button>
  )

  if (!groupthemeId_current) {
    return <CreateNewThemeButton />
  }


  return (
    <>
      <DeleteModal
        header={"Caution! This is not a reversable action."}

        modalContent={<h3 style={{ "textAlign": "center" }}>{"Are you sure you want to delete the group "}</h3>}
        icon={<Icon name='warning sign' />}
        size='small'
        showModal={!!deleteSelectionForModal} // used as boolean
        setShowModal={setDeleteSelectionForModal}
        handleConfirm={deleteConfirmed}
        handleDecline={(info) => console.log("Nothing to do here, yet", info)}
      />

      <h2>{currentClassroomName} Group Topics </h2>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine></Table.HeaderCell>
            <Table.HeaderCell>Topic</Table.HeaderCell>
            <Table.HeaderCell>Groups</Table.HeaderCell>
            <Table.HeaderCell>Date Created</Table.HeaderCell>
            <Table.HeaderCell singleLine></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {allGroupthemes}
        </Table.Body>
      </Table>
      <CreateNewThemeButton />
      <br />
      <br />
    </>
  )
};

export default GroupThemesList;



