import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { SimpleSpinner } from "../common/Spinners"
import CurrentGroupTable from './CurrentGroupTable'
import { isEmpty } from 'lodash';
// import { updateStudentProfile_2 } from '../../redux/student/studentActions'
import { getStudentGroupDetails } from '../../redux/classroom/classroomActions'

const StudentClassroomsDisplay = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { studentData: { classroomId_current, classroomIds, _id: studentId }, loading } = useSelector(state => state.student)

  const { groupthemeId_current, groupData, studentIds } = useSelector(state => state.classroom)

  const topic = groupthemeId_current?.topic

  // what to add to dependents array if TEACHER changed groups?
  useEffect(() => {
    groupthemeId_current?._id && dispatch(getStudentGroupDetails(groupthemeId_current._id, studentId))
  }, [])


  if (loading) return <SimpleSpinner />

  if (Array.isArray((groupData) || isEmpty(groupData))) return <h1>No group data</h1>

  return (<>
    {groupthemeId_current?._id && studentIds
      ? <>
        <CurrentGroupTable
          groupData={groupData}
          currentClassroomName={classroomId_current?.name}
          studentIds={studentIds}
          studentId={studentId}
          topic={topic} />
      </>
      : <h2>No group currently assigned</h2>}
    <br />
    <br />

  </>)
}


export default StudentClassroomsDisplay;