import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
//import RenderSVG from '../common/RenderSVG';
import Gif from '../common/gif'

export default function PhysicalGoodTeacherView(props) {

  const { state, state: { name, description = "", prizesRanOutFlag = null, quantityRemaining, quantityOffered, purchaserIds, purchaserLedger, image, _id: physicalGoodId } } = props.history.location

  const { error: classroomError, loading: classroomLoading, _id: classroomId } = useSelector(state => state.classroom)

  const history = useHistory()

  // useEffect(() => {
  //   console.log("LOAD")
  // }, [])

  const displayRowClickHandler = (row) => {
    console.log(row)
  }


  if (classroomLoading) { return <h1>Loading</h1> }
  if (classroomError) {
    console.log(classroomError)
    return (<><h1>Error</h1><h6>{classroomError.message}</h6></>)
  }

  const purchaserArray = Object.keys(purchaserLedger)
  const studentPurchasesDisplay = purchaserArray?.map((id) => {
    //console.log("purchaserLedger[id]", purchaserLedger[id])
    const displayRowContent =
      <tr>
        <td key={id} onClick={displayRowClickHandler}>{purchaserLedger[id].displayName}</td>
        <td>{purchaserLedger[id]?.teacherName}</td>
        <td>{purchaserLedger[id]?.classroomName}</td>
        <td> {purchaserLedger[id].quantityPurchased} </td>
        {purchaserLedger[id].delivered
          ? <td className="positive"><i className="icon checkmark"></i> Delivered</td>
          : <td className="negative"> To be delivered</td>}
      </tr>
    return displayRowContent;
  })



  return (<>
    <h1>{name}</h1>
    {image?.gif ? <Gif id={image.gif} size={200} /> : null}
    <h3>Sold: {purchaserIds.length} <br />Remaining: {quantityRemaining}</h3>
    <table className="ui celled table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Teacher</th>
          <th>Classroom</th>
          <th>Quantity</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {studentPurchasesDisplay}
      </tbody>
    </table>
    <br />
    <br />

  </>);
}




