import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { getStudents, getTeachers, getAdmins } from '../../redux/super/superActions'
import SingleStudent from "./singleStudent"
//import StudentProfile from "./studentProfile"
import { increaseStudentCoins, decreaseStudentCoins } from "../../redux/super/superActions"
import UserContainer from '../common/UserContainer'
import { Container } from "semantic-ui-react"
import "./superUsers.css"
  ;

const SuperOptions = () => {
  const dispatch = useDispatch()
  const { loading, students, teachers, admins, error } = useSelector(state => state.super)

  const handleStudentProfileClick = (stud) => {
    console.log("LOAD STUDENT PAGE--->", stud)
  }

  const handleAddCoin = (stud) => {
    const { _id } = stud
    dispatch(increaseStudentCoins(_id, 1))
  }

  const handleSubtractCoin = (stud) => {
    const { _id } = stud
    dispatch(increaseStudentCoins(_id, -1))
  }

  const handleTeacherProfileClick = (tcr) => {
    console.log("LOAD TEACHER PAGE----->", tcr)
  }

  const handleAdminProfileClick = (tcr) => {
    console.log("LOAD ADMIN---->", tcr)
  }

  useEffect(() => {
    dispatch(getStudents())
    dispatch(getTeachers())
    dispatch(getAdmins())
  }, [])



  return (
    <Container>
      <UserContainer />
      <h1>Users</h1>
      <div >
        <ul>To Do:
          <li className="todo">Add cache / fix state</li>
          <li className="todo">Add input to set coins</li>
          <li className="todo">Add User profile on name click</li>
          <li className="todo">In the profile, add/update/delete fields</li>
        </ul>
      </div>

      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>{error.message}</h2>
      ) : (
            <>
              <h2>Current Students</h2>
              <div>
                <ul>
                  {students &&
                    students.map(student =>
                      <li
                        key={student._id}>
                        <SingleStudent
                          student={student}
                          handleStudentProfileClick={handleStudentProfileClick}
                          handleSubtractCoin={handleSubtractCoin}
                          handleAddCoin={handleAddCoin}
                        />
                      </li>)}
                </ul>
              </div>
              <h2>Current Teachers</h2>
              <div>
                {teachers &&
                  teachers.map(teacher => <li
                    onClick={() => handleTeacherProfileClick(teacher)}
                    key={teacher._id}>
                    {teacher.displayName}
                  </li>)}
              </div>
              <h2>Current Administrators</h2>
              <div>
                {admins &&
                  admins.map(admin => <li
                    onClick={() => handleAdminProfileClick(admin)}

                    key={admin._id}>
                    {admin.displayName}
                  </li>)}
              </div>
            </>
          )
      }

    </Container>)
}


export default SuperOptions;


