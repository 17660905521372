import React, { useState, useEffect } from 'react';
import Confetti from 'react-dom-confetti';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { Icon, Button } from "semantic-ui-react"
import { motion } from 'framer-motion';
import { getClassroom } from "../../redux/classroom/classroomActions"
import pickRandomItem from "../../utils/pickRandomItem"
import StudentSelectedCard from "./spinnerStudentCard"
import StudentSelectedWinner from "./spinnerStudentWinner"
import { increaseStudentCoinsByTeacher } from "../../redux/teacher/teacherActions"
import RenderLottie from '../common/RenderLottie'

const confettiDelay = 3000

// const variants = {
//   hover: {
//     scale: 1.1,
//     textShadow: "0px 0px 5px rgb(255,255,255)",
//     // boxShadow: "0px 0px 8px rgb(0,0,0)",
//     transition: {
//       duration: 0.9,
//       yoyo: Infinity
//     }
//   }
// }

// const winnerTextStyle = {
//   color: "gold",
//   fontSize: "6em",
//   fontWeight: "bold",
//   // margin: "-40px 0px 1px 0px",
//   textShadow: "2px 2px 4px #000000"
// }

const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: "200",
  dragFriction: 0.12,
  duration: confettiDelay,
  stagger: 3,
  width: "20px",
  height: "20px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};


const SpinnerTeacher2 = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { setShowNavBar, location: { state: { lottie: animatedSpinner } = {} } } = props

  const { classroomData: { studentIds, settings: { spinner } = {} } } = useSelector(state => state.classroom)

  const [spinnerMoving, setSpinnerMoving] = useState(true)
  const [randomStudent, setRandomStudent] = useState(null)
  const [showConfetti, setShowConfetti] = useState(false)
  const [teaser, setTeaser] = useState(null)
  const [winner, setWinner] = useState(false)
  const [spinnerChoice, setSpinnerChoice] = useState("spinners/3074-fireworks") // fireworks as temp default

  const { teacherData:
    { classroomId_current,
      _id: teacherId,
      schoolId
    } }
    = useSelector(state => state.teacher)

  const coinName = schoolId && schoolId.coinName || "Kansha Coin"


  useEffect(() => {
    setShowNavBar(false)
    return () => setShowNavBar(true)
  }, [])

  useEffect(() => {
    if (animatedSpinner) {
      // if a brand new spinner justr set
      setSpinnerChoice("spinners/" + animatedSpinner)
    } else if (spinner) {
      // otherwise check setting
      setSpinnerChoice("spinners/" + spinner)
    }
  }, [])

  useEffect(() => {
    classroomId_current
      && dispatch(getClassroom(classroomId_current._id))
  }, [classroomId_current])

  // find better way to do this
  useEffect(() => {
    if (studentIds?.length && spinnerMoving) {
      const interval = setInterval(() => {
        randomTeaser()
      }, 400.);
      return () => clearInterval(interval);
    }
  }, [spinnerMoving, studentIds]);

  const spinnerSelector = () => {
    history.push('/spinnerselector')
  }


  const studentsRemaining = []
  const studentsCalledOn = []
  const displayStudents = studentIds && studentIds.map(student => {
    return (
      <li>{student.displayName}</li>
    )
  })


  const initateSpin = () => {
    setWinner(false)
    setSpinnerMoving(true)
    setRandomStudent(null)
  }

  const stopSpin = () => {
    setRandomStudent(pickRandomItem(studentIds))
    setSpinnerMoving(false)
  }

  const randomTeaser = () => {
    setTeaser(pickRandomItem(studentIds))
  }

  const chooseAnother = () => {
    initateSpin()
  }

  const issueToken = (student) => {
    setShowConfetti(true)
    setWinner(true)
    dispatch(increaseStudentCoinsByTeacher(student._id, 1, teacherId))
    setTimeout(initateSpin, confettiDelay)
  }

  if (!classroomId_current || !classroomId_current._id) {
    return (
      <>
        <h2>No class has been selected.</h2>
        <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
      </>
    )
  }

  if (studentIds?.length < 2) {
    return (<h2>The class needs at least two students for the wheel.</h2>)
  }


  let display = <>
    <motion.h2
      initial={{ x: 1500, y: 30 }}
      animate={{ x: 0 }}
      transition={{ delay: .25 }}>
      {teaser && teaser.displayName}
    </motion.h2>

    <div onClick={stopSpin}>
      <RenderLottie
        size={500}
        filename={spinnerChoice}
        style={{ margin: "-30px" }}
      />
    </div>

    <motion.h6
      initial={{ x: 1500, y: 25 }}
      animate={{ x: 0, y: 25 }}
      transition={{ delay: .25 }}
      style={{ margin: "-30px" }} >
      <Icon
        onClick={() => history.push('/play')}
        name='home'
        size='huge' />
    </motion.h6>
    <br />
    <br />
    <motion.h6
      initial={{ x: -1500, y: 25 }}
      animate={{ x: 0, y: 26 }}
      transition={{ delay: .45 }}
    // style={{ margin: "5px" }}
    >

      <Icon
        onClick={spinnerSelector}
        name='edit'
        size='large'
      />
    </motion.h6>
  </>


  if (randomStudent) {
    display = <>
      <StudentSelectedCard
        schoolCurrency={coinName}
        randomStudent={randomStudent}
        issueToken={issueToken}
        chooseAnother={chooseAnother} />
    </>
  }

  if (winner) {
    display = <StudentSelectedWinner
      schoolCurrency={coinName}
      randomStudent={randomStudent}
      initateSpin={initateSpin}
      issueToken={issueToken} />
  }

  return (
    <>
      <Confetti active={winner} config={confettiConfig} />
      {display}
    </>
  )
}


export default SpinnerTeacher2;

