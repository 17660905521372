import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Button, Container } from "semantic-ui-react"
import { useHistory } from 'react-router-dom'
import { useModal } from "react-modal-hook";
import ReactModal from "react-modal";

import Lottie from 'react-lottie'
import waitingPidgeon from '../../img2/lottie/ui/40101-waiting-pigeon.json'

import { SimpleSpinner } from "../common/Spinners"
import { getAllPrizes, getClassroom } from "../../redux/classroom/classroomActions"
import { getStudentBalance, clearStudentRouting } from "../../redux/student/studentActions"

import RenderSVG from '../common/RenderSVG';
import RafflePrizesDisplay from './RafflePrizesDisplay'
import RaffleTicketsDisplay from './RaffleTicketsDisplay'
import raffleTicket from "../../img/raffleTicket.png"

import useSound from 'use-sound';
import blop from '../../sound/blop.mp3'


const RaffleMainStudent = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPrizes, setCurrentPrizes] = useState(null)
  const [amountOfTicketsToBuy, setAmountOfTicketsToBuy] = useState(0)
  const [tryToBuyTicket, setTryToBuyTicket] = useState(false)
  const [playBlop] = useSound(blop);

  // A
  const {
    loading: profileLoading,
    error: profileError,
    studentData,
    studentData: { classroomId_current, _id: studentId }
  } = useSelector(state => state.student)

  // A
  const {
    classroomData: { name, schoolId },
    raffleTicketIds,
    raffle,
    prizesAll,
    //prizeMostRecent,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)

  const { active: raffleActive } = raffle || {}

  const coinName = schoolId?.coinName

  useEffect(() => {
    dispatch(getStudentBalance(studentId))
  }, [])

  useEffect(() => {
    if (classroomId_current?._id) {
      dispatch(getClassroom(classroomId_current._id))
      dispatch(getAllPrizes(classroomId_current._id))
      setCurrentPrizes(prizesAll)
    }
  }, [classroomId_current, dispatch])


  // Update  when new item added
  useEffect(() => { setCurrentPrizes(prizesAll) }, [prizesAll])


  const addTicket = () => {
    playBlop()
    return setAmountOfTicketsToBuy(amountOfTicketsToBuy + 1)
  }

  const studentTickets = raffleTicketIds?.filter((ticket) => ticket.studentId === studentId)


  // tickets graphics
  const ticketDisplay = []
  for (let i = 0; i < amountOfTicketsToBuy; i++) {
    ticketDisplay.push(<img key={i} alt={""} src={raffleTicket} width="60px" height="36px" />)
  }

  const [showRaffleInProgressModal, hideRaffleInProgressModal] = useModal(() => {

    const confirmExitModal = () => {
      setAmountOfTicketsToBuy(0)
      hideRaffleInProgressModal()
    }

    return (
      <ReactModal isOpen >
        <Container style={{ "border": "5px solid green" }}>
          <h2>Sorry, but the  {raffle?.title} Raffle is currently in progress.</h2>
          <Lottie
            width={250}
            height={250}
            options={{
              loop: false,
              autoplay: true,
              animationData: waitingPidgeon,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
          />
          <h2>You can get tickets again as soon as this raffle finishes.</h2>

          <Button color="green" size="large"
            onClick={confirmExitModal}
          >Okay</Button>

        </Container>
      </ReactModal >
    )
  }, [amountOfTicketsToBuy, studentData, raffle]
  );


  const ticketPurchaseAttempt = () => {
    // checking if raffle is in progress
    dispatch(getClassroom(classroomId_current._id))
    setTryToBuyTicket(true)
  }

  // checking if raffle is in progress
  useEffect(() => {
    if (!classroomLoading && tryToBuyTicket) {

      if (raffleActive) {
        showRaffleInProgressModal()
      } else {
        history.push('/rafflebuytickets', {
          pricePerTicket: 1, amountOfTicketsToBuy
        })
      }
    }
  }, [classroomLoading, raffleActive, tryToBuyTicket])



  if (profileLoading || classroomLoading) { return <SimpleSpinner /> }


  if (!classroomId_current || profileError || classroomError) {
    return (<>
      <h2>You need to be a part of a class in order to join a raffle.</h2>
      <Button
        onClick={() => {
          dispatch(clearStudentRouting())
          return history.push("/classroomjoin")
        }}
        size="large"
        color="blue">Join a class
      </Button>
    </>)
  }

  return (<>
    {classroomId_current && classroomId_current._id
      ? <h1>The {name && name} Raffle</h1>
      : null}
    <div>
      {/* 
      <Button size="massive" onClick={showRaffleInProgressModal}>Test Modal</Button> */}

    </div>
    <RenderSVG
      size={"200px"}
      svgFilename={"ui/general/raffleMachine"}
    />
    <br />
    <br />
    <RafflePrizesDisplay prizesAll={currentPrizes} />
    <br />
    <br />

    <RaffleTicketsDisplay
      studentData={studentData}
      studentTickets={studentTickets}
    />

    <h2 style={{ color: "skyBlue", fontSize: "2em" }}>Click to use your {coinName}s to buy raffle tickets</h2>
    <div>
      <Button onClick={addTicket} size="huge" inverted color="blue">ADD</Button>
      <br />
      <br />
    </div>
    {ticketDisplay}
    <h3 style={{ color: "skyBlue", fontSize: "1.5em" }} >Cost: 1 {coinName} per ticket x {amountOfTicketsToBuy} = {amountOfTicketsToBuy} {coinName}s</h3>
    {amountOfTicketsToBuy > 0
      ? <Button size="massive" color="blue" onClick={ticketPurchaseAttempt}>Buy {amountOfTicketsToBuy} Tickets </Button>
      : null
    }
    <br />
    <br />
  </>
  );
}


export default RaffleMainStudent;


