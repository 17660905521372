import React, { useEffect, useState } from 'react';
import { getStudentProfile } from '../../redux/student/studentActions'
import { getClassroom } from '../../redux/classroom/classroomActions'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import RenderSVG from '../common/RenderSVG';
import { SimpleSpinner } from "../common/Spinners"
//import Lottie from 'react-lottie'


const StudentStore = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentCoin } = props


  // AUTH
  const { loading, error, user: { fb_uid } } = useSelector(state => state.auth)

  // A
  const {
    loading: profileLoading,
    error: profileError,
    studentData: { displayName, avatar, classroomId_current }
  } = useSelector(state => state.student)


  // B - Classroom
  const { currentClassroom } = useSelector(state => state.student)


  //1 
  useEffect(() => {
    dispatch(getStudentProfile(fb_uid))
  }, [])



  //2
  useEffect(() => {
    classroomId_current && dispatch(getClassroom(classroomId_current._id))
  }, [classroomId_current])


  //3
  if (profileLoading || loading) {
    return <SimpleSpinner />
  }


  return (<>
    {currentClassroom && currentClassroom.schoolId
      ?
      (<>
        <h1>The {currentClassroom.schoolId.name} Store</h1>
      </>)
      :
      null}
    <div>
      <RenderSVG
        size={"300px"}
        svgFilename={"ui/general/001-shopping-cart"} />
    </div>
    <h2>A place to use your {currentCoin}s</h2>
    <h3>Coming soon!</h3>
    <br />
  </>
  );
}
export default StudentStore;







