// orig source: https://github.com/lewagon/react-giphy

import React from 'react';

const SearchBar = (props) => {
  const { searchFunction, setTitle, title = "Title" } = props
  const handleUpdate = (event) => {
    searchFunction(event.target.value);
    setTitle(event.target.value)
  }
  return (<>
    <div
      style={{ width: "500px", display: "block", margin: "auto" }}>


      <label>{title}</label>
      <input
        type="text"
        // style={{ width: "75%", display: "block", margin: "auto" }}
        onChange={handleUpdate} />
    </div>
  </>
  );
}
export default SearchBar;
