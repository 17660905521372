import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";

const NavTeacherSecond = () => {
  const [activeItem, setActiveItem] = useState('home');

  return (
    <Menu color='red' widths={2} >

      <Menu.Item
        className="menu-button"
        name='Current class'
        active={activeItem === 'currentclass'}
        onClick={() => setActiveItem('currentclass')}
        as={Link}
        to={"/currentclassroommain"}
      />

      <Menu.Item
        className="menu-button"
        name='all classes'
        active={activeItem === 'classes'}
        onClick={() => setActiveItem('classes')}
        as={Link}
        to={"/classroomsdisplay"}
      />

    </Menu>
  );
}

export default NavTeacherSecond;

