import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'

class RenderAllAvatars extends Component {

  handleClick = (avatarId) => {
    this.props.handleAvatarSelection(avatarId)
  }

  render() {
    const { size, superHeros } = this.props
    const avatarKeys = Object.keys(superHeros)

    const allAvatars = avatarKeys.map(avatarId => {
      return (
        <svg id={avatarId}
          onClick={() => this.handleClick(avatarId, superHeros[avatarId])}
          enableBackground="new 0 0 512 512"
          height={size}
          width={size}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg">
          {superHeros[avatarId]}
        </svg>
      )
    })
    return (<Container>
      {allAvatars}
    </Container>)
  }
}
export default RenderAllAvatars;


/*
<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
 */

