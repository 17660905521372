import React, { useState } from 'react';
import { useSelector } from "react-redux"
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import _ from 'lodash'
// import "./fourcorners.css"
import RenderAllAvatars from '../common/RenderAllAvatars';

// push icon, displayName, and userIde
// new name: four colors
// the person who is "it" can keep the colors or hit shuffle
// make a distint "chime sound" for each color

const FourCorners = (props) => {
  console.log("props:", props)
  const { studentDataFromProps: { studentData: { _id: studentId } } } = props

  const { loading: profileLoading, error: profileError, studentData } = useSelector(state => state.student)
  const { avatar, displayName, _id } = studentData || null

  const [avatarPosition, setAvatarPosition] = useState(null)
  const [avatarSize, setAvatarSize] = useState(50)


  const gameCode = { active: true } // for what?

  // get from classroom settings and teacherDash
  const boardColors = {
    upperLeft: "yellow",
    upperRight: "green",
    lowerLeft: "red",
    lowerRight: "orange"
  }

  // [displayName, avatar]
  const [avatarsUpperRight, setAvatarsUpperRight] = useState([])
  const [avatarsUpperLeft, setAvatarsUpperLeft] = useState([])
  const [avatarsLowerRight, setAvatarsLowerRight] = useState([])
  const [avatarsLowerLeft, setAvatarsLowerLeft] = useState([])


  const addPlayerToPosition = function ({ _id, avatarId, avatarSet, displayName }, square) {
    const playerPod = {
      _id, avatarId, avatarSet, displayName
    }

    if (square === "UR") {
      const newAvatarsUR = [...avatarsUpperRight]
      newAvatarsUR.push(playerPod)
      setAvatarsUpperRight(newAvatarsUR)
    }

    if (square === "UL") {
      const newAvatarsUL = [...avatarsUpperLeft]
      newAvatarsUL.push(playerPod)
      setAvatarsUpperLeft(newAvatarsUL)
    }

    if (square === "LL") {
      const newAvatarsLL = [...avatarsLowerLeft]
      newAvatarsLL.push(playerPod)
      setAvatarsLowerLeft(newAvatarsLL)
    }

    if (square === "LR") {
      const newAvatarsLR = [...avatarsLowerRight]
      newAvatarsLR.push(playerPod)
      setAvatarsLowerRight(newAvatarsLR)
    }

    return null
  }

  const handleBoxSelection = (position) => {
    setAvatarPosition(position)
    console.log("position", position)
    addPlayerToPosition({
      _id: studentId,
      avatarId: avatar,
      avatarSet: "superHeros",
      displayName
    },
      position)

  }

  const handleAvatarClick = () => {
    console.log("GIGGLE")
    setAvatarSize(avatarSize + 10)
  }

  console.log("avatar", avatar, displayName, _id, gameCode)

  // make a PLAYER CLASS
  const playerTemplate = {
    avatar: "la",
    displayName: "lala",
    a: null,
    b: null,
    c: null
  }

  // render all avatars with hover over name quality
  let renderAvatarsUL
  let renderAvatarsUR
  let renderAvatarsLL
  let renderAvatarsLR


  return (
    <>
      <h1>Four Colors</h1>
      <h6>Click on the color to hide under</h6>
      {avatarPosition}

      <div id="four-corners">
        {/*   <section id="timer" onClick={handleBoxSelection.bind(null, "TIME START")}>Timer here</section> */}

        <section id="upper-left" onClick={handleBoxSelection.bind(null, "UL")} >
          {JSON.stringify(avatarsUpperLeft)}
        </section>

        <section id="upper-right" onClick={handleBoxSelection.bind(null, "UR")}>
          {JSON.stringify(avatarsUpperRight)}
        </section>

        <section id="lower-left" onClick={handleBoxSelection.bind(null, "LL")} >
          {JSON.stringify(avatarsLowerLeft)}
        </section>

        <section id="lower-right" onClick={handleBoxSelection.bind(null, "LR")}>
          {JSON.stringify(avatarsLowerRight)}

        </section>

        <section id="lower-across" onClick={handleAvatarClick}>
          <RenderAvatar
            size={avatarSize}
            avatarId={avatar.avatarId}
            setOfAvatars={superHeros}
          />
        </section>
      </div>
    </>
  )

}

export default FourCorners

