import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { SimpleSpinner } from "../common/Spinners"
import { getAllTransactions, deleteTransaction } from "../../redux/classroom/classroomActions"
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import RenderSVG from '../common/RenderSVG';

const CurrentClass = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [showTransactions, setShowTransactions] = useState(null)
  const [transactionToEdit, setTransactionToEdit] = useState(null)
  const [refresh, setRefresh] = useState(null)

  const {
    loading, teacherData: {
      classroomId_current,
      schoolId } } = useSelector(state => state.teacher)

  const coinName = schoolId?.coinName

  const { classroomTransactionsAll, groupthemeIds, studentIds, groupthemeId_current, image, grade = "-" } = useSelector(state => state.classroom)

  const classroomImage = image?.svg

  const toggleTransactions = () => {
    setShowTransactions(!showTransactions)
  }
  const onClickDeleteTransaction = (trans) => {
    setTransactionToEdit(trans)
    showDeleteModal()
  }

  const confirmDeleteTransaction = (trans) => {
    dispatch(deleteTransaction(transactionToEdit._id))
    setRefresh(true)
    hideDeleteModal()
  }


  useEffect(() => {
    classroomId_current && dispatch(getAllTransactions(classroomId_current._id))
  }, [showTransactions, refresh])

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return (
      <ReactModal isOpen>
        <div>
          <h2>Are you sure you want to delete the transaction record comment?</h2>
          <h6>DATE: {transactionToEdit.datePosted}</h6>
          <h6>SENDER: {transactionToEdit.senderId?.displayName}</h6>
          <h6>RECEIVER: {transactionToEdit.receiverId?.displayName}</h6>
          <h6>AMOUNT:{transactionToEdit.amount} </h6>
          <h6>MESSAGE:  {transactionToEdit.message}</h6>
          <Button color="red" size="large" onClick={confirmDeleteTransaction}>Yes. Permanently remove this comment from the transactions</Button>
        </div>
        <br />
        <div>
          <Button color="grey" size="large" onClick={hideDeleteModal}>Exit</Button>
        </div>
      </ReactModal>
    )
  },
    [transactionToEdit]
  );

  useEffect(() => {
    classroomId_current && dispatch(getAllTransactions(classroomId_current._id))
  }, [showTransactions])



  if (loading) {
    return <SimpleSpinner />
  }

  const transactionsDisplay = classroomTransactionsAll?.map((trans) => {
    return (
      <>
        <h6 style={{ "margin": ".3em" }}>
          {trans.datePosted}  <span style={{ "color": "blue" }}>
            {trans.senderId.firstName} sent {trans.amount} {coinName}{trans.amount === 1 ? null : "s"} to {trans.receiverId.firstName}:
          </span>

          <span style={{ "color": "green" }}>
            {" "}  {trans.message} {" "}
          </span>

          <span
            onClick={onClickDeleteTransaction.bind(null, trans)}
            style={{ cursor: 'pointer' }}>{" "} ❌ </span>
        </h6>
      </>)
  })

  if (!schoolId) {
    return (
      <>
        <h2> You must be part of a school before creating a classroom.</h2>
        <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
      </>
    )
  }

  if(!classroomId_current) {
    return <>
    <h2>No classroom currently selected</h2>
    <Button color="green" onClick={() => history.push('/classroomscreate')}>Create a New Class</Button>
    </>
  }

  return (<>
    <h1>{classroomId_current?.name}</h1>

    {classroomImage &&
      <RenderSVG
        size="150"
        svgFilename={classroomImage} />}

    <h2>Grade: {grade}</h2>
    <h2>Students: {studentIds && studentIds.length}</h2>
    <h4>Click to show a simple list of all students and icons</h4>
    <h2>Group Sets: {groupthemeIds && groupthemeIds.length}</h2>
    <h4>Click to show a list of the group categories ans the groups within it</h4>
    <h2 onClick={toggleTransactions} style={{ cursor: 'pointer' }}>Transactions: {classroomTransactionsAll && classroomTransactionsAll.length}</h2>
    {showTransactions ? transactionsDisplay : null}
    <Button
      color="blue"
      onClick={() => history.push('/classroomsdisplay')}
    >See All Classes</Button>
  </>
  );
}
export default CurrentClass;



