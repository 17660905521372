import React, { useState, useEffect } from 'react';

const RenderSVG = (props) => {
  const { size, svgFilename } = props
  let [icon, setIcon] = useState('');

  useEffect(() => {
    async function makeItHappen() {
      try {

        let importedIcon = await import(`../../img2/${svgFilename}.svg`);
        setIcon(importedIcon.default);
      } catch (err) {
        console.error(err)
        setIcon(null);
      }
    }
    makeItHappen()
  }, [svgFilename, size]);


  return (<>
    <img
      height={size}
      width={size}
      alt={svgFilename}
      src={icon}
    />
  </>)
};

export default RenderSVG;

