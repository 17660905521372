const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: "200",
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "20px",
  height: "20px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

export default confettiConfig