import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { SimpleSpinner } from "../common/Spinners"
import { Table, Button } from 'semantic-ui-react'
import { updateStudentProfile_2 } from '../../redux/student/studentActions'
import { getClassroom } from '../../redux/classroom/classroomActions'

export default function ClassroomsDisplayList() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { studentData: { classroomId_current, classroomIds, _id: studentId }, loading } = useSelector(state => state.student)

  // const { groupthemeId_current, groupData, studentIds } = useSelector(state => state.classroom)

  const setCurrentClassroom = (classroom) => {
    const updatedData = { classroomId_current: classroom._id }
    dispatch(updateStudentProfile_2(studentId, updatedData))
    dispatch(getClassroom(classroom._id))
  }

  // const onClickDelete = (classroom) => {
  //   //setDeleteSelectionForModal(classroom)
  // }

  // const deleteConfirmed = () => {
  //   //dispatch(deleteClass(deleteSelectionForModal._id, teacherId))
  // }

  const allClassrooms = classroomIds
    ? classroomIds.map(classroom => {

      if (!classroom) { return }
      let activeLight = "🔘"
      if (classroomId_current) {
        if (classroomId_current._id === classroom._id) {
          activeLight = "🟢"
        }
      }
      // console.log("classroom", classroom)
      return (<>

        <Table.Row key={classroom._id} style={{ cursor: 'pointer' }} onClick={setCurrentClassroom.bind(null, classroom)}>

          <Table.Cell>
            <span
              // onClick={setCurrentClassroom.bind(null, classroom)}
              data-tip={"Set as current class"}>
              {activeLight}
            </span>
          </Table.Cell>

          <Table.Cell> {classroom?.name}</Table.Cell>
          <Table.Cell> {classroom?.grade ? classroom?.grade : null}</Table.Cell>
          <Table.Cell> {classroom?.teacherIds[0]?.displayName}</Table.Cell>
          <Table.Cell> {classroom?.description}</Table.Cell>
        </Table.Row>

      </>)
    })

    : null

  if (loading) return <SimpleSpinner />

  return (<>
    <h1>All Classes</h1>


    <Table celled padded>
      <Table.Header>
        <Table.Row>

          <Table.HeaderCell>Current</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Grade</Table.HeaderCell>
          <Table.HeaderCell>Teacher</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>

        </Table.Row>
      </Table.Header>

      <Table.Body>{allClassrooms}</Table.Body>

    </Table>
    <Button onClick={() => history.push('/classroomjoin')}>Join a new class</Button>
    <br />
  </>
  )
}
