import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SuperRoute = ({ component: RouteComponent, ...rest }) => {
  const user = useSelector(state => state.auth.user)
  // const superData = useSelector(state => state.super)

  const { userType } = user || {}
  return (
    <Route {...rest}
      render={routeProps =>
        !user || userType !== "super"
          ? (<Redirect to={"/login"} />)
          : (<RouteComponent {...routeProps} />)
      }
    />
  )
}

export default SuperRoute