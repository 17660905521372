import React, { useState } from 'react';
import { Container, Button } from "semantic-ui-react"
import { useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import DisplayBoard from './PhysicalGoodsDisplayBoard'
import Lottie from 'react-lottie'
import school from '../../img2/lottie/ui/64386-university-school-icon.json'


const SchoolStoreTeacher = () => {
  const {
    error,
    loading,
    _id: classroomId,
    schoolId,
    // schoolwidePhysicalGoods,
    // physicalGoodMostRecentClassroom
  } = useSelector(state => state.classroom)

  const { name: schoolName, _id: currentSchoolId } = schoolId || {}
  const [showOnlyCurrentlyAvailable, setShowOnlyCurrentlyAvailable] = useState(false)

  const history = useHistory()

  const viewItem = (pg) => {
    history.push("/physicalgoodteacherview", pg)
  }
  return (
    <Container>
      <h1 style={{ margin: "10px 5px 0px 5px" }}> {schoolName} Schoolwide Store</h1>
      <Lottie
        width={150}
        height={150}
        options={{
          loop: false,
          autoplay: true,
          animationData: school,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }} />
      <br />

      <DisplayBoard
        showOnlyCurrentlyAvailable={showOnlyCurrentlyAvailable} viewItem={viewItem}
        schoolId={currentSchoolId} />

      <Button
        color="blue"
        onClick={() => setShowOnlyCurrentlyAvailable(prev => !prev)}>
        {showOnlyCurrentlyAvailable ? "Show everything" : "Show only what is available"}
      </Button>

      {/*       
      <h3>Get classroom-only items: {JSON.stringify(classroomId)} </h3> */}
      <br />
      <br />
      <br />

    </Container>
  )
};
export default SchoolStoreTeacher;
