import React from 'react';
import { useSelector } from "react-redux"
import convertNameToInitials from "../../utils/convertNameToInitials"

const UserContainer = (props) => {
  const { color, fontSize, pretext = "", posttext = "" } = props
  const authState = useSelector(state => state.auth)
  const { user: { displayName } } = authState || null
  const initials = convertNameToInitials(displayName)

  return (
    <h6 style={{ color, fontSize }}> {displayName ? displayName.split(" ")[0] + " " + initials[1] : ""} </h6>
  )
}

export default UserContainer
