export const GET_STUDENT_PROFILE_REQUEST = "GET_STUDENT_PROFILE_REQUEST"
export const GET_STUDENT_PROFILE_SUCCESS = "GET_STUDENT_PROFILE_SUCCESS"
export const GET_STUDENT_PROFILE_FAILURE = "GET_STUDENT_PROFILE_FAILURE"

export const CREATE_STUDENT_PROFILE_REQUEST = "CREATE_STUDENT_PROFILE_REQUEST"
export const CREATE_STUDENT_PROFILE_SUCCESS = "CREATE_STUDENT_PROFILE_SUCCESS"
export const CREATE_STUDENT_PROFILE_FAILURE = "CREATE_STUDENT_PROFILE_FAILURE"

export const UPDATE_STUDENT_PROFILE_REQUEST = "UPDATE_STUDENT_PROFILE_REQUEST"
export const UPDATE_STUDENT_PROFILE_SUCCESS = "UPDATE_STUDENT_PROFILE_SUCCESS"
export const UPDATE_STUDENT_PROFILE_FAILURE = "UPDATE_STUDENT_PROFILE_FAILURE"

export const DELETE_STUDENT_PROFILE_REQUEST = "DELETE_STUDENT_PROFILE_REQUEST"
export const DELETE_STUDENT_PROFILE_SUCCESS = "DELETE_STUDENT_PROFILE_SUCCESS"
export const DELETE_STUDENT_PROFILE_FAILURE = "DELETE_STUDENT_PROFILE_FAILURE"

export const CHECK_CLASS_CODE_REQUEST = "CHECK_CLASS_CODE_REQUEST"
export const CHECK_CLASS_CODE_SUCCESS = "CHECK_CLASS_CODE_SUCCESS"
export const CHECK_CLASS_CODE_FAILURE = "CHECK_CLASS_CODE_FAILURE"

export const JOIN_CLASS_WITH_CODE_REQUEST = "JOIN_CLASS_WITH_CODE_REQUEST"
export const JOIN_CLASS_WITH_CODE_SUCCESS = "JOIN_CLASS_WITH_CODE_SUCCESS"
export const JOIN_CLASS_WITH_CODE_FAILURE = "JOIN_CLASS_WITH_CODE_FAILURE"

export const GET_CLASSROOM_REQUEST_STUDENT = "GET_CLASSROOM_REQUEST_STUDENT"
export const GET_CLASSROOM_SUCCESS_STUDENT = "GET_CLASSROOM_SUCCESS_STUDENT"
export const GET_CLASSROOM_FAILURE_STUDENT = "GET_CLASSROOM_FAILURE_STUDENT"

export const ADD_GROUP_TO_STUDENT_REQUEST = "ADD_GROUP_TO_STUDENT_REQUEST"
export const ADD_GROUP_TO_STUDENT_SUCCESS = "ADD_GROUP_TO_STUDENT_SUCCESS"
export const ADD_GROUP_TO_STUDENT_FAILURE = "ADD_GROUP_TO_STUDENT_FAILURE"

export const REMOVE_GROUP_FROM_STUDENT_REQUEST = "REMOVE_GROUP_FROM_STUDENT_REQUEST"
export const REMOVE_GROUP_FROM_STUDENT_SUCCESS = "REMOVE_GROUP_FROM_STUDENT_SUCCESS"
export const REMOVE_GROUP_FROM_STUDENT_FAILURE = "REMOVE_GROUP_FROM_STUDENT_FAILURE"

// REMOVE CLASSROOM FROM STUDNET in case of entire classroom delete
export const REMOVE_CLASSROOM_FROM_STUDENT_REQUEST = "REMOVE_CLASSROOM_FROM_STUDENT_REQUEST"
export const REMOVE_CLASSROOM_FROM_STUDENT_SUCCESS = "REMOVE_CLASSROOM_FROM_STUDENT_SUCCESS"
export const REMOVE_CLASSROOM_FROM_STUDENT_FAILURE = "REMOVE_CLASSROOM_FROM_STUDENT_FAILURE"


export const ADJUST_STUDENT_COINS_BY_TRANSFER_REQUEST = "ADJUST_STUDENT_COINS_BY_TRANSFER_REQUEST"
export const ADJUST_STUDENT_COINS_BY_TRANSFER_SUCCESS = "ADJUST_STUDENT_COINS_BY_TRANSFER_SUCCESS"
export const ADJUST_STUDENT_COINS_BY_TRANSFER_FAILURE = "ADJUST_STUDENT_COINS_BY_TRANSFER_FAILURE"

// -- delete --? 
export const ADJUST_STUDENT_COINS_BY_DONATION_REQUEST = "ADJUST_STUDENT_COINS_BY_DONATION_REQUEST"
export const ADJUST_STUDENT_COINS_BY_DONATION_SUCCESS = "ADJUST_STUDENT_COINS_BY_DONATION_SUCCESS"
export const ADJUST_STUDENT_COINS_BY_DONATION_FAILURE = "ADJUST_STUDENT_COINS_BY_DONATION_FAILURE"


export const GET_STUDENT_BALANCE_REQUEST = "GET_STUDENT_BALANCE_REQUEST"
export const GET_STUDENT_BALANCE_SUCCESS = "GET_STUDENT_BALANCE_SUCCESS"
export const GET_STUDENT_BALANCE_FAILURE = "GET_STUDENT_BALANCE_FAILURE"


export const CLEAR_STUDENT_ROUTING = "CLEAR_STUDENT_ROUTING"
export const CLEAR_STUDENT = "CLEAR_STUDENT"