import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateCommonGood, deleteCommonGood } from '../../redux/classroom/classroomActions'
import { increaseStudentCoinsByTeacher } from "../../redux/teacher/teacherActions"
import { SimpleSpinner } from "../common/Spinners"
import RenderSVG from '../common/RenderSVG';


export default function CommonGoodsEdit(props) {
  const { state: { _id: commonGoodId, title, description = "", targetAmount, contributorIds, contributorLedger, image: { svg } } } = props.history.location


  const [tokenRefunds, setTokenRefunds] = useState([])
  // watch removed
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      title,
      description,
      targetAmount
    }
  });

  const dispatch = useDispatch()
  const history = useHistory()
  const { error, loading, onCompleteRoute } = useSelector(state => state.classroom)

  const onSubmit = update => {
    update.targetAmount = parseInt(update.targetAmount)
    dispatch(updateCommonGood(commonGoodId, update))
  };

  const onSubmitDelete = () => {
    const contributorIdsContributions = [...contributorIds]

    contributorIdsContributions.forEach(item => {
      // console.log(item)
      item.contribution = contributorLedger[item._id][0]
    })

    setTokenRefunds(contributorIdsContributions)

    // refund
    contributorIds.forEach(student => {
      const amountToRefund = contributorLedger[student._id][0]

      // const tempRefundDisplay = [...refundDisplay]
      // tempRefundDisplay.push([student.displayName, amountToRefund])

      // third argument is null 
      // as to not subtract from teacher token "supply"

      dispatch(increaseStudentCoinsByTeacher(student._id, amountToRefund, null))
    })

    dispatch(deleteCommonGood(commonGoodId))
  }


  if (onCompleteRoute) {
    history.push(onCompleteRoute, tokenRefunds)
  }

  if (loading) { return <SimpleSpinner /> }

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }

  return (
    <>
      <h1>Edit: {title}</h1>
      <RenderSVG
        size={"100"}
        svgFilename={svg} />
      <form onSubmit={e => e.preventDefault()}>
        <label>Name</label>
        <input
          name="title"
          type="text"
          ref={register({
            required: "You must include a common good title",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.title && <p>{errors.title.message}</p>}

        <label>Description (optional)</label>
        <input
          name="description"
          type="text"
          ref={register()}
        />
        <label>Target Price</label>
        <input
          name="targetAmount"
          type="text"
          ref={register()}
        />

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Update {title}</Button>

      </form>
      <br />
      <Button onClick={onSubmitDelete} size="tiny" color="black">Refund & Delete</Button>
      <br />
      <br />

    </>
  );
}