import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import generatePassword from 'password-generator'
import { updateClassroom } from '../../redux/classroom/classroomActions'
import classroomIcons from '../../data/icons_classrooms.json'
import RenderSVG from '../common/RenderSVG'

export default function ClassroomEdit(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { state: { _id: classroomId, name, joinCode, grade = "-", description = "", image } } = props.history.location

  const [joinCodeLocal, setJoinCodeLocal] = useState(joinCode)
  const [classroomUpdated, setClassroomUpdated] = useState(false)

  const iconNumberOrig = classroomIcons.set_1.findIndex(object => {
    return object.english === image?.english;
  });

  const [iconNumber, setIconNumber] = useState(iconNumberOrig !== -1 ? iconNumberOrig : 0)

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name,
      grade,
      joinCode: joinCodeLocal,
      description
    }
  });


  const { error, loading } = useSelector(state => state.classroom)

  const onSubmit = updatedClassroomData => {
    updatedClassroomData.joinCode = joinCodeLocal
    updatedClassroomData.image = classroomIcons.set_1[iconNumber]
    dispatch(updateClassroom(classroomId, updatedClassroomData))
    setClassroomUpdated(true)
  };


  const changeIcon = () => {
    let newIconNumber = iconNumber + 1
    if (newIconNumber > classroomIcons.set_1.length - 1) {
      newIconNumber = 0
    }
    setIconNumber(newIconNumber)
  }

  const updateJoinCode = () => {
    setJoinCodeLocal(generatePassword(8))
  }

  if (loading && classroomUpdated) { history.push("/classroomsdisplay") }

  if (loading) return <h1>Loading</h1>

  if (error) {
    return <>
      <h1>Error</h1>
      <h6>{error.message}</h6>
    </>
  }


  return (
    <>
      <h1>Edit Classroom</h1>
      {iconNumber !== null &&
        <div onClick={changeIcon}>
          <RenderSVG
            size="100"
            // svgFilename={icon}
            svgFilename={classroomIcons.set_1[iconNumber]?.svg}
          />
        </div>}

      <form onSubmit={e => e.preventDefault()}>

        <label>Name</label>
        <input
          name="name"
          type="text"
          ref={register({
            required: "You must include a classroom name",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.name && <p>{errors.name.message}</p>}

        <label>Grade</label>
        <input
          name="grade"
          type="text"
          ref={register()}
        />

        <label>Description (optional)</label>
        <input
          name="description"
          type="text"
          ref={register()}
        />

        <label >Join Code (click to regenerate randomly)</label>
        <h3 style={{ fontSize: '3em', cursor: 'pointer' }} onClick={updateJoinCode}>{joinCodeLocal}</h3>

        <br />
        <br />


        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save</Button>

      </form>

    </>
  );
}




