import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { updateRaffle } from '../../redux/classroom/classroomActions'
//import RenderSVG from '../common/RenderSVG'



export default function CommonGoodCreate() {


  const { raffle: { pricePerTicket: oldPrice, ticketsRemaining } } = useSelector(state => state.classroom)




  const { register, errors, handleSubmit, watch } = useForm({
    // defaultValues: {
    //   firstName,
    //   lastName,
    //   email
    // }
  });
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()

  const [amountOfTickets, setAmountOfTickets] = useState(ticketsRemaining); // from databsae
  const [pricePerTicket, setPricePerTicket] = useState(oldPrice);

  const { teacherData: { classroomId_current }, loading } = useSelector(state => state.teacher)

  const { onCompleteRoute } = useSelector(state => state.classroom)


  const onSubmit = ticketData => {

    // get this from state
    const raffleDetails = {
      active: true,
      title: "",
      description: "",
      dateCreated: "",
      datedEdited: "",
      dateOfEvent: ""
    }

    //classroomId_current._id
    if (classroomId_current?._id) {
      raffleDetails.ticketsRemaining = parseInt(amountOfTickets)
      raffleDetails.pricePerTicket = parseInt(pricePerTicket)

      const dataToSend = {
        raffle: raffleDetails
      }

      //console.log("DATA TO SEND", dataToSend)
      dispatch(updateRaffle(classroomId_current._id, dataToSend))

    } else {
      console.error("CHECK INPUTS")
    }

  };


  const handleTicketAdjust = (tickets) => {
    if (tickets < 1) { return }
    setAmountOfTickets(tickets)
  }

  const handlePriceAdjust = (price) => {
    if (price < 1) { return }
    setPricePerTicket(price)
  }

  if (onCompleteRoute) { history.push(onCompleteRoute) }

  if (loading) { return <h1>Loading</h1> }

  return (
    <>
      <h1>Tickets</h1>

      <form onSubmit={e => e.preventDefault()}>

        <label>Tickets Available</label>
        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={amountOfTickets} // get from 
          onChange={(e) => handleTicketAdjust(e.target.value)}
        />

        <h1>{" "}</h1>
        <label>Cost of each ticket</label>
        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={pricePerTicket}
          onChange={(e) => handlePriceAdjust(e.target.value)}
        />
        <h1>{" "}</h1>

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save Tickets
        </Button>

        <h2>{"   "}</h2>

      </form>
      <h1>{" "}</h1>

    </>
  );
}




