import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import { Segment, Button, Container } from 'semantic-ui-react'
import { ToastContainer } from 'react-toastify'
import { clearStudentRouting } from '../../redux/student/studentActions'
import { getClassroom } from '../../redux/classroom/classroomActions'
import useSound from 'use-sound';
//import UserContainer from '../common/UserContainer'
import WelcomeNewStudent from './welcomeNewUser'
import SchoolDetails from './SchoolDetails.jsx'
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import { SimpleSpinner } from "../common/Spinners"
import { motion } from 'framer-motion';
import goat from '../../sound/goat.mp3'
import notify from '../../utils/notify'
// import SvgSlider from "../common/SvgSlider"
// import RenderSVG from '../common/RenderSVG';
// import Mysteries from './Mysteries';

import firebase from "../../config/fbConfig"
import useGoogleLogout from '../hooks/useGoogleLogout'
import { clearUserGL } from '../../redux/auth/authActions';
import { clearSuper } from '../../redux/super/superActions'
import { clearStudent } from '../../redux/student/studentActions'
import { clearTeacher } from '../../redux/teacher/teacherActions'
import { clearClassroom } from '../../redux/classroom/classroomActions'
import { clearAdmin } from '../../redux/admin/adminActions'


const StudentDashboard = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const googleLogout = useGoogleLogout()

  const { setCurrentCoin, setShowNavBar } = props
  console.log("STUDENT props===>", props)

  const [playGoat] = useSound(goat);

  const [playSounds, setPlaySounds] = useState(false)
  // const [showSlider, setShowSlider] = useState(true)

  const [profileEditingPermission, setProfileEditingPermission] = useState(true) // change this on studentData Object

  // AUTH
  const { loading: authLoading, error: studentAuthError, user } = useSelector(state => state.auth)

  // A
  const {
    loading: studentLoading,
    error: studentLoadingError,
    studentData
  } = useSelector(state => state.student)

  const { displayName, avatar, classroomId_current, isNewStudentAccount } = studentData || {}


  // B - Classroom
  const { classroomData: currentClassroom, image, grade = "" } = useSelector(state => state.classroom)

  const classroomImage = image?.svg

  // C - avatar fun
  const [avatarSize, setAvatarSize] = useState(350)
  const [animateAvatar, setAnimateAvatar] = useState(false)

  const signOut = () => {
    // all are needed?
    dispatch(clearSuper())
    dispatch(clearStudent())
    dispatch(clearTeacher())
    dispatch(clearClassroom())
    dispatch(clearAdmin())
    dispatch(clearUserGL())

    localStorage.clear();
    firebase.auth().signOut().then(() => { googleLogout() })
  }


  useEffect(() => {
    dispatch(clearStudentRouting())
  }, [dispatch])


  useEffect(() => {
    if (classroomId_current) {dispatch(getClassroom(classroomId_current._id))}
  }, [classroomId_current])


  useEffect(() => {
    if (currentClassroom?.schoolId?.coinName) {setCurrentCoin(currentClassroom.schoolId.coinName)}
  }, [studentLoading])

  useEffect(() => {
    if(isNewStudentAccount) setShowNavBar(false)
    else setShowNavBar(true)
  }, [isNewStudentAccount])


  const handleEditStudentProfile = () => {
    if (profileEditingPermission) {
      const userObj = { ...user }
      userObj.fullAuthObject = null
      history.push('/studentprofileedit/', { userObj, studentData })
      // 
    } else {
     notify({ message: "Sorry, you can not edit your profile name", position: "TOP_CENTER", noticeType: "warning" })
      if (playSounds) { playGoat() }
    }
  }

  //4
  if (studentLoading || authLoading) { return <SimpleSpinner /> }
  if (!studentData || studentAuthError || studentLoadingError ) { history.push("/loginerror") }

  // --- REGULAR USER FLOW --- //

  if (isNewStudentAccount){
    return ( <>
      <h1>Welcome to Kansha!</h1>
      <Segment>
      <h2><WelcomeNewStudent data={studentData} /></h2>
      </Segment>
      <Button color="black" onClick={signOut}>Log Out</Button>
    </ >
    )}


return (<Container>
  <ToastContainer />
  <div id="dashboardsvg">
          <RenderAvatar
            size={avatarSize}
            avatarId={avatar.avatarId}
            setOfAvatars={superHeros}
          />
        </div>
        {animateAvatar
          ? <motion.div
            initial={{ scale: .2 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 300 }} >
            <RenderAvatar
              size={80}
              avatarId={avatar.avatarId}
              setOfAvatars={superHeros} />
          </motion.div>
          : null}

          <h2
          onClick={() => handleEditStudentProfile()}
          style={{ cursor: 'pointer', fontSize: "5em" }}
          className="hvr-grow">{displayName}
          </h2>

          {currentClassroom?.teacherIds
          ? (<><h3
            style={{ "fontSize": "2em", cursor: 'pointer' }}
            onClick={() => history.push("/classroomsdisplaylist/")}> {currentClassroom?.teacherIds[0]?.displayName}'s {currentClassroom.name}
            <br />
            Grade {grade ? grade : null}
          </h3>
          <br /></>)
          : null}

        {currentClassroom && currentClassroom?.schoolId
          ? <SchoolDetails schoolData={currentClassroom?.schoolId} />
          : studentData?.classroomIds.length > 0
            ? <div>
              <Button
              size="massive"
              color="green"
              onClick={() => history.push("/classroomsdisplaylist")}
              >Please update your current classroom
             
            </Button>
              </div>
            : <div>
              <Button
                onClick={() => history.push("/classroomjoin")}
                size="huge"
                color="blue">Please add a class
              </Button>
      
            </div>}
            <br/>
        <Button color="black" onClick={signOut}>Log Out</Button>
        <br/>
        <br/>
        <br/>
</Container>)
}

export default StudentDashboard;



/* const handleCheckIn = (id) => {
  console.log("SEND Time and ID to db:", id)
  //setAlreadyCheckedIn(true) // layter set this IN check-in component which updates monggo
  history.push('/checkin')

  // push to /checkin
  // how are you feeling?
  //  on a scale of 1 to 10
  // create /studentcheckin/
  // {
  //   q1:
  //   q2:
  //   comment:
  // }
  // push to an object, or a set!--> to checkin record
  // key = date+AM/PM , value os object above
}
 */