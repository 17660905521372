import React, { useState } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { updateTeacherNavbar } from "../../redux/teacher/teacherActions"
import { useDispatch, useSelector } from "react-redux"


const NavTeacherTokens = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    navbar: { tokens: tokensNavbarState } } = useSelector(state => state.teacher)


  const [activeItem, setActiveItem] = useState(tokensNavbarState || 'tokens');

  const tokenomicsView = () => {
    setActiveItem('tokens')
    dispatch(updateTeacherNavbar({ tokens: "tokens" }))
    history.push("/tokens")
  }

  const commongoodsView = () => {
    setActiveItem('commongoodsteacher')
    dispatch(updateTeacherNavbar({ tokens: "commongoodsteacher" }))
    history.push("/commongoodsteacher")
  }

  const raffleView = () => {
    setActiveItem('rafflemainteacher')
    dispatch(updateTeacherNavbar({ tokens: "rafflemainteacher" }))
    history.push("/rafflemainteacher")
  }

  const storeView = () => {
    setActiveItem('schoolstoreteacher')
    dispatch(updateTeacherNavbar({ tokens: "schoolstoreteacher" }))
    history.push("/schoolstoreteacher")
  }


  return (
    <Menu color='red' widths={4} >

      <Menu.Item
        className="menu-button"
        name='tokens'
        active={activeItem === 'tokens'}
        onClick={tokenomicsView}>
          <Icon name='chart line' size='large'/>
          <span>Tokenomics</span>
          </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='commongoodsteacher'
        active={activeItem === 'commongoodsteacher'}
        onClick={commongoodsView}>
          <Icon name='group' size='large'/>
          <span>Community</span>
          </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='rafflemainteacher'
        active={activeItem === 'rafflemainteacher'}
        onClick={raffleView}>
          <Icon name='ticket' size='large'/>
          <span>Raffle</span>
          </Menu.Item>

      <Menu.Item
        className="menu-button"
        name='schoolstoreteacher'
        active={activeItem === 'schoolstoreteacher'}
        onClick={storeView}>
        <Icon name='shopping cart' size='large'/>
        <span>Store</span>
      </Menu.Item>

    </Menu>
  );
}

export default NavTeacherTokens;

