
// This function breaks array into nuumber of groups, but unlike Lodash chunk, 
// remainders are integrated into groups beginning with index[0] and ascending

const myChunk = (arr, numberOfGroups) => {

  const result = []
  const size = Math.floor(arr.length / numberOfGroups)
  const remainderLength = arr.length % numberOfGroups

  // the actual remainder slice out of input after groups evenly created
  let remainderDistribution = arr.slice((arr.length) - remainderLength, arr.length)


  // build groups
  let beginningPoint = 0
  let endpoint = 0
  let remainderToAdd = 0

  // each iteration slices a group from input arr
  for (let i = 0; i <= numberOfGroups - 1; i++) {

    // if item exists in remainderDistribution, endpoint increased by 1
    remainderToAdd = remainderDistribution[i] ? 1 : 0
    endpoint = beginningPoint + size + remainderToAdd

    // create subarray i
    result[i] = arr.slice(beginningPoint, endpoint)

    // move points for next iteration
    beginningPoint = endpoint
  }
  return result
}

// let mainArray = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m"]
// let groups = 3
// const result = myChunk(mainArray, groups) //expected output [[a,b,c,d], [e,f,g], [h,i,j]]
// console.log(result)

export default myChunk