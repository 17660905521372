import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { createPrize, getAllPrizes } from '../../redux/classroom/classroomActions'
import GifUI from "../common/gif_UI"


export default function PrizeCreate() {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()

  const [amount, setAmount] = useState(1);
  const [selectedGifId, setSelectedGifId] = useState("xT9IgDEI1iZyb2wqo8")
  const [prizeSubmitted, setPrizeSubmitted] = useState(false)
  const [title, setTitle] = useState("")

  const { teacherData: { classroomId_current, schoolId: { _id: schoolId, coinName } }, loading } = useSelector(state => state.teacher)

  // const { onCompleteRoute } = useSelector(state => state.classroom)

  const onSubmit = prizeData => {
    setPrizeSubmitted(() => true)

    if (classroomId_current?._id) {
      prizeData.classroomId = classroomId_current._id
      prizeData.quantityOffered = amount
      prizeData.quantityRemaining = amount
      prizeData.archived = false
      prizeData.image = { gif: selectedGifId }
      prizeData.title = title

      dispatch(createPrize(prizeData))
      dispatch(getAllPrizes(classroomId_current._id))
    }
  };

  const setTokenAmount = (tokens) => {
    if (tokens < 1) { return }
    setAmount(tokens)
  }


  if (prizeSubmitted && !loading) {
    history.push('/rafflemainteacher')
  }



  if (loading) { return <h1>Loading</h1> }

  return (
    <>
      <GifUI heading="Add Raffle Prize" setTitle={setTitle} selectedGifId={selectedGifId} setSelectedGifId={setSelectedGifId} />

      <form onSubmit={e => e.preventDefault()}>

        {/* <label>Title</label>
        <input
          name="title"
          type="text"
          ref={register({
            required: "You must include a title",
            minLength: {
              value: 2,
              message: "title must have at least 2 characters"
            }
          })}
        />
        {errors.title && <p>{errors.title.message}</p>} */}


        <label>Short description</label>
        <input
          name="description"
          type="text"
          ref={register({
            required: "You must include a description",
            minLength: {
              value: 2,
              message: "Name must have at least 2 characters"
            }
          })}
        />
        {errors.description && <p>{errors.description.message}</p>}


        <label>Quantity to Raffle Off</label>

        <input
          type="number"
          pattern="^-?[0-9]\d*\.?\d*$"
          required
          value={amount}
          onChange={(e) => setTokenAmount(e.target.value)}
        />
        <h1>{" "}</h1>

        <Button
          onClick={handleSubmit(onSubmit)}
          size="huge"
          type="submit"
          inverted color='blue'
        >Save Raffle Item
        </Button>

        <h2>{"   "}</h2>

      </form>
      <h1>{" "}</h1>

    </>
  );
}




