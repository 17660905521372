import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Card, Button } from 'semantic-ui-react'
import { superHeros } from "../../img/avatars/superHeros"
import RenderAvatar from '../common/RenderAvatar';
import { increaseStudentCoinsByTeacher } from "../../redux/teacher/teacherActions"
import { useDispatch } from "react-redux"
import notify from '../../utils/notify'
import useSound from 'use-sound';
import tokenGiven from '../../sound/token_given.mp3'
import magicSpell from '../../sound/magic_spell.mp3'
import tokenRemoved from '../../sound/token_removed.mp3'

const StudentCards = (props) => {
  const [inflateIcon, setInflateIcon] = useState({})
  const [stars, setStars] = useState({})
  const dispatch = useDispatch()
  const [playTokenGiven] = useSound(tokenGiven)
  const [playMagicSpell] = useSound(magicSpell)
  const [playTokenRemoved] = useSound(tokenRemoved)

  const { data: studentInformation, quickPoint, quickDock, showStudentDetails, teacherId, coinName } = props
  const studentOrderedByFirst = _.sortBy(studentInformation, function (stud) { return stud.firstName })

  useEffect(() => {
    const starsObject = {}
    studentInformation.forEach(student => starsObject[student._id] = 0)
    setStars(starsObject)
  }, [])

  // CHANGE TO SPIN!
  const handleTokenGiven = (student) => {
    playTokenGiven()
    quickPoint(student)

    const updatedStars = { ...stars }
    updatedStars[student._id] = updatedStars[student._id] + 1
    setStars(updatedStars)
  }


  const handleTokenTaken = (student) => {
    playTokenRemoved()
    quickDock(student)
    const updatedStars = { ...stars }

    let updatedAmount = updatedStars[student._id] - 1
    updatedAmount = updatedAmount < 0 ? 0 : updatedAmount

    updatedStars[student._id] = updatedAmount
    // updatedStars[student._id] = updatedStars[student._id] - 1
    setStars(updatedStars)
  }

  const awardAll = (allStudents) => {
    playMagicSpell()
    notify({ message: `One ${coinName} has been sent to each student in this class!`, noticeType: "success", autoClose: 3500 })


    const updatedStars = { ...stars }
    const awardArray = allStudents.map(st => st._id)

    awardArray.forEach(student => {
      updatedStars[student] = updatedStars[student] + 1
      dispatch(increaseStudentCoinsByTeacher(student, 1, teacherId))
    })

    setStars(updatedStars)
  }


  const studentCardsDisplay = studentOrderedByFirst.map(student => {
    const tileStars = new Array(stars[student._id]).fill("⭐️")

    return (
      <Card>
        <Card.Content>
          <RenderAvatar
            // size={inflateIcon && inflateIcon[student._id] ? inflateIcon[student._id] : 40}
            size={40}
            avatarId={student.avatar.avatarId}
            setOfAvatars={superHeros}
          />
          <Card.Header>{student.displayName}</Card.Header>
          {/* <Card.Meta>{showStudentDetails ? "did/didn't check-in" : null}</Card.Meta>
          <Card.Description>
            {showStudentDetails ? (<>{student.firstName} is currently feeling great</>) : null}
          </Card.Description> */
          }

          <Card.Description><span role="img">{tileStars}</span>

          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {showStudentDetails ? (<>
            <Button
              onClick={() => handleTokenTaken(student)}
              // onClick={() => quickDock(student)}
              size='small'
              color='grey'
              basic
              content="-"
            />
            {" "}{student.coins || 0}{" "}
          </>) : null}
          <Button
            onClick={showStudentDetails
              ? () => handleTokenGiven(student)
              : () => handleTokenGiven(student)
            }
            size='small'
            color='grey'
            basic
            content="+"
          />
        </Card.Content>
      </Card >
    )
  })

  return (
    <>
      <Card.Group itemsPerRow={4}>
        {studentCardsDisplay}
      </Card.Group>
      <br />
      <Button inverted color='green' size="huge" onClick={() => awardAll(studentInformation)}>Award all {studentInformation.length} students!</Button>
    </>

  )
}

export default StudentCards

