import React, { useEffect, useState } from 'react';
import { getAllCommonGoods } from "../../redux/classroom/classroomActions"
import { useSelector, useDispatch } from "react-redux"
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { SimpleSpinner } from "../common/Spinners"
import CommonGoodsTeacherDisplayBoard from './CommonGoodsTeacherDisplayBoard'
import Lottie from 'react-lottie'
import teamwork from '../../img2/lottie/ui/45218-team-work'


const CommonGoodsTeacherMain = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //const { currentCoin } = props

  const [currentCommonGoods, setCurrentCommonGoods] = useState(null)

  // A
  const {
    loading: profileLoading,
    error: profileError,
    teacherData: { classroomId_current }
  } = useSelector(state => state.teacher)

  // A
  const {
    classroomData: { name },
    commonGoodsAll,
    loading: classroomLoading,
    error: classroomError,
  } = useSelector(state => state.classroom)


  //2
  useEffect(() => {
    classroomId_current && dispatch(getAllCommonGoods(classroomId_current._id))
    setCurrentCommonGoods(commonGoodsAll)
  }, [classroomId_current && classroomId_current._id])


  // Update  when new item added
  useEffect(() => {
    setCurrentCommonGoods(commonGoodsAll)
  }, [commonGoodsAll])



  // const deleteItem = (itemToDelete) => {
  //   // HERE
  //   // TO DO: iterate over all contributors and refund the tokens

  //   dispatch(deleteCommonGood(itemToDelete._id))
  //   // remove item locally and setState
  //   const newItemsList = commonGoodsAll.filter(item => item._id !== itemToDelete._id)


  //   setCurrentCommonGoods(newItemsList)
  // }

  const editItem = (item) => {
    history.push('/commongoodsedit', item)
  }
  const viewItem = (item) => {
    history.push('/commongoodsview', item)
  }

  if (profileLoading || classroomLoading) {
    return <SimpleSpinner />
  }

  if (profileError || classroomError) {
    return <h2>Error loading teacher profile</h2>
  }

  if (!classroomId_current || !classroomId_current?._id) {
    return (
      <>
        <h2>No class has been selected.</h2>
        <Button size="large" onClick={() => history.push('/teacherdashboard')}>Dashboard</Button>
      </>
    )
  }

  return (<>
    <h1>{name && name} Community Donations</h1>
    <Lottie
      width={250}
      height={250}
      options={{
        loop: false,
        autoplay: true,
        animationData: teamwork,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
    />
    <CommonGoodsTeacherDisplayBoard
      viewItem={viewItem}
      editItem={editItem}
      // payUp={payUp}
      currentCommonGoods={currentCommonGoods} />
    <br />

    <Button
      size="huge"
      color="black"
      onClick={() => history.push("/commongoodscreate")}>
      Add New Item</Button>
    <br />
    <br />
    <br />
  </>
  );


}
export default CommonGoodsTeacherMain;

