import React, { useState, useRef, useEffect } from 'react';
import notify from '../../utils/notify'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux"
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import { createUserGL, clearUserGL } from '../../redux/auth/authActions';
import { SimpleSpinner } from "../common/Spinners"
import newUserLogo from '../../img2/lottie/ui/newUser.json'
const style = { "fontFamily": "Passion One", "color": "blue", "fontSize": "3em" }

//--- TODO AUGUST: create DB for "Super" ref only --//
const tempAdminCodes = ["husd2021", "sfusd2021", "oak2021", "mpls2021"]

export default function SignUpWithGoogle(props) {
  const [userType, setUserType] = useState("initial")
  const { handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, user, error: errorMessage } = useSelector(state => state.auth)
  const { state: googleUserProfile } = props.history.location

  // -------- Instead of modal  --------- //
  const [askForCode, setAskForCode] = useState(false)
  const [dataForAdminSignup, setDataForAdminSignup] = useState(null)
  const [accessCodeInput, setAccessCodeInput] = useState({})

  const handleInputChange = (e) => setAccessCodeInput({
    ...accessCodeInput,
    [e.currentTarget.name]: e.currentTarget.value
  })

  useEffect(() => {
    dispatch(clearUserGL())
  }, [])

  useEffect(() => {
    if (user) { goToLogin() }
    // add all log out
  })

  const goToLogin = () => {
    //dispatch(clearUserGL()) why clear?
    history.push('/login')
  }

  const createStudentGoogleAccount = () => {
    setUserType(() => 'student')
  }

  const createTeacherGoogleAccount = () => {
    setUserType(() => 'teacher')
  }

  const createAdminGoogleAccount = () => {
    setUserType(() => 'admin')
  }


  const submitGoogleUserInfo = () => {
    const googleUserProfilePlusUserType = { ...googleUserProfile }
    googleUserProfilePlusUserType.user_type = userType


    if (userType === "admin") {
      // check temporary code
      // AUGUST TODO: set up special DB
      setAskForCode(true)
      setDataForAdminSignup(googleUserProfilePlusUserType)


    } else {
      // teacher or student
      dispatch(createUserGL(googleUserProfilePlusUserType))
    }
  };

  useEffect(() => {
    if (userType !== 'initial') { submitGoogleUserInfo() }
  }, [userType])

  const onSubmitAdmin = (e) => {
    if (tempAdminCodes.includes(accessCodeInput.specialcode)) {
      dispatch(createUserGL(dataForAdminSignup))

    } else {
      setAccessCodeInput({ specialcode: "" })
      notify({ message: "Sorry, that code is note valid. Please try again", position: "TOP_RIGHT", noticeType: "warning" })

    }
  }

  if (loading) { return <SimpleSpinner /> }

  if (askForCode && !errorMessage) {
    return (
      <h2 style={style}>Please enter your special access code
        <form>
          <div>
            <label>Enter 5-10 character code:</label>
            <input
              type="text"
              name="specialcode"
              onChange={handleInputChange}
              value={accessCodeInput.specialcode}
            />
          </div>

          <Button
            onClick={handleSubmit(onSubmitAdmin)}
            size="huge"
            type="submit"
            inverted color='blue'
          >Submit</Button>
        </form>
      </h2>)
  }

  if (userType === 'initial') {
    return (
      <>
        <h1>Sign up for one-click login using your Google account.</h1>

        <Lottie
          width={250}
          height={250}
          options={{
            loop: false,
            autoplay: true,
            animationData: newUserLogo,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />

        <h3>Please select your the type of account:</h3>
        <br />
        <div>
          <Icon size="huge" color="blue" name="star" />
          <Button size="huge" color="blue" onClick={createStudentGoogleAccount}> Student</Button>
        </div>
        <br />
        <br />
        <div>
          <Icon size="huge" color="green" name="star" />
          <Button size="huge" color="green" onClick={createTeacherGoogleAccount}> Teacher</Button>
        </div>
        <br />
        <br />
        <div>
          <Icon size="huge" color="purple" name="star" />
          <Button size="huge" color="purple" onClick={createAdminGoogleAccount}> Admin</Button>
        </div>
      </>
    )
  }

  return (
    <>
      <h1>Nothing to see here</h1>
      <h2>{JSON.stringify(userType)}</h2>
    </>)
}

